import React, { useEffect, useMemo, useState } from 'react';

import { IntegrationPointTypes } from '../../constants/integrationPointTypes';
import promoCampaignTypes from '../../constants/promoCampaignTypes';
import type { IPromoCampaign } from '../../api/types';
import { scrollToTop } from '../../utils/helper';
import { getCategoryListFromCampaignList } from '../../utils/promoCampaignHelper';
import { campaignListToSelectedRecord } from '../../utils/mapper';
import { useAppSettings } from '../../component/AppSettings';
import MainPageLayout from '../../component/MainPageLayout/MainPageLayout';
import PresentCard, { PresentCardSkeleton } from '../../component/PresentCard';
import PresentCardList from '../../component/PresentCardList';
import ChosenItemsPanel from '../../component/ChosenItemsPanel';
import { useAnalyticsQueue, useFooterHeight } from '../../hooks';
import FilterPanel from '../../component/FilterPanel/FilterPanel';
import { usePromoCampaignsQuery } from '../../hooks/services/promoCampaigns';

type Props = {
    goNext: (ids: string[]) => void;
};

const Presents = ({ goNext }: Props) => {
    const { footerChange } = useFooterHeight();
    const { allPresentsSelected, maxPresentsNumber } = useAppSettings();
    const { pushMetrics } = useAnalyticsQueue();
    const [filterList, setFilterList] = useState<number[]>([]);
    const [selectedList, setSelectedList] = useState<Record<string, boolean>>({});
    const selectedItemsCount = useMemo(
        () => Object.values(selectedList).filter((selected) => selected).length,
        [selectedList]
    );
    const {
        data: promoCampaigns,
        isFetching,
        error,
    } = usePromoCampaignsQuery({
        integrationPoint: IntegrationPointTypes.PRESENTS,
        type: promoCampaignTypes.PRESENT,
    });

    const categoryList = useMemo(
        () => (promoCampaigns ? getCategoryListFromCampaignList(promoCampaigns) : []),
        [promoCampaigns]
    );
    const filteredList = useMemo(() => {
        if (!promoCampaigns) {
            return [];
        }

        return filterList.length === 0
            ? promoCampaigns
            : promoCampaigns.filter(
                  (promoCampaign) =>
                      promoCampaign.categoryList.filter((category) => filterList.includes(category.categoryId)).length >
                          0 || selectedList[promoCampaign.id]
              );
    }, [filterList, promoCampaigns, selectedList]);

    useEffect(() => {
        pushMetrics('PRESENT_MAIN_SHOW');
        scrollToTop();
    }, []);

    useEffect(() => {
        if (promoCampaigns && !maxPresentsNumber && allPresentsSelected) {
            setSelectedList(campaignListToSelectedRecord(promoCampaigns));
        }
    }, [promoCampaigns, maxPresentsNumber, allPresentsSelected]);

    useEffect(() => footerChange(), [selectedItemsCount]);

    const handleClick = ({
        id: campaignId,
        name: campaignName,
        dzoName,
        originalId,
        productCampaignId,
    }: IPromoCampaign) => {
        const selected = selectedList[campaignId];
        if (selected || !maxPresentsNumber || selectedItemsCount < maxPresentsNumber) {
            const eventAction = selected ? 'PRESENT_MAIN_REMOVE_OFFER_CLICK' : 'PRESENT_MAIN_CHOICE_OFFER_CLICK';
            pushMetrics(eventAction, { campaignId: originalId, campaignName, dzoName, productCampaignId });
            setSelectedList((prev) => ({ ...prev, [campaignId]: !selected }));
        }
    };

    const handleDetailClick = ({ name: campaignName, dzoName, originalId, productCampaignId }: IPromoCampaign) => {
        pushMetrics('MAIN_DETAIL_CLICK', { campaignId: originalId, campaignName, dzoName, productCampaignId });
    };

    const onReset = () => {
        pushMetrics('PRESENT_MAIN_REMOVE_OFFERS_CLICK');
        setSelectedList({});
    };

    const goToOffers = () => {
        if (!selectedItemsCount) {
            return;
        }

        const idList = promoCampaigns!
            .filter(({ id }) => selectedList[id])
            .map(({ id: campaignId, name: campaignName, dzoName, originalId, productCampaignId }) => {
                pushMetrics('PRESENT_MAIN_GET_CLICK', {
                    campaignId: originalId,
                    campaignName,
                    dzoName,
                    productCampaignId,
                });
                return campaignId;
            });

        goNext(idList);
    };

    return (
        <MainPageLayout
            footer={!!selectedItemsCount && <ChosenItemsPanel onClick={goToOffers} onReset={onReset} />}
            filterPanel={
                <FilterPanel categoryList={categoryList} filterList={filterList} setFilterList={setFilterList} />
            }
            cardList={
                <PresentCardList>
                    {!isFetching && !error ? (
                        filteredList.map((item) => (
                            <PresentCard
                                key={item.id}
                                campaign={item}
                                handleClick={handleClick}
                                handleDetailClick={handleDetailClick}
                                selected={selectedList[item.id]}
                            />
                        ))
                    ) : (
                        <>
                            <PresentCardSkeleton />
                            <PresentCardSkeleton />
                            <PresentCardSkeleton />
                        </>
                    )}
                </PresentCardList>
            }
            errorData={error}
        />
    );
};

export default Presents;
