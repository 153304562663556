import { publicRequest } from '../axios';

export type LocationItem = {
    id: number;
    name: string;
    parentName: string;
};

export type LocationItemListResponse = {
    list: LocationItem[];
};

export async function getLocationsByText(value: string): Promise<LocationItemListResponse> {
    try {
        const { data } = await publicRequest.get<LocationItemListResponse>(`/location/search?name=${value}`);

        return data;
    } catch (e) {
        return { list: [] };
    }
}
