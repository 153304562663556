import { useLocation, matchPath } from 'react-router-dom';
import { APP_ROUTE, PAGES } from '../constants/route';
import { APP_MECHANICS_VALUES } from '../constants/appMechanics';

const { PRODUCTS, PRESENTS, BUNDLES, MIX, SAPSAN } = PAGES;

type Mechanics = typeof PRODUCTS | typeof PRESENTS | typeof BUNDLES | typeof MIX | typeof SAPSAN;

export const getActiveMechanics = (pathname: string) => {
    const match = matchPath(
        {
            path: `${APP_ROUTE.GENERAL}/:mechanics`,
            end: false,
        },
        pathname
    );

    const mechanics = match?.params.mechanics;

    if (!mechanics || ![PRODUCTS, PRESENTS, BUNDLES, MIX, SAPSAN].includes(mechanics as Mechanics)) {
        return '';
    }

    return APP_MECHANICS_VALUES[mechanics.toUpperCase() as keyof typeof APP_MECHANICS_VALUES];
};

export const useActiveMechanics = () => {
    const { pathname } = useLocation();
    return getActiveMechanics(pathname);
};
