import type { ReactElement, ReactNode } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import throttle from 'lodash/throttle';

import MainHeader from '../../containers/MainHeader';
import Content from '../Content';
import type { HeaderProps } from '../Header';
import { HeaderBackgroundColor } from '../Header';
import type { FilterTagsProps } from '../FilterTags';
import { Footer } from '../Footer';
import { useAppSettings } from '../AppSettings';
import { useFooterHeight } from '../../hooks';
import { useClient } from '../Client';
import { useUser } from '../User';
import type { IBaseResponse } from '../../api/types';
import { Authority } from '../../api/services/auth';
import { RequestStatusTypes } from '../../constants/RequestStatusTypes';
import CatLayoutBody from '../CatLayout/CatLayoutBody';

import styles from './MainPageLayout.module.scss';

export type MainPageLayoutProps = {
    filterPanel?: ReactElement<FilterTagsProps>;
    cardList: ReactElement;
    footer?: ReactNode;
    errorData?: IBaseResponse | null;
};

/**
 * SCROLLING_THRESHOLD - минимальный оцениваемый размер прокрутки в px,
 * служит для исключения реагирования на случайные действия.
 * MIN_SCROLL_Y - минимальный размер прокрутки в px после которого
 * при скролле вверх будет появляться панель навигации
 */
const SCROLLING_THRESHOLD = 50;
const MIN_SCROLL_Y = 300;

const MainPageLayout = ({ filterPanel, footer, cardList, errorData }: MainPageLayoutProps) => {
    const nodeRef = useRef<HTMLDivElement>(null);
    const headerNodeRef = useRef<HTMLDivElement>(null);
    const { client } = useClient();
    const { role } = useUser();
    const { hasOnlyOneMechanic } = useAppSettings();
    const { footerChange } = useFooterHeight();
    const [showHeader, setShowHeader] = useState(false);

    useEffect(() => {
        let isMounted = true;
        let previousScrollY = window.pageYOffset || document.documentElement.scrollTop;

        const scrolledMoreThanThreshold = (currentScrollY: number) =>
            Math.abs(currentScrollY - previousScrollY) > SCROLLING_THRESHOLD;

        const onScroll = throttle(() => {
            const currentScrollY = window.pageYOffset || document.documentElement.scrollTop;
            if (isMounted && scrolledMoreThanThreshold(currentScrollY)) {
                const isScrollingUp = currentScrollY < previousScrollY;
                setShowHeader(isScrollingUp && currentScrollY > MIN_SCROLL_Y);
                previousScrollY = currentScrollY > 0 ? currentScrollY : 0;
            }
        }, 100);

        window.addEventListener('scroll', onScroll);

        return () => {
            isMounted = false;
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    const headerProps: HeaderProps = { position: 'static', backgroundColor: HeaderBackgroundColor.WHITE, shadow: true };

    return (
        <div className={styles.page}>
            {((client && !client.isTechnical) || role !== Authority.ReferralLink || !hasOnlyOneMechanic) && (
                <CSSTransition
                    in={showHeader}
                    nodeRef={headerNodeRef}
                    timeout={150}
                    mountOnEnter={true}
                    classNames={{
                        enter: styles.headerEnter,
                        enterActive: styles.headerEnterActive,
                        exit: styles.headerExit,
                        exitActive: styles.headerExitActive,
                        exitDone: styles.headerExitDone,
                    }}
                >
                    <div className={styles.header} ref={headerNodeRef}>
                        <MainHeader {...headerProps} isDuplicate />
                    </div>
                </CSSTransition>
            )}
            <MainHeader {...headerProps} />
            {!errorData ? (
                <Content filterPanel={filterPanel} cardList={cardList} showTopPanel />
            ) : (
                <div className={styles.errorDataWrapper}>
                    <CatLayoutBody
                        type={RequestStatusTypes.NOT_FOUND}
                        title={errorData.message}
                        description={errorData.description}
                        requestId={errorData.requestId}
                    />
                </div>
            )}
            <Footer>
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        key={footer ? 'footer' : 'personal'}
                        timeout={150}
                        classNames={{
                            enter: styles.fadeEnter,
                            enterActive: styles.fadeEnterActive,
                            exit: styles.fadeExit,
                            exitActive: styles.fadeExitActive,
                        }}
                        onEntered={footerChange}
                        nodeRef={nodeRef}
                    >
                        <div ref={nodeRef}>{footer}</div>
                    </CSSTransition>
                </SwitchTransition>
            </Footer>
        </div>
    );
};

export default MainPageLayout;
