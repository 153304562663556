const stripCountryCode = (input: string): string => input.replace(/^(\+7|7|8)/, '');

const splitIntoFormattedParts = (input: string, partLengths: number[]): string[] => {
    let remainingInput: string = input;
    const formattedParts: string[] = [];

    partLengths.forEach((length) => {
        if (remainingInput.length > 0) {
            const part = remainingInput.slice(0, length);
            remainingInput = remainingInput.slice(length);
            formattedParts.push(part);
        }
    });

    return formattedParts;
};

export const formatPhoneNumber = (inputValue: string): string => {
    const countryCode = '+7';
    const areaCodeLength = 3;
    const firstPartLength = 3;
    const secondPartLength = 2;
    const thirdPartLength = 2;

    const formattedParts = splitIntoFormattedParts(stripCountryCode(inputValue), [
        areaCodeLength,
        firstPartLength,
        secondPartLength,
        thirdPartLength,
    ]);

    return formattedParts.length > 0
        ? `${countryCode} (${formattedParts[0]}${formattedParts[1] ? ') ' : ''}${formattedParts.slice(1).join('-')}`
        : countryCode;
};
