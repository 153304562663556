import { authenticatedRequest, publicRequest } from '../axios';
import type { IBaseResponse, IPromoCampaign } from '../types';
import { APP_CODE_HEADER } from '../../constants/common';
import type { ClientInfo } from '../../component/Client/types';
import type { FeedbackQuestionDto } from '../types';

type saveFeedbackRequest = {
    clientId?: ClientInfo['id'];
    feedbackQuestionId: FeedbackQuestionDto['id'];
    rating: number;
    clientFeedback?: string;
    campaignId?: IPromoCampaign['id'];
    campaignName?: IPromoCampaign['name'];
};

export function saveFeedback(data: saveFeedbackRequest, appCode: string) {
    return publicRequest.post<IBaseResponse>('/feedback/add', data, {
        headers: {
            [APP_CODE_HEADER]: appCode,
        },
    });
}

type GetFeedbackQuestionsResponse = IBaseResponse & {
    list: FeedbackQuestionDto[];
};

export async function getFeedbackQuestions() {
    const {
        data: { list },
    } = await authenticatedRequest.get<GetFeedbackQuestionsResponse>('feedback/questions');

    return list;
}
