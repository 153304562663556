import React from 'react';
import type { UseControllerProps } from 'react-hook-form';
import { useController, useWatch } from 'react-hook-form';

import type { PrivacyPolicyProps } from '../../../../component/Consent/PrivacyPolicy';
import PrivacyPolicy from '../../../../component/Consent/PrivacyPolicy';
import type { FormValues } from '../types';

type Props = Pick<PrivacyPolicyProps, 'privacyEventAction' | 'smmrEventAction' | 'promoCampaign'> &
    Omit<UseControllerProps<FormValues>, 'name'>;

const PrivacyPolicyField = ({ privacyEventAction, smmrEventAction, promoCampaign }: Props) => {
    const {
        field,
        fieldState: { invalid },
    } = useController({
        name: 'agreeWithConsent',
        rules: { required: true },
    });

    const phoneNumber = useWatch({
        name: 'phoneNumber',
    });

    return (
        <PrivacyPolicy
            privacyEventAction={privacyEventAction}
            smmrEventAction={smmrEventAction}
            promoCampaign={promoCampaign}
            phoneNumber={phoneNumber}
            {...field}
            error={invalid}
        />
    );
};

export default PrivacyPolicyField;
