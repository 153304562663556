import React from 'react';
import type { UseControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';

import { OfferTermsConsent } from '../Consent/OfferTermsConsent';
import type { IPromoCampaign } from '../../api/types';

type Props = Omit<UseControllerProps<{ agreeWithOfferTerms: boolean }>, 'name'> & {
    promoCampaign: IPromoCampaign;
};

const OfferTermsConsentField = ({ promoCampaign }: Props) => {
    const {
        field,
        fieldState: { invalid },
    } = useController({
        defaultValue: false,
        name: 'agreeWithOfferTerms',
        rules: { required: true },
    });

    return <OfferTermsConsent {...field} promoCampaign={promoCampaign} error={invalid} />;
};

export default OfferTermsConsentField;
