import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMount } from 'react-use';

import {
    SMMR_CONSENT_ID,
    CONSENT_ID,
    SBER_ID_CODE,
    SBER_ID_LOCAL_PHONE_NUMBER,
    SBER_ID_LOCAL_STATE,
    SBER_ID_STATE,
} from '../../constants/queryParams';
import PageAccessDenied from '../../containers/Errors/PageAccessDenied';
import { SBER_ID_REDIRECT_URL } from '../../constants/sberId';
import getQueryStringFromObj from '../../utils/getQueryStringFromObj';
import { generateClientAuthPath } from '../../utils/generatePathHelper';
import { useGetSberIdClientInfo } from '../../hooks';
import type { SberIdClientInfoError } from '../../api/services/sberIdService';

const getPathnameWithConsentId = (path: string) => {
    const url = new URL(path, window.location.origin);
    const consentId = url.searchParams.get(CONSENT_ID) || '';
    const smmrConsentId = url.searchParams.get(SMMR_CONSENT_ID) || '';

    url.searchParams.delete(CONSENT_ID);
    url.searchParams.delete(SMMR_CONSENT_ID);

    return { pathname: `${url.pathname}${url.search}`, consentId, smmrConsentId };
};

const useErrorBoundary = (error: SberIdClientInfoError) => error.statusCode !== 200;

const AuthSberId = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const sberIdCode = searchParams.get(SBER_ID_CODE) || '';
    const sberIdState = searchParams.get(SBER_ID_STATE) || '';
    const { mutate: getSberIdClientInfo, error } = useGetSberIdClientInfo(useErrorBoundary);

    useMount(() => {
        getSberIdClientInfo(
            {
                state: sberIdState,
                code: sberIdCode,
                redirectUri: SBER_ID_REDIRECT_URL,
            },
            {
                onSuccess: ({ user: { appCode, path }, phoneNumber }, { state }) => {
                    const { consentId, smmrConsentId, pathname } = getPathnameWithConsentId(path);

                    navigate(
                        {
                            pathname: generateClientAuthPath(appCode),
                            search: getQueryStringFromObj({
                                to: pathname,
                                [SBER_ID_LOCAL_PHONE_NUMBER]: phoneNumber,
                                [SBER_ID_LOCAL_STATE]: state,
                                [CONSENT_ID]: consentId,
                                [SMMR_CONSENT_ID]: smmrConsentId,
                            }),
                        },
                        { replace: true }
                    );
                },
            }
        );
    });

    if (error) {
        const {
            user: { path },
            message,
            description,
        } = error;

        return (
            <PageAccessDenied
                header={message}
                message={description}
                onClick={() => navigate(getPathnameWithConsentId(path).pathname, { replace: true })}
                buttonLabel="Продолжить"
            />
        );
    }

    return null;
};

export default AuthSberId;
