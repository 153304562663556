import type { ReactElement, ReactNode } from 'react';
import type { CSSProperties } from 'react';
import React, { Children, cloneElement, useLayoutEffect, useRef, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useEvent } from 'react-use';
import cn from 'classnames';

import { TEST_ID_ADVERTISING_TOOLTIP } from './constants';

import styles from './Tooltip.module.scss';

type Props = {
    onTooltipClick?: () => void;
    children: ReactElement;
    title: ReactNode;
};

type TooltipStyle = {
    top?: CSSProperties['top'];
    right?: CSSProperties['right'];
    left?: CSSProperties['left'];
    verticalPosition?: 'top' | 'bottom';
    horizontalPosition?: 'right' | 'left';
};

function getTooltipStyle(content: HTMLElement, tooltip: HTMLElement) {
    const contentRect = content.getBoundingClientRect();
    const tooltipRect = tooltip.getBoundingClientRect();
    const vw = document.body.clientWidth;
    const vh = window.innerHeight;
    const nextToolTipStyle: TooltipStyle = {};

    if (contentRect.top + contentRect.height / 2 < vh / 2) {
        nextToolTipStyle.top = contentRect.bottom + 6;
        nextToolTipStyle.verticalPosition = 'bottom';
    } else {
        nextToolTipStyle.top = contentRect.top - tooltipRect.height - 6;
        nextToolTipStyle.verticalPosition = 'top';
    }

    if (contentRect.left + contentRect.width / 2 <= vw / 2) {
        nextToolTipStyle.left = contentRect.left;
        nextToolTipStyle.horizontalPosition = 'right';
    } else {
        nextToolTipStyle.right = vw - contentRect.right;
        nextToolTipStyle.horizontalPosition = 'left';
    }

    return nextToolTipStyle;
}

const Tooltip = ({ children, title }: Props) => {
    const contentRef = useRef<HTMLElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [tooltipStyle, setTooltipStyle] = useState<TooltipStyle>({});
    const [isShowTooltip, setIsShowTooltip] = useState(false);

    const onChangeTooltipStyle = useCallback(() => {
        if (!isShowTooltip || !contentRef.current || !tooltipRef.current) {
            setTooltipStyle({});
            return;
        }

        setTooltipStyle(getTooltipStyle(contentRef.current, tooltipRef.current));
    }, [isShowTooltip]);

    const onClick = useCallback((event: Event) => {
        if (!contentRef.current) {
            return;
        }

        const content = contentRef.current;

        if (content.contains(event.target as Node | null)) {
            setIsShowTooltip((prevState) => !prevState);
            return;
        }

        if (!tooltipRef.current) {
            return;
        }

        const tooltip = tooltipRef.current;

        if (!content.contains(event.target as Node | null) && !tooltip.contains(event.target as Node | null)) {
            setIsShowTooltip(false);
        }
    }, []);

    const handler = isShowTooltip ? onChangeTooltipStyle : null;
    useEvent('scroll', handler);
    useEvent('resize', handler);
    useEvent('click', onClick, window, { capture: true });

    useLayoutEffect(() => {
        onChangeTooltipStyle();
    }, [onChangeTooltipStyle]);

    const { verticalPosition, horizontalPosition, ...style } = tooltipStyle;

    return (
        <>
            {cloneElement(Children.only(children), {
                ref: contentRef,
            })}
            {isShowTooltip &&
                createPortal(
                    <div
                        className={cn(styles.tooltip, styles[`tooltip-${verticalPosition}-${horizontalPosition}`])}
                        style={style}
                        ref={tooltipRef}
                        data-test-id={TEST_ID_ADVERTISING_TOOLTIP}
                    >
                        {title}
                    </div>,
                    document.body
                )}
        </>
    );
};

export default Tooltip;
