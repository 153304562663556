import type { ComponentProps } from 'react';
import React, { useContext } from 'react';

import { ModalTypes, OPEN_MODAL_ACTION } from '../../constants/modal';
import { RULES_MODAL_TITLE } from '../../constants/common';
import { ModalCtx } from '../../reducer/modal';
import { ADVERTISING_ERID_PREFIX, ADVERTISING_RULES_LABEL, ADVERTISING_TRADE_NAME_PREFIX } from './constants';
import Tooltip from './Tooltip';
import Tag from './Tag';

import styles from './AdvertisingTag.module.scss';

export type AdvertisingTagProps = Pick<ComponentProps<typeof Tag>, 'tagPosition' | 'onClick'> & {
    tradeName?: string | null;
    erid?: string;
    rules?: string;
};

/** Необходимо разместить внутри родителя с position: relative  */
const AdvertisingTag = ({ tradeName, erid, rules, tagPosition, onClick }: AdvertisingTagProps) => {
    const { dispatch } = useContext(ModalCtx);

    if (!tradeName && !erid && !rules) {
        return null;
    }

    const onRulesClick = () => {
        dispatch({
            type: OPEN_MODAL_ACTION,
            payload: {
                modalType: ModalTypes.PROMOTION_RULES_MODAL,
                modalTitle: RULES_MODAL_TITLE,
                modalMessage: rules,
            },
        });
    };

    return (
        <div className={styles.advertisingTag}>
            <Tooltip
                title={
                    <p className={styles.advertisingTagTitle}>
                        {tradeName && `${ADVERTISING_TRADE_NAME_PREFIX}${tradeName}`}
                        {tradeName && erid && <br />}
                        {erid && `${ADVERTISING_ERID_PREFIX}${erid}`}
                        {(tradeName || erid) && rules && (
                            <>
                                <br />
                                <br />
                            </>
                        )}
                        {rules && (
                            <span className={styles.rules} onClick={onRulesClick}>
                                {ADVERTISING_RULES_LABEL}
                            </span>
                        )}
                    </p>
                }
            >
                <Tag tagPosition={tagPosition} onClick={onClick} />
            </Tooltip>
        </div>
    );
};

export default AdvertisingTag;
