import React from 'react';
import { useWatch } from 'react-hook-form';

import type { ButtonSizes } from '../../Button';
import SubmitButton from '../../SubmitButton';
import SuccessPageModalBody from '../../SuccessPageModalBody';
import type { FormValues } from '../types';
import FeedbackCommentInput from './FeedbackCommentInput';
import RatingInput from './RatingInput';

import styles from './FeedbackFormModalBody.module.scss';

interface RatingModalProps {
    question: string;
    buttonSize: ButtonSizes;
    onCancel: () => void;
}

export const TEST_ID_FEEDBACK_FORM = 'feedback-form';
export const TEST_ID_FEEDBACK_FORM_SUBMIT_BTN = 'feedback-form-submit-button';

const FeedbackFormModalBody = ({ question, buttonSize, onCancel }: RatingModalProps) => {
    const rating = useWatch<FormValues, 'rating'>({
        name: 'rating',
    });

    return (
        <SuccessPageModalBody
            onCloseButtonClick={onCancel}
            dataTestId={TEST_ID_FEEDBACK_FORM}
            renderContent={() => (
                <div className={styles.content}>
                    <p className={styles.header}>{question}</p>
                    <RatingInput />
                    {!rating && <div className={styles.extraSpaceForMobile} />}
                    {!!rating && (
                        <div className={styles.appearingBlock}>
                            <FeedbackCommentInput />
                        </div>
                    )}
                </div>
            )}
            renderButtons={
                rating
                    ? () => (
                          <SubmitButton
                              label="Отправить"
                              size={buttonSize}
                              fullWidth
                              dataTestId={TEST_ID_FEEDBACK_FORM_SUBMIT_BTN}
                          />
                      )
                    : undefined
            }
        />
    );
};

export default FeedbackFormModalBody;
