import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { noop } from '../../../utils/helper';
import useDevices from '../../../hooks/useDevices';
import { useAppSettings } from '../../../component/AppSettings';
import type { ButtonSizes } from '../../../component/Button';
import SubmitButton from '../../../component/SubmitButton';
import { useLoginClient } from '../../../hooks';
import type { FormValues, Submit } from './types';
import PhoneNumberField from './PhoneNumberField';
import PrivacyPolicyField from './PrivacyPolicyField';
import ErrorMessageField from './ErrorMessageField';

import styles from './common.module.scss';

type Props = {
    onSubmit?: (clientId: number) => Promise<unknown>;
    onMount?: () => void;
    className?: string;
    children?: ReactNode;
};

const GiftClientAuthForm = ({ onMount = noop, onSubmit, className, children }: Props) => {
    const methods = useForm<FormValues>({
        defaultValues: {
            phoneNumber: '',
            agreeWithConsent: false,
        },
        mode: 'onSubmit',
    });

    const {
        handleSubmit,
        formState: { dirtyFields },
    } = methods;

    const { mutateAsync: loginClient, error } = useLoginClient('IDENTIFICATION_FRIEND_OFFER_GET_CLICK');
    const { consentId, smmrConsentId } = useAppSettings();
    const [phoneNumberChanged, setPhoneNumberChanged] = useState(false);
    const { isMobile } = useDevices();
    const buttonSize: ButtonSizes = !isMobile ? 'x-large' : 'large';

    useEffect(() => {
        if (phoneNumberChanged) {
            return;
        }

        if (dirtyFields.phoneNumber) {
            setPhoneNumberChanged(true);
        }
    }, [dirtyFields.phoneNumber, phoneNumberChanged]);

    useEffect(onMount, []);

    const submit: Submit = async ({ phoneNumber }) => {
        const { clientId } = await loginClient({ phoneNumber, consentId, smmrConsentId });
        await onSubmit?.(clientId);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submit)} className={className}>
                {children}
                <div className={styles.clientOfferField}>
                    <PhoneNumberField />
                </div>
                <div className={styles.clientOfferField}>
                    <PrivacyPolicyField
                        privacyEventAction="IDENTIFICATION_FRIEND_OFFER_POLICY_CLICK"
                        smmrEventAction="IDENTIFICATION_FRIEND_OFFER_SMMR_CLICK"
                    />
                </div>
                <ErrorMessageField error={error?.message} />
                <div className={styles.buttonsBlock}>
                    <SubmitButton label="Войти" type="contained" size={buttonSize} fullWidth />
                </div>
            </form>
        </FormProvider>
    );
};

export default GiftClientAuthForm;
