import React from 'react';

import Button from '../Button';
import { ReactComponent as EmailIcon } from '../../static/svgs/letter-envelope.svg';

import styles from './EmailSendPanel.module.scss';

type EmailSendPanelProps = {
    onClick: () => void;
};

export const EMAIL_SEND_PANEL_HEADER = 'Сохраните предложение';
export const EMAIL_SEND_PANEL_BUTTON_LABEL = 'Отправить на email';
export const EMAIL_SEND_PANEL_DESCRIPTION = 'Мы отправим вам на email, чтобы можно было воспользоваться потом';
export const DATA_TEST_ID_EMAIL_SEND_PANEL = 'send-email-panel';

const EmailSendPanel = ({ onClick }: EmailSendPanelProps) => (
    <div onClick={onClick} className={styles.panel} data-test-id={DATA_TEST_ID_EMAIL_SEND_PANEL}>
        <div className={styles.body}>
            <div className={styles.content}>
                <div className={styles.imageWrapper}>
                    <EmailIcon className={styles.image} />
                </div>
                <div className={styles.textsBlock}>
                    <p className={styles.header}>{EMAIL_SEND_PANEL_HEADER}</p>
                    <p className={styles.description}>{EMAIL_SEND_PANEL_DESCRIPTION}</p>
                </div>
            </div>
            <Button
                type="contained"
                className={styles.button}
                dataTestId={DATA_TEST_ID_EMAIL_SEND_PANEL + '-pseudo-button'}
            >
                {EMAIL_SEND_PANEL_BUTTON_LABEL}
            </Button>
        </div>
    </div>
);

export default EmailSendPanel;
