import React from 'react';
import { useParams } from 'react-router-dom';

import { ClientProvider } from '../../component/Client';
import Console from '../../pages/console';
import { AppSettingsCtx } from '../../component/AppSettings';
import { useAppSettingsQuery } from '../../hooks';
import { useAppInterceptor } from '../../hooks/services/apps';
import { useUser } from '../../component/User';

type Params = {
    appCode: string;
};

const App = () => {
    const user = useUser();
    const { appCode = '' } = useParams<Params>();
    useAppInterceptor(appCode);

    const { data, isFetching } = useAppSettingsQuery(appCode, user.role);

    if (!data || isFetching) {
        return null;
    }

    return (
        <AppSettingsCtx.Provider value={data}>
            <ClientProvider>
                <Console />
            </ClientProvider>
        </AppSettingsCtx.Provider>
    );
};

export default App;
