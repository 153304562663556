import React from 'react';

import { useDevices } from '../../hooks';
import FormContainer from '../FormContainer';
import CorpLogo from '../CorpLogo';
import ExitButton from '../ExitButton';
import Header from '../Header';
import type { ErrorScreenProps } from './types';
import Themes from '../../constants/Themes';
import CatLayoutBody from './CatLayoutBody';

import styles from './CatLayout.module.scss';

const CatLayout = ({ type, title, description, requestId, onClick, label }: ErrorScreenProps) => {
    const { isDesktop } = useDevices();
    const theme = isDesktop ? Themes.LIGHT : Themes.DARK;

    return (
        <FormContainer
            contentClassName={styles.wrapper}
            renderHeader={() => (
                <Header
                    theme={theme}
                    renderLeft={() => <CorpLogo theme={theme} />}
                    renderRight={() => <ExitButton />}
                />
            )}
        >
            <CatLayoutBody
                type={type}
                title={title}
                description={description}
                requestId={requestId}
                onClick={onClick}
                label={label}
            />
        </FormContainer>
    );
};

export default CatLayout;
