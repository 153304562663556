import React, { useCallback } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import NavItem from './NavItem';
import type { NavItemRouteProps } from './types';

const NavItemRoute = ({ to, children, onClick, ...rest }: NavItemRouteProps) => {
    const navigate = useNavigate();
    const match = useMatch(to);

    const onNavClick = useCallback(() => {
        if (onClick) {
            onClick();
        }

        navigate(to);
    }, [to, onClick]);

    return (
        <NavItem {...rest} active={!!match} onClick={onNavClick}>
            {children}
        </NavItem>
    );
};

export default NavItemRoute;
