import type { InputHTMLAttributes, MouseEvent } from 'react';
import React, { forwardRef, useRef } from 'react';
import cn from 'classnames';

import useForkRef from '../../hooks/useForkRef';
import { ReactComponent as Cross } from '../../static/svgs/cross.svg';

import styles from './BaseInput.module.scss';

export type BaseInputProps = {
    label?: string;
    inputClassName?: string;
    error?: boolean | string;
    errorMsg?: string;
    focused?: boolean;
    onClear?: () => void;
    fullWidth?: boolean;
    adaptive?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
    (
        {
            value,
            name,
            focused,
            label,
            inputClassName,
            onClear,
            error,
            errorMsg,
            disabled,
            className,
            fullWidth,
            adaptive = true,
            readOnly,
            ...restProps
        },
        ref
    ) => {
        const inputRef = useRef<HTMLInputElement>(null);
        const handleInputRef = useForkRef(ref, inputRef);

        const handleMouseDown = (e: MouseEvent<HTMLElement>) => {
            if (inputRef.current && e.target !== inputRef.current) {
                e.preventDefault();
                inputRef.current.focus();
            }
        };

        const handleClear = () => {
            onClear?.();
        };

        const fieldClasses = cn(styles.field, className, {
            [styles.fieldActive]: focused,
            [styles.fieldError]: error,
            [styles.fieldFullWidth]: fullWidth,
        });

        const labelClasses = cn(styles.label, {
            [styles.labelActive]: focused || value,
        });

        const inputClasses = cn(styles.input, { [styles.inputWithLabel]: !!label }, inputClassName, {
            [styles.inputDisabled]: disabled,
        });

        const isShowClearIcon = onClear && (value || value === 0) && !readOnly && !disabled;

        return (
            <>
                <div
                    className={cn(fieldClasses, {
                        [styles.scaled]: !adaptive,
                    })}
                    onMouseDown={handleMouseDown}
                >
                    {readOnly ? (
                        <span className={inputClasses} ref={handleInputRef} data-test-id={`form-control-input-${name}`}>
                            {value}
                        </span>
                    ) : (
                        <input
                            value={value}
                            name={name}
                            disabled={disabled}
                            ref={handleInputRef}
                            className={inputClasses}
                            data-test-id={`form-control-input-${name}`}
                            {...restProps}
                        />
                    )}
                    {isShowClearIcon && (
                        <div
                            className={styles.clearIcon}
                            onMouseDown={handleClear}
                            data-test-id={`form-control-clean-${name}`}
                        >
                            <Cross />
                        </div>
                    )}
                    {label && (
                        <span data-test-id={`form-control-label-${name}`} className={labelClasses}>
                            {label}
                        </span>
                    )}
                </div>
                {error && errorMsg && <p className={styles.errorMsg}>{errorMsg}</p>}
            </>
        );
    }
);

BaseInput.displayName = 'BaseInput';

export default BaseInput;
