import React from 'react';
import cn from 'classnames';

import type { LineProps } from './types';

import styles from './Line.module.css';

const Line = ({ color = 'black8' }: LineProps) => <hr className={cn(styles.hr, color)} />;

export default Line;
