export function copyToClipboard(value: string, postCopyCallback?: () => void) {
    try {
        const fakeElement = document.createElement('textarea');
        // Reset box model
        fakeElement.style.border = '0';
        fakeElement.style.padding = '0';
        fakeElement.style.margin = '0';
        // // Move element out of screen horizontally
        fakeElement.style.position = 'absolute';
        fakeElement.style.left = '-9999px';
        // Move element to the same position vertically
        const yPosition = window.pageYOffset || document.documentElement.scrollTop;
        fakeElement.style.top = `${yPosition}px`;

        fakeElement.setAttribute('readonly', '');
        fakeElement.value = value;
        document.body.appendChild(fakeElement);
        fakeElement.select();
        document.execCommand('copy');
        document.body.removeChild(fakeElement);
        postCopyCallback && postCopyCallback();
    } catch (e) {
        console.error(`copy to clipboard failed. ${e}`);
    }
}
