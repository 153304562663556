import React from 'react';
import cn from 'classnames';

import styles from './DotsLoader.module.scss';

type Props = {
    adaptive?: boolean;
    narrow?: boolean;
    color?: 'white' | 'black';
};

const DotsLoader = ({ adaptive = true, color = 'white', narrow = false }: Props) => (
    <div
        className={cn(styles.dotLoader, { [styles[color]]: true, [styles.scaled]: !adaptive, [styles.narrow]: narrow })}
        data-test-id="dots-loader"
    >
        <div />
        <div />
        {!narrow && <div />}
    </div>
);

export default DotsLoader;
