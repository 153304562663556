import type { IPromoCampaignDto, IPromoCampaignListResponse } from '../types';
import type PromoCampaignTypes from '../../constants/promoCampaignTypes';
import type { IntegrationPointTypes } from '../../constants/integrationPointTypes';
import { authenticatedRequest } from '../axios';

export type GetAllPromoCampaignsOptions = {
    integrationPoint: IntegrationPointTypes;
    type?: PromoCampaignTypes;
    campaigns?: string[];
    clientId?: number;
    externalId?: string;
};

export async function getAllPromoCampaigns({
    type,
    campaigns = [],
    clientId,
    externalId,
    integrationPoint,
}: GetAllPromoCampaignsOptions): Promise<IPromoCampaignDto[]> {
    const {
        data: { promoCampaignDtoList },
    } = await authenticatedRequest.post<IPromoCampaignListResponse>('/promo-campaign/list/filter', {
        active: true,
        type,
        integrationPoint,
        campaigns,
        excludeGroupedOnly: true,
        clientId,
        externalId,
        campaignSettings: {},
    });

    return promoCampaignDtoList;
}
