import type { FeedbackScreenType } from '../../constants/FeedbackScreenTypes';
import type { FeedbackEvents } from './types';

export function normalizeFeedbackComment(value: string) {
    const patternForNormalizingQuotes = /(['"`“”«»])/g;
    const patternForCollapsingMultipleSpaces = /\s\s+/g;
    const patternForRequestOnBackend = /[^A-ZА-ЯЁ\d\s.,_/:%()?!№"«»-]/gi;
    const patternForCollapsingMultipleSpecialSymbols = /([^A-ZА-ЯЁ\d])(?=\1)/gi;

    const normalizedValue = value
        .trim()
        .replace(patternForNormalizingQuotes, '-')
        .replace(patternForCollapsingMultipleSpaces, ' ')
        .replace(patternForRequestOnBackend, '_')
        .replace(patternForCollapsingMultipleSpecialSymbols, '');

    return normalizedValue ? normalizedValue : undefined;
}

export function getFeedbackEventsForScreen(screenType: FeedbackScreenType): FeedbackEvents {
    return {
        eventForFeedbackBannerShow: `${screenType}_FEEDBACK_BANNER_SHOW`,
        eventForFeedbackBannerClick: `${screenType}_FEEDBACK_BANNER_CLICK`,
        eventForFeedbackBannerClose: `${screenType}_FEEDBACK_BANNER_CLOSE_BTN_CLICK`,
        eventForSendFeedback: `${screenType}_SEND_FEEDBACK_BTN_CLICK`,
    };
}
