import React, { useContext } from 'react';

import { ModalCtx } from '../../../reducer/modal';
import { CLOSE_MODAL_ACTION } from '../../../constants/modal';
import CommonPopUpLayout from '../CommonPopUpLayout';

import { ReactComponent as AttentionIcon } from '../../../static/svgs/attention.svg';

import styles from './WarningPopUp.module.scss';

const WarningPopUp = () => {
    const {
        dispatch,
        state: { modalTitle, requestId },
    } = useContext(ModalCtx);

    const closeModal = () => dispatch({ type: CLOSE_MODAL_ACTION });

    return (
        <CommonPopUpLayout
            title={modalTitle}
            onClick={closeModal}
            icon={<AttentionIcon />}
            buttonLabel="Продолжить"
            centered
        >
            {requestId && <p className={styles.requestId}>ID запроса: {requestId}</p>}
        </CommonPopUpLayout>
    );
};

export default WarningPopUp;
