import type { ReactNode } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import IdleTimer from 'react-idle-timer';

import { useAppSettings } from '../AppSettings';
import { ModalCtx } from '../../reducer/modal';
import { ModalTypes, OPEN_MODAL_ACTION } from '../../constants/modal';
import type { ClientInfo, ExtraClientData } from './types';
import ClientCtx from './ClientCtx';

type Props = {
    children?: ReactNode;
};

const ClientProvider = ({ children }: Props) => {
    const [state, setState] = useState<ClientInfo | null>(null);
    const { inactivityTime } = useAppSettings();
    const { dispatch } = useContext(ModalCtx);

    const setClient = useCallback((data: ClientInfo) => {
        setState(data);
    }, []);

    const removeClient = useCallback(() => {
        setState(null);
    }, []);

    const setPhoneNumberConfirmation = useCallback((isPhoneNumberConfirmed: boolean) => {
        setState((prevState) =>
            prevState
                ? {
                      ...prevState,
                      isPhoneNumberConfirmed,
                  }
                : prevState
        );
    }, []);

    const setExtraData = useCallback((extraData: ExtraClientData) => {
        setState((prevState) =>
            prevState
                ? {
                      ...prevState,
                      ...extraData,
                  }
                : prevState
        );
    }, []);

    const clientCtx = useMemo(
        () => ({
            client: state,
            setClient,
            removeClient,
            setPhoneNumberConfirmation,
            setExtraData,
        }),
        [state, setClient, removeClient, setPhoneNumberConfirmation, setExtraData]
    );

    const handleOnIdle = useCallback(() => {
        dispatch({ type: OPEN_MODAL_ACTION, payload: { modalType: ModalTypes.INACTIVITY_MODAL } });
    }, []);

    return (
        <ClientCtx.Provider value={clientCtx}>
            {state && !state.isTechnical && <IdleTimer timeout={inactivityTime} onIdle={handleOnIdle} debounce={250} />}
            {children}
        </ClientCtx.Provider>
    );
};

export default ClientProvider;
