import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { ModalCtx } from '../../reducer/modal';
import { CHANGE_FOOTER_ACTION, ModalTypes, OPEN_MODAL_ACTION } from '../../constants/modal';
import { sendEmail, sendPush, sendPushForGift } from '../../api/services/notificationService';
import { moveToExternalResource, scrollToTop } from '../../utils/helper';
import { generateSuccessCards, getSuccessPageCardType } from '../../utils/successPageHelper';
import { USED_WITH_VALUE } from '../../constants/queryParams';
import type { SUCCESS_PAGE_CARD_TYPE } from '../../constants/successPageCardTypes';
import { useAnalyticsQueue, useFooterHeight, useIntegrationPoint } from '../../hooks';
import { useAppSettings } from '../AppSettings';
import { ERROR_STATUS_CODE } from '../../constants/common';
import SuccessPageModalTypes from '../../constants/SuccessPageModalTypes';
import EmailSendPanel from '../EmailSendPanel';
import { Footer } from '../Footer';
import ExitButton from '../ExitButton';
import BackButton from '../BackButton';
import Header from '../Header';
import type { ISuccessCard } from '../SuccessPageCard';
import SuccessPageCard, { SuccessPageSaleSubscriptionCard } from '../SuccessPageCard';
import SuccessPageSkeletonLayout from './SuccessPageSkeletonLayout';
import type { ModalState, SuccessPageLayoutProps } from './types';
import SuccessPageBackground from '../SuccessPageBackground';
import SuccessPagePopUp from './SuccessPagePopUp';
import { useClient } from '../Client';
import Loader from '../Loader';
import { SuccessPagePromoCampaignForm, SuccessPagePromoCampaignsForm } from './SuccessPageForm';
import FooterInfo from '../FooterInfo';
import { SBER_CONTACT_INFO } from '../../constants/contactInfo';
import { useUser } from '../User';
import { Authority } from '../../api/services/auth';
import { useOffersAddQuery } from '../../hooks/services/offer/offers';
import CatLayout from '../CatLayout';
import { FeedbackScreenType } from '../../constants/FeedbackScreenTypes';
import { RequestStatusTypes } from '../../constants/RequestStatusTypes';
import { SaleMechanicCode } from '../../api/types';
import FeedbackBanner from '../FeedbackBanner';

import styles from './SuccessPageLayout.module.scss';

const SuccessPageLayout = ({
    goBack,
    promoCampaigns,
    promoCampaignIds,
    promoCampaignsError,
    campaignGroupId,
}: SuccessPageLayoutProps) => {
    const integrationPoint = useIntegrationPoint();
    const { dispatch } = useContext(ModalCtx);
    const { role } = useUser();
    const { allowEmailNotification, advertisingConsentId } = useAppSettings();
    const { pushMetrics } = useAnalyticsQueue();
    const { client } = useClient();
    const [{ modalType, message, description, requestId, card, onSubmit, onMount }, setModalState] =
        useState<ModalState>({});
    const [isLoading, setIsLoading] = useState(false);
    const { footerHeight } = useFooterHeight();
    const [offersAddsQueryEnabled, setOffersAddsQueryEnabled] = useState(false);
    const promoCampaignsCount = promoCampaignIds.length;

    const offersAddQuery = useOffersAddQuery({
        integrationPoint,
        clientId: (offersAddsQueryEnabled ? client?.id : undefined) as number,
        promoCampaignIds,
        campaignGroupId,
    });

    const offersResp =
        !offersAddQuery.isFetching && !offersAddQuery.isIdle && offersAddQuery.data ? offersAddQuery.data : undefined;

    const warning = useMemo(
        () =>
            offersResp?.description
                ? {
                      description: offersResp.description,
                      requestId: offersResp.requestId,
                  }
                : undefined,
        [offersResp?.description]
    );
    const error = promoCampaignsError || offersAddQuery.error;
    const cards = useMemo(
        () => generateSuccessCards(offersResp?.offers, promoCampaigns),
        [offersResp?.offers, promoCampaigns]
    );
    const cardType: SUCCESS_PAGE_CARD_TYPE = getSuccessPageCardType(cards.length);

    useEffect(scrollToTop, []);

    useLayoutEffect(() => {
        dispatch({ type: CHANGE_FOOTER_ACTION });
    }, [cards]);

    useEffect(() => {
        warning &&
            dispatch({
                type: OPEN_MODAL_ACTION,
                payload: {
                    modalType: ModalTypes.WARNING,
                    modalTitle: warning.description,
                    requestId: warning.requestId,
                },
            });
    }, [warning]);

    useEffect(() => {
        if (error) {
            pushMetrics('ERROR_NO_OFFER_SHOW');
            return;
        }

        cards.forEach(({ name, dzoName, promoCodeId, offerId, productCampaignId, originalId, saleMechanicCode }) => {
            if (cards.length > 1 || saleMechanicCode !== SaleMechanicCode.SALE_SUBSCRIPTION) {
                pushMetrics('SUCCESS_MAIN_OFFERS_SHOW', {
                    campaignId: originalId,
                    campaignName: name,
                    dzoName,
                    promoCodeId,
                    offerId,
                    productCampaignId,
                    emailEnabled: allowEmailNotification.toString(),
                });
            }
        });
    }, [cards, error]);

    const onAuthSubmit = useCallback(() => {
        setOffersAddsQueryEnabled(true);
    }, []);

    const onBackButtonClick = () => {
        pushMetrics(!offersAddsQueryEnabled ? 'IDENTIFICATION_INSTALLATION_BACK_CLICK' : 'SUCCESS_BACK_BTN_CLICK');
        goBack();
    };

    const closeModal = useCallback(() => setModalState({}), []);

    const onExternalLinkClick = useCallback(
        ({ name: campaignName, dzoName, personalUrl, productCampaignId, originalId }: ISuccessCard) => {
            if (personalUrl) {
                pushMetrics('SUCCESS_MAIN_OFFERS_TO_WEBSITE_CLICK', {
                    campaignId: originalId,
                    campaignName,
                    dzoName,
                    productCampaignId,
                });
                moveToExternalResource({ url: personalUrl, usedWithValue: USED_WITH_VALUE.BUTTON });
            } else {
                // TODO: send error to backend
                console.error('Incorrect url for alternative useOffer');
            }
        },
        [pushMetrics]
    );

    const onSendPushClick = useCallback(
        async (card: ISuccessCard, isGift?: boolean) => {
            setIsLoading(true);
            const { name: campaignName, dzoName, offerId, productCampaignId, originalId } = card;

            pushMetrics(isGift ? 'SUCCESS_MAIN_OFFERS_GIVE_OFFER_CLICK' : 'SUCCESS_SEND_OFFER_CLICK', {
                campaignId: originalId,
                campaignName,
                dzoName,
                productCampaignId,
            });

            try {
                await (isGift ? sendPushForGift(offerId) : sendPush(offerId));
            } catch (e) {
                if (e?.statusCode === ERROR_STATUS_CODE.NOT_ACCEPTABLE) {
                    setModalState({
                        modalType: client?.isSberIdAuth
                            ? SuccessPageModalTypes.ADVERTISING_AGREEMENT
                            : SuccessPageModalTypes.SBOL_PHONE_NUMBER_CONFIRMATION,
                        onSubmit: () => {
                            pushMetrics('SUCCESS_SEND_OFFER_SHOW', {
                                campaignId: originalId,
                                campaignName,
                                dzoName,
                                productCampaignId,
                            });
                            pushMetrics('CONFIRM_CONSENT_CONFIRM_CLICK', {
                                consentId: advertisingConsentId,
                            });
                            onSendPushClick(card, isGift);
                        },
                        onMount: () => pushMetrics('CONFIRM_CONSENT_SHOW'),
                    });
                } else {
                    setModalState({
                        modalType: SuccessPageModalTypes.ERROR,
                        onSubmit: closeModal,
                        message: e?.message,
                        description: e?.description,
                        requestId: e?.requestId,
                    });
                }
            } finally {
                setIsLoading(false);
            }
        },
        [client?.isSberIdAuth]
    );

    const sendOffersByEmail = async () => {
        await sendEmail(cards.map(({ offerId }) => offerId as number));
    };

    const onSendEmailClick = () => {
        pushMetrics('SUCCESS_SEND_OFFER_EMAIL_BTN_CLICK');
        setModalState({
            modalType: SuccessPageModalTypes.EMAIL,
            onSubmit: sendOffersByEmail,
        });
    };

    const openQrModal = (card: ISuccessCard) =>
        setModalState({
            modalType: SuccessPageModalTypes.QR,
            message: 'Отсканируйте QR‑код',
            description: 'Наведите камеру телефона и перейдите по ссылке',
            card,
        });

    const goBackFromErrorScreen = () => {
        pushMetrics('ERROR_NEXT_BTN_CLICK');
        goBack();
    };

    if (error) {
        return (
            <CatLayout
                type={RequestStatusTypes.EMPTY}
                title={error.message}
                description={error.description}
                requestId={error.requestId}
                onClick={goBackFromErrorScreen}
            />
        );
    }

    return (
        <div className={styles.page}>
            <div className={styles.body}>
                <SuccessPageBackground />
                <div className={styles.contentWrapper} style={{ paddingBottom: footerHeight }}>
                    <div className={styles.content}>
                        {cards.length === 1 && (
                            <div className={styles.bannerCsiWrapper}>
                                <FeedbackBanner screenType={FeedbackScreenType.SUCCESS} campaign={cards[0]} />
                            </div>
                        )}
                        <div className={styles.contentGrid}>
                            {!promoCampaigns ? (
                                <Loader />
                            ) : !offersAddsQueryEnabled ? (
                                promoCampaignsCount === 1 ? (
                                    <SuccessPagePromoCampaignForm
                                        promoCampaign={promoCampaigns[0]}
                                        onSubmit={onAuthSubmit}
                                    />
                                ) : (
                                    <SuccessPagePromoCampaignsForm onSubmit={onAuthSubmit} />
                                )
                            ) : cards.length !== 0 ? (
                                cards.map((card) =>
                                    card.saleMechanicCode === SaleMechanicCode.SALE_SUBSCRIPTION &&
                                    promoCampaignsCount === 1 ? (
                                        <SuccessPageSaleSubscriptionCard
                                            key={card.id}
                                            card={card}
                                            onOfferAddRefetch={offersAddQuery.refetch}
                                        />
                                    ) : (
                                        <SuccessPageCard
                                            key={card.id}
                                            card={card}
                                            cardType={cardType}
                                            onExternalLinkClick={onExternalLinkClick}
                                            onSendPushClick={onSendPushClick}
                                            openQrModal={openQrModal}
                                            loading={isLoading}
                                        />
                                    )
                                )
                            ) : (
                                <SuccessPageSkeletonLayout selectedItems={promoCampaignsCount} />
                            )}
                        </div>
                    </div>
                    {role === Authority.ReferralLink && (
                        <div className={styles.contactInfo}>
                            <FooterInfo contactInfo={SBER_CONTACT_INFO} position="center" textWhite />
                        </div>
                    )}
                </div>
                <div className={styles.topPanel}>
                    <div className={styles.pseudoBackgroundWrapper}>
                        <SuccessPageBackground />
                    </div>
                    <Header
                        position="sticky"
                        renderLeft={() => <BackButton onClick={onBackButtonClick} />}
                        renderRight={() => <ExitButton />}
                    />
                </div>
                <Footer>
                    {allowEmailNotification &&
                        cards.length !== 0 &&
                        cards[0].saleMechanicCode !== SaleMechanicCode.SALE_SUBSCRIPTION && (
                            <EmailSendPanel onClick={onSendEmailClick} />
                        )}
                </Footer>
                <SuccessPagePopUp
                    type={modalType}
                    onCancel={closeModal}
                    onSubmit={onSubmit}
                    onMount={onMount}
                    card={card}
                    message={message}
                    description={description}
                    requestId={requestId}
                />
            </div>
        </div>
    );
};

export default SuccessPageLayout;
