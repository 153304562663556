import React, { useCallback, useContext } from 'react';
import cn from 'classnames';
import Modal from 'react-modal';

import { ModalCtx } from '../../reducer/modal';
import { CLOSE_MODAL_ACTION, ModalTypes } from '../../constants/modal';

import styles from './CustomModal.module.scss';

const { INACTIVITY_MODAL, PROMOTION_RULES_MODAL } = ModalTypes;

const getModalProps = (modalType: ModalTypes) => {
    let contentClass: string;
    let hasOnRequestClose: boolean;

    switch (modalType) {
        case PROMOTION_RULES_MODAL:
            contentClass = cn(styles.content, styles.informationModal);
            hasOnRequestClose = true;
            break;
        case INACTIVITY_MODAL:
            contentClass = cn(styles.content, styles.inactivityModal);
            hasOnRequestClose = true;
            break;
        default:
            contentClass = cn(styles.content, styles.defaultModal);
            hasOnRequestClose = true;
    }

    return { contentClass, hasOnRequestClose };
};

type Props = Record<string, unknown>;

const CustomModal = ({ children, ...restPros }: Props) => {
    const {
        state: { modalType, isModalOpen },
        dispatch,
    } = useContext(ModalCtx);

    const handleClose = useCallback(() => dispatch({ type: CLOSE_MODAL_ACTION }), []);

    const { contentClass, hasOnRequestClose } = getModalProps(modalType);

    return (
        <>
            {isModalOpen && (
                <Modal
                    {...restPros}
                    {...(hasOnRequestClose ? { onRequestClose: handleClose } : {})}
                    isOpen={isModalOpen}
                    className={contentClass}
                    overlayClassName={styles.overlay}
                    bodyOpenClassName={styles.bodyOpen}
                    ariaHideApp={false}
                    shouldFocusAfterRender={false}
                >
                    {children}
                </Modal>
            )}
        </>
    );
};

export default CustomModal;
