import { BASE_TABLET_WIDTH } from '../constants/breakpoints';
import type { USED_WITH_VALUE } from '../constants/queryParams';
import { USED_WITH } from '../constants/queryParams';

export const getScreenDifferenceRatio = () => {
    const screenWidth = window.innerWidth;
    const targetWidth = screenWidth < BASE_TABLET_WIDTH ? BASE_TABLET_WIDTH : screenWidth;
    return screenWidth / targetWidth;
};

export const getScalingFactor = () => {
    const scalingFactor = (100 / window.innerWidth) * getScreenDifferenceRatio();
    return `${scalingFactor}vw`;
};

export function changeScalingFactor() {
    document.documentElement.style.setProperty('--sf', getScalingFactor());
}

// Todo: сомнительная проверка на IE
export const ieDetector = () => navigator.userAgent.includes('.NET');

export function scrollToTop() {
    window.scrollTo(0, 0);
}

export function noop() {}

export function sleep(time: number) {
    return new Promise((resolve) => setTimeout(resolve, 1000 * time));
}

export function isCanvas(obj: HTMLCanvasElement | HTMLElement): obj is HTMLCanvasElement {
    return obj.tagName === 'CANVAS';
}

export const getNumbers = (str: string) => str.replace(/\D/gm, '');

export function moveToExternalResource({
    url,
    target = '_blank',
    usedWithValue,
}: {
    url: string;
    target?: '_blank' | '_self';
    usedWithValue?: USED_WITH_VALUE;
}) {
    if (url) {
        const urlWithParams = new URL(url);
        if (usedWithValue) {
            urlWithParams.searchParams.append(USED_WITH, usedWithValue);
        }
        window.open(urlWithParams.toString(), target);
    } else {
        // TODO: send error to backend
        console.error('external url does not exist');
    }
}
