import type { ReactNode } from 'react';
import React, { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary';

import type { IBaseResponse } from '../../api/types';
import { useUserQuery } from '../../hooks';
import PageAccessDenied from '../Errors/PageAccessDenied';
import { LoginType } from '../../api/services/auth';
import { ROUTE } from '../../constants/route';
import { authenticatedRequest, getAuthenticatedResponseErrorHandler } from '../../api/axios';
import { ERROR_STATUS_CODE } from '../../constants/common';
import { LABELS } from '../../constants/labels';

export type ErrorFallbackProps = Omit<FallbackProps, 'error'> & {
    error: FallbackProps['error'] & IBaseResponse;
};

const ErrorFallback = ({ error, resetErrorBoundary }: ErrorFallbackProps) => {
    const isForbidden = error.statusCode === ERROR_STATUS_CODE.FORBIDDEN;
    const {
        data,
        isFetchedAfterMount,
        error: userError,
    } = useUserQuery({
        enabled: isForbidden,
        useErrorBoundary: false,
        refetchOnMount: isForbidden ? 'always' : undefined,
    });
    const navigate = useNavigate();

    if (isForbidden) {
        if (!isFetchedAfterMount) {
            return null;
        }

        if (userError) {
            return (
                <PageAccessDenied
                    header={userError.message}
                    message={userError.description}
                    requestId={userError.requestId}
                />
            );
        }

        if (data) {
            const { loginType } = data;
            const props =
                loginType === LoginType.SBER_REGISTRY
                    ? {
                          onClick: () => {
                              navigate(ROUTE.APPS);
                              resetErrorBoundary();
                          },
                          buttonLabel: 'Выбрать витрину',
                      }
                    : {};

            return (
                <PageAccessDenied
                    header={error.message}
                    message={error.description}
                    requestId={error.requestId}
                    {...props}
                />
            );
        }

        return null;
    }

    return (
        <PageAccessDenied
            header={error.message || LABELS.ERRORS.COMMON}
            requestId={error.requestId}
            message={error.description}
        />
    );
};

type ErrorInterceptorProps = {
    children?: ReactNode;
};

const ErrorInterceptor = ({ children }: ErrorInterceptorProps) => {
    const handleError = useErrorHandler();

    useLayoutEffect(() => {
        const interceptor = authenticatedRequest.interceptors.response.use(
            undefined,
            getAuthenticatedResponseErrorHandler(handleError)
        );

        return () => {
            authenticatedRequest.interceptors.response.eject(interceptor);
        };
    }, []);

    return <>{children}</>;
};

type ErrorBoundaryContainerProps = {
    children?: ReactNode;
};

const ErrorBoundaryContainer = ({ children }: ErrorBoundaryContainerProps) => (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ErrorInterceptor>{children}</ErrorInterceptor>
    </ErrorBoundary>
);

export default ErrorBoundaryContainer;
