import { generatePath } from 'react-router-dom';
import { APP_ROUTE } from '../constants/route';

export const generateAppPath = (appCode: string) => generatePath(APP_ROUTE.APP, { appCode });
export const generateGeneralPath = (appCode: string) => generatePath(APP_ROUTE.GENERAL, { appCode });
export const generateClientAuthPath = (appCode: string) => generatePath(APP_ROUTE.CLIENT_AUTH, { appCode });
export const generateQrRedirectPath = (appCode: string) => generatePath(APP_ROUTE.QR_REDIRECT, { appCode });
export const generateSapsanPath = (appCode: string) => generatePath(APP_ROUTE.SAPSAN, { appCode });
export const generateSapsanProductPath = (appCode: string, promoCampaignId: string | number) =>
    generatePath(APP_ROUTE.SAPSAN_PRODUCT, { appCode, promoCampaignId: `${promoCampaignId}` });
export const generateGiftPath = (appCode: string, promoCampaignId: string | number, offerId: string) =>
    generatePath(APP_ROUTE.GIFT, { appCode, promoCampaignId: `${promoCampaignId}`, offerId });
export const generateProductsPath = (appCode: string) => generatePath(APP_ROUTE.PRODUCTS, { appCode });
export const generateProductPath = (appCode: string, promoCampaignId: string | number) =>
    generatePath(APP_ROUTE.PRODUCT, { appCode, promoCampaignId: `${promoCampaignId}` });
export const generateBundlesPath = (appCode: string) => generatePath(APP_ROUTE.BUNDLES, { appCode });
export const generateBundlePath = (appCode: string, bundleId: string | number) =>
    generatePath(APP_ROUTE.BUNDLE, { appCode, bundleId: `${bundleId}` });
export const generatePresentsPath = (appCode: string) => generatePath(APP_ROUTE.PRESENTS, { appCode });
export const generateMixPath = (appCode: string) => generatePath(APP_ROUTE.MIX, { appCode });
export const generateMixProductPath = (appCode: string, promoCampaignId: string | number) =>
    generatePath(APP_ROUTE.MIX_PRODUCT, { appCode, promoCampaignId: `${promoCampaignId}` });
export const generateMixBundlePath = (appCode: string, bundleId: string | number) =>
    generatePath(APP_ROUTE.MIX_BUNDLE, { appCode, bundleId: `${bundleId}` });
