import React, { useMemo } from 'react';

import type { IBundle, IBundleLink, IPromoCampaign } from '../../api/types';
import MainPageCardList from '../../component/MainPageCardList';
import BundleCard from '../../containers/Bundles/BundleCard';
import ProductCard from '../../containers/Products/ProductCard';

type Props = {
    onProductCardClick: (product: IPromoCampaign) => void;
    onProductDetailClick: (product: IPromoCampaign) => void;
    onBundleCardClick: (bundle: IBundle) => void;
    bundles: IBundle[];
    products: IPromoCampaign[];
    filterList: number[];
};

const BUNDLE_CARD_KEY = `bundle-card-`;
const PRODUCT_CARD_KEY = 'product-card-';

function MainPageContent({
    onProductCardClick,
    onProductDetailClick,
    onBundleCardClick,
    bundles,
    products,
    filterList,
}: Props) {
    const filteredList = (elem: IBundleLink | IPromoCampaign) =>
        elem.categoryList.filter((el) => filterList.includes(el.categoryId)).length;
    const filteredBundles = useMemo(
        () =>
            filterList.length
                ? bundles.filter((bundle) => bundle.links.filter((link) => filteredList(link)).length)
                : bundles,
        [filterList, bundles]
    );

    const filteredProducts = useMemo(
        () => (filterList.length ? products.filter((product) => filteredList(product)) : products),
        [filterList, products]
    );

    return (
        <MainPageCardList>
            {filteredBundles.map((bundle) => (
                <BundleCard key={`${BUNDLE_CARD_KEY}${bundle.id}`} bundle={bundle} onClick={onBundleCardClick} />
            ))}
            {filteredProducts.map((product) => (
                <ProductCard
                    key={`${PRODUCT_CARD_KEY}${product.id}`}
                    product={product}
                    onClick={onProductCardClick}
                    onDetailClick={onProductDetailClick}
                />
            ))}
        </MainPageCardList>
    );
}

export default MainPageContent;
