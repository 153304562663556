import React, { useCallback, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AppSelection from '../../component/AppSelection';
import { useAppsQuery } from '../../hooks/services/apps';

const Apps = () => {
    const navigate = useNavigate();

    const goToApp = useCallback((code: string) => {
        navigate(code);
    }, []);

    const { data, isFetching } = useAppsQuery();

    useLayoutEffect(() => {
        if (data?.length === 1) {
            goToApp(data[0].code);
        }
    }, [data, goToApp]);

    if (!data || isFetching) {
        return null;
    }

    return <AppSelection list={data} onItemClick={goToApp} />;
};

export default Apps;
