import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { checkEmailIsVerified, saveClientExtraDataToBackend } from '../../api/services/clientService';
import type { SaveClientExtraDataRequest } from '../../api/services/clientService/types';
import { useAppSettings } from '../AppSettings';
import { ERROR_STATUS_CODE } from '../../constants/common';
import { useClient } from '../Client';
import useDevices from '../../hooks/useDevices';
import type { ButtonSizes } from '../Button';
import { useAnalyticsQueue } from '../../hooks';
import type { EmailState, ErrorArguments, FormValues, Props, StagesState, Submit } from './types';
import { EmailStage } from './types';
import AwaitingModalBody from './AwaitingModalBody';
import EmailFormModalBody from './EmailFormModalBody';
import SuccessModalBody from './SuccessModalBody';
import ErrorModalBody from './ErrorModalBody';

import styles from './EmailConfirmationForm.module.scss';

const { AWAIT, CONFIRMATION, ERROR, SUCCESS } = EmailStage;

const initialState = { stage: CONFIRMATION };

const EmailConfirmationForm = ({ onCancel, onSubmit }: Props) => {
    const { client, setExtraData } = useClient();
    const { pushMetrics } = useAnalyticsQueue();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { id: clientId, email: savedEmail, agreeWithAdvertising, isEmailConfirmed: savedIsEmailConfirmed } = client!;
    const { advertisingConsentId } = useAppSettings();
    const [{ email, isEmailConfirmed }, setEmailState] = useState<EmailState>({
        email: savedEmail,
        isEmailConfirmed: savedIsEmailConfirmed,
    });
    const [{ stage, errorData }, setStagesState] = useState<StagesState>(initialState);
    const { isMobile } = useDevices();
    const buttonSize: ButtonSizes = !isMobile ? 'x-large' : 'large';
    const methods = useForm<FormValues>({
        defaultValues: {
            email: email && !isEmailConfirmed ? email : '',
            agreeWithAdvertising,
        },
        mode: 'all',
    });

    const setError = ({ message, svEvent, description, requestId }: ErrorArguments) =>
        setStagesState({
            stage: ERROR,
            errorData: {
                message,
                requestId,
                description,
                svEvent,
            },
        });

    const sendEmail = async () => {
        try {
            await checkEmailIsVerified(clientId);
            await onSubmit();
            setStagesState({ stage: SUCCESS });
        } catch (e) {
            return Promise.reject(e);
        }
    };

    const onSubmitButtonClick: Submit = async (data) => {
        pushMetrics('CONFIRM_EMAIL_SEND_OFFER_EMAIL_BTN_CLICK', { consentId: advertisingConsentId });
        try {
            const requestData: Partial<SaveClientExtraDataRequest> = {};
            if (!isEmailConfirmed) {
                requestData.email = data.email;
                setEmailState((prevState) => ({ ...prevState, email: data.email }));
            }
            if (!agreeWithAdvertising && data.agreeWithAdvertising) {
                requestData.consentId = advertisingConsentId;
            }
            if (!isEmpty(requestData)) {
                await saveClientExtraDataToBackend({ ...requestData, clientId });
                setExtraData({
                    email: isEmailConfirmed ? email : requestData.email,
                    isEmailConfirmed: true,
                    agreeWithAdvertising: true,
                });
            }
            await sendEmail();
        } catch (e) {
            if (e.statusCode === ERROR_STATUS_CODE.NOT_ACCEPTABLE) {
                setStagesState({ stage: AWAIT });
            } else {
                setError({
                    message: e.message,
                    svEvent: 'ERROR_SEND_OFFER_EMAIL_SHOW',
                    description: e.description,
                    requestId: e.requestId,
                });
            }
        }
    };

    const resetStage = () => setStagesState(initialState);

    const setAnotherEmail = () => {
        pushMetrics('CONFIRM_EMAIL_CHANGE_BTN_CLICK');
        setEmailState({ email: '', isEmailConfirmed: false });
    };

    const onCancelWaitingClick = () => {
        pushMetrics('WAIT_CONFIRM_EMAIL_BACK_BTN_SHOW');
        resetStage();
    };

    const onCancelClick = () => {
        resetStage();
        onCancel();
    };

    if (stage === ERROR && errorData) {
        return (
            <ErrorModalBody {...errorData} onClick={resetStage} onCancelClick={onCancelClick} buttonSize={buttonSize} />
        );
    }

    if (stage === AWAIT) {
        return (
            <AwaitingModalBody
                email={email}
                sendEmail={sendEmail}
                setError={setError}
                onClick={onCancelWaitingClick}
                onCancelClick={onCancelClick}
                buttonSize={buttonSize}
            />
        );
    }

    if (stage === SUCCESS) {
        return <SuccessModalBody onClick={onCancelClick} buttonSize={buttonSize} />;
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmitButtonClick)} className={styles.form}>
                <EmailFormModalBody
                    email={email}
                    isEmailConfirmed={isEmailConfirmed}
                    agreeWithAdvertising={agreeWithAdvertising}
                    onClick={setAnotherEmail}
                    onCancelClick={onCancelClick}
                    buttonSize={buttonSize}
                />
            </form>
        </FormProvider>
    );
};

export default EmailConfirmationForm;
