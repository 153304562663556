import type { IntegrationPointTypes } from '../constants/integrationPointTypes';
import type behaviorTypes from '../constants/behaviorTypes';
import type PromoCampaignTypes from '../constants/promoCampaignTypes';
import type PromoCodeTypes from '../constants/promoCodeTypes';
import type { CampaignSettings } from '../constants/CampaignSettings';
import type {
    ClientIdentificationTypes,
    ClientIdentificationTypesFromBack,
} from '../constants/ClientIdentificationTypes';
import type { FeedbackScreenType } from '../constants/FeedbackScreenTypes';

export type Status = 'NOK' | 'Ok';

export enum PromoCampaignUrlTypes {
    URL_LANDING = 'URL_LANDING',
    URL_SUCCESS_QR = 'URL_SUCCESS_QR',
    URL_SUCCESS_BUTTON = 'URL_SUCCESS_BUTTON',
    TARGET_ACTION_BUTTON = 'TARGET_ACTION_BUTTON',
}

export interface IBaseResponse {
    status?: Status;
    statusCode?: number;
    message: string;
    description?: string;
    requestId?: string;
}

export interface ICategoryDto {
    categoryId: number;
    categoryName: string;
    categoryUrl: string;
    active: boolean;
}

export enum EridType {
    LANDING_ERID = 'LANDING_ERID',
    MAIN_ERID = 'MAIN_ERID',
    SUCCESS_ERID = 'SUCCESS_ERID',
}

export enum EridDtoType {
    LANDING_ERID = 'LANDING_ERID',
    PRESENT_MAIN_ERID = 'PRESENT_MAIN_ERID',
    PRODUCT_MAIN_ERID = 'PRODUCT_MAIN_ERID',
    SUCCESS_ERID = 'SUCCESS_ERID',
}

export type CreativeDto = {
    type: EridDtoType;
    erid: string;
};

export type Creatives = Record<EridType, string>;

export type ProductOfferingIds = {
    productCampaignId?: string;
};

export enum SaleMechanicCode {
    LINK_PROMOCODE = 'LINK_PROMOCODE',
    SALE_SUBSCRIPTION = 'SALE_SUBSCRIPTION',
}

export type IPromoCampaign = Pick<
    IPromoCampaignDto,
    | 'dzoCode'
    | 'dzoName'
    | 'dzoId'
    | 'name'
    | 'tradeName'
    | 'type'
    | 'categoryList'
    | 'integrationPoint'
    | 'promoCodeType'
    | 'saleMechanicCode'
> & {
    id: string;
    productCampaignId?: string;
    originalId?: number;
    logoUrl: string;
    offerDescription: string;
    rules: string;
    allowClientNotification: boolean;
    allowFriendNotification: boolean;
    clientIdentificationTypes: ClientIdentificationTypes[];
    showLanding: boolean;
    showQR: boolean;
    showGoToSiteButton: boolean;
    successButtonLabel: string;
    screenUrl?: string;
    screenMobileUrl?: string;
    detailsButtonUrl?: string | null;
    detailsButtonLabel: string;
    targetActionButtonLabel: string;
    landingBypassEnabled?: boolean;
    showDetailButton?: boolean;
    header: string;
    cardUrl: string;
    description: string;
    headerManual: string;
    manualSubscription: string;
    offerTermsLink: string;
    creatives: Creatives;
};

export type IBundleLink = Pick<
    IPromoCampaignDto,
    | 'creatives'
    | 'dzoId'
    | 'dzoName'
    | 'dzoCode'
    | 'tradeName'
    | 'name'
    | 'categoryList'
    | 'texts'
    | 'banners'
    | 'settings'
    | 'behaviorType'
    | 'promoCampaignActions'
    | 'productOfferingIds'
> & {
    id: number;
    campaignId: number;
};

export interface ICampaignSettings {
    [CampaignSettings.CLIENT_IDENTIFICATION_TYPES]?: ClientIdentificationTypesFromBack[];
    [CampaignSettings.CLIENT_NOTIFICATION_ENABLED]?: boolean;
    [CampaignSettings.FRIEND_NOTIFICATION_ENABLED]?: boolean;
    [CampaignSettings.LANDING_HIDING_ENABLED]?: boolean;
    [CampaignSettings.DISPLAY_LOGO_ON_BUNDLE]?: boolean;
    [CampaignSettings.LANDING_BYPASS_ENABLED]?: boolean;
    [CampaignSettings.SALE_ENABLED]?: boolean;
    [CampaignSettings.CLIENT_REGISTRATION_REQUIRED]?: boolean;
}

export interface IPromoCampaignAction {
    type?: PromoCampaignUrlTypes;
    name?: string | null;
    url?: string | null;
    urlActive: boolean;
}

export type IPromoCampaignDto = {
    id?: number;
    productOfferingIds: ProductOfferingIds;
    dzoId: number;
    dzoName: string;
    dzoCode: string;
    tradeName?: string;
    name: string;
    behaviorType: behaviorTypes;
    categoryList: ICategoryDto[];
    clientApplicationDto: ClientApplicationDto;
    banners: IPromoCampaignBanner[];
    texts: IPromoCampaignText[];
    settings: ICampaignSettings;
    promoCampaignActions: IPromoCampaignAction[];
    type: PromoCampaignTypes;
    integrationPoint?: IntegrationPointTypes;
    promoCodeType: PromoCodeTypes;
    creatives: CreativeDto[];
    saleMechanicCode?: SaleMechanicCode;
};

export interface IPromoCampaignListResponse extends IBaseResponse {
    promoCampaignDtoList: IPromoCampaignDto[];
}

export interface ClientAppCodeListResponse {
    clientApplicationCodeList: ClientApplicationDto[];
    message: string;
    status: Status;
}

export interface ClientApplicationDto {
    code: string;
    displayName: string;
    id: number;
    isDeleted?: boolean;
    name: string;
    orderNumber?: number;
}

export interface IPromoCampaignText {
    id?: number;
    type: string;
    value: string;
}

export interface IPromoCampaignBanner {
    id?: number;
    orderNumber: number;
    type: string;
    url: string;
}

export interface IBundleListResponse extends IBaseResponse {
    groups: IBundle[];
}

export interface IBundle {
    banners: IPromoCampaignBanner[];
    clientApplicationDto: ClientApplicationDto;
    id: number;
    links: IBundleLink[];
    texts: IPromoCampaignText[];
    name?: string;
}

export type FeedbackQuestionDto = {
    id: number;
    question: string;
    screen: FeedbackScreenType;
};
