import type { ReactNode } from 'react';
import React from 'react';

import { ReactComponent as Particles } from '../../static/svgs/particles.svg';

import styles from './ClientOffer.module.scss';

type Props = {
    children?: ReactNode;
};

const ClientOfferLayout = ({ children }: Props) => (
    <div className={styles.page}>
        <Particles className={styles.pageBackgroundImage} />
        {children}
    </div>
);

export default ClientOfferLayout;
