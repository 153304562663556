import React from 'react';
import { useController } from 'react-hook-form';

import type { FormValues } from '../../types';
import Input from '../../../Input';

import styles from './FeedbackCommentInput.module.scss';

const LABEL = 'Комментарий';

const FeedbackCommentInput = () => {
    const {
        field: { onChange, value, ...field },
        fieldState: { invalid },
    } = useController<FormValues, 'clientFeedback'>({
        name: 'clientFeedback',
    });

    return (
        <>
            <label>
                <p className={styles.info}>{LABEL}</p>
                <Input
                    placeholder="Расскажите подробнее"
                    error={invalid}
                    onChange={onChange}
                    onClear={() => onChange('')}
                    fullWidth
                    autoComplete="off"
                    maxLength={200}
                    value={value}
                    {...field}
                />
            </label>
            {value && (
                <div className={styles.limitBlock}>
                    <p>Максимум - 200 символов</p>
                    <p>{value.length} / 200</p>
                </div>
            )}
        </>
    );
};

export default FeedbackCommentInput;
