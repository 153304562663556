import type { FocusEvent } from 'react';
import React, { forwardRef, useState, useEffect, useRef } from 'react';
import type {
    ChangeEvent,
    AutosuggestPropsSingleSection,
    OnSuggestionSelected,
    InputProps as AutosuggestInputProps,
    BlurEvent,
} from 'react-autosuggest';
import ReactAutosuggest from 'react-autosuggest';
import type { InputProps } from '../Input';
import Input from '../Input';

export type AutosuggestProps = {
    value: any;
    onChange: (data: any) => void;
    onClear: () => void;
} & Pick<InputProps, 'label' | 'error' | 'name' | 'onFocus' | 'fullWidth'> &
    Omit<
        AutosuggestPropsSingleSection<any>,
        'renderInputComponent' | 'inputProps' | 'multiSection' | 'onSuggestionSelected'
    > &
    Pick<AutosuggestInputProps<any>, 'onBlur'>;

const Autosuggest = forwardRef<HTMLInputElement, AutosuggestProps>(
    (
        {
            value,
            name,
            onBlur,
            onFocus,
            onChange,
            onClear,
            label,
            error,
            fullWidth,
            getSuggestionValue,
            ...autosuggestProps
        },
        ref
    ) => {
        const [val, setVal] = useState(getSuggestionValue(value));
        const isChangeCalled = useRef(false);

        useEffect(() => {
            setVal(getSuggestionValue(value));
        }, [value]);

        const handleChange = (event: React.FormEvent<HTMLElement>, { newValue }: ChangeEvent) => {
            setVal(newValue);
            isChangeCalled.current = true;
        };

        const onSuggestionSelected: OnSuggestionSelected<any> = (_, { suggestion }) => {
            onChange(suggestion);
            isChangeCalled.current = false;
        };

        const handleClear = () => {
            setVal('');
            onClear();
        };

        const handleBlur = (event: FocusEvent<HTMLElement>, params?: BlurEvent<any>) => {
            if (isChangeCalled.current) {
                handleClear();
            }

            onBlur?.(event, params);
        };

        return (
            <ReactAutosuggest
                {...autosuggestProps}
                inputProps={{
                    value: val,
                    onChange: handleChange,
                    onBlur: handleBlur,
                    onFocus,
                    name,
                    ref,
                }}
                focusInputOnSuggestionClick={false}
                getSuggestionValue={getSuggestionValue}
                onSuggestionSelected={onSuggestionSelected}
                renderInputComponent={({ name, ...inputProps }) => (
                    <Input
                        label={label}
                        error={error}
                        name={name!}
                        fullWidth={fullWidth}
                        onClear={handleClear}
                        {...inputProps}
                    />
                )}
            />
        );
    }
);

Autosuggest.displayName = 'Autosuggest';

export default Autosuggest;
