import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMount } from 'react-use';

import {
    CONSENT_ID,
    SBER_ID_LOCAL_PHONE_NUMBER,
    SBER_ID_LOCAL_STATE,
    SMMR_CONSENT_ID,
} from '../../../constants/queryParams';
import { useLoginClient } from '../../../hooks';
import PageAccessDenied from '../../Errors/PageAccessDenied';
import { useAppSettings } from '../../../component/AppSettings';
import { generateAppPath } from '../../../utils/generatePathHelper';

const ClientAuthPage = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { appCode } = useAppSettings();
    const { mutate: loginClient, error } = useLoginClient('IDENTIFICATION_SBERID_INSTALLATION_GET_CLICK');
    const phoneNumber = searchParams.get(SBER_ID_LOCAL_PHONE_NUMBER) || '';
    const sberIdState = searchParams.get(SBER_ID_LOCAL_STATE) || '';
    const consentId = parseInt(searchParams.get(CONSENT_ID) || '');
    const smmrConsentId = parseInt(searchParams.get(SMMR_CONSENT_ID) || '');
    const to = searchParams.get('to') || generateAppPath(appCode);

    useMount(() => {
        loginClient(
            { phoneNumber, state: sberIdState, consentId, smmrConsentId },
            {
                onSuccess: () => {
                    navigate(to, { replace: true });
                },
            }
        );
    });

    if (error) {
        return (
            <PageAccessDenied
                header={error.message}
                message={error.description}
                buttonLabel="Продолжить"
                onClick={() => navigate(to, { replace: true })}
            />
        );
    }

    return null;
};

export default ClientAuthPage;
