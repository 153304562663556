import React, { useEffect, useState } from 'react';

import { useLoginClient } from '../../../../hooks';
import { TECHNICAL_PERSONAL_NUMBER } from '../../../../constants/common';
import Loader from '../../../Loader';
import type { IPromoCampaign } from '../../../../api/types';
import { SaleMechanicCode } from '../../../../api/types';
import { useClient } from '../../../Client';
import { SuccessPageCardLayout } from '../../../SuccessPageCard';
import OfferTermsConsentForm from '../../../OfferTermsConsentForm';
import { ClientAuthForm } from '../../../../containers/Client/Form';
import { ClientIdentificationTypes } from '../../../../constants/ClientIdentificationTypes';
import PromoCodeTypes from '../../../../constants/promoCodeTypes';

import styles from '../SuccessPageForm.module.scss';

const { PHONE, SBER_ID, TECHNICAL } = ClientIdentificationTypes;

const TechnicalAuth = () => {
    const { mutate: loginClient } = useLoginClient('IDENTIFICATION_INSTALLATION_GET_CLICK');

    useEffect(() => {
        loginClient({ phoneNumber: TECHNICAL_PERSONAL_NUMBER });
    }, []);

    return <Loader />;
};

const ClientIdentificationWeight: Record<ClientIdentificationTypes, number> = {
    [TECHNICAL]: 0,
    [PHONE]: 1,
    [SBER_ID]: 2,
} as const;

const useClientAuthRequired = (clientIdentificationTypes: ClientIdentificationTypes[]) => {
    const { client } = useClient();

    if (!client) {
        return true;
    }

    const { identificationType } = client;

    return !clientIdentificationTypes.some(
        (el) => ClientIdentificationWeight[identificationType] >= ClientIdentificationWeight[el]
    );
};

type Props = {
    promoCampaign: IPromoCampaign;
    onSubmit: () => void;
};

const SuccessPagePromoCampaignForm = ({ promoCampaign, onSubmit }: Props) => {
    const { clientIdentificationTypes, offerTermsLink } = promoCampaign;
    const clientAuthRequired = useClientAuthRequired(clientIdentificationTypes);
    const [agreeWithConsentRequired, setAgreeWithConsentRequired] = useState(
        () => !clientAuthRequired && !!offerTermsLink
    );
    const { client } = useClient();
    const phoneNumber = !client?.isTechnical ? client?.phoneNumber : '';

    useEffect(() => {
        if (!agreeWithConsentRequired && !clientAuthRequired) {
            onSubmit();
        }
    }, [agreeWithConsentRequired, clientAuthRequired, onSubmit]);

    if (!clientAuthRequired) {
        return agreeWithConsentRequired ? (
            <SuccessPageCardLayout promoCampaign={promoCampaign}>
                <OfferTermsConsentForm
                    title={<p className={styles.formTitle}>{'Подтвердите, чтобы оформить подписку'}</p>}
                    promoCampaign={promoCampaign}
                    onSubmit={() => setAgreeWithConsentRequired(false)}
                />
            </SuccessPageCardLayout>
        ) : null;
    }

    if (clientIdentificationTypes.includes(TECHNICAL)) {
        return <TechnicalAuth />;
    }

    return (
        <SuccessPageCardLayout promoCampaign={promoCampaign}>
            <ClientAuthForm
                title={
                    <p className={styles.formTitle}>
                        {promoCampaign.saleMechanicCode === SaleMechanicCode.SALE_SUBSCRIPTION
                            ? 'Войдите по Сбер ID, чтобы оформить подписку'
                            : `Войдите, чтобы получить ${
                                  promoCampaign.promoCodeType === PromoCodeTypes.NONE ? 'предложение' : 'промокод'
                              }`}
                    </p>
                }
                phoneNumber={phoneNumber}
                promoCampaign={promoCampaign}
                showAuthByPhoneNumber={clientIdentificationTypes.includes(PHONE)}
                showAuthBySberID={clientIdentificationTypes.includes(SBER_ID)}
            />
        </SuccessPageCardLayout>
    );
};

export default SuccessPagePromoCampaignForm;
