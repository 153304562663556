import React, { useCallback, useEffect, useState } from 'react';

import MainPageLayout from '../../component/MainPageLayout/MainPageLayout';
import BundleCard from './BundleCard';
import MainPageCardList from '../../component/MainPageCardList';
import { scrollToTop } from '../../utils/helper';
import { getBundleList } from '../../api/services/bundleService';
import type { IBaseResponse, IBundle } from '../../api/types';
import { useAnalyticsQueue } from '../../hooks';
import { IntegrationPointTypes } from '../../constants/integrationPointTypes';
import { MainPageCardSkeleton } from '../../component/MainPageCard';

type Props = {
    goNext: (id: number) => void;
};

const Bundles = ({ goNext }: Props) => {
    const [bundles, setBundles] = useState<IBundle[]>([]);
    const { pushMetrics } = useAnalyticsQueue();
    const [errorInfo, setErrorInfo] = useState<IBaseResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        let isMounted = true;
        pushMetrics('MAIN_BUNDLE_SHOW');

        scrollToTop();

        const loadData = async () => {
            try {
                setLoading(true);

                const groups = await getBundleList({
                    integrationPoint: IntegrationPointTypes.BUNDLE,
                });

                isMounted && setBundles(groups);
            } catch (error) {
                setErrorInfo(error);
            } finally {
                setLoading(false);
            }
        };

        loadData();

        return () => {
            isMounted = false;
        };
    }, []);

    const onBundleCardClick = useCallback(
        ({ id, name: campaignGroupName }: IBundle) => {
            pushMetrics('MAIN_BUNDLE_CLICK', { campaignGroupName });
            goNext(id);
        },
        [bundles, pushMetrics, goNext]
    );

    return (
        <MainPageLayout
            cardList={
                <MainPageCardList>
                    {!loading && !errorInfo
                        ? bundles.map((bundle) => (
                              <BundleCard key={bundle.id} bundle={bundle} onClick={onBundleCardClick} />
                          ))
                        : Array.from(Array(3)).map((_, index) => (
                              <MainPageCardSkeleton key={'bundle' + index} id={index} />
                          ))}
                </MainPageCardList>
            }
            errorData={errorInfo}
        />
    );
};

export default Bundles;
