import React from 'react';
import { Navigate, Route, Routes, useNavigate, useResolvedPath } from 'react-router-dom';

import { BundleRouter } from '../Bundle';
import { ProductRouter } from '../Product';
import Mix from './Mix';
import { PAGES, PARAMS } from '../../constants/route';
import type { IBundle, IPromoCampaign } from '../../api/types';
import { useAnalyticsQueue } from '../../hooks';

const MixRouter = () => {
    const navigate = useNavigate();
    const { pathname } = useResolvedPath('.');
    const { pushMetrics } = useAnalyticsQueue();

    const goToMix = () => navigate('.');

    const onProductDetailClick = ({
        dzoName,
        id: campaignId,
        name: campaignName,
        productCampaignId,
        originalId,
    }: IPromoCampaign) => {
        pushMetrics('MAIN_DETAIL_CLICK', { campaignId: originalId, campaignName, dzoName, productCampaignId });
        goToProduct(campaignId);
    };

    const goToProduct = (promoCampaignId: string) => {
        navigate(`${PAGES.PRODUCTS}/${promoCampaignId}`);
    };

    const goToOffer = ({
        dzoName,
        id: campaignId,
        name: campaignName,
        productCampaignId,
        originalId,
    }: IPromoCampaign) => {
        pushMetrics('PRODUCT_OFFER_CLICK', { campaignId: originalId, campaignName, dzoName, productCampaignId });
        navigate(`${PAGES.PRODUCTS}/${campaignId}/${PAGES.SUCCESS}`);
    };

    const goToBundle = ({ id: bundleId, name: campaignGroupName }: IBundle) => {
        pushMetrics('MAIN_BUNDLE_CLICK', { campaignGroupName });
        navigate(`${PAGES.BUNDLES}/${bundleId}`);
    };

    return (
        <Routes>
            <Route
                path="/"
                element={<Mix goToOffer={goToOffer} goToProduct={onProductDetailClick} goToBundle={goToBundle} />}
            />
            <Route path={`${PAGES.BUNDLES}/${PARAMS.BUNDLE}/*`} element={<BundleRouter goBack={goToMix} />} />
            <Route path={`${PAGES.PRODUCTS}/${PARAMS.PRODUCT}/*`} element={<ProductRouter goBackPath={pathname} />} />
            <Route path="*" element={<Navigate to=".." replace />} />
        </Routes>
    );
};

export default MixRouter;
