import React from 'react';
import { useController } from 'react-hook-form';

import { phoneValidator } from '../../../../utils/validators';
import PhoneNumber from '../../../../component/PhoneNumber';

export const EMPTY_PHONE_NUMBER_WARNING = 'Необходимо ввести номер телефона';
export const INCOMPLETE_PHONE_NUMBER_WARNING = 'Введите номер полностью';

const PhoneNumberField = () => {
    const {
        field: { onChange, ...field },
        fieldState: { invalid },
    } = useController({
        name: 'phoneNumber',
        rules: { required: true, validate: phoneValidator },
    });

    return (
        <PhoneNumber
            label="Введите телефон"
            autoComplete="off"
            error={invalid}
            errorMsg={!field.value ? EMPTY_PHONE_NUMBER_WARNING : INCOMPLETE_PHONE_NUMBER_WARNING}
            onChange={onChange}
            onClear={() => {
                onChange('');
            }}
            autoFocus
            fullWidth
            {...field}
        />
    );
};

export default PhoneNumberField;
