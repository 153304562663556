import type { QueryFunctionContext } from 'react-query';
import { useMutation, useQuery } from 'react-query';

import type { IBaseResponse, IPromoCampaignDto } from '../../../api/types';
import { addGiftClientOffer, getGiftOffer } from '../../../api/services/offerService';
import { getCampaignBanner, getCampaignText } from '../../../utils/promoCampaignHelper';
import { arrayOfCreativesToRecord, convertArrayToRecordByType } from '../../../utils/mapper';
import type { IntegrationPointTypes } from '../../../constants/integrationPointTypes';
import bannerTypes from '../../../constants/bannerTypes';
import PromoCampaignTypes from '../../../constants/promoCampaignTypes';
import promoTextTypes from '../../../constants/promoTextTypes';

const getGiftOfferKey = (offerUuid: string) => ['giftOffer', offerUuid] as const;

type GiftOfferQueryKeys = ReturnType<typeof getGiftOfferKey>;

const getGiftOfferFn = ({ queryKey: [, offerId] }: QueryFunctionContext<GiftOfferQueryKeys>) => getGiftOffer(offerId);

const getGiftOfferQueryOptions = (offerId: string) => ({
    queryKey: getGiftOfferKey(offerId),
    queryFn: getGiftOfferFn,
});

export type GiftOfferInfo = {
    campaignId?: number;
    campaignName: string;
    dzoName: string;
    tradeName?: string;
    header?: string;
    logoUrl?: string | null;
    cardUrl?: string | null;
    productCampaignId?: string;
    appCode: string;
    integrationPoint?: IntegrationPointTypes;
    erid?: string;
};

const selectGiftOfferInfo = (data: IPromoCampaignDto) => {
    const creatives = arrayOfCreativesToRecord(data.creatives, data.type);
    const banners = convertArrayToRecordByType(data.banners);
    const texts = convertArrayToRecordByType(data.texts);

    return {
        erid: creatives.MAIN_ERID,
        campaignId: data.id,
        campaignName: data.name,
        productCampaignId: data.productOfferingIds.productCampaignId,
        appCode: data.clientApplicationDto!.code,
        dzoName: data.dzoName,
        tradeName: data.tradeName,
        integrationPoint: data.integrationPoint,
        logoUrl: getCampaignBanner(banners, bannerTypes.LOGO_MAIN),
        header: getCampaignText(texts, [
            data.type === PromoCampaignTypes.PRODUCT ? promoTextTypes.HEADER_PRODUCT : promoTextTypes.HEADER_PRESENT,
            promoTextTypes.HEADER,
        ]),
        cardUrl: getCampaignBanner(banners, [
            data.type === PromoCampaignTypes.PRODUCT ? bannerTypes.CARD_PRODUCT : bannerTypes.CARD_PRESENT,
            bannerTypes.CARD,
        ]),
    };
};

export const useGiftOfferQuery = (offerId: string, onError?: () => void) =>
    useQuery<IPromoCampaignDto, IBaseResponse, GiftOfferInfo, GiftOfferQueryKeys>({
        ...getGiftOfferQueryOptions(offerId),
        select: selectGiftOfferInfo,
        useErrorBoundary: true,
        onError: () => {
            onError?.();
        },
    });

export const useAddGiftOffer = () =>
    useMutation(addGiftClientOffer, {
        useErrorBoundary: true,
    });
