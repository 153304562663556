import type { ReactNode } from 'react';
import React from 'react';
import cn from 'classnames';

import styles from './Footer.module.scss';

type FooterProps = {
    children?: ReactNode;
    className?: string;
};

/*
Basic footer wrapper component.
Don't remove `id` for footer. It's used in useFooterHeight!!!
*/
const Footer = ({ children, className }: FooterProps) => (
    <div id="footer" className={cn(styles.footerContainer, className)}>
        {children}
    </div>
);

export default Footer;
