import type { ReactNode } from 'react';
import React from 'react';
import Modal from 'react-modal';

import styles from './SuccessPageModal.module.scss';

type SuccessPageModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    children?: ReactNode;
};

const SuccessPageModal = ({ isOpen, closeModal, children }: SuccessPageModalProps) => (
    <Modal
        isOpen={isOpen}
        overlayClassName={styles.modalOverlay}
        bodyOpenClassName={styles.bodyOpen}
        className={styles.modalContent}
        onRequestClose={closeModal}
        ariaHideApp={false}
        shouldFocusAfterRender={false}
        closeTimeoutMS={300}
        testId="custom-modal"
    >
        {children}
    </Modal>
);

export default SuccessPageModal;
