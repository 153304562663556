import type { ReactNode } from 'react';
import React from 'react';

import styles from './MainPageCardList.module.scss';

type Props = {
    children?: ReactNode;
};

const MainPageCardList = ({ children }: Props) => <div className={styles.list}>{children}</div>;

export default MainPageCardList;
