import React from 'react';

import styles from './FilterTagBody.module.scss';

export interface FilterTagBodyProps {
    text: string;
    imgUrl: string;
}

const FilterTagBody = ({ imgUrl, text }: FilterTagBodyProps) => (
    <div className={styles.body}>
        <img className={styles.img} src={imgUrl} alt="" />
        <span className={styles.text}>{text}</span>
    </div>
);

export default FilterTagBody;
