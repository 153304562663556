import type { ReactNode } from 'react';
import React from 'react';
import styles from './Nav.module.scss';

type Props = {
    children?: ReactNode;
};

const Nav = ({ children }: Props) => <div className={styles.nav}>{children}</div>;

export default Nav;
