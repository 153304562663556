import type { ReactNode } from 'react';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import useDevices from '../../hooks/useDevices';
import { DzoLogoSkeleton } from '../DzoLogo';

import styles from './MainPageCard.module.scss';

export type MainPageCardProps = {
    imgUrl?: string | null;
    title?: string;
    description?: string;
    dataTestId?: string;
    renderLogo?: () => ReactNode;
    renderFooter?: () => ReactNode;
    advertising?: ReactNode;
    icons?: string[];
};

export type MainPageCardSkeletonProps = {
    id?: number;
};

export const MainPageCardSkeletonTestId = 'MainPageCardSkeletonTestId';

const MainPageCard = ({
    imgUrl,
    title,
    description,
    dataTestId,
    renderLogo,
    renderFooter,
    icons,
    advertising,
}: MainPageCardProps) => {
    const { isMobile } = useDevices();
    const iconsBlock = icons && (
        <div className={styles.iconsBlock}>
            {icons.map((iconUrl) => (
                <img key={iconUrl} src={iconUrl} alt="" />
            ))}
        </div>
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.card} data-test-id={dataTestId}>
                <div className={styles.content}>
                    <div className={styles.textsBlock}>
                        {isMobile && iconsBlock}
                        {renderLogo && <div className={styles.logoWrapper}>{renderLogo()}</div>}
                        {title && <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />}
                        {description && <div className={styles.description}>{description}</div>}
                    </div>
                    {imgUrl && <div className={styles.imageWrapper} style={{ backgroundImage: `url(${imgUrl})` }} />}
                    {!isMobile && iconsBlock}
                    {renderFooter && <div className={styles.buttonsBlock}>{renderFooter()}</div>}
                </div>
                {advertising}
            </div>
        </div>
    );
};

export const MainPageCardSkeleton = ({ id = 1 }: MainPageCardSkeletonProps) => {
    const { isMobile } = useDevices();

    return (
        <div className={styles.wrapper} data-test-id={MainPageCardSkeletonTestId + id}>
            <div className={styles.card}>
                <div className={styles.content}>
                    <div className={styles.textsBlock}>
                        <div className={styles.logoWrapper}>
                            <DzoLogoSkeleton centered={isMobile} />
                        </div>
                        <div className={styles.title}>
                            <Skeleton count={1.6} className={styles.skeletonTitle} />
                        </div>
                        <div className={styles.description}>
                            <Skeleton count={1.9} />
                        </div>
                    </div>
                    <div className={styles.imageWrapper}>
                        <Skeleton className={styles.skeletonImage} containerClassName={styles.skeletonImageWrapper} />
                    </div>
                    <div className={styles.buttonsBlock}>
                        <Skeleton containerClassName={styles.skeletonButton} />
                        {isMobile && <Skeleton containerClassName={styles.skeletonButton} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainPageCard;
