export enum ModalTypes {
    NONE = 0,
    INACTIVITY_MODAL,
    PROMOTION_RULES_MODAL,
    LOGOUT,
    WARNING,
}

export const OPEN_MODAL_ACTION = 'OPEN_MODAL_ACTION';
export const CLOSE_MODAL_ACTION = 'CLOSE_MODAL_ACTION';
export const CHANGE_FOOTER_ACTION = 'CHANGE_FOOTER_ACTION';
