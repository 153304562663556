import type { ReactElement } from 'react';
import React, { cloneElement } from 'react';

import type { FilterTagsProps } from '../FilterTags';
import HomePageTopPanel from '../HomePageTopPanel';

import styles from './Content.module.scss';

export type ContentProps = {
    filterPanel?: ReactElement<FilterTagsProps>;
    cardList: ReactElement;
    showTopPanel?: boolean;
};

const Content = ({ filterPanel, cardList, showTopPanel = false }: ContentProps) => (
    <div className={styles.contentWrapper}>
        {showTopPanel && <HomePageTopPanel />}
        <div className={styles.body}>
            {filterPanel && (
                <>
                    {cloneElement(filterPanel as ReactElement<FilterTagsProps>, {
                        ...(filterPanel as ReactElement<FilterTagsProps>).props,
                    })}
                </>
            )}
            <div className={styles.content}>{cardList}</div>
        </div>
    </div>
);

export default Content;
