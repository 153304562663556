import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useMount } from 'react-use';
import type { SberidSDKProps, SberidSDKSuccessResult } from '@sberid/js-sdk';
import { SberidSDK } from '@sberid/js-sdk';

import { useAppSettings } from '../AppSettings';
import { useStateRef } from '../../hooks';
import { CONSENT_ID, SMMR_CONSENT_ID } from '../../constants/queryParams';
import { getOidcParams } from '../../api/services/sberIdService';
import { SBER_ID_CLOUD_ENABLED, SBER_ID_URL } from '../../constants/sberId';
import { useUser } from '../User';

import styles from './SberIdButton.module.scss';

type Props = {
    onButtonClick: () => Promise<string | null>;
    onPersonalizationStatus: (personalization: boolean) => void;
    onSuccessCallback: (data: SberidSDKSuccessResult) => Promise<void> | void;
};

const SberIdButton = ({ onButtonClick, onPersonalizationStatus, onSuccessCallback }: Props) => {
    const { pathname, search } = useLocation();
    const { consentId, smmrConsentId } = useAppSettings();
    const { role } = useUser();

    const onPersonalizationStatusRef = useStateRef(onPersonalizationStatus);
    const onButtonClickRef = useStateRef(onButtonClick);
    const onSuccessCallbackRef = useStateRef(onSuccessCallback);
    const sbSDK = useRef<SberidSDK>();
    const isLoadingRef = useRef(false);
    const sberIdContainerRef = useRef<HTMLDivElement>(null);

    useMount(() => {
        const initSDK = async () => {
            const searchParams = new URLSearchParams(search);
            searchParams.set(CONSENT_ID, `${consentId}`);
            searchParams.set(SMMR_CONSENT_ID, `${smmrConsentId}`);
            const path = `${pathname}?${searchParams}`;
            const baseUrl = SBER_ID_URL;
            const optionalProps: Partial<SberidSDKProps> = SBER_ID_CLOUD_ENABLED
                ? {
                      cloud: {
                          enable: true,
                          baseUrl, // TODO: возможно в дальнейшем и для cloud нужно будет передавать baseUrl на верхнем уровне
                      },
                  }
                : {
                      baseUrl,
                  };

            const oidcParams = await getOidcParams({ path, role });

            sbSDK.current = new SberidSDK({
                ...optionalProps,
                oidc: oidcParams,
                onPersonalizationStatus: (personalization) => {
                    onPersonalizationStatusRef.current?.(personalization);
                },
                onSuccessCallback: async (data) => {
                    try {
                        await onSuccessCallbackRef.current(data!);
                    } finally {
                        isLoadingRef.current = false;
                    }
                },
                onButtonClick: async (e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    if (isLoadingRef.current) {
                        return false;
                    }

                    const loginHint = await onButtonClickRef.current();

                    if (loginHint === null) {
                        return false;
                    }

                    isLoadingRef.current = true;

                    if (loginHint) {
                        await sbSDK.current!.setOIDCParams({
                            ...oidcParams,
                            login_hint: loginHint,
                        });
                    }

                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    sbSDK.current!.handleButtonClick(e);

                    return true;
                },
                fastLogin: {
                    enable: true,
                    timeout: 2000,
                    mode: 'default',
                    onErrorDefaultMode: () => true,
                },
                sa: {
                    enable: false,
                },
                personalization: true,
                container: sberIdContainerRef.current!,
                display: 'page',
            });
        };

        void initSDK();
    });

    return <div className={styles.buttonContainer} ref={sberIdContainerRef} />;
};

export default SberIdButton;
