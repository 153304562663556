import React from 'react';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import { SELECTION } from '../../constants/labels';
import { useAnalyticsQueue } from '../../hooks';
import AdvertisingTag from '../AdvertisingTag';
import Button from '../Button';
import DzoLogo, { DzoLogoSkeleton } from '../DzoLogo';

import type { PresentCardProps } from './types';

import { ReactComponent as CheckMark } from '../../static/svgs/ic-check-mark-s.svg';

import styles from './PresentCard.module.scss';

export const DATA_TEST_ID_PREFIX_CARD = 'present-card-';
export const DATA_TEST_ID_PREFIX_SELECT_BTN = 'present-select-btn-';
export const PRESENT_SKELETON_CLASSNAME = 'skeleton-present';

// TODO: убрать CARD, DESCRIPTION и HEADER после миграции
const { SELECT, SELECTED } = SELECTION;

const PresentCard = ({ handleClick, campaign, selected = false }: PresentCardProps) => {
    const { pushMetrics } = useAnalyticsQueue();
    const {
        id: campaignId,
        name: campaignName,
        dzoName,
        tradeName,
        productCampaignId,
        originalId,
        creatives,
        logoUrl,
        header,
        description,
        cardUrl,
        rules,
    } = campaign;
    const erid = creatives.MAIN_ERID;

    const onSelectBtnClick = () => handleClick(campaign);

    const onAdvertisingTagClick = () => {
        pushMetrics('ADVERTISEMENT_CLICK', {
            campaignId: originalId,
            campaignName,
            dzoName,
            productCampaignId,
            tradeName,
            erid,
        });
    };

    return (
        <div className={styles.wrapper}>
            <div
                className={cn(styles.card, { [styles.selected]: selected })}
                data-test-id={DATA_TEST_ID_PREFIX_CARD + campaignId}
            >
                <div className={styles.topBlock}>
                    <div className={styles.logoWrapper}>
                        <DzoLogo
                            logoUrl={logoUrl}
                            dzoName={dzoName}
                            dataTestId={`dzo-logo-${campaignId}`}
                            centered={false}
                        />
                    </div>
                    <div className={styles.cardImage}>
                        <img src={cardUrl} alt={campaignName} />
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.textBlock}>
                        <div className={styles.header} dangerouslySetInnerHTML={{ __html: header }} />
                        <p className={styles.description}>{description}</p>
                    </div>
                    <div className={styles.buttonBlock}>
                        <Button
                            {...(!selected ? { label: SELECT } : { label: SELECTED, icon: <CheckMark /> })}
                            type="contained"
                            onClick={onSelectBtnClick}
                            dataTestId={DATA_TEST_ID_PREFIX_SELECT_BTN + campaignId}
                        />
                    </div>
                </div>
                <AdvertisingTag tradeName={tradeName} erid={erid} onClick={onAdvertisingTagClick} rules={rules} />
            </div>
        </div>
    );
};

export const PresentCardSkeleton = () => (
    <div className={cn(styles.wrapper, PRESENT_SKELETON_CLASSNAME)}>
        <div className={styles.card}>
            <div className={styles.topBlock}>
                <div className={styles.logoWrapper}>
                    <DzoLogoSkeleton centered={false} />
                </div>
                <div className={styles.cardImage}>
                    <Skeleton className={styles.skeleton} />
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.textBlock}>
                    <Skeleton className={styles.skeleton} containerClassName={styles.header} />
                    <Skeleton
                        count={2}
                        className={styles.skeleton}
                        containerClassName={cn(styles.description, styles.skeletonContainer)}
                    />
                </div>
                <div className={styles.buttonBlock}>
                    <Skeleton className={styles.buttonSkeleton} width={110} />
                </div>
            </div>
        </div>
    </div>
);

export default PresentCard;
