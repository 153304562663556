import React from 'react';
import QRCode from 'qrcode.react';
import cn from 'classnames';

import { USED_WITH, USED_WITH_VALUE } from '../../../constants/queryParams';

import styles from '../SuccessPageCard.module.scss';

const DescriptionBlock = ({ value }: { value: string }) => (
    <div className={styles.descriptionWrapper}>
        <div className={styles.offerDescription} dangerouslySetInnerHTML={{ __html: value }} />
    </div>
);

const InfoBlock = ({ value, dataTestId }: { value: string; dataTestId: string }) => (
    <p className={styles.info} data-test-id={dataTestId}>
        {value}
    </p>
);

const QrBlock = ({ url, size = 'normal' }: { url: string; size?: 'normal' | 'large' }) => (
    <div className={cn(styles.qrWrapper, styles[`qr-size-${size}`])} data-test-id="qr-code-block">
        <QRCode value={`${url}&${USED_WITH}=${USED_WITH_VALUE.QR}`} renderAs="svg" className={styles.qrOverride} />
    </div>
);

export { DescriptionBlock, InfoBlock, QrBlock };
