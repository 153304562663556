import type { QueryClientConfig } from 'react-query';
import { QueryClient } from 'react-query';
import type { IBaseResponse } from '../types';
import { ERROR_STATUS_CODE } from '../../constants/common';

const useErrorBoundary = (error: unknown) =>
    (error as IBaseResponse).statusCode === ERROR_STATUS_CODE.UNAUTHORIZED ||
    (error as IBaseResponse).statusCode === ERROR_STATUS_CODE.FORBIDDEN ||
    !(error as IBaseResponse).statusCode;

export const queryClientConfig: QueryClientConfig = {
    defaultOptions: {
        queries: {
            retryOnMount: true,
            refetchOnWindowFocus: false,
            retry: false,
            useErrorBoundary,
            cacheTime: 15 * 1000,
            staleTime: 10 * 1000,
        },
        mutations: {
            retry: false,
            useErrorBoundary,
        },
    },
};

export const createQueryClient = (config = queryClientConfig) => new QueryClient(config);
