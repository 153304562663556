export const ALL_PRESENTS_SELECTED = 'all_presents_selected';
export const CLIENT_IDENTIFICATION_TYPES = 'client_identification_types';
export const HOME_PAGE_HEADER = 'home_page_header';
export const HOME_PAGE_HEADER_PRESENTS = 'home_page_header_present';
export const HOME_PAGE_HEADER_BUNDLES = 'home_page_header_bundle';
export const HOME_PAGE_HEADER_MIX = 'home_page_header_mix';
export const HOME_PAGE_DESCRIPTION = 'home_page_description';
export const INACTIVITY_TIME = 'inactivity_time';
export const MAX_PRESENTS_NUMBER = 'max_presents_number';
export const MECHANICS = 'mechanics';
export const NOTIFICATION_TYPES = 'notification_types';
export const CLIENT_NOTIFICATION_ENABLED = 'client_notification_enabled';
export const FRIEND_NOTIFICATION_ENABLED = 'friend_notification_enabled';
export const PRIVACY_POLICY = 'privacy_policy';
export const ADVERTISING_POLICY = 'advertising_policy';
export const SMMR_POLICY = 'smmr_policy';
export const APP_THEME = 'vitrina_theme';
export const TOKEN_LIFETIME = 'token_lifetime';
export const PRODUCT_CATALOG_INTEGRATION_ENABLED = 'product_catalog_integration_enabled';
export const CSI_ENABLED = 'csi_enabled';

export type ConsentType = typeof PRIVACY_POLICY | typeof SMMR_POLICY | typeof ADVERTISING_POLICY;
