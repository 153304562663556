import React, { useContext } from 'react';

import { ModalCtx } from '../../reducer/modal';
import { ModalTypes } from '../../constants/modal';
import CustomModal from '../CustomModal';
import LogoutModal from '../LogoutModal';
import InactivityPopUp from './InactivityPopUp';
import InformationPopUp from './InformationPopUp';
import WarningPopUp from './WarningPopUp';

const { INACTIVITY_MODAL, PROMOTION_RULES_MODAL, WARNING, LOGOUT } = ModalTypes;

const PopUp = () => {
    const {
        state: { modalType, isModalOpen },
    } = useContext(ModalCtx);

    return modalType === LOGOUT ? (
        <LogoutModal />
    ) : (
        <>{isModalOpen && <CustomModal>{generateBody(modalType)}</CustomModal>}</>
    );
};

export function generateBody(type: ModalTypes) {
    switch (type) {
        case INACTIVITY_MODAL:
            return <InactivityPopUp />;
        case PROMOTION_RULES_MODAL:
            return <InformationPopUp />;
        case WARNING:
            return <WarningPopUp />;
        default:
            return null;
    }
}

export default PopUp;
