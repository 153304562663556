import React from 'react';

import Themes from '../../constants/Themes';
import { ReactComponent as SberLogo } from '../../static/svgs/sber-logo.svg';
import { ReactComponent as SberLogoWhite } from '../../static/svgs/sber-logo-white.svg';

import styles from './CorpLogo.module.scss';

type CorpLogoProps = {
    theme?: Themes;
};

const CorpLogo = ({ theme = Themes.LIGHT }: CorpLogoProps) =>
    theme === Themes.LIGHT ? <SberLogo className={styles.logo} /> : <SberLogoWhite className={styles.logo} />;

export default CorpLogo;
