import React, { Children, cloneElement, useCallback } from 'react';

import type { FilterTagsProps, SelectedComponentProps } from './types';
import Tag from '../Tag';

import { ReactComponent as CancelIcon } from '../../static/svgs/cross-black.svg';

import styles from './FilterTags.module.scss';

export const DATA_TEST_ID_CANCEL_BTN = 'filter-tags-cancel';

const FilterTags = ({ onChange, selectedItems = [], onCancel, children }: FilterTagsProps) => {
    const onClick = useCallback((value, selected) => onChange(value, !selected), [onChange]);

    return (
        <div className={styles.filterTags}>
            <div className={styles.block}>
                {Children.map(children, (child: React.ReactElement<SelectedComponentProps>) =>
                    cloneElement(child, {
                        ...child.props,
                        key: `filter-tag-${child.props.value}`,
                        onClick,
                        selected: selectedItems.includes(child.props.value),
                    })
                )}
            </div>
            {!!selectedItems?.length && (
                <Tag
                    key="cancel"
                    onClick={onCancel}
                    value="cancel"
                    className={styles.cancelTag}
                    dataTestId={DATA_TEST_ID_CANCEL_BTN}
                >
                    <CancelIcon className={styles.cancelTagImg} />
                </Tag>
            )}
        </div>
    );
};

export default FilterTags;
