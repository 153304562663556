export const USED_WITH = 'usedWith';
export const APP_CODE = 'appCode';
export const PARTNER_NO = 'partnerNo';
export const EXTERNAL_ID = 'externalId';
export const EXTERNAL_BUNDLE_ID = 'externalBundleId';
export const JWT_TOKEN = 'auth_token';
export const ID = 'id';
export const CODE = 'code';
export const SBER_ID_REDIRECT = 'sberIDRedirect';
export const SBER_ID_SOURCE = 'source';
export const SBER_ID_CODE = 'code';
export const SBER_ID_STATE = 'state';
export const SBER_ID_LOCAL_STATE = 'sberIdLocalState';
export const SBER_ID_LOCAL_PHONE_NUMBER = 'sberIdLocalPhoneNumber';
export const RESOURCE = 'resource';
export const ACTION_ID = 'actionId';
export const CONSENT_ID = 'consentId';
export const SMMR_CONSENT_ID = 'smmrConsentId';

export enum USED_WITH_VALUE {
    BUTTON = 'BUTTON',
    QR = 'QR',
}
