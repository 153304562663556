import React from 'react';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import { PromoCodeSkeleton } from '../../../component/PromoCode';

import styles from '../ClientOffer.module.scss';

type ClientOfferViewContentSkeletonProps = {
    client?: boolean;
};

const ClientOfferViewContentSkeleton = ({ client }: ClientOfferViewContentSkeletonProps) => (
    <div className={styles.content}>
        <Skeleton containerClassName={cn(styles.logo, styles.logoSkeleton)} />
        <Skeleton
            containerClassName={styles.bannerSkeletonWrapper}
            className={cn(styles.banner, styles.bannerSkeleton)}
        />
        {!client ? (
            <>
                <Skeleton containerClassName={cn(styles.instructions, styles.instructionsViewSkeleton)} count={1.6} />
                <PromoCodeSkeleton />
            </>
        ) : (
            <>
                <Skeleton containerClassName={styles.phoneNumberSkeleton} />
                <Skeleton containerClassName={styles.buttonSkeleton} />
                <Skeleton containerClassName={styles.buttonSkeleton} />
            </>
        )}
    </div>
);

export default ClientOfferViewContentSkeleton;
