import type { ReactNode } from 'react';
import React, { useReducer } from 'react';
import { ModalCtx, initialState, userReducer } from '../reducer/modal';

type Props = {
    children: ReactNode;
};

const ContextContainer = ({ children }: Props) => {
    const [state, dispatch] = useReducer(userReducer, initialState);
    const contextValue = { state, dispatch } as React.ContextType<typeof ModalCtx>;

    return <ModalCtx.Provider value={contextValue}>{children}</ModalCtx.Provider>;
};

export default ContextContainer;
