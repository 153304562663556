import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { sleep } from '../../utils/helper';
import { copyToClipboard } from '../../utils/copyToClipboard';
import type { TextJawProps } from './types';
import ScalableText from './ScalableText';
import { ReactComponent as CopyIcon } from '../../static/svgs/copy-icon.svg';

import styles from './TextJaw.module.scss';

const TextJaw = ({
    text,
    visibleText = text,
    dataTestId = 'text-jaw',
    onCopyText,
    successMessage,
    className,
    wrapperClassName,
    clipped = false,
    scalable = false,
}: TextJawProps) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        let isMounted = true;

        (async function () {
            await sleep(2);
            isMounted && setShow(false);
        })();

        return () => {
            isMounted = false;
        };
    }, [show]);

    const copyText = () => {
        if (text) {
            copyToClipboard(text, () => {
                setShow(true);
                onCopyText?.(text);
            });
        }
    };

    return (
        <div className={cn(styles.textWrapper, wrapperClassName)} onClick={copyText} data-test-id={dataTestId}>
            {scalable && !clipped ? (
                <ScalableText text={visibleText} className={className} dataTestId={dataTestId} />
            ) : (
                <p className={cn(styles.text, { [styles.clip]: clipped }, className)}>{visibleText}</p>
            )}
            <CopyIcon />
            {show && successMessage && (
                <div className={styles.successMsgWrapper} data-test-id="success-modal">
                    <div className={styles.successMsg}>
                        <span>{successMessage}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TextJaw;
