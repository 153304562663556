import { useQuery } from 'react-query';

import { getFeedbackQuestions } from '../../../api/services/feedbackService';
import { useAppSettings } from '../../../component/AppSettings';
import type { FeedbackQuestionsMap } from '../../../component/FeedbackBanner';

async function getFeedbackQuestionsFn() {
    const feedbackQuestions = await getFeedbackQuestions();

    return feedbackQuestions.reduce<FeedbackQuestionsMap>(
        (obj, element) => ({
            ...obj,
            [element.screen]: element,
        }),
        {} as FeedbackQuestionsMap
    );
}

export function useFeedbackQuestions() {
    const { appCode } = useAppSettings();

    return useQuery<FeedbackQuestionsMap>({
        queryKey: [appCode, 'feedbackQuestions'],
        queryFn: getFeedbackQuestionsFn,
        cacheTime: Infinity,
        staleTime: Infinity,
    });
}
