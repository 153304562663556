import React from 'react';
import { useParams } from 'react-router-dom';

import { RequestStatusTypes } from '../../constants/RequestStatusTypes';
import { useInfoMessage } from '../../hooks/services/Info';
import InformContainer from '../../component/InformContainer';
import { CatLayoutBody } from '../../component/CatLayout';

import { ReactComponent as ApplyIcon } from '../../static/svgs/apply.svg';

import styles from './Info.module.scss';

type Params = {
    code: string;
};

const Info = () => {
    const { code = '' } = useParams<Params>();
    const { data, error } = useInfoMessage(code);

    return (
        <div className={styles.page}>
            {error ? (
                <CatLayoutBody
                    type={RequestStatusTypes.NOT_FOUND}
                    title={error.message}
                    contentClassName={styles.content}
                />
            ) : data ? (
                <InformContainer
                    icon={<ApplyIcon />}
                    header={data.message}
                    contentClassName={styles.content}
                    adaptive
                />
            ) : null}
        </div>
    );
};

export default Info;
