import { APP_MECHANICS_VALUES } from './appMechanics';

export enum IntegrationPointTypes {
    PRODUCTS = 'ECOSYSTEM',
    PRESENTS = 'PRESENTS',
    BUNDLE = 'BUNDLE',
    MIX = 'MIX',
    SAPSAN = 'SAPSAN',
}

export const MECHANICS_TO_INTEGRATION_POINT = {
    [APP_MECHANICS_VALUES.PRODUCTS]: IntegrationPointTypes.PRODUCTS,
    [APP_MECHANICS_VALUES.PRESENTS]: IntegrationPointTypes.PRESENTS,
    [APP_MECHANICS_VALUES.BUNDLES]: IntegrationPointTypes.BUNDLE,
    [APP_MECHANICS_VALUES.MIX]: IntegrationPointTypes.MIX,
    [APP_MECHANICS_VALUES.SAPSAN]: IntegrationPointTypes.SAPSAN,
};
