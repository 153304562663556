import React, { useContext, useEffect } from 'react';

import { ModalCtx } from '../../../reducer/modal';
import { CLOSE_MODAL_ACTION, ModalTypes } from '../../../constants/modal';
import { useAnalyticsQueue } from '../../../hooks';
import CommonPopUpLayout from '../CommonPopUpLayout';

import styles from './InformationPopUp.module.scss';

const { PROMOTION_RULES_MODAL } = ModalTypes;

const InformationPopUp = () => {
    const { pushMetrics } = useAnalyticsQueue();
    const {
        dispatch,
        state: { modalTitle, modalMessage, modalType },
    } = useContext(ModalCtx);

    useEffect(() => {
        if (modalType === PROMOTION_RULES_MODAL) {
            pushMetrics('SHOW_TERMS_MODAL');
        }
    }, [modalType]);

    const closeModal = () => dispatch({ type: CLOSE_MODAL_ACTION });

    return (
        <CommonPopUpLayout title={modalTitle} onClick={closeModal}>
            <div dangerouslySetInnerHTML={{ __html: modalMessage }} className={styles.message} />
        </CommonPopUpLayout>
    );
};

export default InformationPopUp;
