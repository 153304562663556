import type { MutationFunction } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { AuthError, AuthResponse, LoginRequestData, UserCurrent, UserInfo } from '../../../api/services/auth';
import { getUser, jwtLogin, loginGift, LoginType, qrLogin } from '../../../api/services/auth';
import type { IBaseResponse } from '../../../api/types';
import { useRefreshTokenTimer } from '../../../component/RefreshTokenTimer';
import useAnalyticsQueue from '../../useAnalyticsQueue';
import { ERROR_STATUS_CODE } from '../../../constants/common';
import ClickStream from '../../../utils/clickStream/ClickStream';

const userKey = ['user'] as const;

const getUseLogin =
    <TData extends AuthResponse, TVariables extends LoginRequestData>(
        mutationFn: MutationFunction<TData, TVariables>,
        loginTypeForError: LoginType
    ) =>
    () => {
        const { start } = useRefreshTokenTimer();
        const queryClient = useQueryClient();
        const { pushMetrics } = useAnalyticsQueue();

        return useMutation<TData, AuthError, TVariables>({
            mutationFn,
            onSuccess: ({ authority, loginType, personalNumber }, variables) => {
                start?.();
                queryClient.setQueryData<UserCurrent>(userKey, {
                    user: {
                        personalNumber,
                        loginType,
                        role: authority,
                    },
                    options: {
                        actionId: variables.extraInfo?.actionId,
                        resource: variables.extraInfo?.resource,
                    },
                });
                ClickStream.updateProfile({ hashUserLoginId: personalNumber });

                pushMetrics('AUTH_SUCCESS', { authority, loginType });
            },
            onError: ({ statusCode }) => {
                // for jwtLogin
                if (statusCode === ERROR_STATUS_CODE.UNPROCESSABLE_ENTITY) {
                    return;
                }

                pushMetrics('AUTH_ERROR', { loginType: loginTypeForError });
            },
        });
    };

export const useQrLogin = getUseLogin(qrLogin, LoginType.DIRECT_LINK);

export const useJwtLogin = getUseLogin(jwtLogin, LoginType.SBER_REGISTRY);

export const useGiftLogin = getUseLogin(loginGift, LoginType.GIFT_LINK);

const userQueryOptions = {
    queryKey: userKey,
    queryFn: getUser,
};

function selectUserInfo({
    user: { role, personalNumber, loginType },
    options: { actionId, resource } = {},
}: UserCurrent): UserInfo {
    return {
        role,
        personalNumber,
        loginType,
        actionId: actionId,
        resource: resource,
    };
}

export const useUserQuery = ({
    enabled = true,
    useErrorBoundary = true,
    refetchOnMount = true,
}: {
    enabled?: boolean;
    useErrorBoundary?: boolean;
    refetchOnMount?: boolean | 'always';
} = {}) => {
    const { start, pause } = useRefreshTokenTimer();
    return useQuery<UserCurrent, IBaseResponse, UserInfo>({
        ...userQueryOptions,
        select: selectUserInfo,
        staleTime: Infinity,
        cacheTime: Infinity,
        onSuccess: ({ personalNumber }) => {
            ClickStream.updateProfile({ hashUserLoginId: personalNumber });
            start?.();
        },
        onError: () => {
            pause?.();
        },
        enabled,
        useErrorBoundary,
        refetchOnMount,
    });
};
