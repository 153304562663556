import React, { forwardRef } from 'react';
import cn from 'classnames';

import { ADVERTISING_TAG_LABEL } from './constants';
import { ReactComponent as InfoWhiteIcon } from '../../static/svgs/info-white.svg';

import styles from './Tag.module.scss';

export enum TagPosition {
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
}

type Props = {
    tagPosition?: TagPosition;
    onClick?: () => void;
};

const Tag = forwardRef<HTMLDivElement, Props>(({ tagPosition = TagPosition.TOP_RIGHT, onClick }, ref) => (
    <div className={cn(styles.tag, styles[`tag-${tagPosition}`])} ref={ref} onClick={onClick}>
        <span className={styles.tagText}>{ADVERTISING_TAG_LABEL}</span>
        <InfoWhiteIcon className={styles.tagIcon} />
    </div>
));

Tag.displayName = 'Tag';

export default Tag;
