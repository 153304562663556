import React from 'react';

import type { TextJawProps } from '../TextJaw';
import TextJaw from '../TextJaw';

import styles from './PromoCode.module.scss';

type PromoCodeProps = Omit<
    TextJawProps,
    'visibleText' | 'scalable' | 'clipped' | 'className' | 'wrapperClassName' | 'successMessage'
>;

const PromoCode = ({ text, dataTestId = 'promo-code', onCopyText }: PromoCodeProps) => (
    <TextJaw
        text={text}
        onCopyText={onCopyText}
        successMessage="Промокод скопирован"
        dataTestId={dataTestId}
        className={styles.promoCode}
        wrapperClassName={styles.promoCodeWrapper}
        scalable
    />
);

export default PromoCode;
