import React, { useMemo, useState } from 'react';
import { useMount } from 'react-use';

import { scrollToTop } from '../../utils/helper';
import type { IPromoCampaign } from '../../api/types';
import MainPageLayout from '../../component/MainPageLayout/MainPageLayout';
import { useActiveMechanics, useAnalyticsQueue, useIntegrationPoint } from '../../hooks';
import ProductCard from './ProductCard';
import MainPageCardList from '../../component/MainPageCardList';
import FilterPanel from '../../component/FilterPanel/FilterPanel';
import { getCategoryListFromCampaignList } from '../../utils/promoCampaignHelper';
import { MainPageCardSkeleton } from '../../component/MainPageCard';
import promoCampaignTypes from '../../constants/promoCampaignTypes';
import { APP_MECHANICS_VALUES } from '../../constants/appMechanics';
import { usePromoCampaignsQuery } from '../../hooks/services/promoCampaigns';
import { useClickPromoCampaignOffer } from '../../hooks/useClickPromoCampaignOffer';

type Props = {
    goToOffer: (promoCampaignId: string) => void;
    goToProduct: (promoCampaignId: string) => void;
};

const Products = ({ goToProduct, goToOffer }: Props) => {
    const [filterList, setFilterList] = useState<number[]>([]);
    const { pushMetrics } = useAnalyticsQueue();
    const mechanics = useActiveMechanics();
    const integrationPoint = useIntegrationPoint();
    const isSapsan = mechanics === APP_MECHANICS_VALUES.SAPSAN;
    const type = !isSapsan ? promoCampaignTypes.PRODUCT : undefined;
    const { onClickPromoCampaignOffer, isLoading } = useClickPromoCampaignOffer();
    const {
        data: promoCampaigns,
        isFetching,
        error,
    } = usePromoCampaignsQuery({
        integrationPoint,
        type,
    });

    const categoryList = useMemo(
        () => (promoCampaigns ? getCategoryListFromCampaignList(promoCampaigns) : []),
        [promoCampaigns]
    );
    const filteredList = useMemo(() => {
        if (!promoCampaigns) {
            return [];
        }

        return filterList.length === 0
            ? promoCampaigns
            : promoCampaigns.filter(
                  (promoCampaign) =>
                      promoCampaign.categoryList.filter((category) => filterList.includes(category.categoryId)).length >
                      0
              );
    }, [filterList, promoCampaigns]);

    useMount(() => {
        pushMetrics('TO_PRODUCTS_PAGE');
        scrollToTop();
    });

    const onProductCardClick = async (promoCampaign: IPromoCampaign) => {
        if (isLoading) {
            return;
        }

        const { dzoName, id: campaignId, name: campaignName, originalId, productCampaignId } = promoCampaign;
        pushMetrics('PRODUCT_OFFER_CLICK', { campaignId: originalId, campaignName, dzoName, productCampaignId });

        onClickPromoCampaignOffer({
            promoCampaign,
            goToOffer: () => goToOffer(campaignId),
        });
    };

    const onProductDetailClick = ({
        dzoName,
        id: campaignId,
        name: campaignName,
        originalId,
        productCampaignId,
    }: IPromoCampaign) => {
        pushMetrics('MAIN_DETAIL_CLICK', { campaignId: originalId, campaignName, dzoName, productCampaignId });
        goToProduct(campaignId);
    };

    return (
        <MainPageLayout
            filterPanel={
                <FilterPanel categoryList={categoryList} filterList={filterList} setFilterList={setFilterList} />
            }
            cardList={
                <MainPageCardList>
                    {!isFetching && !error
                        ? filteredList.map((product) => (
                              <ProductCard
                                  key={`${product.id}`}
                                  product={product}
                                  onClick={onProductCardClick}
                                  onDetailClick={
                                      product.type === promoCampaignTypes.PRODUCT ? onProductDetailClick : undefined
                                  }
                              />
                          ))
                        : Array.from(Array(3)).map((_, index) => (
                              <MainPageCardSkeleton key={'product' + index} id={index} />
                          ))}
                </MainPageCardList>
            }
            errorData={error}
        />
    );
};

export default Products;
