import { authenticatedRequest, publicRequest } from '../../axios';
import type { RegisterClientRequest, RegisterClientResponse, SaveClientExtraDataRequest } from './types';
import type { IBaseResponse } from '../../types';
import { APP_CODE_HEADER } from '../../../constants/common';

export async function registerClient(appCode: string, registerClient: RegisterClientRequest) {
    const { state, consentId, smmrConsentId, phoneNumber } = registerClient;
    const consentIds = [consentId, smmrConsentId].filter(Boolean);
    const body = state ? { state, consentIds } : { phoneNumber, consentIds };
    const { data } = await publicRequest.post<RegisterClientResponse>('/client/register', body, {
        headers: {
            [APP_CODE_HEADER]: appCode,
        },
    });

    return data;
}

/**
 * Сохранение дополнительной информации о клиенте (email, версия согласия и т.д.).
 * При передаче email, который ранее не был подтвержден для данного клиента,
 * бэк автоматом отправляет на него письмо со ссылкой на подтверждение
 **/
export async function saveClientExtraDataToBackend(data: SaveClientExtraDataRequest) {
    return await authenticatedRequest.post<IBaseResponse>('/client/save/extra-data', data);
}

export async function checkEmailIsVerified(clientId: number) {
    return await authenticatedRequest.get(`client/email/verified/${clientId}`);
}

export async function logoutClient(appCode: string, clientId: number) {
    return await publicRequest.post(
        '/client/logout',
        { clientId },
        {
            headers: {
                [APP_CODE_HEADER]: appCode,
            },
        }
    );
}
