import type { ReactNode } from 'react';
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import type { FormValues, Submit } from '../types';

type Props = {
    onSubmit: Submit;
    className?: string;
    children?: ReactNode;
};

const Form = ({ onSubmit, className, children }: Props) => {
    const methods = useForm<FormValues>({
        defaultValues: {
            code: '',
            agreeWithAdvertising: false,
        },
        mode: 'all',
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} className={className}>
                {children}
            </form>
        </FormProvider>
    );
};

export default Form;
