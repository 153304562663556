import { AppThemes } from '../../constants/AppThemes';
import defenderFatherlandDayThemeImgUrl from '../../static/themes/defenderFatherlandDay/main-page-image.svg';
import summerThemeImgUrl from '../../static/themes/summer/main-page-image.svg';
import victoryDayThemeImgUrl from '../../static/themes/victoryDay/main-page-image.svg';
import womensDayThemeImgUrl from '../../static/themes/womensDay/main-page-image.svg';
import defaultThemeImgUrl from '../../static/themes/default/main-page-image.svg';
import greenDayThemeImgUrl from '../../static/themes/greenDay/main-page-image.png';
import newYearThemeImgUrl from '../../static/themes/newYear/main-page-image.png';

const { DEFAULT, DEFENDER_FATHERLAND_DAY, GREEN_DAY, NEW_YEAR, SUMMER, VICTORY_DAY, WOMENS_DAY } = AppThemes;

export function getAppThemeImage(appTheme: AppThemes) {
    switch (appTheme) {
        case DEFENDER_FATHERLAND_DAY:
            return defenderFatherlandDayThemeImgUrl;
        case GREEN_DAY:
            return greenDayThemeImgUrl;
        case NEW_YEAR:
            return newYearThemeImgUrl;
        case SUMMER:
            return summerThemeImgUrl;
        case VICTORY_DAY:
            return victoryDayThemeImgUrl;
        case WOMENS_DAY:
            return womensDayThemeImgUrl;
        case DEFAULT:
        default:
            return defaultThemeImgUrl;
    }
}
