import React from 'react';
import { Navigate, Route, Routes, useNavigate, useResolvedPath } from 'react-router-dom';
import Presents from './Presents';
import SuccessPage from '../SuccessPage/SuccessPage';
import { PAGES } from '../../constants/route';
import SelectedOffers from '../SelectedOffers';

const PresentsRouter = () => {
    const navigate = useNavigate();
    const { pathname } = useResolvedPath('.');

    const goToPresents = () => navigate('.');

    const goToSuccessPage = (ids: string[]) =>
        navigate({
            pathname: PAGES.SUCCESS,
            search: new URLSearchParams({
                selectedOffers: ids.join(','),
            }).toString(),
        });

    return (
        <Routes>
            <Route path="/" element={<Presents goNext={goToSuccessPage} />} />
            <Route
                path={PAGES.SUCCESS}
                element={
                    <SelectedOffers
                        render={(selectedOffers) =>
                            selectedOffers?.length ? (
                                <SuccessPage
                                    selectedItems={selectedOffers}
                                    goBackPath={pathname}
                                    goBack={goToPresents}
                                />
                            ) : (
                                <Navigate to=".." replace />
                            )
                        }
                    />
                }
            />
            <Route path="*" element={<Navigate to=".." replace />} />
        </Routes>
    );
};

export default PresentsRouter;
