import type { MouseEvent } from 'react';
import React from 'react';

import type { productTargetKeys } from '../../../utils/clickStream';
import type { ConsentType } from '../../../constants/settings';

import styles from './ConsentLink.module.scss';

type Props = {
    type: ConsentType;
    onLinkClick: (type: ConsentType, eventAction: productTargetKeys) => void;
    eventAction: productTargetKeys;
    children: string;
};

const ConsentLink = ({ type, onLinkClick, eventAction, children }: Props) => {
    const onClick = (e: MouseEvent) => {
        e.preventDefault();

        onLinkClick(type, eventAction);
    };

    return (
        <span className={styles.link} onClick={onClick}>
            {children}
        </span>
    );
};

export default ConsentLink;
