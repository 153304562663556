import type { ClickStreamTargetsList, productTargetValues } from './types';
import { DELIMITER } from '../../constants/clickStreamTargetList';

export default function getMechanicActions(list: ClickStreamTargetsList) {
    const actionList: Record<string, string> = {};

    const getEventName = (valueObj: productTargetValues | ClickStreamTargetsList, keyString?: string) => {
        Object.entries(valueObj).forEach(([key, value]) => {
            if (!keyString) {
                return getEventName(value, key);
            }
            if (typeof value === 'object') {
                const filledKey = key !== 'Empty' ? keyString + DELIMITER + key : keyString;
                getEventName(value, filledKey);
            } else {
                actionList[key] = keyString + DELIMITER + value;
            }
        });
    };

    getEventName(list);
    return actionList;
}
