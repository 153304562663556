import type { InputHTMLAttributes, ReactNode } from 'react';
import React, { forwardRef } from 'react';
import cn from 'classnames';

import styles from './Checkbox.module.scss';

export type CheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> & {
    name: string;
    value: boolean;
    label: ReactNode;
    error?: boolean | string;
    dataTestId?: string;
    id?: string;
};

export const DATA_TEST_ID_CHECKBOX = 'checkbox';
export const REQUIRED_FIELD_WARNING = 'Необходимо подтвердить';

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    ({ value, label, error, disabled, dataTestId = DATA_TEST_ID_CHECKBOX, ...props }, ref) => (
        <>
            <label className={cn(styles.checkbox, { [styles.disabled]: disabled, [styles.error]: error })}>
                <input {...props} checked={value} ref={ref} type="checkbox" data-test-id={dataTestId} />
                <p>{label}</p>
            </label>
            {error && <p className={styles.errorMsg}>{REQUIRED_FIELD_WARNING}</p>}
        </>
    )
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
