const promoTextTypes = {
    HEADER: 'HEADER',
    HEADER_PRESENT: 'HEADER_PRESENT',
    HEADER_PRESENT_QR: 'HEADER_PRESENT_QR',
    HEADER_PRODUCT: 'HEADER_PRODUCT',
    HEADER_PRODUCT_QR: 'HEADER_PRODUCT_QR',
    DESCRIPTION: 'DESCRIPTION',
    DESCRIPTION_PRESENT: 'DESCRIPTION_PRESENT',
    DESCRIPTION_PRODUCT: 'DESCRIPTION_PRODUCT',
    RULES: 'RULES',
    MANUAL_SUBSCRIPTION: 'MANUAL_SUBSCRIPTION',
    HEADER_MANUAL: 'HEADER_MANUAL',
} as const;

export default promoTextTypes;
