import React from 'react';

import styles from './ErrorMessageField.module.css';

type Props = {
    error?: string;
};

const ErrorMessageField = ({ error }: Props) => (error ? <div className={styles.error}>{error}</div> : null);

export default ErrorMessageField;
