import type { ReactNode } from 'react';
import React, { useCallback } from 'react';
import cn from 'classnames';

import { noop } from '../../utils/helper';

import styles from './Tag.module.scss';

export interface TagProps {
    selected?: boolean;
    children: ReactNode;
    onClick?: (value: string | number, selected: boolean) => void;
    value: string | number;
    className?: string;
    dataTestId?: string;
}

const Tag = ({
    selected = false,
    onClick = noop,
    value,
    className,
    dataTestId = 'filter-tags-item',
    children,
}: TagProps) => {
    const click = useCallback(() => {
        onClick(value, selected);
    }, [onClick, value, selected]);

    return (
        <div
            className={cn(styles.tag, className, { [styles.selected]: selected })}
            onClick={click}
            data-test-id={dataTestId}
        >
            <div className={styles.body}>{children}</div>
        </div>
    );
};

export default Tag;
