import type { IBaseResponse, IPromoCampaignDto } from '../../types';
import type { IntegrationPointTypes } from '../../../constants/integrationPointTypes';

export type AddClientOffersOptions = {
    clientId: number;
    promoCampaignIds: string[];
    campaignGroupId?: string | null;
    integrationPoint?: IntegrationPointTypes;
};

export interface IAdditionalInfo {
    campaignGroupId?: number | null;
}

export interface IClientOffer {
    offerId: number;
    personalUrl: string;
    paymentUrl?: string;
    promoCode?: string | null;
    promoCodeId?: number | null;
    campaignId: string;
}

export type IClientOffers = {
    offers: Record<string, IClientOffer>;
} & Pick<IBaseResponse, 'description' | 'requestId'>;

export interface IAddOffersResponse extends IBaseResponse {
    list: IClientOffer[];
}

export interface IOfferInfoResponse {
    campaign: IPromoCampaignDto;
}

export enum OfferState {
    RESERVED = 'InProgress.Reserved',
    PAID = 'InProgress.Paid',
    PAID_WITH_RESTRICTION = 'InProgress.PaidWithRestriction',
    ERROR_BUTTON = 'error.button',
    ERROR = 'error',
}

export type OfferStateResponse = IBaseResponse & {
    state: OfferState;
};
