import React from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './Landing.module.scss';

export const DATA_TEST_ID_LANDING_SKELETON = 'landing-skeleton';

const LandingSkeleton = () => (
    <div className={styles.imgWrapper} data-test-id={DATA_TEST_ID_LANDING_SKELETON}>
        <div className={styles.skeletonContent}>
            <div className={styles.skeletonTextBlock}>
                <Skeleton className={styles.skeletonLogo} containerClassName={styles.skeletonLogoWrapper} />
                <Skeleton containerClassName={styles.titleWrapper} className={styles.title} count={2.8} />
                <Skeleton containerClassName={styles.descriptionWrapper} className={styles.description} count={1.7} />
            </div>
            <Skeleton className={styles.skeletonImage} containerClassName={styles.skeletonImageWrapper} />
        </div>
        <div className={styles.skeletonDescriptionBlock}>
            <div className={styles.skeletonTitle}>
                <Skeleton count={0.8} containerClassName={styles.skeletonTitle} />
            </div>
            <div className={styles.skeletonDescription}>
                <Skeleton count={2.3} />
            </div>
        </div>
    </div>
);

export default LandingSkeleton;
