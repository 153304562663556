import React, { useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { getTextFullWidth } from '../../../utils/stringHelper';
import { useWindowResize } from '../../../hooks/window-event-hooks';

import styles from '../TextJaw.module.scss';

type Props = {
    text: string;
    className?: string;
    dataTestId?: string;
};

const ScalableText = ({ text, className, dataTestId = 'text' }: Props) => {
    const textRef = useRef<HTMLDivElement>(null);
    const [fontSize, setFontSize] = useState(0);

    function changeTextSize() {
        if (!textRef.current) {
            return;
        }

        setFontSize(0);

        const textBlockWidth = textRef.current.getBoundingClientRect().width;
        const refFontSize = parseInt(window.getComputedStyle(textRef.current, null).getPropertyValue('font-size'), 10);
        const fontFamily = window.getComputedStyle(textRef.current, null).getPropertyValue('font-family');
        const fontWeight = parseInt(window.getComputedStyle(textRef.current, null).getPropertyValue('font-weight'), 10);
        const fullWidth = Math.floor(getTextFullWidth({ text, fontSize: refFontSize, fontFamily, fontWeight }));

        if (textBlockWidth < fullWidth) {
            const newFontSize = Math.floor((refFontSize * textBlockWidth) / fullWidth);
            if (newFontSize < refFontSize) {
                setFontSize(newFontSize);
            }
        }
    }

    useLayoutEffect(() => {
        changeTextSize();
    }, []);

    useWindowResize(changeTextSize);

    return (
        <p
            className={cn(styles.text, className)}
            style={fontSize ? { fontSize: `${fontSize}px` } : {}}
            ref={textRef}
            data-test-id={`scalable-${dataTestId}`}
        >
            {text}
        </p>
    );
};

export default ScalableText;
