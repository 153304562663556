import type { ReactNode } from 'react';
import React, { Children } from 'react';
import cn from 'classnames';

import styles from './PresentCardList.module.scss';

type Props = {
    children?: ReactNode;
};

const PresentCardList = ({ children }: Props) => (
    <div className={styles.listWrapper}>
        <div className={cn(styles.list, { [styles.doubleColumn]: Children.count(children) === 2 })}>{children}</div>
    </div>
);

export default PresentCardList;
