import type { FocusEvent } from 'react';
import React, { forwardRef, useState } from 'react';
import type { BaseInputProps } from '../BaseInput';
import BaseInput from '../BaseInput';

export type InputProps = Omit<BaseInputProps, 'focused' | 'name' | 'value'> & {
    name: string;
    value: string;
};

const Input = forwardRef<HTMLInputElement, InputProps>(({ onBlur, onFocus, ...props }, ref) => {
    const [focused, setFocused] = useState(false);

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        setFocused(true);
        onFocus?.(e);
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        setFocused(false);
        onBlur?.(e);
    };

    return <BaseInput {...props} onFocus={handleFocus} onBlur={handleBlur} focused={focused} ref={ref} />;
});

Input.displayName = 'Input';

export default Input;
