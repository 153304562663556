import type { ReactNode } from 'react';
import React, { createContext, useContext } from 'react';

import type { UserInfo } from '../../api/services/auth';
import { useUserQuery } from '../../hooks';

export const UserCtx = createContext<UserInfo | undefined>(undefined);

export const useUser = () => {
    const userCtx = useContext(UserCtx);

    if (typeof userCtx === 'undefined') {
        throw Error(`Use useUser() inside <UserProvider>`);
    }

    return userCtx;
};

type Props = {
    children?: ReactNode;
};

export const UserProvider = ({ children }: Props) => {
    const { data, isFetching } = useUserQuery();

    if (!data || isFetching) {
        return null;
    }

    return <UserCtx.Provider value={data}>{children}</UserCtx.Provider>;
};
