import { useQuery } from 'react-query';

import { checkSupportsCertificate } from '../../../api/services/certificate';
import { CERTIFICATE_CHANGE } from '../../../constants/appEnvironments';
import { AppCertificateChangeTypes } from '../../../constants/common';

const { FORBIDDEN, WARNING } = AppCertificateChangeTypes;

const getSupportsCertificateQueryFn = async () => {
    if (CERTIFICATE_CHANGE === FORBIDDEN || CERTIFICATE_CHANGE === WARNING) {
        return await checkSupportsCertificate();
    }

    return { supportsCertificate: true };
};

type SupportCertificateResponse = { supportsCertificate: boolean };

export function useSupportsCertificate() {
    return useQuery<SupportCertificateResponse>({
        queryKey: 'certificate',
        queryFn: getSupportsCertificateQueryFn,
        useErrorBoundary: false,
        staleTime: Infinity,
        cacheTime: Infinity,
    });
}
