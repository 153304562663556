import React, { useCallback, useEffect } from 'react';
import { scrollToTop } from '../../utils/helper';
import useDevices from '../../hooks/useDevices';
import Header from '../Header';
import AppThemeDecorator from '../AppThemeDecorator';
import Themes from '../../constants/Themes';
import CorpLogo from '../CorpLogo';
import DecorationPanelContainer from '../../containers/DecorationPanelContainer';
import type { IAppItem, IAppSelection } from './types';
import FooterInfo from '../FooterInfo';
import { useUser } from '../User';
import { Authority } from '../../api/services/auth';

import { ReactComponent as ArrowIcon } from '../../static/svgs/arrow-right-black.svg';

import styles from './AppSelection.module.scss';

const TITLE = 'Выберите витрину';
const CONTACT_INFO =
    'Если отсутствует нужная витрина, то создайте заявку в АС «СберДруг» по шаблону «Вход в АС Витрина Платформы дистрибуции»';

export function AppItem({ displayName, handleClick, code }: IAppItem) {
    const onClick = useCallback(() => handleClick(code), [handleClick, code]);

    return (
        <div className={styles.item} onClick={onClick} data-test-id="login-redirect-button">
            <p className={styles.appName}>{displayName}</p>
            <div className={styles.pseudoButton}>
                <ArrowIcon />
            </div>
        </div>
    );
}

export default function AppSelection({ list, onItemClick }: IAppSelection) {
    const { isDesktop } = useDevices();
    const { role } = useUser();
    const theme = isDesktop ? Themes.LIGHT : Themes.DARK;

    useEffect(scrollToTop, []);

    return (
        <DecorationPanelContainer>
            <AppThemeDecorator>
                <div className={styles.page}>
                    <Header position="static" theme={theme} renderLeft={() => <CorpLogo theme={theme} />} />
                    <div className={styles.content}>
                        <p className={styles.title}>{TITLE}</p>
                        <div className={styles.itemsBlock}>
                            {list.map(({ id, code, displayName }) => (
                                <AppItem key={id} displayName={displayName} code={code} handleClick={onItemClick} />
                            ))}
                        </div>
                        {role !== Authority.ReferralLink && <FooterInfo position="left" contactInfo={CONTACT_INFO} />}
                    </div>
                </div>
            </AppThemeDecorator>
        </DecorationPanelContainer>
    );
}
