import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getBundleList } from '../../api/services/bundleService';
import type { BundleParams } from './types';
import { SERVER_ERROR } from '../../constants/ErrorLabels';
import { bundleLinkToPromoCampaign } from '../../utils/mapper';
import SuccessPageLayout from '../../component/SuccessPageLayout';
import { useIntegrationPoint } from '../../hooks';
import type { IBaseResponse, IPromoCampaign } from '../../api/types';
import { useAppSettings } from '../../component/AppSettings';

type Props = {
    goBack: () => void;
    selectedItems: string[];
};

const BundleSuccessPage = ({ goBack, selectedItems }: Props) => {
    const { bundleId = '' } = useParams<BundleParams>();
    const [promoCampaigns, setPromoCampaigns] = useState<IPromoCampaign[]>();
    const [promoCampaignsError, setPromoCampaignsError] = useState<IBaseResponse>();
    const integrationPoint = useIntegrationPoint();
    const appSettings = useAppSettings();

    useEffect(() => {
        let isMounted = true;

        (async function () {
            try {
                const groups = await getBundleList({
                    ids: [parseInt(bundleId, 10)],
                    integrationPoint,
                });

                if (!isMounted) {
                    return;
                }

                const { links = [] } = groups[0];

                setPromoCampaigns(
                    links
                        .filter(({ campaignId: id }) => selectedItems.includes(`${id}`))
                        .map((el) => bundleLinkToPromoCampaign(el, appSettings))
                );
            } catch (e) {
                isMounted &&
                    setPromoCampaignsError({
                        message: e?.message || SERVER_ERROR,
                        description: e?.description,
                        requestId: e?.requestId,
                    });
            }
        })();

        return () => {
            isMounted = false;
        };
    }, [selectedItems]);

    return (
        <SuccessPageLayout
            promoCampaigns={promoCampaigns}
            goBack={goBack}
            promoCampaignIds={selectedItems}
            promoCampaignsError={promoCampaignsError}
            campaignGroupId={bundleId}
        />
    );
};

export default BundleSuccessPage;
