import React, { useState } from 'react';
import { useController } from 'react-hook-form';

import type { FormValues } from '../../types';
import Star from '../../../../static/svgs/Star';

import styles from './RatingInput.module.scss';

const RatingInput = () => {
    const [hover, setHover] = useState(0);
    const {
        field: { value, onChange, ...field },
    } = useController<FormValues, 'rating'>({
        name: 'rating',
        rules: {
            required: true,
        },
    });

    return (
        <div className={styles.ratingBox} data-test-id="rating-input-wrapper">
            {[...Array(5)].map((_, index) => {
                const ratingValue = index + 1;
                return (
                    <label
                        key={`star-${index}`}
                        className={styles.star}
                        onMouseEnter={() => setHover(ratingValue)}
                        onMouseLeave={() => setHover(0)}
                    >
                        <input
                            {...field}
                            data-test-id={`rating-radio-${ratingValue}`}
                            onChange={() => onChange(ratingValue)}
                            autoComplete="off"
                            type="radio"
                            autoFocus
                        />
                        <Star filled={ratingValue <= (hover || value)} />
                    </label>
                );
            })}
        </div>
    );
};

export default RatingInput;
