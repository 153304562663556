import React, { useEffect } from 'react';
import cn from 'classnames';

import { noop } from '../../utils/helper';
import Button from '../Button';
import type { IInformContainer } from './types';

import styles from './InformContainer.module.scss';

const InformContainer = ({
    icon,
    requestId,
    header,
    headerClassName,
    message,
    messageClassName,
    buttonLabel,
    buttonCancelLabel,
    onMount = noop,
    onClick,
    onCancelClick,
    contentClassName,
    buttonClassName,
    buttonCancelClassName,
    adaptive,
    buttonCancelProps,
    buttonProps,
    loading,
}: IInformContainer) => {
    useEffect(onMount, []);

    return (
        <div className={cn(styles.content, contentClassName)}>
            <div className={styles.iconWrapper} data-test-id="inform-container-icon">
                {typeof icon === 'string' ? <img src={icon} alt="" className={styles.icon} /> : icon}
            </div>
            <p className={cn(styles.header, headerClassName)}>{header}</p>
            {message && <p className={cn(styles.message, messageClassName)}>{message}</p>}
            {requestId && <p className={styles.info}>ID запроса: {requestId}</p>}
            <div className={styles.buttonBlock}>
                {onCancelClick && buttonCancelLabel && (
                    <Button
                        {...buttonCancelProps}
                        type="outline"
                        label={buttonCancelLabel}
                        onClick={onCancelClick}
                        className={buttonCancelClassName ?? buttonClassName}
                        adaptive={adaptive}
                        dataTestId="button-cancel"
                        loading={loading}
                    />
                )}
                {onClick && buttonLabel && (
                    <Button
                        {...buttonProps}
                        label={buttonLabel}
                        type="contained"
                        onClick={onClick}
                        className={buttonClassName}
                        adaptive={adaptive}
                        dataTestId="button-submit"
                        loading={loading}
                    />
                )}
            </div>
        </div>
    );
};

export default InformContainer;
