import React, { useEffect, useState } from 'react';

import { ERROR_STATUS_CODE } from '../../constants/common';
import { useDevices, useAnalyticsQueue } from '../../hooks';
import { saveClientExtraDataToBackend } from '../../api/services/clientService';
import { sendVerificationCode, verifyCode } from '../../api/services/notificationService';
import { useClient } from '../Client';
import { useAppSettings } from '../AppSettings';
import type { ButtonSizes } from '../Button';
import Button from '../Button';
import AdvertisingConsentField from '../AdvertisingConsentField';
import ButtonWithTimeout from '../ButtonWithTimeout';
import SubmitButton from '../SubmitButton';
import SuccessPageModalBody from '../SuccessPageModalBody';

import type { ErrorState, Props, State, Submit } from './types';
import CodeInput from './CodeInput';
import Form from './Form';
import SbolWarning from './SbolWarning';

import { ReactComponent as SuccessIcon } from '../../static/svgs/apply.svg';
import { ReactComponent as ErrorIcon } from '../../static/svgs/not-found.svg';
import { ReactComponent as ReloadIcon } from '../../static/svgs/reload-icon-grey.svg';

import styles from './SbolPhoneConfirmationForm.module.scss';

const FORM_TITLE = 'Подтвердите номер';
const RESEND_CODE_BUTTON_LABEL = 'Отправить код повторно';
const SUCCESS_MESSAGE = 'Предложение отправлено';
const BUTTON_LABEL = 'Продолжить';

const initialState = { sent: false, incorrectCode: false };

const SbolPhoneConfirmationForm = ({
    onCancel,
    onSubmit,
    pushSVEventSuccessOfferShow,
    showCloseButton = true,
}: Props) => {
    const { client, setExtraData, setPhoneNumberConfirmation } = useClient();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { id: clientId, phoneNumber, agreeWithAdvertising } = client!;
    const { advertisingConsentId } = useAppSettings();
    const { pushMetrics } = useAnalyticsQueue();
    const [{ sent, incorrectCode }, setState] = useState<State>(initialState);
    const [{ message, description, requestId }, setErrorState] = useState<ErrorState>({});
    const { isMobile } = useDevices();
    const buttonSize: ButtonSizes = !isMobile ? 'x-large' : 'large';

    useEffect(() => {
        (async () => {
            await sendCode();
        })();
        pushMetrics('CONFIRM_CODE_SHOW');
    }, []);

    const setError = (message: string, description?: string, requestId?: string) =>
        setErrorState({
            message,
            description,
            requestId,
        });

    const sendCode = async () => {
        try {
            await sendVerificationCode(clientId);
        } catch (e) {
            setError(e.message, e.description, e.requestId);
        }
    };

    const onSubmitButtonClick: Submit = async (data) => {
        const { code } = data;
        try {
            pushMetrics('CONFIRM_SEND_CLICK');

            if (!agreeWithAdvertising && data.agreeWithAdvertising) {
                await saveClientExtraDataToBackend({ consentId: advertisingConsentId, clientId });
                setExtraData({ agreeWithAdvertising: true });
            }

            await verifyCode(clientId, code);
            setPhoneNumberConfirmation(true);

            if (onSubmit) {
                await onSubmit();
            }

            setState((prevState) => ({ ...prevState, sent: true, incorrectCode: false }));
        } catch (e) {
            const { statusCode = ERROR_STATUS_CODE.INTERNAL_SERVER_ERROR } = e;
            if (statusCode === ERROR_STATUS_CODE.NOT_ACCEPTABLE) {
                setState((prevState) => ({ ...prevState, incorrectCode: true }));
            }
            setError(e.message, e.description, e.requestId);
        }
    };

    const onResendBtnClickWithIncorrectCode = async () => {
        pushMetrics('CONFIRM_REPEAT_SEND_CLICK');
        setErrorState({});
        setState(initialState);
        await sendCode();
    };

    const onResendButtonClick = async () => {
        pushMetrics('CONFIRM_REPEAT_SEND_CLICK');
        await sendCode();
    };

    const onCloseButtonClick = showCloseButton ? onCancel : undefined;

    if (incorrectCode) {
        return (
            <SuccessPageModalBody
                renderTopBlock={() => <ErrorIcon />}
                onCloseButtonClick={onCloseButtonClick}
                header={message}
                description={description}
                dataTestId="push-incorrect-code-modal"
                renderButtons={() => (
                    <Button
                        label={RESEND_CODE_BUTTON_LABEL}
                        type="contained"
                        size={buttonSize}
                        onClick={onResendBtnClickWithIncorrectCode}
                        dataTestId="push-incorrect-code-modal-resend-button"
                    />
                )}
            />
        );
    }

    if (message) {
        return (
            <SuccessPageModalBody
                renderTopBlock={() => <ErrorIcon />}
                header={message}
                description={description}
                requestId={requestId}
                dataTestId="sbol-confirmation-error"
                renderButtons={() => (
                    <Button
                        label={BUTTON_LABEL}
                        onClick={onCancel}
                        type="contained"
                        size={buttonSize}
                        dataTestId="sbol-confirmation-error-continue-button"
                    />
                )}
            />
        );
    }

    if (sent) {
        return (
            <SuccessPageModalBody
                renderTopBlock={() => <SuccessIcon />}
                header={SUCCESS_MESSAGE}
                dataTestId="successful-sbol-modal"
                renderButtons={() => (
                    <Button
                        label={BUTTON_LABEL}
                        onClick={onCancel}
                        type="contained"
                        size={buttonSize}
                        dataTestId="successful-sbol-modal-continue-button"
                    />
                )}
                onMount={pushSVEventSuccessOfferShow}
            />
        );
    }

    return (
        <Form onSubmit={onSubmitButtonClick} className={styles.content}>
            <SuccessPageModalBody
                onCloseButtonClick={onCloseButtonClick}
                header={`${FORM_TITLE}\n${phoneNumber}`}
                description="Введите код из Push-уведомления"
                dataTestId="sbol-phone-confirmation-form"
                renderContent={() => (
                    <>
                        <SbolWarning />
                        <CodeInput />
                        {!agreeWithAdvertising && (
                            <div className={styles.field}>
                                <AdvertisingConsentField eventAction="CONFIRM_CODE_ADVERTISING_POLICY_BTN_CLICK" />
                            </div>
                        )}
                    </>
                )}
                renderButtons={() => (
                    <>
                        <SubmitButton label="Подтвердить" size={buttonSize} fullWidth />
                        <ButtonWithTimeout
                            label={RESEND_CODE_BUTTON_LABEL}
                            labelSent="Код отправлен!"
                            timeoutSuffix="Отправить повторно через "
                            onClick={onResendButtonClick}
                            clicksLimit={1}
                            dataTestId="resend-sbol-code-button"
                            size={buttonSize}
                            color="brand"
                            type="text"
                            icon={<ReloadIcon />}
                            iconPosition="right"
                            className={styles.buttonReset}
                            fullWidth
                            timeoutOnStart
                        />
                    </>
                )}
            />
        </Form>
    );
};

export default SbolPhoneConfirmationForm;
