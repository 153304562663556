import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { GIFT_ROUTE, PARAMS, ROUTE } from './constants/route';
import { changeScalingFactor } from './utils/helper';
import { useWindowResize } from './hooks/window-event-hooks';
import CertificateWarning from './component/CertificateWarning';
import { QRAuth } from './component/QRLogin';
import JWTLogin from './component/JWTLogin';
import AppsRouter from './containers/Apps';
import { ClientOfferShare } from './containers/ClientOffer';
import AuthSberId from './component/AuthSberId';
import { WheelOne } from './containers/Wheel';
import { RefreshTokenTimerProvider } from './component/RefreshTokenTimer';
import Info from './containers/Info';
import GiftLogin from './component/GiftLogin';
import { UserProvider } from './component/User';
import ErrorBoundaryContainer from './containers/ErrorBoundaryContainer';
import ContextContainer from './containers/ContextContainer';
import CertificateContainer from './containers/CertificateContainer';

import './static/fonts/fonts.css';
import styles from './App.module.css';

const App = () => {
    useWindowResize(changeScalingFactor);

    return (
        <CertificateContainer>
            <ErrorBoundaryContainer>
                <RefreshTokenTimerProvider>
                    <ContextContainer>
                        <div className={styles.app} data-test-id="app">
                            <CertificateWarning />
                            <Routes>
                                <Route path={ROUTE.CORE} element={<QRAuth />} />
                                <Route path={ROUTE.AUTH_SBER_ID} element={<AuthSberId />} />
                                <Route path={GIFT_ROUTE.SEND_PAGE} element={<ClientOfferShare />} />
                                <Route path={GIFT_ROUTE.GET_PAGE} element={<GiftLogin />} />
                                <Route
                                    path={`${ROUTE.APPS}/*`}
                                    element={
                                        <UserProvider>
                                            <AppsRouter />
                                        </UserProvider>
                                    }
                                />
                                <Route path={ROUTE.JWT_LOGIN} element={<JWTLogin />} />
                                <Route path={`${ROUTE.INFO}/${PARAMS.INFO_CODE}`} element={<Info />} />
                                <Route path={ROUTE.FORTUNE_WHEEL} element={<WheelOne />} />
                                <Route path={ROUTE.WHEEL_ONE} element={<WheelOne />} />
                                {/*<Route path={ROUTE.PROMO_LANDING} element={<PromoLanding />} />*/}
                                <Route path="*" element={<Navigate to={ROUTE.APPS} replace />} />
                            </Routes>
                        </div>
                    </ContextContainer>
                </RefreshTokenTimerProvider>
            </ErrorBoundaryContainer>
        </CertificateContainer>
    );
};

export default App;
