import React from 'react';
import Skeleton from 'react-loading-skeleton';
import cn from 'classnames';

import type { TextJawProps } from './types';

import styles from './TextJaw.module.scss';

const TextJawSkeleton = ({ dataTestId = 'text-jaw' }: Pick<TextJawProps, 'dataTestId'>) => (
    <Skeleton
        containerClassName={cn(styles.textWrapper, styles.skeleton)}
        className={styles.text}
        containerTestId={`${dataTestId}-skeleton`}
    />
);

export default TextJawSkeleton;
