import React from 'react';

import type { ICategoryDto } from '../../api/types';
import FilterTags from '../../component/FilterTags';
import Tag from '../../component/Tag';
import FilterTagBody from '../../component/FilterTagBody';
import { useAnalyticsQueue } from '../../hooks';

type Props = {
    categoryList: ICategoryDto[];
    setFilterList: (filterList: number[]) => void;
    filterList: number[];
};

const FilterPanel = ({ categoryList, setFilterList, filterList }: Props) => {
    const { pushMetrics } = useAnalyticsQueue();

    const onChange = (value: number, selected: boolean) => {
        const categoryName = categoryList.find((i) => i.categoryId === value)?.categoryName;
        if (selected) {
            pushMetrics('MAIN_FILTER_CLICK', { categoryName });
            setFilterList([...filterList, value]);
        } else {
            pushMetrics('MAIN_FILTER_REMOVE', { categoryName });
            setFilterList(filterList.filter((el) => el !== value));
        }
    };

    const onCancel = () => {
        pushMetrics('MAIN_RESET_FILTERS');
        setFilterList([]);
    };

    return categoryList.length ? (
        <FilterTags onChange={onChange} onCancel={onCancel} selectedItems={filterList}>
            {categoryList.map(({ categoryId, categoryName, categoryUrl }) => (
                <Tag key={categoryId} value={categoryId} dataTestId={`filter-tags-item-${categoryId}`}>
                    <FilterTagBody text={categoryName} imgUrl={categoryUrl} />
                </Tag>
            ))}
        </FilterTags>
    ) : null;
};

export default FilterPanel;
