import React, { useEffect, useState } from 'react';
import type { MouseEvent } from 'react';

import { useAnalyticsQueue, useFeedbackQuestions } from '../../hooks';
import type { FeedbackScreenType } from '../../constants/FeedbackScreenTypes';
import Button from '../Button';
import type { CampaignData } from './types';
import { getFeedbackEventsForScreen } from './utils';
import FeedbackFormModal from './FeedbackFormModal';
import { ReactComponent as CloseIcon } from '../../static/svgs/cross-black.svg';
import { ReactComponent as HeartIcon } from '../../static/svgs/heart-fill.svg';

import styles from './FeedbackBanner.module.scss';

export type FeedbackBannerProps = {
    screenType: FeedbackScreenType;
    campaign?: CampaignData;
};

export const TEST_ID_FEEDBACK_BANNER = 'feedback-banner';
export const TEST_ID_FEEDBACK_BANNER_CLOSE_BTN = 'feedback-banner-close-btn';

function FeedbackBanner({ screenType, campaign }: FeedbackBannerProps) {
    const [showBanner, setShowBanner] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { pushMetrics } = useAnalyticsQueue();
    const { data: feedbackQuestions } = useFeedbackQuestions();
    const questionData = feedbackQuestions?.[screenType];
    const { eventForFeedbackBannerClick, eventForFeedbackBannerClose, eventForFeedbackBannerShow } =
        getFeedbackEventsForScreen(screenType);
    const { dzoName, originalId, productCampaignId, name: campaignName } = campaign || {};
    const metrics = {
        campaignId: originalId,
        campaignName,
        dzoName,
        productCampaignId,
    };

    useEffect(() => {
        if (questionData) {
            pushMetrics(eventForFeedbackBannerShow, metrics);
        }
    }, [questionData]);

    const openFeedbackForm = () => {
        pushMetrics(eventForFeedbackBannerClick, metrics);
        setIsModalOpen(true);
    };

    const closeBanner = () => setShowBanner(false);

    const closeModal = () => setIsModalOpen(false);

    const handleCloseBanner = (e: MouseEvent) => {
        e.stopPropagation();
        pushMetrics(eventForFeedbackBannerClose, metrics);
        closeBanner();
    };

    if (!questionData || !showBanner) {
        return null;
    }

    return (
        <>
            <div className={styles.banner} onClick={openFeedbackForm} data-test-id={TEST_ID_FEEDBACK_BANNER}>
                <HeartIcon className={styles.heartIcon} title="heart-icon" />
                <div className={styles.content}>
                    <p className={styles.text}>{questionData.question}</p>
                    <p className={styles.pseudoRateButton}>Оценить</p>
                </div>
                <Button
                    className={styles.closeButton}
                    icon={<CloseIcon />}
                    type="text"
                    onClick={handleCloseBanner}
                    size="small"
                    dataTestId={TEST_ID_FEEDBACK_BANNER_CLOSE_BTN}
                />
            </div>
            {isModalOpen && (
                <FeedbackFormModal
                    questionData={questionData}
                    screenType={screenType}
                    campaign={campaign}
                    closeModal={closeModal}
                    closeBanner={closeBanner}
                />
            )}
        </>
    );
}

export default FeedbackBanner;
