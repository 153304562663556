import { useContext } from 'react';

import { AppSettingsCtx } from '../component/AppSettings';
import { AppThemes } from '../constants/AppThemes';

export const useAppTheme = () => {
    const appCtx = useContext(AppSettingsCtx);

    return appCtx?.appTheme || AppThemes.DEFAULT;
};
