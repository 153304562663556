import React, { useCallback } from 'react';

import type { IPromoCampaign } from '../../api/types';
import { useAnalyticsQueue, useDevices } from '../../hooks';
import AdvertisingTag from '../../component/AdvertisingTag';
import Button from '../../component/Button';
import DzoLogo from '../../component/DzoLogo';
import MainPageCard from '../../component/MainPageCard';
import { ReactComponent as ArrowIcon } from '../../static/svgs/arrow-left.svg';

import styles from './ProductCard.module.scss';

export type ProductCardProps = {
    product: IPromoCampaign;
    onClick: (product: IPromoCampaign) => void;
    detailLabel?: string;
    onDetailClick?: (product: IPromoCampaign) => void;
};

const ProductCard = ({ product, detailLabel = 'Подробнее', onClick, onDetailClick }: ProductCardProps) => {
    const { pushMetrics } = useAnalyticsQueue();
    const { isMobile } = useDevices();
    const {
        id,
        dzoName,
        name: campaignName,
        originalId,
        productCampaignId,
        tradeName,
        creatives,
        cardUrl,
        logoUrl,
        header,
        description,
        rules,
        showLanding,
        targetActionButtonLabel,
    } = product;
    const erid = creatives.MAIN_ERID;

    const handleClick = useCallback(() => {
        onClick(product);
    }, [onClick, product]);

    const handleDetailClick = useCallback(() => {
        onDetailClick?.(product);
    }, [onDetailClick, product]);

    const onAdvertisingTagClick = () => {
        pushMetrics('ADVERTISEMENT_CLICK', {
            campaignId: originalId,
            campaignName,
            dzoName,
            productCampaignId,
            tradeName,
            erid,
        });
    };

    return (
        <MainPageCard
            dataTestId={`product-card-${id}`}
            imgUrl={cardUrl}
            renderLogo={() => (
                <DzoLogo logoUrl={logoUrl} dzoName={dzoName} dataTestId={`dzo-logo-${id}`} centered={isMobile} />
            )}
            title={header}
            description={description}
            renderFooter={() => (
                <>
                    <Button
                        label={targetActionButtonLabel}
                        onClick={handleClick}
                        type="contained"
                        dataTestId={`product-get-btn-${id}`}
                    />
                    {showLanding && !!onDetailClick && (
                        <Button
                            {...(isMobile ? { icon: <ArrowIcon /> } : { label: detailLabel })}
                            onClick={handleDetailClick}
                            className={styles.buttonDetail}
                            type="outline"
                            dataTestId={`product-detail-btn-${id}`}
                        />
                    )}
                </>
            )}
            advertising={
                <AdvertisingTag tradeName={tradeName} erid={erid} onClick={onAdvertisingTagClick} rules={rules} />
            }
        />
    );
};

export default ProductCard;
