export enum APP_MECHANICS_TYPES {
    PRODUCTS = 'ECOSYSTEM',
    PRESENTS = 'PRESENTS',
    BUNDLES = 'BUNDLE',
    MIX = 'MIX',
    SAPSAN = 'SAPSAN',
}

export const APP_TAB_LABELS = {
    PRODUCTS: 'Продукты',
    BUNDLES: 'Выгодно',
    PRESENTS: 'Подарки',
    MIX: 'Микс',
} as const;

export const APP_MECHANICS_VALUES = {
    PRODUCTS: 'Product',
    PRESENTS: 'Present',
    BUNDLES: 'Bundle',
    MIX: 'Mix',
    SAPSAN: 'Sapsan',
} as const;
