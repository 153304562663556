import React, { useContext } from 'react';
import cn from 'classnames';

import { ModalCtx } from '../../reducer/modal';
import { CLOSE_MODAL_ACTION } from '../../constants/modal';
import useDevices from '../../hooks/useDevices';
import Button from '../Button';
import type { NavItemProps } from './types';

import styles from './NavItem.module.scss';

const NavItem = ({ active = false, onClick, dataTestId = 'nav-item', children }: NavItemProps) => {
    const { dispatch } = useContext(ModalCtx);
    const { isMobile } = useDevices();

    const onItemClick = () => {
        if (isMobile) {
            dispatch({ type: CLOSE_MODAL_ACTION });
        }
        onClick();
    };

    return (
        <Button
            className={cn(styles.navItem, {
                [styles.active]: active,
                [styles.sidebarItem]: isMobile,
            })}
            onClick={onItemClick}
            dataTestId={dataTestId}
            type="text"
            color={active ? 'brand' : 'secondary'}
        >
            {children}
        </Button>
    );
};

export default NavItem;
