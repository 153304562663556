import React from 'react';

import Button from '../Button';

import styles from './ChosenItemsPanel.module.scss';

const SUBMIT_BUTTON_LABEL = 'Получить';
const RESET_BUTTON_LABEL = 'Сбросить';

export interface Props {
    onReset: () => void;
    onClick: () => void;
}

const ChosenItemsPanel = ({ onReset, onClick }: Props) => (
    <div className={styles.footerContainer}>
        <div className={styles.footer}>
            <Button
                label={RESET_BUTTON_LABEL}
                onClick={onReset}
                type="outline"
                size="large"
                dataTestId="footer-button-reset"
            />
            <Button
                label={SUBMIT_BUTTON_LABEL}
                onClick={onClick}
                type="contained"
                size="large"
                dataTestId="footer-button-next"
            />
        </div>
    </div>
);

export default ChosenItemsPanel;
