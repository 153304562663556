import React from 'react';

import ConsentLink from '../ConsentLink';
import type { ConsentType } from '../../../constants/settings';
import type { productTargetKeys } from '../../../utils/clickStream';
import type { IPromoCampaign } from '../../../api/types';
import { useAnalyticsQueue } from '../../../hooks';

const INFORMATION = 'соглашаюсь с условиями';
const CLICKABLE_TEXT = 'оферты на заключение договора';

type Props = {
    promoCampaign: IPromoCampaign;
};

const OfferTermsConsentLink = ({ promoCampaign: { id: campaignId, offerTermsLink } }: Props) => {
    const { pushMetrics } = useAnalyticsQueue();

    const onClick = () => {
        pushMetrics('CONTRACT_CLICK', { campaignId });
        window.open(offerTermsLink, '_blank');
    };

    return (
        <>
            {INFORMATION}{' '}
            <ConsentLink type={'' as ConsentType} onLinkClick={onClick} eventAction={'' as productTargetKeys}>
                {CLICKABLE_TEXT}
            </ConsentLink>
        </>
    );
};

export default OfferTermsConsentLink;
