import { SUCCESS_PAGE_CARD_TYPE } from '../constants/successPageCardTypes';
import type { IPromoCampaign } from '../api/types';

import type { ISuccessCard } from '../component/SuccessPageCard';
import type { IClientOffer } from '../api/services/offerService/types';

const { SINGLE, COMMON } = SUCCESS_PAGE_CARD_TYPE;

export function getSuccessPageCardType(campaignListSize: number) {
    switch (campaignListSize) {
        case 1:
            return SINGLE;
        default:
            return COMMON;
    }
}

export const generateSuccessCards = (
    offers: Record<string, IClientOffer> = {},
    promoCampaigns: IPromoCampaign[] = []
) =>
    promoCampaigns.reduce<ISuccessCard[]>((successCards, promoCampaign) => {
        const offer = offers[promoCampaign.id];

        if (offer) {
            return [
                ...successCards,
                {
                    ...promoCampaign,
                    ...offer,
                },
            ];
        }

        return successCards;
    }, []);
