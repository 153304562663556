import React, { forwardRef } from 'react';

import { useAppSettings } from '../../AppSettings';
import type { CheckboxProps } from '../../Checkbox';
import ConsentCheckbox from '../ConsentCheckbox/ConsentCheckbox';
import { PRIVACY_POLICY, SMMR_POLICY } from '../../../constants/settings';
import useConsentModalProps from '../../../hooks/useConsentModalProps';
import ConsentLink from '../ConsentLink';
import type { productTargetKeys } from '../../../utils/clickStream';
import { OfferTermsConsentLink } from '../OfferTermsConsent';
import type { IPromoCampaign } from '../../../api/types';

export type PrivacyPolicyProps = Omit<CheckboxProps, 'label'> & {
    phoneNumber: string;
    privacyEventAction?: productTargetKeys;
    smmrEventAction?: productTargetKeys;
    promoCampaign?: IPromoCampaign;
};

const INFORMATION = 'Я даю согласие на';
const PRIVACY = 'обработку моих персональных данных ПАО «Сбербанк»';
const SMMR = 'ООО «СберМегаМаркет-Ритейл»';

const PrivacyPolicy = forwardRef<HTMLInputElement, PrivacyPolicyProps>(
    (
        {
            phoneNumber,
            privacyEventAction = 'IDENTIFICATION_INSTALLATION_POLICY_CLICK',
            smmrEventAction = 'IDENTIFICATION_INSTALLATION_SMMR_CLICK',
            promoCampaign,
            ...props
        },
        ref
    ) => {
        const { smmrConsentId } = useAppSettings();
        const { consentModalProps, onClick } = useConsentModalProps(phoneNumber);

        const label = (
            <>
                {INFORMATION}
                {' '}
                <ConsentLink type={PRIVACY_POLICY} eventAction={privacyEventAction} onLinkClick={onClick}>
                    {PRIVACY}
                </ConsentLink>
                {smmrConsentId && (
                    <>
                        {' и '}
                        <ConsentLink type={SMMR_POLICY} eventAction={smmrEventAction} onLinkClick={onClick}>
                            {SMMR}
                        </ConsentLink>
                    </>
                )}
                {promoCampaign?.offerTermsLink && (
                    <>
                        {' и '}
                        <OfferTermsConsentLink promoCampaign={promoCampaign} />
                    </>
                )}
            </>
        );

        return <ConsentCheckbox checkboxProps={{ label, ...props }} consentModalProps={consentModalProps} ref={ref} />;
    }
);

PrivacyPolicy.displayName = 'PrivacyPolicy';

export default PrivacyPolicy;
