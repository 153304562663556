/**
 * QR: qr - enabled, goToSiteBtn - disabled
 * EMPTY: qr - disabled, goToSiteBtn - disabled
 * WEB_ONLY: qr - disabled, goToSiteBtn - enabled
 * COMBO: qr - enabled, goToSiteBtn - enabled
 */
enum behaviorTypes {
    QR = 'QR',
    EMPTY = 'EMPTY',
    WEB_ONLY = 'WEB_ONLY',
    COMBO = 'COMBO',
}

export default behaviorTypes;
