import React, { forwardRef } from 'react';

import type { CheckboxProps } from '../../Checkbox';
import Checkbox from '../../Checkbox';
import OfferTermsConsentLink from './OfferTermsConsentLink';
import type { IPromoCampaign } from '../../../api/types';

type Props = Omit<CheckboxProps, 'label'> & {
    promoCampaign: IPromoCampaign;
};

const OfferTermsConsent = forwardRef<HTMLInputElement, Props>(({ promoCampaign, ...props }, ref) => {
    const label = (
        <>
            {'Я '}
            <OfferTermsConsentLink promoCampaign={promoCampaign} />
        </>
    );

    return <Checkbox {...props} label={label} ref={ref} />;
});

OfferTermsConsent.displayName = 'OfferTermsConsent';

export default OfferTermsConsent;
