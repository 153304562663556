import React, { useEffect, useState } from 'react';
import { useInterval } from 'react-use';

import { useAnalyticsQueue } from '../../../hooks';
import { ERROR_STATUS_CODE } from '../../../constants/common';
import SuccessPageModalBody from '../../SuccessPageModalBody';
import type { ButtonSizes } from '../../Button';
import Button from '../../Button';
import Loader from '../../Loader';
import type { ErrorArguments } from '../types';

type Props = {
    email: string;
    sendEmail: () => void;
    setError: (data: ErrorArguments) => void;
    onClick: () => void;
    onCancelClick: () => void;
    buttonSize: ButtonSizes;
};

export const AWAITING_TITLE = 'Ожидаем подтверждения';
export const TIMEOUT_MESSAGE = 'Превышено время ожидания';
export const TIMEOUT_DESCRIPTION = 'Подтвердите email и повторите попытку позже';
export const AWAITING_DELAY = 5000;
export const AWAITING_TIMEOUT = 10 * 60 * 1000; // Ожидание подтверждения не должно превышать 10 минут

const AwaitingModalBody = ({ email, sendEmail, setError, onClick, onCancelClick, buttonSize }: Props) => {
    const { pushMetrics } = useAnalyticsQueue();
    const [awaitingTime, setAwaitingTime] = useState(AWAITING_TIMEOUT);

    useEffect(() => {
        pushMetrics('WAIT_CONFIRM_EMAIL_SHOW');
    }, []);

    useInterval(async () => {
        try {
            await sendEmail();
        } catch (e) {
            if (e.statusCode === ERROR_STATUS_CODE.NOT_ACCEPTABLE) {
                if (awaitingTime - AWAITING_DELAY > 0) {
                    setAwaitingTime((prevState) => prevState - AWAITING_DELAY);
                } else {
                    setError({
                        message: TIMEOUT_MESSAGE,
                        svEvent: 'ERROR_CONFIRM_EMAIL_SHOW',
                        description: TIMEOUT_DESCRIPTION,
                    });
                }
            } else {
                setError({
                    message: e.message,
                    svEvent: 'ERROR_SEND_OFFER_EMAIL_SHOW',
                    description: e.description,
                });
            }
        }
    }, AWAITING_DELAY);

    return (
        <SuccessPageModalBody
            onCloseButtonClick={onCancelClick}
            header={AWAITING_TITLE}
            description={`На ${email} отправлено письмо со ссылкой для подтверждения`}
            dataTestId="email-confirmation-awaiting-modal"
            renderContent={() => <Loader />}
            renderButtons={() => (
                <Button label="Назад" size={buttonSize} onClick={onClick} dataTestId="go-back-to-email-button" />
            )}
        />
    );
};

export default AwaitingModalBody;
