export const DELIMITER = '/';

const Authorization = {
    SBERID_BTN_CLICK: 'SberIDBtn_Click',
    ADDITIONAL_LOGIN_INFO_SHOW: 'AddInfo_Show',
    LOGIN_AFTER_ADDITIONAL_REG: 'NextBtn_Click',
    AUTH_ERROR: 'Error_Show',
    AUTH_SUCCESS: 'Successfully',
    CONTRACT_CLICK: 'Contract_Click',
} as const;

const Empty = {
    // TODO: обсудить использование события 'Terms_Click' после рефакторинга ClickStream
    SHOW_TERMS_MODAL: 'Terms_Show',
    TO_ANOTHER_MECHANICS_TAB: 'Tab_Click',
    OPEN_LOGOUT_MODAL: 'OpenLogOut_Click',
    CLOSE_LOGOUT_MODAL: 'CloseLogOut_Click',
    USER_LOGOUT_CLICK: 'UserLogOut_Click',
    CLIENT_LOGOUT_CLICK: 'ClientLogOut_Click',
    SELECT_APP_LOGOUT_CLICK: 'SelectApp_LogOut_Click',
    INACTIVITY_SHOW: 'Inactivity_Show',
    INACTIVITY_NEXT_CLICK: 'Inactivity_NextBtn_Click',
    INACTIVITY_EXIT_CLICK: 'Inactivity_ExitBtn_Click',
    ORDER_STATUS_SHOW: 'OrderStatus_Show',
} as const;

const Main = {
    MAIN_BUNDLE_SHOW: 'Bundle_Show',
    MAIN_BUNDLE_CLICK: 'Bundle_Click',

    MAIN_DETAIL_CLICK: 'Detail_Click',
    TO_PRODUCTS_PAGE: 'Product_Show',
    PRODUCT_OFFER_CLICK: 'Product_Offer_Click',

    PRESENT_MAIN_SHOW: 'Present_Show',
    PRESENT_MAIN_CHOICE_OFFER_CLICK: 'Present_ChoiceOffer_Click',
    PRESENT_MAIN_REMOVE_OFFER_CLICK: 'Present_RemoveOffer_Click',
    PRESENT_MAIN_REMOVE_OFFERS_CLICK: 'Present_RemoveOffers_Click',
    PRESENT_MAIN_GET_CLICK: 'Present_GetBtn_Click',

    MIX_MAIN_SHOW: 'Mix_Show',

    MAIN_FILTER_CLICK: 'Filter_Click',
    MAIN_FILTER_REMOVE: 'FilterRemove_Click',
    MAIN_RESET_FILTERS: 'ResetFilters_Click',

    /** feedback (CSI) */
    MAIN_FEEDBACK_BANNER_SHOW: 'Csi_Show',
    MAIN_FEEDBACK_BANNER_CLICK: 'Csi_Click',
    MAIN_FEEDBACK_BANNER_CLOSE_BTN_CLICK: 'Csi_ExitBtn_Click',
    MAIN_SEND_FEEDBACK_BTN_CLICK: 'CsiEstimation_GetBtn_Click',
} as const;

const Bundle = {
    BUNDLE_BUNDLE_SHOW: 'Show',
    BUNDLE_REMOVE_OFFER_CLICK: 'RemoveOffer_Click',
    BUNDLE_CHOOSE_OFFER_CLICK: 'ChooseOffer_Click',
    BUNDLE_REMOVE_OFFERS_CLICK: 'RemoveOffers_Click',
    BUNDLE_GET_BTN_CLICK: 'GetBtn_Click',
    BUNDLE_DETAIL_CLICK: 'Detail_Click',
} as const;

const Landing = {
    GO_BACK_FROM_PRODUCT: 'Product_BackBtn_Click',
    SHOW_PRODUCT_DETAILS: 'Product_Show',
    GET_PRODUCT: 'Product_GetBtn_Click',
    LANDING_TO_WEBSITE_CLICK: 'ToWebsite_Click',

    /** feedback (CSI) */
    LANDING_FEEDBACK_BANNER_SHOW: 'Csi_Show',
    LANDING_FEEDBACK_BANNER_CLICK: 'Csi_Click',
    LANDING_FEEDBACK_BANNER_CLOSE_BTN_CLICK: 'Csi_ExitBtn_Click',
    LANDING_SEND_FEEDBACK_BTN_CLICK: 'CsiEstimation_GetBtn_Click',
} as const;

const Identification = {
    IDENTIFICATION_INSTALLATION_SHOW: 'Installation_Show',
    IDENTIFICATION_INSTALLATION_GET_CLICK: 'Installation_GetBtn_Click',
    IDENTIFICATION_INSTALLATION_BACK_CLICK: 'Installation_BackBtn_Click',
    IDENTIFICATION_INSTALLATION_POLICY_CLICK: 'Installation_Policy_Click',
    IDENTIFICATION_INSTALLATION_SMMR_CLICK: 'Installation_Smmr_Click',
    IDENTIFICATION_FRIEND_OFFER_SHOW: 'FriendOffer_Show',
    IDENTIFICATION_FRIEND_OFFER_GET_CLICK: 'FriendOffer_GetBtn_Click',
    IDENTIFICATION_FRIEND_OFFER_POLICY_CLICK: 'FriendOffer_Policy_Click',
    IDENTIFICATION_FRIEND_OFFER_SMMR_CLICK: 'FriendOffer_Smmr_Click',

    IDENTIFICATION_SBERID_INSTRUCTION_CLICK: 'SberIdInstruction_Click',
    IDENTIFICATION_SBERID_BACK_BTN_CLICK: 'SberIdInstruction_BackBtn_Click',
    IDENTIFICATION_SBERID_BTN_CLICK: 'SberId_Click',
    IDENTIFICATION_SBERID_SHOW: 'SberId_Show',
    IDENTIFICATION_SBERID_INSTALATION_POLICY_CLICK: 'SberId_Installation_Policy_Click',
    IDENTIFICATION_SBERID_INSTALLATION_GET_CLICK: 'SberId_Installation_GetBtn_Click',
} as const;

const Success = {
    SUCCESS_MAIN_OFFERS_SHOW: 'MainOffers_Show',
    SUCCESS_SEND_OFFER_CLICK: 'SendOfferBtn_Click',
    SUCCESS_SHOW_QR_CLICK: 'ShowQRBtn_Click',
    SUCCESS_SCAN_BTN_CLICK: 'ScanBtn_Click',
    SUCCESS_BACK_PROMO_BTN_CLICK: 'BackPromoBtn_Click',
    SUCCESS_BACK_BTN_CLICK: 'BackBtn_Click',
    SUCCESS_SEND_OFFER_SHOW: 'SendOffer_Show',
    SUCCESS_MAIN_OFFERS_MENU_CLICK: 'MainOffers_Menu_Click',
    SUCCESS_MAIN_OFFERS_OPEN_HELP_PANEL_CLICK: 'MainOffers_HowGetOffer_Click',
    SUCCESS_MAIN_OFFERS_COPY_PROMOCODE_CLICK: 'MainOffers_CopyPromocode_Click',
    SUCCESS_MAIN_OFFERS_COPY_LINK_CLICK: 'MainOffers_CopyLink_Click',
    SUCCESS_MAIN_OFFERS_TO_WEBSITE_CLICK: 'MainOffers_ToWebsite_Click',
    SUCCESS_MAIN_OFFERS_GIVE_OFFER_CLICK: 'MainOffers_GiveOffer_Click',

    SUCCESS_ORDER_OFFER_GUIDE_SHOW: 'OrderOffer_Guide_Show',
    SUCCESS_ORDER_OFFER_APP_CLICK: 'OrderOffer_ToApp_Click',
    SUCCESS_ORDER_OFFER_PAY_SHOW: 'OrderOffer_Pay_Show',
    SUCCESS_ORDER_OFFER_PAY_CLICK: 'OrderOffer_Pay_Click',
    SUCCESS_ORDER_OFFER_ERROR_SHOW: 'OrderOffer_Error_Show',
    SUCCESS_ORDER_OFFER_ERROR_CLICK: 'OrderOffer_OrderBtn_Click',

    SUCCESS_ORDER_BTN_CLICK: 'OrderBtn_Click',
    SUCCESS_ORDER_SHOW_QR_CLICK: 'OrderShowQRBtn_Click',
    SUCCESS_ORDER_DETAIL_BTN_CLICK: 'OrderDetailBtn_Click',
    SUCCESS_ORDER_INFO_BTN_CLICK: 'OrderInfoBtn_Click ',

    /** ClientOffer */
    SUCCESS_CLIENT_OFFER_SHOW: 'ClientOffer_Show',
    SUCCESS_CLIENT_OFFER_COPY_OFFER_CLICK: 'ClientOffer_CopyOffer_Click',
    SUCCESS_CLIENT_OFFER_GIVE_OFFER_CLICK: 'ClientOffer_GiveOffer_Click',
    /** FriendOffer */
    SUCCESS_FRIEND_PRESENT_SHOW: 'FriendPresent_Show',
    SUCCESS_FRIEND_PRESENT_GET_BTN_CLICK: 'FriendPresent_GetBtn_Click',
    SUCCESS_FRIEND_PRESENT_COPY_PROMO_CODE_CLICK: 'FriendPresent_CopyPromocode_Click',

    /** Email Confirmaion */
    SUCCESS_SEND_OFFER_EMAIL_SHOW: 'SendOfferEmail_Show',
    SUCCESS_SEND_OFFER_EMAIL_BTN_CLICK: 'SendOfferEmailBtn_Click',

    /** feedback (CSI) */
    SUCCESS_FEEDBACK_BANNER_SHOW: 'Csi_Show',
    SUCCESS_FEEDBACK_BANNER_CLICK: 'Csi_Click',
    SUCCESS_FEEDBACK_BANNER_CLOSE_BTN_CLICK: 'Csi_ExitBtn_Click',
    SUCCESS_SEND_FEEDBACK_BTN_CLICK: 'CsiEstimation_GetBtn_Click',
} as const;

const Csi = {
    FEEDBACK_MODAL_CLOSE_BTN_CLICK: 'Modal_CloseBtn_Click',
    FEEDBACK_SUCCESS_SHOW: 'Success_Show',
    FEEDBACK_SUCCESS_EXIT_BTN_CLICK: 'Success_ExitBtn_Click',
};

const ConfirmEmail = {
    CONFIRM_EMAIL_SHOW: 'Show',
    CONFIRM_EMAIL_CHANGE_BTN_CLICK: 'ChangeBtn_Click',
    CONFIRM_EMAIL_SEND_OFFER_EMAIL_BTN_CLICK: 'SendOfferEmailBtn_Click',
    CONFIRM_EMAIL_ADVERTISING_POLICY_BTN_CLICK: 'AdvertisingPolicyBtn_Click',
} as const;

const WaitConfirmEmail = {
    WAIT_CONFIRM_EMAIL_SHOW: 'Show',
    WAIT_CONFIRM_EMAIL_BACK_BTN_SHOW: 'BackBtn_Click',
} as const;

const WarningPlate = {
    WARNING_PLATE_INSTALLING_CERTIFICATE_CLICK: 'InstallingCertificate_Click',
    WARNING_PLATE_INSTALLING_YANDEX_CLICK: 'InstallingYandex_Click',
    WARNING_PLATE_MORE_DETAILED_CLICK: 'MoreDetailed_Click',
    WARNING_PLATE_CLOSE_CLICK: 'Close_Click',
} as const;

const CertificateExpired = {
    CERTIFICATE_EXPIRED_SHOW: 'Show',
    CERTIFICATE_EXPIRED_INSTALLING_YANDEX_CLICK: 'InstallingYandex_Click',
    CERTIFICATE_EXPIRED_INSTALLING_CERTIFICATE_CLICK: 'InstallingCertificate_Click',
} as const;

const ConfirmConsent = {
    CONFIRM_CONSENT_SHOW: 'Show',
    CONFIRM_CONSENT_CONFIRM_CLICK: 'ConfirmBtn_Click',
    CONFIRM_CONSENT_ADVERTISING_POLICY_BTN_CLICK: 'AdvertisingPolicyBtn_Click',
} as const;

const Confirm = {
    CONFIRM_CODE_SHOW: 'Code_Show',
    CONFIRM_REPEAT_SEND_CLICK: 'RepeatSend_Click',
    CONFIRM_SEND_CLICK: 'SendBtn_Click',
    CONFIRM_BACK_CLICK: 'BackBtn_Click',
    CONFIRM_CODE_ADVERTISING_POLICY_BTN_CLICK: 'Code_AdvertisingPolicyBtn_Click',
} as const;

const Error = {
    ERROR_FRIEND_PRESENT_SHOW: 'FriendPresent_Show',
    ERROR_CLIENT_OFFER_SHOW: 'ClientOffer_Show',
    ERROR_SCREEN_SHOW: 'Screen_Show',
    ERROR_ORDER_STATUS_BTN_CLICK: 'OrderStatusBtn_Click',
    ERROR_ORDER_MAIN_BTN_CLICK: 'OrderMainBtn_Click',
    ERROR_ORDER_OFFER_BTN_CLICK: 'Order_OfferBtn_Click',
    ERROR_NO_PRESENTS_SHOW: 'NoPresents_Show',
    ERROR_NEXT_BTN_CLICK: 'NextBtn_Click',
    ERROR_NO_OFFER_SHOW: 'NoOffer_Show',
    ERROR_SEND_OFFER_EMAIL_SHOW: 'SendOfferEmail_Show',
    ERROR_CONFIRM_EMAIL_SHOW: 'ConfirmEmail_Show',
} as const;

const PersonalPage = {
    PERSONAL_PAGE_SHOW: 'Show',

    PERSONAL_PAGE_FILTER_CLICK: 'Filter_Click',
    PERSONAL_PAGE_FILTER_REMOVE: 'FilterRemove_Click',
    PERSONAL_PAGE_RESET_FILTERS: 'ResetFilters_Click',

    PERSONAL_PAGE_OFFER_CLICK: 'Offer_Click',
    PERSONAL_PAGE_DETAIL_CLICK: 'Detail_Click',
} as const;

const Advantage = {
    ADVANTAGE_SHOW: 'Show',
    ADVANTAGE_CLOSE_CLICK: 'Close_Click',
} as const;

const Advertisement = {
    ADVERTISEMENT_CLICK: 'Click',
} as const;

export const clickStreamTargetPagesLotteryWheel = {
    LOTTERY_WHEEL_SHOW: 'Wheel_Show',
    LOTTERY_WHEEL_SPIN_CLICK: 'Wheel_Spin_Click',
    LOTTERY_WHEEL_RESULT_SBERSTRAH_15_SHOW: 'Result_Sberstrahovanie15_Show',
    LOTTERY_WHEEL_RESULT_SBERSTRAH_10_SHOW: 'Result_Sberstrahovanie10_Show',
    LOTTERY_WHEEL_RESULT_SBERMOBILE_SHOW: 'Result_SberMobile_Show',
    LOTTERY_WHEEL_RESULT_SBERBOX_SHOW: 'Result_SberBox_Show',
    LOTTERY_WHEEL_RESULT_SBER_MEGAMARKET_500_SHOW: 'Result_SMM-500_Show',
    LOTTERY_WHEEL_RESULT_SAMOKAT_SHOW: 'Result_Samokat_Show',
    LOTTERY_WHEEL_RESULT_SURPRISE_SHOW: 'Result_Surprise_Show',
    LOTTERY_WHEEL_RESULT_CLOSE_CLICK: 'Result_Close_Click',
} as const;

export const clickStreamTargetPagesSinglePromocode = {
    SINGLE_PROMOCODE_ONBOARDING_SHOW: 'Onboarding_Show',
    SINGLE_PROMOCODE_MEGAMARKET_INSTALL_CLICK: 'Onboarding_MegaMarketInstall_Click',
    SINGLE_PROMOCODE_SBERMARKET_INSTALL_CLICK: 'Onboarding_SberMarketInstall_Click',
    SINGLE_PROMOCODE_SAMOKAT_INSTALL_CLICK: 'Onboarding_SamokatInstall_Click',
    SINGLE_PROMOCODE_EAPTEKA_INSTALL_CLICK: 'Onboarding_EAptekaInstall_Click',
    SINGLE_PROMOCODE_ADVERTISEMENT_CLICK: 'Advertisement/Click', // тут "/" - это костыль под Единый промокод
} as const;

export const clickStreamTargetPagesShelf = {
    Advertisement,
    Advantage,
    Authorization,
    Bundle,
    CertificateExpired,
    Csi,
    Confirm,
    ConfirmConsent,
    ConfirmEmail,
    Empty,
    Error,
    Identification,
    Landing,
    Main,
    PersonalPage,
    Success,
    WaitConfirmEmail,
    WarningPlate,
};

export const clickStreamTargetList = {
    EcosystemShelf: clickStreamTargetPagesShelf,
    EcoLottery: clickStreamTargetPagesLotteryWheel,
    EcoSinglePromocode: clickStreamTargetPagesSinglePromocode,
};

export const pagesTargetList = {
    ...Authorization,
    ...Empty,
    ...Main,
    ...Identification,
    ...Bundle,
    ...Landing,
    ...Success,
    ...ConfirmEmail,
    ...WaitConfirmEmail,
    ...WarningPlate,
    ...ConfirmConsent,
    ...CertificateExpired,
    ...Error,
    ...Confirm,
    ...PersonalPage,
    ...Advantage,
    ...Advertisement,
    ...Csi,
    ...clickStreamTargetPagesLotteryWheel,
    ...clickStreamTargetPagesSinglePromocode,
};
