import sberMMarket from '../../static/images/sberMMarket.png';
import sberMobile from '../../static/images/sberMobile.png';
import samokat from '../../static/images/samokat.png';
import surprize from '../../static/images/surprize.png';
import prizeCube from '../../static/images/prize-cube.png';
import type { SectorObjType } from './FortuneWheelWrapper';

export const sectorsListWheelOne: SectorObjType[] = [
    {
        title: 'CubePrize2',
        description: 'на 3 программы в <br/> комплексном страховании',
        discount: '-15%',
        background: { '#63F998': 60, '#2BDF00': 100 },
        image: prizeCube,
        svEvent: 'LOTTERY_WHEEL_RESULT_SBERSTRAH_15_SHOW',
    },
    {
        title: 'Сбермобайл',
        description: 'на 3 месяца связи',
        discount: '-50%',
        background: { '#F7D155': 0, '#F17F5B': 48.53, '#EB4258': 100 },
        image: sberMobile,
        svEvent: 'LOTTERY_WHEEL_RESULT_SBERMOBILE_SHOW',
    },
    {
        title: 'Сюрприз',
        discount: 'Сюрприз',
        background: { '#00A2FF': 0, '#00D1FF': 49.5, '#37DEAF': 61.84, '#68EA68': 83.6, '#FBF217': 100 },
        image: surprize,
        svEvent: 'LOTTERY_WHEEL_RESULT_SURPRISE_SHOW',
    },
    {
        title: 'CubePrize1',
        description: 'на 2 программы в <br/> комплексном страховании',
        discount: '-10%',
        background: { '#A9FFAF': 44, '#00DFD2': 100 },
        image: prizeCube,
        svEvent: 'LOTTERY_WHEEL_RESULT_SBERSTRAH_10_SHOW',
    },
    {
        title: 'Samokat',
        description: 'на первый заказ <br/> при покупке от 800₽',
        discount: '-300 ₽',
        background: '#EB5168',
        image: samokat,
        svEvent: 'LOTTERY_WHEEL_RESULT_SAMOKAT_SHOW',
    },
    {
        title: 'СберМегаМаркет',
        description: 'за первый заказ от 1000 ₽',
        discount:
            '<span style="display: inline-flex; align-items: center; gap: 4px">' +
            '500' +
            '<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_5814_29)"><path d="M6.5571 0C7.53577 0 8.42203 0.165184 9.2158 0.495553C10.0097 0.825922 10.6838 1.28293 11.2384 1.86658C11.8039 2.45023 12.2389 3.1385 12.5433 3.93139C12.8478 4.72427 13 5.57772 13 6.49175C13 7.40576 12.8478 8.25922 12.5433 9.05206C12.2389 9.84498 11.8039 10.5332 11.2384 11.1169C10.6838 11.7006 10.0097 12.163 9.2158 12.5045C8.42203 12.8348 7.53577 13 6.5571 13H0L0 6.49175C0 5.57772 0.152239 4.72427 0.45671 3.93139C0.772061 3.1385 1.21247 2.45023 1.77792 1.86658C2.35425 1.28293 3.04475 0.825922 3.84945 0.495553C4.65413 0.165184 5.55668 0 6.5571 0ZM8.98745 7.40026C8.82437 7.67557 8.56338 7.90682 8.2045 8.09403C7.85653 8.28124 7.40532 8.37484 6.8507 8.37484C6.29612 8.37484 5.85572 8.23168 5.5295 7.94536C5.21415 7.64803 5.05647 7.18551 5.05647 6.55782C5.05647 5.93011 5.20327 5.47311 5.49687 5.18679C5.79047 4.90046 6.23631 4.75731 6.83439 4.75731C7.34547 4.75731 7.76412 4.86743 8.09037 5.08767C8.42744 5.29691 8.70474 5.56671 8.92226 5.89708V3.60102C8.70474 3.43584 8.41112 3.29818 8.04142 3.18805C7.67172 3.07794 7.26935 3.01736 6.83439 3.00636C5.68173 2.99534 4.75743 3.29267 4.06149 3.89835C3.37641 4.50402 3.03388 5.39052 3.03388 6.55782C3.03388 7.15248 3.12631 7.67557 3.31117 8.12703C3.49603 8.57854 3.75702 8.95302 4.09411 9.25031C4.43121 9.54769 4.82812 9.7734 5.28483 9.92761C5.74154 10.0707 6.24175 10.1423 6.78546 10.1423C7.03556 10.1423 7.27479 10.1203 7.50314 10.0762C7.74239 10.0212 7.95984 9.95508 8.15563 9.87805C8.35135 9.78989 8.51985 9.70182 8.66128 9.61374C8.81346 9.52559 8.92218 9.43751 8.98745 9.34944V7.40026Z" fill="white"/></g><defs><clipPath id="clip0_5814_29"><rect width="13" height="13.5" fill="white"/></clipPath></defs></svg>' +
            '</span>',
        background: { '#553E93': 0, '#694B81': 59.58, '#E59713': 100 },
        image: sberMMarket,
        svEvent: 'LOTTERY_WHEEL_RESULT_SBER_MEGAMARKET_500_SHOW',
    },
];
