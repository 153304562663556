import React from 'react';

import { noop } from '../../../utils/helper';
import { useDevices } from '../../../hooks';
import SuccessPageModal from '../../SuccessPageModal';
import SuccessPageModalTypes from '../../../constants/SuccessPageModalTypes';
import type { ISuccessCard } from '../../SuccessPageCard';
import { QrBlock } from '../../SuccessPageCard';
import SuccessPageModalBody from '../../SuccessPageModalBody';
import Button from '../../Button';
import AdvertisingConfirmationForm from '../../AdvertisingConfirmationForm';
import EmailConfirmationForm from '../../EmailConfirmationForm';
import SbolPhoneConfirmationForm from '../../SbolPhoneConfirmationForm';
import { ReactComponent as ErrorIcon } from '../../../static/svgs/not-found.svg';

import styles from './SuccessPagePopUp.module.scss';

type Props = {
    type?: SuccessPageModalTypes;
    onSubmit?: () => void;
    onCancel: () => void;
    onMount?: () => void;
    card?: ISuccessCard;
    message?: string;
    description?: string;
    requestId?: string;
};

const SuccessPagePopUp = ({
    type,
    message = '',
    description,
    requestId,
    card,
    onSubmit = noop,
    onCancel,
    onMount,
}: Props) => {
    const { isMobile } = useDevices();
    let body = null;

    switch (type) {
        case SuccessPageModalTypes.QR:
            if (card) {
                body = (
                    <SuccessPageModalBody
                        onCloseButtonClick={onCancel}
                        header="Отсканируйте QR‑код"
                        description="Наведите камеру телефона и перейдите по ссылке"
                        renderContent={() => (
                            <div className={styles.qrWrapper}>
                                <QrBlock url={card.personalUrl} size="large" />
                            </div>
                        )}
                    />
                );
            }
            break;
        case SuccessPageModalTypes.EMAIL:
            body = <EmailConfirmationForm onSubmit={onSubmit} onCancel={onCancel} />;
            break;
        case SuccessPageModalTypes.SBOL_PHONE_NUMBER_CONFIRMATION:
            body = (
                <SbolPhoneConfirmationForm
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    pushSVEventSuccessOfferShow={onMount}
                />
            );
            break;
        case SuccessPageModalTypes.ADVERTISING_AGREEMENT:
            body = (
                <AdvertisingConfirmationForm
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    pushSVEventSuccessOfferShow={onMount}
                />
            );
            break;
        case SuccessPageModalTypes.ERROR:
            body = (
                <SuccessPageModalBody
                    renderTopBlock={() => <ErrorIcon />}
                    header={message}
                    description={description}
                    requestId={requestId}
                    renderButtons={() => (
                        <Button
                            label="Продолжить"
                            onClick={onCancel}
                            type="contained"
                            size={!isMobile ? 'x-large' : 'large'}
                            dataTestId="error-screen-continue-button"
                        />
                    )}
                />
            );
            break;
    }

    return (
        <SuccessPageModal closeModal={onCancel} isOpen={!!type}>
            {body}
        </SuccessPageModal>
    );
};

export default SuccessPagePopUp;
