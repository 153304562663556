import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';
import type { GetAllPromoCampaignsOptions } from '../../../api/services/promoCampaignService';
import { getAllPromoCampaigns } from '../../../api/services/promoCampaignService';
import type { IBaseResponse, IPromoCampaign, IPromoCampaignDto } from '../../../api/types';
import { useCallback } from 'react';
import { useAppSettings } from '../../../component/AppSettings';
import { promoCampaignDtoToPromoCampaign } from '../../../utils/mapper';

const getPromoCampaignsKeys = (filters: GetAllPromoCampaignsOptions) => ['promoCampaigns', filters] as const;

type PromoCampaignsKeys = ReturnType<typeof getPromoCampaignsKeys>;

const getPromoCampaignsFn = ({ queryKey: [, filters] }: QueryFunctionContext<PromoCampaignsKeys>) =>
    getAllPromoCampaigns(filters);

export const usePromoCampaignsQuery = ({ campaigns = [], ...filters }: GetAllPromoCampaignsOptions) => {
    const queryClient = useQueryClient();
    const appSettings = useAppSettings();

    const transformData = useCallback(
        (data: IPromoCampaignDto[]) => data.map((el) => promoCampaignDtoToPromoCampaign(el, appSettings)),
        [appSettings]
    );

    return useQuery<IPromoCampaignDto[], IBaseResponse, IPromoCampaign[], PromoCampaignsKeys>({
        queryKey: getPromoCampaignsKeys({ campaigns, ...filters }),
        queryFn: getPromoCampaignsFn,
        select: transformData,
        initialData: () => {
            if (!campaigns?.length) {
                return;
            }

            const promoCampaigns = queryClient
                .getQueryData<IPromoCampaignDto[]>(getPromoCampaignsKeys({ campaigns: [], ...filters }))
                ?.filter(({ id, productOfferingIds }) =>
                    campaigns.includes(
                        appSettings.prodCatEnabled ? (productOfferingIds.productCampaignId as string) : `${id}`
                    )
                );

            if (promoCampaigns?.length === campaigns.length) {
                return promoCampaigns;
            }
        },
        initialDataUpdatedAt: () => {
            if (!campaigns?.length) {
                return;
            }

            return queryClient.getQueryState<IPromoCampaignDto[]>(getPromoCampaignsKeys({ campaigns: [], ...filters }))
                ?.dataUpdatedAt;
        },
    });
};
