import React, { forwardRef } from 'react';
import type { NumberInputProps } from '../NumberInput';
import NumberInput from '../NumberInput';
import { formatPhoneNumber } from '../../utils/phoneNumberHelper';

export type PhoneNumberProps = {
    onChange?: (value: string) => void;
} & Omit<NumberInputProps, 'onChange' | 'format'>;

const PhoneNumber = forwardRef<HTMLInputElement, PhoneNumberProps>(({ onChange, ...props }, ref) => (
    <NumberInput
        {...props}
        mask="_"
        format={formatPhoneNumber}
        onValueChange={({ formattedValue }) => onChange?.(formattedValue)}
        ref={ref}
    />
));

PhoneNumber.displayName = 'PhoneNumber';

export default PhoneNumber;
