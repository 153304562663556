import type { ReactNode } from 'react';
import React from 'react';
import cn from 'classnames';

import DotsLoader from '../DotsLoader';

import styles from './Button.module.scss';

export type ButtonActionTypes = 'button' | 'submit' | 'reset';
export type ButtonTypes = 'contained' | 'outline' | 'text';
export type ButtonSizes = 'small' | 'medium' | 'large' | 'x-large';
export type ButtonTypeColor = 'brand' | 'secondary';
export type ButtonIconPosition = 'left' | 'right';

export type ButtonProps = {
    id?: string;
    label?: string;
    color?: ButtonTypeColor;
    icon?: React.ReactNode;
    iconPosition?: ButtonIconPosition;
    htmlType?: ButtonActionTypes;
    type?: ButtonTypes;
    size?: ButtonSizes;
    adaptive?: boolean;
    fullWidth?: boolean;
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    dataTestId?: string;
    children?: ReactNode;
};

const Button = ({
    htmlType = 'button',
    label = '',
    icon,
    iconPosition = 'left',
    type = 'outline',
    color = type === 'contained' ? 'brand' : 'secondary',
    size = 'medium',
    adaptive = true,
    className,
    children,
    fullWidth = false,
    disabled = false,
    dataTestId = 'button',
    loading = false,
    ...restProps
}: ButtonProps) => {
    const iconOnly = (!!children || !label) && !!icon;
    const buttonClasses = cn(
        styles.btn,
        styles[`color--${color}`],
        styles[`size--${size}`],
        styles[`type--${type}`],
        {
            [styles.scaled]: !adaptive, // TODO: remove after kick all of the 'sf' variable from styles
            [styles.btnIconOnly]: iconOnly,
            [styles[`btn-icon-${iconPosition}`]]: (!!children || !!label) && !!icon,
            [styles.loading]: loading,
            [styles.fullWidth]: fullWidth,
        },
        className
    );

    const iconNode = icon && <span className={styles.btnIcon}>{icon}</span>;
    const labelNode = (
        <>
            {iconPosition === 'left' && iconNode}
            {label && <span className={styles.label}>{label}</span>}
            {iconPosition === 'right' && iconNode}
        </>
    );

    return (
        <button
            {...restProps}
            type={htmlType}
            className={buttonClasses}
            data-test-id={dataTestId}
            disabled={disabled || loading}
        >
            {loading ? (
                <DotsLoader color={type === 'contained' ? 'white' : 'black'} adaptive={adaptive} narrow={iconOnly} />
            ) : (
                children || labelNode
            )}
        </button>
    );
};

export default Button;
