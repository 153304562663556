import type { QueryFunctionContext } from 'react-query';
import { useQuery } from 'react-query';
import { getInformationMessage } from '../../../api/services/infoService';
import type { IBaseResponse } from '../../../api/types';

const getInfoMessageKeys = (code: string) => ['infoMessage', code] as const;

type InfoMessageKeys = ReturnType<typeof getInfoMessageKeys>;

function getInformationMessageFn({ queryKey: [, code] }: QueryFunctionContext<InfoMessageKeys>) {
    return getInformationMessage(code);
}

export function useInfoMessage(code: string) {
    return useQuery<IBaseResponse, IBaseResponse, IBaseResponse, InfoMessageKeys>(
        getInfoMessageKeys(code),
        getInformationMessageFn
    );
}
