import { getNumbers } from './helper';

export const sbolPhoneConfirmationCodeValidator = (value: string) => {
    const regexp = /^[\d_]{6}$/;
    return getNumbers(value).length === 6 && !!value.match(regexp);
};

export const emailPattern = /^[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const phoneLength = 11;

export const phoneValidator = (value: string) => {
    const regexp = /^(\+7|8)[- ]?\(?\d{0,3}\)?[- ]?\d{0,3}([- ]?\d{0,2}){0,2}$/;
    return getNumbers(value).length === phoneLength && !!value.match(regexp);
};
