import {
    ADVERTISING_POLICY,
    ALL_PRESENTS_SELECTED,
    APP_THEME,
    CLIENT_IDENTIFICATION_TYPES,
    CLIENT_NOTIFICATION_ENABLED,
    FRIEND_NOTIFICATION_ENABLED,
    HOME_PAGE_HEADER,
    HOME_PAGE_HEADER_BUNDLES,
    HOME_PAGE_HEADER_MIX,
    HOME_PAGE_HEADER_PRESENTS,
    INACTIVITY_TIME,
    MAX_PRESENTS_NUMBER,
    MECHANICS,
    NOTIFICATION_TYPES,
    PRIVACY_POLICY,
    PRODUCT_CATALOG_INTEGRATION_ENABLED,
    SMMR_POLICY,
    CSI_ENABLED,
} from '../../../constants/settings';
import { APP_MECHANICS_TYPES } from '../../../constants/appMechanics';
import type { IAppSettings, ISettingsListResponse } from './types';
import NotificationTypes from '../../../constants/notificationTypes';
import { AppThemes } from '../../../constants/AppThemes';
import { ClientIdentificationTypes } from '../../../constants/ClientIdentificationTypes';
import { publicRequest } from '../../axios';
import { APP_CODE_HEADER } from '../../../constants/common';

export const DEFAULT_INACTIVITY_TIME = 60; // in seconds

export async function getAppSettings(appCode: string) {
    const { data } = await publicRequest.get<ISettingsListResponse>('/settings/list', {
        headers: {
            [APP_CODE_HEADER]: appCode,
        },
    });

    return data;
}

export function getMechanicsValue(arr: string[], type: string) {
    return !!arr.find((elem) => elem === type);
}

export function getInactivityTime(time?: number) {
    return (time || DEFAULT_INACTIVITY_TIME) * 1000;
}

export function getAllPresentsSelected(value?: boolean) {
    return !!value;
}

export function getMaxPresentsNumber(number?: number) {
    return number ? number : null;
}

export async function getConsent(appCode: string, id?: number) {
    const { data } = await publicRequest.get<string>(`/consent/${id}`, {
        responseType: 'text',
        headers: {
            [APP_CODE_HEADER]: appCode,
        },
    });

    return data;
}

export function setSettings(settings: ISettingsListResponse, appCode: string): IAppSettings {
    const {
        [CSI_ENABLED]: csiEnabled,
        [MECHANICS]: mechanics = [],
        [NOTIFICATION_TYPES]: notificationTypes = [],
        [CLIENT_IDENTIFICATION_TYPES]: clientIdentificationTypes = [],
        [CLIENT_NOTIFICATION_ENABLED]: clientNotificationEnabled,
        [FRIEND_NOTIFICATION_ENABLED]: friendNotificationEnabled,
        [INACTIVITY_TIME]: inactivityTime,
        [PRIVACY_POLICY]: consentId,
        [ADVERTISING_POLICY]: advertisingConsentId,
        [SMMR_POLICY]: smmrConsentId,
        [MAX_PRESENTS_NUMBER]: maxPresentsNumber,
        [ALL_PRESENTS_SELECTED]: allPresentsSelected,
        [HOME_PAGE_HEADER]: homePageHeader = '',
        [HOME_PAGE_HEADER_PRESENTS]: homePageHeaderPresents = '',
        [HOME_PAGE_HEADER_BUNDLES]: homePageHeaderBundles = '',
        [HOME_PAGE_HEADER_MIX]: homePageHeaderMix = '',
        [APP_THEME]: appTheme,
        [PRODUCT_CATALOG_INTEGRATION_ENABLED]: prodCatEnabled = false,
    } = settings;

    const hasProductMechanics = getMechanicsValue(mechanics, APP_MECHANICS_TYPES.PRODUCTS);
    const hasPresentsMechanics = getMechanicsValue(mechanics, APP_MECHANICS_TYPES.PRESENTS);
    const hasBundleMechanics = getMechanicsValue(mechanics, APP_MECHANICS_TYPES.BUNDLES);
    const hasMixMechanics = getMechanicsValue(mechanics, APP_MECHANICS_TYPES.MIX);
    const hasSapsanMechanics = getMechanicsValue(mechanics, APP_MECHANICS_TYPES.SAPSAN);

    const hasOnlyOneMechanic = mechanics.length === 1;

    const allowPushNotification = notificationTypes.includes(NotificationTypes.PUSH);
    const allowEmailNotification = notificationTypes.includes(NotificationTypes.EMAIL);

    const showAuthByPhoneNumber = clientIdentificationTypes.includes(ClientIdentificationTypes.PHONE);
    const showAuthBySberID = clientIdentificationTypes.includes(ClientIdentificationTypes.SBER_ID);

    return {
        csiEnabled,
        appCode,
        hasProductMechanics,
        hasPresentsMechanics,
        hasBundleMechanics,
        hasMixMechanics,
        hasSapsanMechanics,
        hasOnlyOneMechanic,
        allowEmailNotification,
        allowPushNotification,
        clientNotificationEnabled: allowPushNotification && clientNotificationEnabled,
        friendNotificationEnabled: allowPushNotification && friendNotificationEnabled,
        smmrConsentId,
        advertisingConsentId,
        consentId,
        homePageHeader,
        homePageHeaderPresents: homePageHeaderPresents ? homePageHeaderPresents : homePageHeader,
        homePageHeaderBundles: homePageHeaderBundles ? homePageHeaderBundles : homePageHeader,
        homePageHeaderMix: homePageHeaderMix ? homePageHeaderMix : homePageHeader,
        maxPresentsNumber: getMaxPresentsNumber(maxPresentsNumber),
        allPresentsSelected: getAllPresentsSelected(allPresentsSelected),
        inactivityTime: getInactivityTime(inactivityTime),
        appTheme: appTheme && Object.values(AppThemes).includes(appTheme) ? appTheme : AppThemes.DEFAULT,
        showAuthByPhoneNumber,
        showAuthBySberID,
        prodCatEnabled,
    };
}
