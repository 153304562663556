import React, { useEffect } from 'react';
import { useMedia } from 'react-use';

import { isCanvas } from '../../utils/helper';
import type { SectorObjType } from './FortuneWheelWrapper';

import './FortuneWheel.scss';

export type FortuneWheelProps = {
    rotateAngle: number;
    easeOutTime: number;
    winNumber: number | null;
    sectorsList: SectorObjType[];
};

const FortuneWheel = ({ rotateAngle, easeOutTime, winNumber, sectorsList }: FortuneWheelProps) => {
    const numOptions = sectorsList.length;
    const isWide = useMedia('(min-width: 600px)');

    const renderWheel = (size: number, radius: number) => {
        // determine number/size of sectors that need to created
        const arcSize = (2 * Math.PI) / numOptions;

        // dynamically generate sectors from state sectorsList
        const wheelElement = document.getElementById('wheel');
        if (wheelElement) {
            while (wheelElement.firstChild) {
                wheelElement.removeChild(wheelElement.firstChild);
                wheelElement.removeAttribute('style');
            }
        }

        if (wheelElement) {
            wheelElement.style.height = size + 'px';
            wheelElement.style.width = size + 'px';
        }

        for (let i = 0; i < numOptions; i++) {
            // Set up wheel slices
            const cv = document.createElement('canvas');
            cv.id = 'slice' + i;
            cv.width = size;
            cv.height = size;
            cv.dataset.slice = '' + i;

            wheelElement && wheelElement.appendChild(cv);
            const sectorObj = sectorsList[i];
            renderSector(i, cv, sectorObj, arcSize, radius);
        }
    };

    const renderSector = (
        index: number,
        canvas: HTMLCanvasElement,
        sectorObj: SectorObjType,
        arc: number,
        radius: number
    ) => {
        // // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const segmentArcAngle = 360 / numOptions;
        const segmentArcLength = (2 * Math.PI) / numOptions;
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;
        const { background } = sectorObj;
        const x = canvas.width / 2;
        const y = canvas.height / 2;

        ctx.beginPath();
        ctx.arc(x, y, radius, arc * index, (index + 1) * arc, false);
        ctx.lineWidth = radius * 2;

        // Установка стиля заливки и отрисовка прямоугольника градиента
        if (typeof background === 'object') {
            const gradient = ctx.createRadialGradient(x, y, 0, x, y, radius * 2);
            // Добавление трёх контрольных точек
            Object.keys(background).map(function (key) {
                gradient.addColorStop(background[key] / 100, key);
            });
            ctx.strokeStyle = gradient;
        } else {
            ctx.strokeStyle = background;
        }
        ctx.font = '16px SB Sans Text;';
        ctx.fillStyle = '#fff';
        ctx.stroke();

        // Create and rotate text labels
        const wheelElement = document.getElementById('wheel');
        const div = document.createElement('div');
        div.classList.add('text');
        div.id = 'text' + index;
        wheelElement && wheelElement.appendChild(div);

        const { description } = sectorsList[index];
        const contentAngle = segmentArcLength * (index + 0.5);
        const contentPosition = {
            x: Math.cos(contentAngle),
            y: Math.sin(contentAngle),
        };
        const transformationCoordinates = 'translateX(-50%) rotate(' + (270 + segmentArcAngle * (index + 0.5)) + 'deg)';

        if (description) {
            const val = document.createElement('span');

            val.classList.add('value');
            val.id = 'value' + index;
            val.innerHTML = description;
            val.style.left = x + contentPosition.x * (radius * 1.4) + 'px';
            val.style.top = y + contentPosition.y * (radius * 1.4) + 'px';
            val.style.transform = transformationCoordinates;
            val.style.transformOrigin = 'top';
            div && div.appendChild(val);
        }

        const disc = document.createElement('span');
        disc.classList.add('discount');
        disc.id = 'discount' + index;
        disc.innerHTML = sectorsList[index].discount;
        const titlePosition = description ? 1.58 : 1.48;
        disc.style.left = x + contentPosition.x * (radius * titlePosition) + 'px';
        disc.style.top = y + contentPosition.y * (radius * titlePosition) + 'px';
        disc.style.transform = transformationCoordinates;
        disc.style.transformOrigin = 'top';
        div && div.appendChild(disc);

        const { additionalText } = sectorsList[index];
        if (additionalText) {
            const addTextSpan = document.createElement('span');
            addTextSpan.classList.add('additional-text');
            addTextSpan.id = 'additional-text' + index;
            addTextSpan.innerHTML = additionalText;
            addTextSpan.style.left = x + contentPosition.x * (radius * 1.5) + 'px';
            addTextSpan.style.top = y + contentPosition.y * (radius * 1.5) + 'px';
            addTextSpan.style.width = radius * (1.2 - numOptions * 0.005) + 'px';
            addTextSpan.style.transform = transformationCoordinates;
            addTextSpan.style.transformOrigin = 'top';
            div && div.appendChild(addTextSpan);
        }

        const imageEl = document.createElement('img');
        imageEl.classList.add('imageEl');
        imageEl.id = 'imageEl' + index;
        imageEl.src = sectorsList[index].image;
        imageEl.style.paddingTop = radius * (0.078 * numOptions) + 'px';
        imageEl.style.maxHeight = radius * (1.1 - numOptions * 0.065) + 'px';
        imageEl.style.maxWidth = radius * (1.1 - numOptions * 0.028) + 'px';
        imageEl.style.left = x + contentPosition.x * 15 + 'px';
        imageEl.style.top = y + contentPosition.y * 15 + 'px';
        imageEl.style.transform = 'translateX(-50%) rotate(' + (270 + segmentArcAngle * (index + 0.5)) + 'deg)';
        imageEl.style.transformOrigin = 'top';

        div && div.appendChild(imageEl);
        ctx.restore();
    };

    useEffect(() => {
        const size = isWide ? 500 : document.documentElement.clientWidth * 0.84;
        const radius = isWide ? 120 : document.documentElement.clientWidth * 0.21;

        renderWheel(size, radius);
    }, []);

    const getResult = () => {
        const winnerSector = document.getElementById('slice' + winNumber);
        if (winnerSector) {
            winnerSector.className = 'win';
        }

        sectorsList.forEach((i, num) => {
            if (num !== winNumber) {
                const canva = document.getElementById('slice' + num);
                const text = document.getElementById('text' + num);
                if (text) {
                    setTimeout(() => {
                        text.style.opacity = '0';
                    }, 10);
                }
                if (canva && isCanvas(canva)) {
                    canva.style.animation = ' 0.7s disappear linear';
                    setTimeout(() => {
                        canva.style.transform = 'scale(0,0)';
                        if (text) {
                            text.style.transform = 'scale(0,0)';
                        }
                    }, 699);
                }
            }

            if (winnerSector && isCanvas(winnerSector)) {
                winnerSector.style.transform = 'scale(1.10, 1.10)';

                const canva = document.getElementById('wheel');
                if (canva) {
                    canva.style.animation = ' winningAnimation 1s forwards .5s';
                }
            }
        });
    };

    useEffect(() => {
        winNumber !== null && getResult();
    }, [winNumber]);

    return (
        <div
            id="wheel"
            style={{
                WebkitTransform: `rotate(${rotateAngle}deg)`,
                WebkitTransition: `-webkit-transform ${easeOutTime}s ease`,
            }}
        />
    );
};

export default FortuneWheel;
