import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';

import { LABELS } from '../../constants/labels';
import { BACKEND_URL, ERROR_STATUS_CODE, Status } from '../../constants/common';

export type AuthenticatedResponseErrorHandlerCallback = ({ message }: { message: string }) => void;

function responseErrorHandler(error: AxiosError) {
    const { response } = error;

    if (!response) {
        return Promise.reject({ message: LABELS.ERRORS.COMMON });
    }

    const { data, status } = response;
    const responseData = data && typeof data === 'object' ? data : {};
    const message =
        status >= ERROR_STATUS_CODE.INTERNAL_SERVER_ERROR
            ? LABELS.ERRORS.COMMON
            : responseData?.message || LABELS.ERRORS.COMMON;

    return Promise.reject({ ...responseData, message, statusCode: status });
}
// только для authenticatedRequest запросов
function getAuthenticatedResponseErrorHandler(callback?: AuthenticatedResponseErrorHandlerCallback) {
    return async function (error: AxiosError) {
        try {
            await responseErrorHandler(error);
        } catch (e) {
            if (!e?.statusCode) {
                return Promise.reject(e);
            }

            if (e.statusCode === ERROR_STATUS_CODE.FORBIDDEN) {
                callback?.({ ...e, message: e.message || LABELS.ERRORS.COMMON });
            }

            return Promise.reject(e);
        }
    };
}

function getAxiosInstance() {
    const instance = axios.create({
        baseURL: BACKEND_URL,
    });

    instance.interceptors.response.use((axiosResponse: AxiosResponse<{ status: Status }>) => {
        if (axiosResponse?.data?.status === Status.NOK) {
            return Promise.reject({ response: axiosResponse });
        }

        return axiosResponse;
    });

    return instance;
}

const authenticatedRequest = getAxiosInstance();
const publicRequest = getAxiosInstance();

publicRequest.interceptors.response.use(undefined, responseErrorHandler);

export { getAuthenticatedResponseErrorHandler, authenticatedRequest, publicRequest };
