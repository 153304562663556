const promoBannersTypes = {
    SCREEN: 'SCREEN', // баннер на детальной странице промо-кампании (лендинг)
    SCREEN_MOBILE: 'SCREEN_MOBILE', // баннер на детальной странице промо-кампании (лендинг) для мобильных устройств
    CARD: 'CARD', // раньше использовался на Главной странице для промо-кампании с типом "Подарок"
    CARD_BUNDLE: 'CARD_BUNDLE', // картинка на Главной странице для бандла
    CARD_PRESENT: 'CARD_PRESENT', // картинка на Главной странице для промо-кампании с типом "Подарок"
    CARD_PRODUCT: 'CARD_PRODUCT', // картинка на Главной странице для промо-кампании с типом "Продукт"
    LOGO_MAIN: 'LOGO_MAIN', // цветной логотип
    LOGO_ICON: 'LOGO_ICON', // квадратный логотип (иконка)
    LINK_OFFER: 'LINK_OFFER', // ссылка, ведущая на сайт партнера с описанием оферты
    TEXT_OFFER: 'TEXT_OFFER', // ссылка, ведущая на PDF-документ с описанием офферты
} as const;

export default promoBannersTypes;
