import type { ReactNode } from 'react';
import type Themes from '../../constants/Themes';

type Position = 'fixed' | 'sticky' | 'relative' | 'static';
export enum HeaderBackgroundColor {
    TRANSPARENT = 'transparent',
    WHITE = 'white',
    WHITE_ALPHA = 'white-alpha',
    GREY = 'grey',
}

export interface HeaderProps {
    renderLeft?: () => ReactNode;
    renderCenter?: () => ReactNode;
    renderRight?: () => ReactNode;
    position?: Position;
    backgroundColor?: HeaderBackgroundColor;
    className?: string;
    htmlElement?: HTMLElement;
    adaptive?: boolean;
    shadow?: boolean;
    theme?: Themes;
    isDuplicate?: boolean;
}
