import React, { useEffect, useMemo } from 'react';

import type { ISuccessCard } from '../types';
import SuccessPageCardLayout from '../SuccessPageCardLayout';
import Loader from '../../Loader';
import type { ButtonSizes } from '../../Button';
import Button from '../../Button';
import { useAnalyticsQueue, useDevices } from '../../../hooks';
import { useOfferStateQuery } from '../../../hooks/services/offer/offers';
import { OfferState } from '../../../api/services/offerService/types';
import ErrorContainer from '../../ErrorContainer';
import { moveToExternalResource } from '../../../utils/helper';
import { USED_WITH_VALUE } from '../../../constants/queryParams';
import type { IBaseResponse } from '../../../api/types';

import { ReactComponent as SuccessIcon } from '../../../static/svgs/apply.svg';

import styles from './SuccessPageSaleSubscriptionCard.module.scss';

type MetricsProps = Pick<ISuccessCard, 'dzoName' | 'productCampaignId' | 'offerId'> & {
    campaignId: ISuccessCard['originalId'];
    campaignName: ISuccessCard['name'];
};

type PaidCardProps = {
    metricsProps: MetricsProps;
    buttonSize: ButtonSizes;
    personalUrl: string;
    title: string;
    description: string;
};

const PaidCard = ({ metricsProps, personalUrl, title, description, buttonSize }: PaidCardProps) => {
    const { pushMetrics } = useAnalyticsQueue();

    useEffect(() => {
        pushMetrics('SUCCESS_ORDER_OFFER_GUIDE_SHOW', metricsProps);
    }, [metricsProps]);

    const onClick = () => {
        pushMetrics('SUCCESS_ORDER_OFFER_APP_CLICK', metricsProps);
        moveToExternalResource({ url: personalUrl, usedWithValue: USED_WITH_VALUE.BUTTON });
    };

    return (
        <>
            <div className={styles.stateContainer}>
                <div className={styles.imgContainer}>
                    <SuccessIcon />
                </div>
                <div className={styles.title}>{title}</div>
                <div className={styles.description}>{description}</div>
            </div>
            <Button label="Управление подпиской" type="contained" size={buttonSize} onClick={onClick} fullWidth />
        </>
    );
};

type PendingCardProps = {
    metricsProps: MetricsProps;
    buttonSize: ButtonSizes;
    paymentUrl: string;
};

const PendingCard = ({ metricsProps, buttonSize, paymentUrl }: PendingCardProps) => {
    const { pushMetrics } = useAnalyticsQueue();

    useEffect(() => {
        pushMetrics('SUCCESS_ORDER_OFFER_PAY_SHOW', metricsProps);
    }, [metricsProps]);

    const onClick = () => {
        pushMetrics('SUCCESS_ORDER_OFFER_PAY_CLICK', metricsProps);
        window.open(paymentUrl, '_blank');
    };

    return (
        <>
            <div className={styles.stateContainer}>
                <div className={styles.imgContainer}>
                    <Loader />
                </div>
                <div className={styles.caption}>Ожидаем подтверждения оплаты</div>
            </div>
            <Button label="К оплате" type="contained" size={buttonSize} onClick={onClick} fullWidth />
        </>
    );
};

type ErrorCardProps = {
    metricsProps: MetricsProps;
    onClick?: () => void;
    buttonSize: ButtonSizes;
} & Pick<IBaseResponse, 'message' | 'description' | 'requestId'>;

const ErrorCard = ({ metricsProps, onClick, buttonSize, message, description, requestId }: ErrorCardProps) => {
    const { pushMetrics } = useAnalyticsQueue();

    useEffect(() => {
        pushMetrics('SUCCESS_ORDER_OFFER_ERROR_SHOW', metricsProps);
    }, [metricsProps]);

    const onButtonClick = () => {
        pushMetrics('SUCCESS_ORDER_OFFER_ERROR_CLICK', metricsProps);
        onClick?.();
    };

    return (
        <>
            <div className={styles.stateContainer}>
                <ErrorContainer message={message} description={description} requestId={requestId} />
            </div>
            {onClick && (
                <Button label="Оформить" type="contained" size={buttonSize} onClick={onButtonClick} fullWidth />
            )}
        </>
    );
};

type SuccessPageSaleSubscriptionCardProps = {
    card: ISuccessCard;
    onOfferAddRefetch: () => void;
};

const SuccessPageSaleSubscriptionCard = ({ card, onOfferAddRefetch }: SuccessPageSaleSubscriptionCardProps) => {
    const {
        originalId: campaignId,
        name: campaignName,
        dzoName,
        productCampaignId,
        offerId,
        paymentUrl,
        personalUrl,
        headerManual,
        manualSubscription,
    } = card;
    const { isMobile } = useDevices();
    const buttonSize: ButtonSizes = !isMobile ? 'x-large' : 'large';
    const {
        offerStateQuery: { data, error, isFetching },
        timeoutExceeded,
    } = useOfferStateQuery(offerId);

    const metricsProps = useMemo<MetricsProps>(
        () => ({ campaignId, campaignName, dzoName, productCampaignId, offerId }),
        [campaignId, campaignName, dzoName, productCampaignId, offerId]
    );

    return (
        <SuccessPageCardLayout promoCampaign={card}>
            {data?.state === OfferState.PAID || data?.state === OfferState.PAID_WITH_RESTRICTION ? (
                <PaidCard
                    title={headerManual}
                    description={manualSubscription}
                    personalUrl={personalUrl}
                    metricsProps={metricsProps}
                    buttonSize={buttonSize}
                />
            ) : error ? (
                <ErrorCard
                    metricsProps={metricsProps}
                    buttonSize={buttonSize}
                    onClick={error.state === OfferState.ERROR_BUTTON ? onOfferAddRefetch : undefined}
                    {...error}
                />
            ) : (data?.state === OfferState.RESERVED && !timeoutExceeded) || isFetching ? (
                <PendingCard paymentUrl={paymentUrl!} metricsProps={metricsProps} buttonSize={buttonSize} />
            ) : (
                <ErrorCard
                    metricsProps={metricsProps}
                    buttonSize={buttonSize}
                    message="Оплата не была получена"
                    description="Попробуйте оформить еще раз"
                    onClick={onOfferAddRefetch}
                />
            )}
        </SuccessPageCardLayout>
    );
};

export default SuccessPageSaleSubscriptionCard;
