import { authenticatedRequest } from '../axios';

export async function sendPush(offerId: number | null) {
    return await authenticatedRequest.post(`/notification/send/offer/${offerId}`);
}

export async function sendPushForGift(offerId: number | null) {
    return await authenticatedRequest.post(`/notification/send/offer/gift/${offerId}`);
}

export async function sendVerificationCode(clientId: number | null) {
    return await authenticatedRequest.post('/notification/send/code', { clientId });
}

export async function verifyCode(clientId: number | null, code: string) {
    return await authenticatedRequest.post('/notification/verify', { clientId, code });
}

export async function sendEmail(offerIds: number[]) {
    return await authenticatedRequest.post('/notification/send/offers/email', { offerIds });
}
