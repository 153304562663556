import React, { forwardRef } from 'react';
import type { NumberFormatPropsBase } from 'react-number-format';
import NumberFormat from 'react-number-format';
import type { InputProps } from '../Input';
import Input from '../Input';

export type NumberInputProps = Omit<NumberFormatPropsBase, 'getInputRef' | 'customInput'> & Omit<InputProps, 'value'>;

const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>((props, ref) => (
    <NumberFormat {...props} getInputRef={ref} customInput={Input} />
));

NumberInput.displayName = 'NumberInput';

export default NumberInput;
