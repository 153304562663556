import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { bundleLinkToPromoCampaign } from '../../utils/mapper';
import { scrollToTop } from '../../utils/helper';
import { getCategoryListFromCampaignList } from '../../utils/promoCampaignHelper';
import { getBundleList } from '../../api/services/bundleService';
import type { ICategoryDto, IPromoCampaign } from '../../api/types';
import type { BundleParams } from './types';
import PresentCard, { PresentCardSkeleton } from '../../component/PresentCard';
import PresentCardList from '../../component/PresentCardList';
import BackButton from '../../component/BackButton/BackButton';
import ExitButton from '../../component/ExitButton';
import Header from '../../component/Header/Header';
import ChosenItemsPanel from '../../component/ChosenItemsPanel';
import FilterPanel from '../../component/FilterPanel/FilterPanel';
import { Footer } from '../../component/Footer';
import { useIntegrationPoint, useAnalyticsQueue } from '../../hooks';

import styles from './Bundle.module.scss';
import { useAppSettings } from '../../component/AppSettings';

type Props = {
    goNext: (ids: string[]) => void;
    goBack: () => void;
};

const Bundle = ({ goNext, goBack }: Props) => {
    const { pushMetrics } = useAnalyticsQueue();
    const { bundleId = '' } = useParams<BundleParams>();
    const [list, setList] = useState<IPromoCampaign[]>([]);
    const [campaignGroupName, setCampaignGroupName] = useState<string>('');
    const [categoryList, setCategoryList] = useState<ICategoryDto[]>([]);
    const [filterList, setFilterList] = useState<number[]>([]);
    const [selectedList, setSelectedList] = useState<Record<string, boolean>>({});
    const selectedItemsCount = useMemo<number>(
        () => Object.values(selectedList).filter((selected) => selected).length,
        [selectedList]
    );
    const [loading, setLoading] = useState<boolean>(false);
    const integrationPoint = useIntegrationPoint();
    const appSettings = useAppSettings();

    useEffect(() => {
        let isMounted = true;
        scrollToTop();

        (async function () {
            try {
                setLoading(true);

                const groups = await getBundleList({
                    ids: [parseInt(bundleId, 10)],
                    integrationPoint,
                });

                const { links = [], name: campaignGroupName = '' } = groups[0];
                const campaignList = links.map((el) => bundleLinkToPromoCampaign(el, appSettings));
                const categoryList = getCategoryListFromCampaignList(campaignList);

                isMounted && setCampaignGroupName(campaignGroupName);
                isMounted && setList(campaignList);
                isMounted && setCategoryList(categoryList);

                campaignList.forEach(({ name: campaignName, dzoName, originalId }) => {
                    pushMetrics('BUNDLE_BUNDLE_SHOW', {
                        campaignGroupName,
                        campaignId: originalId,
                        campaignName,
                        dzoName,
                    });
                });
                setLoading(false);
            } catch (e) {
                console.error(`Active promo campaign list get error: ${e.message}`);
            }
        })();

        return () => {
            isMounted = false;
        };
    }, []);

    const handleClick = ({ id: campaignId, name: campaignName, dzoName, originalId }: IPromoCampaign) => {
        const selected = selectedList[campaignId];
        const eventAction = selected ? 'BUNDLE_REMOVE_OFFER_CLICK' : 'BUNDLE_CHOOSE_OFFER_CLICK';
        pushMetrics(eventAction, { campaignId: originalId, campaignName, dzoName, campaignGroupName });
        setSelectedList((prev) => ({ ...prev, [campaignId]: !selected }));
    };

    const onReset = () => {
        pushMetrics('BUNDLE_REMOVE_OFFERS_CLICK', { campaignGroupName });
        setSelectedList({});
    };

    const handleDetailClick = ({ name: campaignName, dzoName, originalId }: IPromoCampaign) => {
        pushMetrics('BUNDLE_DETAIL_CLICK', { campaignId: originalId, campaignName, dzoName, campaignGroupName });
    };

    const goToOffers = () => {
        if (selectedItemsCount) {
            const idList = list
                .filter(({ id }) => selectedList[id])
                .map(({ id: campaignId, name: campaignName, dzoName, originalId }) => {
                    pushMetrics('BUNDLE_GET_BTN_CLICK', {
                        campaignGroupName,
                        campaignId: originalId,
                        campaignName,
                        dzoName,
                    });
                    return campaignId;
                });
            goNext(idList);
        }
    };

    const filteredList = useMemo(
        () =>
            filterList.length === 0
                ? list
                : list.filter(
                      ({ id, categoryList }) =>
                          categoryList.filter((el) => filterList.includes(el.categoryId)).length > 0 || selectedList[id]
                  ),
        [filterList, list, selectedList]
    );

    return (
        <div className={styles.page}>
            <Header
                position="sticky"
                renderLeft={() => <BackButton onClick={goBack} />}
                renderRight={() => <ExitButton />}
            />
            <div className={styles.body}>
                <FilterPanel categoryList={categoryList} filterList={filterList} setFilterList={setFilterList} />
                <PresentCardList>
                    {!loading
                        ? filteredList.map((item) => (
                              <PresentCard
                                  key={item.id}
                                  campaign={item}
                                  handleClick={handleClick}
                                  handleDetailClick={handleDetailClick}
                                  selected={selectedList[item.id]}
                              />
                          ))
                        : Array.from(Array(3)).map((_, index) => <PresentCardSkeleton key={index} />)}
                </PresentCardList>
            </div>
            <Footer>{selectedItemsCount !== 0 && <ChosenItemsPanel onClick={goToOffers} onReset={onReset} />}</Footer>
        </div>
    );
};

export default Bundle;
