/**
 * Здесь должны быть перечислены все возможные темы приложения.
 * Значение константы должно быть задано в стиле camelCase.
 * В авторизованной зоне тема берется из настроек приложения.
 * На отдельных экранах может задаваться конкретная тема (по умолчанию это DEFAULT).
 * Для применения темы используется AppThemeDecorator.
 * Также для каждой темы должно быть добавлено изображение для Главной страницы, которое должно быть
 * размещено в папке "/src/static/themes/${theme}/" (используется в HomePageTopPanel).
 */
export enum AppThemes {
    DEFAULT = 'default',
    DEFENDER_FATHERLAND_DAY = 'defenderFatherlandDay',
    GREEN_DAY = 'greenDay',
    NEW_YEAR = 'newYear',
    SUMMER = 'summer',
    VICTORY_DAY = 'victoryDay',
    WOMENS_DAY = 'womensDay',
}
