import React from 'react';

import type { HeaderProps } from '../../component/Header';
import Header, { HeaderBackgroundColor } from '../../component/Header';
import CorpLogo from '../../component/CorpLogo';
import ExitButton from '../../component/ExitButton';
import { useAppSettings } from '../../component/AppSettings';
import useDevices from '../../hooks/useDevices';
import MechanicsNavbar from '../../component/MechanicsNavbar';
import Themes from '../../constants/Themes';

const { WHITE, TRANSPARENT } = HeaderBackgroundColor;

export const TEST_ID_ADDITIONAL_EXIT_BTN = 'additional-exit-button';

const MainHeader = ({ backgroundColor = WHITE, isDuplicate, ...restProps }: HeaderProps) => {
    const { hasOnlyOneMechanic } = useAppSettings();
    const { isMobile } = useDevices();
    const theme: Themes = backgroundColor === TRANSPARENT ? Themes.DARK : Themes.LIGHT;

    return (
        <Header
            {...restProps}
            theme={theme}
            backgroundColor={backgroundColor}
            isDuplicate={isDuplicate}
            renderLeft={() => <CorpLogo theme={theme} />}
            renderCenter={() => !hasOnlyOneMechanic && !isMobile && <MechanicsNavbar />}
            renderRight={() => (
                <ExitButton
                    {...(isDuplicate ? { id: 'additional-exit-button', dataTestId: TEST_ID_ADDITIONAL_EXIT_BTN } : {})}
                />
            )}
        />
    );
};

export default MainHeader;
