import React from 'react';
import cn from 'classnames';

import { SUCCESS_PAGE_CARD_TYPE } from '../../constants/successPageCardTypes';
import { INFO_PROMO_CODE } from '../../constants/common';
import { useAppSettings } from '../AppSettings';
import { useAnalyticsQueue } from '../../hooks';
import AdvertisingTag from '../AdvertisingTag';
import ButtonWithTimeout from '../ButtonWithTimeout';
import Button from '../Button';
import DzoLogo from '../DzoLogo';
import PromoCode from '../PromoCode';
import type { SuccessPageCardProps } from './types';
import { DescriptionBlock, InfoBlock, QrBlock } from './SuccessPageCardParts';
import { ReactComponent as ArrowShare } from '../../static/svgs/arrow-share.svg';
import { ReactComponent as QrIcon } from '../../static/svgs/qr-icon.svg';
import { ReactComponent as SberIcon } from '../../static/svgs/sber-icon.svg';

import styles from './SuccessPageCard.module.scss';

const { SINGLE } = SUCCESS_PAGE_CARD_TYPE;

export const DEFAULT_GO_TO_SITE_BTN_LABEL = 'Перейти на сайт';

const SuccessPageCard = ({
    card,
    cardType,
    openQrModal,
    onExternalLinkClick,
    onSendPushClick,
    loading,
}: SuccessPageCardProps) => {
    const {
        id,
        name: campaignName,
        logoUrl,
        dzoName,
        personalUrl,
        offerDescription,
        promoCode,
        allowClientNotification,
        allowFriendNotification,
        showQR,
        showGoToSiteButton,
        promoCodeId,
        offerId,
        successButtonLabel,
        productCampaignId,
        originalId,
        rules,
        tradeName,
        creatives,
    } = card;
    const erid = creatives.SUCCESS_ERID;
    const { clientNotificationEnabled, friendNotificationEnabled } = useAppSettings();
    const { pushMetrics } = useAnalyticsQueue();

    const onSendPushButtonClick = () => onSendPushClick(card);

    const onSendGiftButtonClick = () => onSendPushClick(card, true);

    const onAdvertisingTagClick = () => {
        pushMetrics('ADVERTISEMENT_CLICK', {
            campaignId: originalId,
            campaignName,
            dzoName,
            productCampaignId,
            tradeName,
            erid,
        });
    };

    const onGoToSiteButtonClick = () => onExternalLinkClick(card);

    const onPromoCodeCopy = () => {
        pushMetrics('SUCCESS_MAIN_OFFERS_COPY_PROMOCODE_CLICK', {
            campaignId: originalId,
            campaignName,
            dzoName,
            promoCodeId,
            offerId,
            productCampaignId,
        });
    };

    const openModal = () => {
        pushMetrics('SUCCESS_SHOW_QR_CLICK', {
            campaignId: originalId,
            campaignName,
            dzoName,
            productCampaignId,
            promoCodeId,
            offerId,
        });
        openQrModal(card);
    };

    return (
        <div
            className={cn(styles.cardWrapper, { [styles.single]: cardType === SINGLE })}
            data-test-id={`card-wrapper-${id}`}
        >
            <AdvertisingTag tradeName={tradeName} erid={erid} onClick={onAdvertisingTagClick} rules={rules} />
            <div>
                <DzoLogo logoUrl={logoUrl} dzoName={dzoName} dataTestId={`dzo-logo-${id}`} />
                <DescriptionBlock value={offerDescription} />
                {cardType === SINGLE && showQR && personalUrl && (
                    <div className={styles.qrBlockSingle}>
                        <QrBlock url={personalUrl} />
                    </div>
                )}
                {promoCode && (
                    <div className={styles.promoCodeBlock}>
                        <PromoCode text={promoCode} onCopyText={onPromoCodeCopy} dataTestId={`promo-code-${id}`} />
                        <InfoBlock value={INFO_PROMO_CODE} dataTestId={`info-block-${id}`} />
                    </div>
                )}
            </div>
            <div className={styles.bottomBlock}>
                {clientNotificationEnabled && allowClientNotification && (
                    <ButtonWithTimeout
                        label="Отправить в Сбербанк Онлайн"
                        timeoutSuffix="Отправить повторно через "
                        onClick={onSendPushButtonClick}
                        color="brand"
                        dataTestId={`send-push-button-${id}`}
                        icon={<SberIcon />}
                        clicksLimit={2}
                        loading={loading}
                        fullWidth
                    />
                )}
                {friendNotificationEnabled && allowFriendNotification && (
                    <ButtonWithTimeout
                        label="Подарить предложение"
                        labelSent="Подарок отправлен"
                        timeoutSuffix="Отправить повторно через "
                        onClick={onSendGiftButtonClick}
                        dataTestId={`send-gift-button-${id}`}
                        clicksLimit={2}
                        color="brand"
                        icon={<ArrowShare />}
                        fullWidth
                    />
                )}
                {showGoToSiteButton && personalUrl && (
                    <Button
                        label={successButtonLabel}
                        onClick={onGoToSiteButtonClick}
                        type="contained"
                        dataTestId={`go-to-site-button-${id}`}
                        fullWidth
                    />
                )}
                {cardType !== SINGLE && showQR && personalUrl && (
                    <Button
                        label="Показать QR-код"
                        icon={<QrIcon />}
                        onClick={openModal}
                        dataTestId={`show-qr-button-${id}`}
                        fullWidth
                    />
                )}
            </div>
        </div>
    );
};

export default SuccessPageCard;
