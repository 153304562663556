import { createContext } from 'react';
import type { ModalContext, ModalReducerActionData, ModalState } from './types';
import { CHANGE_FOOTER_ACTION, CLOSE_MODAL_ACTION, ModalTypes, OPEN_MODAL_ACTION } from '../constants/modal';

export const ModalCtx = createContext<ModalContext>({} as ModalContext);

export const initialState: ModalState = {
    modalType: ModalTypes.NONE,
    isModalOpen: false,
    modalTitle: '',
    modalMessage: '',
    additionalMenuList: undefined,
    footerChanges: 0,
    exitButtonId: undefined,
};

export function userReducer(state: ModalState, action: ModalReducerActionData): ModalState {
    switch (action.type) {
        case OPEN_MODAL_ACTION:
            return { ...state, ...action.payload, isModalOpen: true };
        case CLOSE_MODAL_ACTION:
            return {
                ...state,
                ...initialState,
            };
        case CHANGE_FOOTER_ACTION: {
            return {
                ...state,
                footerChanges: state.footerChanges + 1,
            };
        }
        default:
            return state;
    }
}
