import React, { forwardRef } from 'react';

import { ADVERTISING_POLICY } from '../../../constants/settings';
import type { productTargetKeys } from '../../../utils/clickStream';
import ConsentCheckbox from '../ConsentCheckbox/ConsentCheckbox';
import type { CheckboxProps } from '../../Checkbox';
import useConsentModalProps from '../../../hooks/useConsentModalProps';
import ConsentLink from '../ConsentLink';

type Props = Omit<CheckboxProps, 'label'> & {
    eventAction: productTargetKeys;
};

const INFORMATION = 'Хочу узнавать о новинках и актуальных предложениях и даю ';
const CLICKABLE_TEXT = 'согласие на рекламную рассылку';

const AdvertisingConsent = forwardRef<HTMLInputElement, Props>(({ eventAction, ...props }, ref) => {
    const { consentModalProps, onClick } = useConsentModalProps();

    const label = (
        <>
            {INFORMATION}{' '}
            <ConsentLink type={ADVERTISING_POLICY} eventAction={eventAction} onLinkClick={onClick}>
                {CLICKABLE_TEXT}
            </ConsentLink>
        </>
    );

    return <ConsentCheckbox checkboxProps={{ label, ...props }} consentModalProps={consentModalProps} ref={ref} />;
});

AdvertisingConsent.displayName = 'AdvertisingConsent';

export default AdvertisingConsent;
