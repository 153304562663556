import React, { useContext } from 'react';
import type { IAppSettings } from '../../api/services/settingService';

export const AppSettingsCtx = React.createContext<IAppSettings | undefined>(undefined);

export const useAppSettings = () => {
    const appSettingsCtx = useContext(AppSettingsCtx);

    if (typeof appSettingsCtx === 'undefined') {
        throw Error(`Use useAppSettings() inside <AppSettingsProvider>`);
    }

    return appSettingsCtx;
};
