import { useCallback, useMemo, useState } from 'react';

import type { ConsentType } from '../constants/settings';
import { ADVERTISING_POLICY, PRIVACY_POLICY } from '../constants/settings';
import { SMMR_POLICY } from '../constants/settings';
import { useAppSettings } from '../component/AppSettings';
import { phoneValidator } from '../utils/validators';
import { getNonBreakingString } from '../utils/stringHelper';
import type { ConsentModalProps } from '../component/Consent/ConsentCheckbox/ConsentCheckbox';
import useAnalyticsQueue from './useAnalyticsQueue';
import type { productTargetKeys } from '../utils/clickStream';

const defaultGetConsentMessage = (consent: string) => consent;

export const TITLE = {
    [PRIVACY_POLICY]: 'Согласие на обработку моих персональных данных ПАО «Сбербанк»',
    [ADVERTISING_POLICY]: 'Согласие на рассылку',
    [SMMR_POLICY]: 'Согласие на обработку моих персональных ООО «СберМегаМаркет-Ритейл»',
} as const;

export const CONSENT_ID_KEY = {
    [PRIVACY_POLICY]: 'consentId',
    [ADVERTISING_POLICY]: 'advertisingConsentId',
    [SMMR_POLICY]: 'smmrConsentId',
} as const;

export const PHONE_NUMBER_TEMPLATE = '+7 (XXX) XXX-XX-XX' as const;

function generatePrivacyPolicyMessage(phone: string, privacyPolicy = '') {
    const phoneNumber = getNonBreakingString(phone ? phone : PHONE_NUMBER_TEMPLATE);
    return privacyPolicy ? privacyPolicy.replace('${phoneNumber}', phoneNumber) : '';
}

const getPrivacyPolicyMessageFn =
    (phoneNumber = '') =>
    (privacyPolicy: string) =>
        generatePrivacyPolicyMessage(phoneValidator(phoneNumber) ? phoneNumber : '', privacyPolicy);

const useConsentModalProps = (
    phoneNumber?: string
): {
    consentModalProps?: ConsentModalProps;
    onClick: (type: ConsentType, eventAction: productTargetKeys) => void;
} => {
    const [consentType, setConsentType] = useState<null | ConsentType>(null);
    const appSettings = useAppSettings();
    const { pushMetrics } = useAnalyticsQueue();

    const onClick = useCallback((type: ConsentType, eventAction: productTargetKeys) => {
        pushMetrics(eventAction, { consentId: appSettings[CONSENT_ID_KEY[type]] });
        setConsentType(type);
    }, []);

    const onClose = useCallback(() => setConsentType(null), []);

    const getPrivacyPolicyMessage = useMemo(() => getPrivacyPolicyMessageFn(phoneNumber), [phoneNumber]);

    return useMemo(() => {
        if (!consentType) {
            return {
                onClick,
            };
        }

        const getConsentMessage = [PRIVACY_POLICY, SMMR_POLICY].includes(consentType)
            ? getPrivacyPolicyMessage
            : defaultGetConsentMessage;

        return {
            consentModalProps: {
                title: TITLE[consentType],
                consentId: appSettings[CONSENT_ID_KEY[consentType]]!,
                getConsentMessage,
                onClose,
            },
            onClick,
        };
    }, [consentType, getPrivacyPolicyMessage]);
};

export default useConsentModalProps;
