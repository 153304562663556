import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import useDevices from '../../hooks/useDevices';
import { saveClientExtraDataToBackend } from '../../api/services/clientService';
import { useClient } from '../Client';
import { useAppSettings } from '../AppSettings';
import type { ButtonSizes } from '../Button';
import Button from '../Button';
import AdvertisingConsentField from '../AdvertisingConsentField';
import SubmitButton from '../SubmitButton';
import SuccessPageModalBody from '../SuccessPageModalBody';

import type { FormValues, Props, State, Submit } from './types';

import { ReactComponent as SuccessIcon } from '../../static/svgs/apply.svg';
import { ReactComponent as ErrorIcon } from '../../static/svgs/not-found.svg';

const SUCCESS_MESSAGE = 'Предложение отправлено';
const BUTTON_LABEL = 'Продолжить';

const initialState = { sent: false, incorrectCode: false };

const AdvertisingConfirmationForm = ({ onCancel, onSubmit, pushSVEventSuccessOfferShow }: Props) => {
    const { client, setExtraData } = useClient();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { id: clientId } = client!;
    const { advertisingConsentId } = useAppSettings();
    const [{ sent, message, description, requestId }, setState] = useState<State>(initialState);
    const { isMobile } = useDevices();
    const buttonSize: ButtonSizes = !isMobile ? 'x-large' : 'large';
    const methods = useForm<FormValues>({
        defaultValues: {
            agreeWithAdvertising: false,
        },
        mode: 'all',
    });

    useEffect(() => {
        pushSVEventSuccessOfferShow && pushSVEventSuccessOfferShow();
    }, []);

    const setError = (message: string, description?: string, requestId?: string) =>
        setState({
            sent: false,
            message,
            description,
            requestId,
        });

    const onCancelClick = () => {
        onCancel();
    };

    const onSubmitButtonClick: Submit = async (data) => {
        const { agreeWithAdvertising } = data;
        try {
            await saveClientExtraDataToBackend({ consentId: advertisingConsentId, clientId });
            setExtraData({ agreeWithAdvertising });
            await onSubmit();
            setState((prevState) => ({ ...prevState, sent: true }));
        } catch (e) {
            setError(e.message, e.description);
        }
    };

    if (message) {
        return (
            <SuccessPageModalBody
                onCloseButtonClick={onCancelClick}
                renderTopBlock={() => <ErrorIcon />}
                header={message}
                description={description}
                requestId={requestId}
                dataTestId="advertising-confirmation-error-modal"
                renderButtons={() => (
                    <Button
                        label={BUTTON_LABEL}
                        onClick={onCancelClick}
                        type="contained"
                        size={buttonSize}
                        dataTestId="advertising-confirmation-error-continue-button"
                    />
                )}
            />
        );
    }

    if (sent) {
        return (
            <SuccessPageModalBody
                onMount={pushSVEventSuccessOfferShow}
                renderTopBlock={() => <SuccessIcon />}
                header={SUCCESS_MESSAGE}
                dataTestId="successful-push-sending-modal"
                renderButtons={() => (
                    <Button
                        label={BUTTON_LABEL}
                        onClick={onCancelClick}
                        type="contained"
                        size={buttonSize}
                        dataTestId="successful-push-sending-modal-continue-button"
                    />
                )}
            />
        );
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmitButtonClick)}>
                <SuccessPageModalBody
                    onCloseButtonClick={onCancelClick}
                    header="Подтверждение"
                    dataTestId="advertising-confirmation-form"
                    renderContent={() => (
                        <AdvertisingConsentField eventAction="CONFIRM_CONSENT_ADVERTISING_POLICY_BTN_CLICK" />
                    )}
                    renderButtons={() => (
                        <SubmitButton
                            label="Подтвердить"
                            size={buttonSize}
                            fullWidth
                            dataTestId="advertising-confirmation-form-submit-button"
                        />
                    )}
                />
            </form>
        </FormProvider>
    );
};

export default AdvertisingConfirmationForm;
