import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Apps from './Apps';
import App from '../App';
import { PARAMS } from '../../constants/route';

const AppsRouter = () => (
    <Routes>
        <Route path="/" element={<Apps />} />
        <Route path={`${PARAMS.APP}/*`} element={<App />} />
    </Routes>
);

export default AppsRouter;
