import { useMount } from 'react-use';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CODE, ID } from '../../constants/queryParams';
import { useGiftLogin } from '../../hooks/services/user';
import { generateGiftPath } from '../../utils/generatePathHelper';

const GiftLogin = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const offerId = searchParams.get(ID) ?? searchParams.get(CODE) ?? '';
    const { mutate: giftLogin } = useGiftLogin();

    useMount(() => {
        giftLogin(
            { authData: offerId },
            {
                onSuccess: ({ campaignId, appCode }) => {
                    navigate(generateGiftPath(appCode, campaignId, offerId), { replace: true });
                },
            }
        );
    });

    return null;
};

export default GiftLogin;
