export const TECHNICAL_PERSONAL_NUMBER = '00000000000';
export const SUPPORT_EMAIL = 'CAB-SP-M0000837@sberbank.ru';
export const TOKEN_REFRESH_TIME = 60 * 1000; // 1 минута
export const APP_CODE_HEADER = 'clientAppCode';
export const BACKEND_URL = window.__app_env__.BACKEND_URL || '';
export const INSTALLING_CERTIFICATES_WEBSITE = 'https://www.sberbank.ru/ru/certificates';
export const YANDEX_BROWSER_WEBSITE = 'https://browser.yandex.ru/';
export const INFO_PROMO_CODE = 'Скопируйте промокод\nи примените для скидки';
export const RULES_MODAL_TITLE = 'Условия';
export const ORDER_STATUS_CHECK_TIME = parseInt(window.__app_env__.ORDER_STATUS_CHECK_TIME || '5', 10) * 1000;
export const ORDER_STATUS_TIMEOUT = parseInt(window.__app_env__.ORDER_STATUS_TIMEOUT || '1200', 10) * 1000;

export enum Status {
    OK = 'Ok',
    NOK = 'NOK',
}

export enum AppCertificateChangeTypes {
    WARNING = 'warning',
    FORBIDDEN = 'forbidden',
}

export const ERROR_STATUS_CODE = {
    NOT_ACCEPTABLE: 406,
    FORBIDDEN: 403,
    UNAUTHORIZED: 401,
    UNPROCESSABLE_ENTITY: 422,
    INTERNAL_SERVER_ERROR: 500,
    AUTHENTICATION_TIMEOUT: 419,
} as const;
