export const ROUTE = {
    CORE: '/',
    APPS: '/apps',
    INFO: '/info',
    JWT_LOGIN: '/login-by-token',
    GIFT: '/gift',
    AUTH_SBER_ID: '/auth-sber-id',
    FORTUNE_WHEEL: '/fortune-wheel',
    WHEEL_ONE: '/wheel-one',
    PROMO_LANDING: '/promolanding',
} as const;

export const PAGES = {
    INSTALL: 'install',
    SUCCESS: 'qr-page',
    PRODUCTS: 'products',
    BUNDLES: 'bundles',
    PRESENTS: 'presents',
    MIX: 'mix',
    CLIENT_AUTH: 'client-auth',
    GENERAL: 'general',
    QR_REDIRECT: 'qr-redirect',
    GIFT: 'gift',
    SAPSAN: 'sapsan',
    OFFER: 'success', // TODO: использовать SUCCESS после замены 'qr-page'
    LANDING: 'landing',
} as const;

export const GIFT_ROUTE = {
    GET_PAGE: `${ROUTE.GIFT}/get`,
    SEND_PAGE: `${ROUTE.GIFT}/send`,
} as const;

export const PARAMS = {
    APP: ':appCode',
    PRODUCT: ':promoCampaignId',
    BUNDLE: ':bundleId',
    OFFER: ':offerId',
    EXTERNAL: ':externalId',
    INFO_CODE: ':code',
} as const;

function generateAppRoutes() {
    const APP = `${ROUTE.APPS}/${PARAMS.APP}` as const;
    const GENERAL = `${APP}/${PAGES.GENERAL}` as const;
    const CLIENT_AUTH = `${APP}/${PAGES.CLIENT_AUTH}` as const;
    const QR_REDIRECT = `${APP}/${PAGES.QR_REDIRECT}` as const;
    const GIFT = `${APP}/${PAGES.GIFT}/${PARAMS.PRODUCT}/${PARAMS.OFFER}` as const;

    const SAPSAN = `${GENERAL}/${PAGES.SAPSAN}` as const;
    const SAPSAN_PRODUCT = `${SAPSAN}/${PARAMS.PRODUCT}` as const;

    const PRODUCTS = `${GENERAL}/${PAGES.PRODUCTS}` as const;
    const PRODUCT = `${PRODUCTS}/${PARAMS.PRODUCT}` as const;

    const BUNDLES = `${GENERAL}/${PAGES.BUNDLES}` as const;
    const BUNDLE = `${BUNDLES}/${PARAMS.BUNDLE}` as const;

    const PRESENTS = `${GENERAL}/${PAGES.PRESENTS}` as const;

    const MIX = `${GENERAL}/${PAGES.MIX}` as const;

    const MIX_PRODUCT = `${MIX}/${PAGES.PRODUCTS}/${PARAMS.PRODUCT}` as const;
    const MIX_BUNDLE = `${MIX}/${PAGES.BUNDLES}/${PARAMS.BUNDLE}` as const;

    return {
        APP,
        GENERAL,
        CLIENT_AUTH,
        QR_REDIRECT,
        SAPSAN,
        SAPSAN_PRODUCT,
        GIFT,
        PRODUCTS,
        PRODUCT,
        BUNDLES,
        BUNDLE,
        PRESENTS,
        MIX,
        MIX_PRODUCT,
        MIX_BUNDLE,
    } as const;
}

export const APP_ROUTE = generateAppRoutes();
