import React from 'react';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import styles from './DzoLogo.module.scss';

type DzoLogoProps = {
    logoUrl?: string | null;
    dzoName: string;
    dataTestId?: string;
    centered?: boolean;
};

const DzoLogo = ({ logoUrl, dzoName, dataTestId = 'dzo-logo', centered = true }: DzoLogoProps) => (
    <div className={cn(styles.logoBlock, { [styles.centered]: centered })} data-test-id={dataTestId}>
        {logoUrl && <img src={logoUrl} alt={`Логотип ${dzoName}`} />}
    </div>
);

export const DzoLogoSkeleton = ({ centered = true }: Pick<DzoLogoProps, 'centered'>) => (
    <Skeleton containerClassName={cn(styles.logoBlock, { [styles.centered]: centered })} className={styles.skeleton} />
);

export default DzoLogo;
