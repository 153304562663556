import React from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Bundle from './Bundle';
import { PAGES } from '../../constants/route';
import BundleSuccessPage from './BundleSuccessPage';
import SelectedOffers from '../SelectedOffers';

type Props = {
    goBack: () => void;
};

const BundleRouter = ({ goBack }: Props) => {
    const navigate = useNavigate();

    const goToSuccessPage = (ids: string[]) =>
        navigate({
            pathname: PAGES.SUCCESS,
            search: new URLSearchParams({
                selectedOffers: ids.join(','),
            }).toString(),
        });

    return (
        <Routes>
            <Route path="/" element={<Bundle goBack={goBack} goNext={goToSuccessPage} />} />
            <Route
                path={PAGES.SUCCESS}
                element={
                    <SelectedOffers
                        render={(selectedOffers) =>
                            selectedOffers?.length ? (
                                <BundleSuccessPage selectedItems={selectedOffers} goBack={goBack} />
                            ) : (
                                <Navigate to=".." replace />
                            )
                        }
                    />
                }
            />
            <Route path="*" element={<Navigate to=".." replace />} />
        </Routes>
    );
};

export default BundleRouter;
