import React from 'react';
import cn from 'classnames';

import Themes from '../../constants/Themes';
import type { HeaderProps } from './types';
import { HeaderBackgroundColor } from './types';

import styles from './Header.module.scss';

const { TRANSPARENT } = HeaderBackgroundColor;

const Header = ({
    renderLeft,
    renderCenter,
    renderRight,
    position = 'relative',
    backgroundColor = TRANSPARENT,
    className,
    theme,
    shadow,
}: HeaderProps) => {
    const headerTheme: Themes = theme ? theme : backgroundColor === TRANSPARENT ? Themes.DARK : Themes.LIGHT;
    const hasOnlyRightSlot = !!renderRight && !renderLeft && !renderCenter;
    const hasOnlyCenterSlot = !!renderCenter && !renderLeft && !renderRight;

    return (
        <div
            id="header"
            className={cn(styles.header, styles[position], styles[backgroundColor], headerTheme, {
                [styles.shadow]: shadow,
            })}
        >
            <div
                className={cn(styles.slots, className, {
                    [styles.center]: hasOnlyCenterSlot,
                    [styles.right]: hasOnlyRightSlot,
                })}
            >
                {renderLeft && <div className={styles.slot}>{renderLeft()}</div>}
                {renderCenter && <div className={styles.slot}>{renderCenter()}</div>}
                {renderRight && <div className={styles.slot}>{renderRight()}</div>}
            </div>
        </div>
    );
};

export default Header;
