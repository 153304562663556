import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import PopUp from '../../component/PopUp';
import General from '../../containers/General';
import { PAGES } from '../../constants/route';
import { ClientAuthPage } from '../../containers/Client/Page';
import { QRRedirect } from '../../component/QRLogin';
import { ClientOfferView } from '../../containers/ClientOffer';

const { GENERAL, CLIENT_AUTH, QR_REDIRECT, GIFT } = PAGES;

const Console = () => (
    <>
        <Routes>
            <Route path={`${GENERAL}/*`} element={<General />} />
            <Route path={`${GIFT}/*`} element={<ClientOfferView />} />
            <Route path={CLIENT_AUTH} element={<ClientAuthPage />} />
            <Route path={QR_REDIRECT} element={<QRRedirect />} />
            <Route path="*" element={<Navigate to={GENERAL} replace />} />
        </Routes>
        <PopUp />
    </>
);

export default Console;
