import React from 'react';
import cn from 'classnames';

import styles from './ErrorContainer.module.scss';
import { ReactComponent as ErrorIcon } from '../../static/svgs/error-icon.svg';

interface Props {
    message?: string;
    description?: string;
    requestId?: string;
    parentHasGap?: boolean; // Управляет ли родитель отступами
}

const ErrorContainer = ({ message, description, requestId, parentHasGap }: Props) => (
    <div
        className={cn(styles.container, {
            [styles.withMargin]: !parentHasGap,
        })}
    >
        <div className={styles.warning}>
            <div className={styles.iconContainer}>
                <ErrorIcon />
            </div>
            <div className={styles.content}>
                {message && <p className={styles.title}>{message}</p>}
                {description && <p className={styles.description}>{description}</p>}
                {requestId && <p className={styles.requestId}>ID запроса: {requestId}</p>}
            </div>
        </div>
    </div>
);

export default ErrorContainer;
