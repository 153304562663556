import type { BaseItem } from '@sbol/clickstream-agent';
import { sendEvent, setConfig, setMeta, setProfile } from '@sbol/clickstream-agent';
import {
    CLICK_STREAM_DEBUG_ENABLED,
    CLICK_STREAM_ENABLED,
    CLICK_STREAM_URL,
    CLICK_STREAM_API_KEY,
} from '../../constants/appEnvironments';
import type {
    ClickStreamConstructorParams,
    ConfigType,
    EventPushData,
    EventType,
    MetaType,
    ProfileType,
} from './types';
import getMechanicActions from './getMechanicActions';
import { clickStreamTargetList } from '../../constants/clickStreamTargetList';
import type { Properties } from '@sbol/clickstream-agent/esm/clickstream/types';

const actionsList = getMechanicActions(clickStreamTargetList);

export default class ClickStream {
    private static instance: ClickStream = new ClickStream({
        isEnabled: CLICK_STREAM_ENABLED,
        isDebugEnabled: CLICK_STREAM_DEBUG_ENABLED,
        config: {
            url: CLICK_STREAM_URL,
            buffer: 1,
            credentials: false,
        },
        meta: {
            apiKey: CLICK_STREAM_API_KEY,
        },
        profile: {
            hashUserLoginId: '',
        },
    });
    private readonly isEnabled: boolean;
    private readonly isDebugEnabled: boolean;

    private constructor(params: ClickStreamConstructorParams) {
        this.isEnabled = params.isEnabled;
        this.isDebugEnabled = params.isDebugEnabled;

        if (this.isEnabled) {
            this.configureSDK(params.config, params.meta, params.profile);
        }
    }

    public static getInstance(): ClickStream {
        return ClickStream.instance;
    }

    public static updateProfile(profile: ProfileType): void {
        setProfile(profile);
    }

    private configureSDK(config: ConfigType, meta: MetaType, profile: ProfileType): void {
        setConfig(config);
        setMeta(meta);
        setProfile(profile);
    }

    private normalizeProperties(properties: EventPushData): Properties {
        if (!properties) {
            return null;
        }

        return Object.entries(properties)
            .filter((pair) => {
                const value = pair[1];
                return value !== null && value !== undefined;
            })
            .map(([key, value]) => ({ key, value: `${value}` }));
    }

    private parseEventString(eventString: string): [string, string] {
        const [eventCategory, ...rest] = eventString.split('/');
        const eventAction = rest.join('/');
        return [eventCategory, eventAction];
    }

    public pushMetrics(event: EventType) {
        const [eventCategory, eventAction] = this.parseEventString(actionsList[event.eventString]);
        const normalizeEvent: BaseItem = { eventCategory, eventAction, value: event.value || '' };
        if (event.properties) {
            normalizeEvent.properties = this.normalizeProperties(event.properties);
        }
        if (this.isEnabled) {
            sendEvent(normalizeEvent);
        }
        if (this.isDebugEnabled) {
            console.log('EVENT', normalizeEvent);
        }
    }
}
