import React from 'react';
import { useMatch } from 'react-router-dom';

import ClientOfferViewContentSkeleton from './ClientOfferViewContentSkeleton';
import ClientOfferLayout from '../ClientOfferLayout';
import AppThemeDecorator from '../../../component/AppThemeDecorator';
import type { GiftOfferInfo } from '../../../hooks/services/offer/gift-offer';
import { useAddGiftOffer, useGiftOfferQuery } from '../../../hooks/services/offer/gift-offer';
import { useDevices, useAnalyticsQueue } from '../../../hooks';
import { INFO_PROMO_CODE } from '../../../constants/common';
import { APP_ROUTE } from '../../../constants/route';
import type { ButtonSizes } from '../../../component/Button';
import Button from '../../../component/Button';
import PromoCode from '../../../component/PromoCode';
import { GiftClientAuthForm } from '../../Client/Form';
import AdvertisingTag from '../../../component/AdvertisingTag';
import DzoLogo from '../../../component/DzoLogo';

import styles from '../ClientOffer.module.scss';

type ClientOfferViewContentProps = {
    offerId: string;
    promoCampaignId: string;
    giftOfferInfo: GiftOfferInfo;
};

const ClientOfferViewContent = ({ giftOfferInfo, offerId, promoCampaignId }: ClientOfferViewContentProps) => {
    const {
        campaignId,
        campaignName,
        dzoName,
        tradeName,
        integrationPoint,
        productCampaignId,
        header,
        logoUrl,
        cardUrl,
        appCode,
        erid,
    } = giftOfferInfo;
    const { pushMetrics } = useAnalyticsQueue();
    const { isMobile } = useDevices();
    const { data, mutateAsync: addGiftOffer } = useAddGiftOffer();
    const buttonSize: ButtonSizes = !isMobile ? 'x-large' : 'large';

    const onSubmit = async (clientId: number) => {
        try {
            await addGiftOffer({
                clientId,
                appCode,
                integrationPoint,
                promoCampaignId,
                offerId,
            });

            pushMetrics('SUCCESS_FRIEND_PRESENT_SHOW', {
                campaignId,
                campaignName,
                dzoName,
                productCampaignId,
            });
        } catch (e) {
            pushMetrics('ERROR_FRIEND_PRESENT_SHOW');
        }
    };

    const onClick = () => {
        pushMetrics('SUCCESS_FRIEND_PRESENT_GET_BTN_CLICK', {
            campaignId,
            campaignName,
            dzoName,
            productCampaignId,
        });

        if (data?.personalUrl) {
            window.location.assign(data.personalUrl);
        }
    };

    const onCopyOffer = () =>
        pushMetrics('SUCCESS_FRIEND_PRESENT_COPY_PROMO_CODE_CLICK', {
            campaignId,
            campaignName,
            dzoName,
            productCampaignId,
        });

    const onShowForm = () =>
        pushMetrics('IDENTIFICATION_FRIEND_OFFER_SHOW', {
            campaignId,
            campaignName,
            dzoName,
            productCampaignId,
        });

    const onAdvertisingTagClick = () => {
        pushMetrics('ADVERTISEMENT_CLICK', {
            campaignId,
            campaignName,
            dzoName,
            productCampaignId,
            appCode,
            tradeName,
            erid,
        });
    };

    const content = (
        <>
            <DzoLogo logoUrl={logoUrl} dzoName={dzoName} />
            {header && <p className={styles.info} dangerouslySetInnerHTML={{ __html: header }} />}
            {cardUrl && <img src={cardUrl} alt="" className={styles.banner} />}
        </>
    );

    return (
        <div className={styles.content}>
            {data ? (
                <>
                    {content}
                    {data.promoCode && (
                        <>
                            <span className={styles.instructions}>{INFO_PROMO_CODE}</span>
                            <PromoCode text={data.promoCode} onCopyText={onCopyOffer} />
                        </>
                    )}
                    <Button
                        label="Перейти"
                        size={buttonSize}
                        type="contained"
                        fullWidth
                        className={styles.button}
                        onClick={onClick}
                        dataTestId="go-to-site-button"
                    />
                </>
            ) : (
                <GiftClientAuthForm onMount={onShowForm} onSubmit={onSubmit} className={styles.formWrapper}>
                    {content}
                </GiftClientAuthForm>
            )}
            {!data && <AdvertisingTag tradeName={tradeName} erid={erid} onClick={onAdvertisingTagClick} />}
        </div>
    );
};

const ClientOfferViewContainer = () => {
    const { pushMetrics } = useAnalyticsQueue();
    const {
        params: { offerId = '', promoCampaignId = '' },
    } = useMatch(APP_ROUTE.GIFT)!;

    const { data } = useGiftOfferQuery(offerId, () => pushMetrics('ERROR_FRIEND_PRESENT_SHOW'));

    return (
        <AppThemeDecorator>
            <ClientOfferLayout>
                {data ? (
                    <ClientOfferViewContent offerId={offerId} promoCampaignId={promoCampaignId} giftOfferInfo={data} />
                ) : (
                    <ClientOfferViewContentSkeleton />
                )}
            </ClientOfferLayout>
        </AppThemeDecorator>
    );
};

export default ClientOfferViewContainer;
