import { useEffect, useRef } from 'react';

const useStateRef = <T extends unknown>(val: T) => {
    const ref = useRef<T>(val);

    useEffect(() => {
        ref.current = val;
    }, [val]);

    return ref;
};

export default useStateRef;
