import React, { useEffect } from 'react';

import { SBER_CONTACT_INFO } from '../../../constants/contactInfo';
import { INSTALLING_CERTIFICATES_WEBSITE, YANDEX_BROWSER_WEBSITE } from '../../../constants/common';
import { INSTALL_CERTIFICATES_BUTTON_LABEL, INSTALL_YANDEX_BROWSER_BUTTON_LABEL } from '../../../constants/labels';
import { moveToExternalResource } from '../../../utils/helper';
import { useAnalyticsQueue } from '../../../hooks';
import Button from '../../../component/Button';
import FooterInfo from '../../../component/FooterInfo';
import catEmpty from '../../../static/images/cat-empty.png';
import yandexBrowserIcon from '../../../static/images/yandex-browser.png';

import styles from './CertificateExpired.module.scss';

export const DATA_TEST_ID_CERTIFICATE_EXPIRED = 'certificate-expired-error';
export const TITLE = 'Чтобы получить предложения установите сертификаты Минцифры';
export const DESCRIPTION =
    'Все онлайн-сервисы Сбера переведены на работу с применением сертификатов Минцифры России. ' +
    'Для доступа к веб-ресурсам Сбера необходимо установить сертификаты или браузер, ' +
    'поддерживающий отечественные сертификаты';

const CertificateExpired = () => {
    const { pushMetrics } = useAnalyticsQueue();

    useEffect(() => {
        pushMetrics('CERTIFICATE_EXPIRED_SHOW');
    }, []);

    const goToInstallCertificate = () => {
        pushMetrics('CERTIFICATE_EXPIRED_INSTALLING_CERTIFICATE_CLICK');
        moveToExternalResource({
            url: INSTALLING_CERTIFICATES_WEBSITE,
            target: '_blank',
        });
    };

    const goToYandexBrowser = () => {
        pushMetrics('CERTIFICATE_EXPIRED_INSTALLING_YANDEX_CLICK');
        moveToExternalResource({ url: YANDEX_BROWSER_WEBSITE, target: '_blank' });
    };

    return (
        <div className={styles.page} data-test-id={DATA_TEST_ID_CERTIFICATE_EXPIRED}>
            <div className={styles.body}>
                <img className={styles.img} alt="Error" src={catEmpty} />
                <div className={styles.textsBlock}>
                    <p className={styles.header}>{TITLE}</p>
                    <p className={styles.description}>{DESCRIPTION}</p>
                </div>
                <div className={styles.buttonsBlock}>
                    <Button
                        label={INSTALL_CERTIFICATES_BUTTON_LABEL}
                        onClick={goToInstallCertificate}
                        type="contained"
                        dataTestId="go-to-install-certificate-btn"
                    />
                    <Button
                        icon={<img src={yandexBrowserIcon} alt="" />}
                        label={INSTALL_YANDEX_BROWSER_BUTTON_LABEL}
                        onClick={goToYandexBrowser}
                        dataTestId="go-to-yandex-browser-btn"
                    />
                </div>
            </div>
            <FooterInfo contactInfo={SBER_CONTACT_INFO} position="center" />
        </div>
    );
};

export default CertificateExpired;
