import React, { useContext, useEffect, useLayoutEffect } from 'react';

import { moveToExternalResource } from '../../utils/helper';
import { APP_MECHANICS_VALUES } from '../../constants/appMechanics';
import { RequestStatusTypes } from '../../constants/RequestStatusTypes';
import PromoCampaignTypes from '../../constants/promoCampaignTypes';
import { ModalCtx } from '../../reducer/modal';
import { usePromoCampaignsQuery } from '../../hooks/services/promoCampaigns';
import { useClickPromoCampaignOffer } from '../../hooks/useClickPromoCampaignOffer';
import { CHANGE_FOOTER_ACTION } from '../../constants/modal';
import BackButton from '../../component/BackButton';
import ExitButton from '../../component/ExitButton';
import Button from '../../component/Button';
import CatLayout from '../../component/CatLayout';
import Landing from '../../component/Landing';
import { useActiveMechanics, useAnalyticsQueue, useDevices, useIntegrationPoint } from '../../hooks';
import type { ProductProps } from './types';

import styles from '../../component/Landing/Landing.module.scss';

export const DEFAULT_DETAILS_LABEL = 'Подробнее';
export const DATA_TEST_ID_DETAILS = 'detail-header-details-link';
export const DATA_TEST_ID_HEADER_INSTALL = 'detail-header-install-button';
export const DATA_TEST_ID_FOOTER_INSTALL = 'detail-footer-install-button';

const Product = ({ goBack, goNext, campaignId }: ProductProps) => {
    const { pushMetrics } = useAnalyticsQueue();
    const { dispatch } = useContext(ModalCtx);
    const { isMobile } = useDevices();
    const integrationPoint = useIntegrationPoint();
    const { onClickPromoCampaignOffer, isLoading } = useClickPromoCampaignOffer();
    const mechanics = useActiveMechanics();
    const { data, error } = usePromoCampaignsQuery({
        integrationPoint,
        type: mechanics === APP_MECHANICS_VALUES.SAPSAN ? undefined : PromoCampaignTypes.PRODUCT,
        campaigns: [campaignId],
    });

    const campaign = data?.[0];

    const {
        detailsButtonUrl,
        detailsButtonLabel = DEFAULT_DETAILS_LABEL,
        showDetailButton,
        originalId,
        name: campaignName,
        dzoName,
        productCampaignId,
        targetActionButtonLabel = '',
    } = campaign || {};

    useLayoutEffect(() => {
        dispatch({ type: CHANGE_FOOTER_ACTION });
    }, [campaign]);

    useEffect(() => {
        if (campaign && (!campaign.showLanding || campaign.type !== PromoCampaignTypes.PRODUCT)) {
            goNext();
        }
    }, [campaign, goNext]);

    const onBackButtonClick = () => {
        pushMetrics('GO_BACK_FROM_PRODUCT', { campaignId: originalId, campaignName, dzoName, productCampaignId });
        goBack();
    };

    const onInstallClick = async () => {
        if (isLoading || !campaign) {
            return;
        }

        pushMetrics('GET_PRODUCT', { campaignId: originalId, campaignName, dzoName, productCampaignId });

        onClickPromoCampaignOffer({
            promoCampaign: campaign,
            goToOffer: () => goNext(),
        });
    };

    const onDetailsClick = () => {
        if (detailsButtonUrl) {
            pushMetrics('LANDING_TO_WEBSITE_CLICK', {
                campaignId: originalId,
                campaignName,
                dzoName,
                productCampaignId,
            });
            moveToExternalResource({ url: detailsButtonUrl });
        }
    };

    if (error) {
        return (
            <CatLayout
                type={RequestStatusTypes.ERROR}
                title={error.message}
                description={error.description}
                requestId={error.requestId}
                onClick={goBack}
                label="Продолжить"
            />
        );
    }

    return (
        <Landing
            campaign={campaign}
            renderHeaderLeftBlock={() => <BackButton onClick={onBackButtonClick} />}
            renderHeaderRightBlock={() => (
                <>
                    {!isMobile && (
                        <>
                            <Button
                                label={targetActionButtonLabel}
                                onClick={onInstallClick}
                                type="contained"
                                dataTestId={DATA_TEST_ID_HEADER_INSTALL}
                            />
                            {showDetailButton && (
                                <Button
                                    label={detailsButtonLabel}
                                    onClick={onDetailsClick}
                                    color="brand"
                                    dataTestId={DATA_TEST_ID_DETAILS}
                                />
                            )}
                        </>
                    )}
                    <ExitButton />
                </>
            )}
            renderFixedFooter={
                isMobile
                    ? () => (
                          <div className={styles.outsideContentWrapper}>
                              <Button
                                  label={targetActionButtonLabel}
                                  onClick={onInstallClick}
                                  type="contained"
                                  dataTestId={DATA_TEST_ID_FOOTER_INSTALL}
                                  fullWidth
                                  size="large"
                              />
                              {showDetailButton && (
                                  <Button
                                      label={detailsButtonLabel}
                                      onClick={onDetailsClick}
                                      type="outline"
                                      dataTestId={DATA_TEST_ID_DETAILS}
                                      fullWidth
                                      size="large"
                                  />
                              )}
                          </div>
                      )
                    : undefined
            }
        />
    );
};

export default Product;
