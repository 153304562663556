import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

type Props = {
    render: (selectedOffers: string[] | null) => JSX.Element;
};

const SelectedOffers = ({ render }: Props) => {
    const [searchParams] = useSearchParams();
    const selectedOffers = searchParams.get('selectedOffers');
    const selectedOffersArr = useMemo(() => (selectedOffers ? selectedOffers.split(',') : null), [selectedOffers]);

    return render(selectedOffersArr);
};

export default SelectedOffers;
