import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Bundles from './Bundles';
import { BundleRouter } from '../Bundle';
import { PARAMS } from '../../constants/route';

const BundlesRouter = () => {
    const navigate = useNavigate();

    const goToBundle = (id: number) => {
        navigate(`${id}`);
    };

    const goToBundles = () => navigate('.');

    return (
        <Routes>
            <Route path="/" element={<Bundles goNext={goToBundle} />} />
            <Route path={`${PARAMS.BUNDLE}/*`} element={<BundleRouter goBack={goToBundles} />} />
        </Routes>
    );
};

export default BundlesRouter;
