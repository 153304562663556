import React, { memo } from 'react';
import cn from 'classnames';

import { useAppTheme } from '../../hooks';
import { ReactComponent as Particles } from '../../static/svgs/particles.svg';

import styles from './SuccessPageBackground.module.scss';

type Props = {
    className?: string;
};

const SuccessPageBackground = ({ className }: Props) => {
    const appTheme = useAppTheme();

    return (
        <div className={cn(styles.background, styles[appTheme], className)}>
            <Particles />
        </div>
    );
};

export default memo(SuccessPageBackground);
