import React from 'react';
import cn from 'classnames';

import DecorationPanelContainer from '../../containers/DecorationPanelContainer';
import type { FormContainerProps } from './types';

import styles from './FormContainer.module.scss';

const FormContainer = ({
    size = 'low',
    centralIcon,
    title = '',
    contentClassName = styles.formWrapper,
    adaptive = true,
    children,
    renderHeader,
    dataTestId,
}: FormContainerProps) => (
    <DecorationPanelContainer>
        <div className={cn(styles.content, { [styles.scaled]: !adaptive })} data-test-id={dataTestId}>
            {renderHeader ? (
                <div className={styles.headerWrapper}>{renderHeader()}</div>
            ) : (
                <div className={cn(styles.panel, styles[size])}>
                    <div className={styles.centralBlock}>
                        {centralIcon && <div className={styles.centralIconWrapper}>{centralIcon}</div>}
                        <label className={styles.title}>{title}</label>
                    </div>
                </div>
            )}
            <div className={styles.formBlock}>
                <div className={contentClassName}>{children}</div>
            </div>
        </div>
    </DecorationPanelContainer>
);

export default FormContainer;
