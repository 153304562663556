import React, { useEffect } from 'react';

import { useAnalyticsQueue } from '../../../hooks';
import { useAppSettings } from '../../AppSettings';
import AdvertisingConsentField from '../../AdvertisingConsentField';
import type { ButtonSizes } from '../../Button';
import Button from '../../Button';
import EmailInput from '../EmailInput';
import SubmitButton from '../../SubmitButton';
import SuccessPageModalBody from '../../SuccessPageModalBody';

import styles from '../EmailConfirmationForm.module.scss';

export const FORM_TITLE = 'Подтвердите email';
export const FORM_DESCRIPTION = 'На этот email мы отправим предложение';
export const SET_ANOTHER_EMAIL_BUTTON_LABEL = 'Указать другую электронную почту';

type Props = {
    email: string;
    isEmailConfirmed: boolean;
    agreeWithAdvertising: boolean;
    onClick: () => void;
    onCancelClick: () => void;
    buttonSize: ButtonSizes;
};

const EmailFormModalBody = ({
    email,
    isEmailConfirmed,
    agreeWithAdvertising,
    onClick,
    onCancelClick,
    buttonSize,
}: Props) => {
    const { pushMetrics } = useAnalyticsQueue();
    const { advertisingConsentId } = useAppSettings();

    useEffect(() => {
        pushMetrics('CONFIRM_EMAIL_SHOW', { consentId: advertisingConsentId });
    }, []);

    return (
        <SuccessPageModalBody
            onCloseButtonClick={onCancelClick}
            header={FORM_TITLE}
            description={FORM_DESCRIPTION}
            dataTestId="email-confirmation-form"
            renderContent={() => (
                <>
                    {isEmailConfirmed ? (
                        <>
                            <div className={styles.emailBlock}>
                                <p>{email}</p>
                            </div>
                            <Button
                                label={SET_ANOTHER_EMAIL_BUTTON_LABEL}
                                onClick={onClick}
                                type="text"
                                color="brand"
                                dataTestId="set-another-email-button"
                                size={buttonSize}
                                fullWidth
                            />
                        </>
                    ) : (
                        <EmailInput />
                    )}
                    {!agreeWithAdvertising && (
                        <AdvertisingConsentField eventAction="CONFIRM_EMAIL_ADVERTISING_POLICY_BTN_CLICK" />
                    )}
                </>
            )}
            renderButtons={() => (
                <SubmitButton label="Подтвердить" size={buttonSize} fullWidth dataTestId="email-form-submit-button" />
            )}
        />
    );
};

export default EmailFormModalBody;
