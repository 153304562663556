import React from 'react';
import { Nav, NavBundleRoute, NavEcosystemRoute, NavMixRoute, NavPresentsRoute } from '../Nav';

const MechanicsNavbar = () => (
    <Nav>
        <NavEcosystemRoute />
        <NavPresentsRoute />
        <NavBundleRoute />
        <NavMixRoute />
    </Nav>
);

export default MechanicsNavbar;
