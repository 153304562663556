import type { QueryFunctionContext } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTimeout } from 'react-use';

import type { IBaseResponse } from '../../../api/types';
import { addClientOffers, getOfferState } from '../../../api/services/offerService';
import type {
    AddClientOffersOptions,
    IClientOffers,
    OfferStateResponse,
} from '../../../api/services/offerService/types';
import { OfferState } from '../../../api/services/offerService/types';
import { ORDER_STATUS_CHECK_TIME, ORDER_STATUS_TIMEOUT } from '../../../constants/common';

const keys = {
    offers: () => ['offers'] as const,
    offersAdd: (options: AddClientOffersOptions) => [...keys.offers(), options] as const,

    offer: (offerId: number) => [...keys.offers(), offerId] as const,
    offerState: (offerId: number) => [...keys.offer(offerId), 'state'] as const,
};

type OffersAddKeys = ReturnType<typeof keys.offersAdd>;
type OfferStateKeys = ReturnType<typeof keys.offerState>;

const offersAddFn = async ({ promoCampaignIds, integrationPoint, clientId, campaignGroupId }: AddClientOffersOptions) =>
    addClientOffers({
        promoCampaignIds,
        clientId,
        integrationPoint,
        campaignGroupId,
    });

const getOffersAddFn = ({ queryKey: [, options] }: QueryFunctionContext<OffersAddKeys>) => offersAddFn(options);

export const useOffersAddQuery = (options: AddClientOffersOptions) =>
    useQuery<IClientOffers, IBaseResponse, IClientOffers, OffersAddKeys>({
        queryKey: keys.offersAdd(options),
        queryFn: getOffersAddFn,
        enabled: !!options.clientId,
    });

export const useAddOffers = () => {
    const queryClient = useQueryClient();

    return useMutation(offersAddFn, {
        onSuccess: (data, options) => {
            queryClient.setQueryData(keys.offersAdd(options), data);
        },
    });
};

const getOfferStateFn = ({ queryKey: [, offerId] }: QueryFunctionContext<OfferStateKeys>) => getOfferState(offerId);

const isStopRefetch = (data: OfferStateResponse | undefined, error: OfferStateResponse | null) => {
    if (error) {
        return true;
    }

    return data && data.state !== OfferState.RESERVED;
};

export const useOfferStateQuery = (offerId: number) => {
    const [isReady, cancel] = useTimeout(ORDER_STATUS_TIMEOUT);
    const timeoutExceeded = isReady() === true;

    const offerStateQuery = useQuery<OfferStateResponse, OfferStateResponse, OfferStateResponse, OfferStateKeys>({
        queryKey: keys.offerState(offerId),
        queryFn: getOfferStateFn,
        useErrorBoundary: false,
        cacheTime: 0,
        staleTime: 0,
        refetchIntervalInBackground: true,
        refetchInterval: (_, query) => {
            if (timeoutExceeded) {
                return false;
            }

            if (isStopRefetch(query.state.data, query.state.error)) {
                cancel();

                return false;
            }

            return ORDER_STATUS_CHECK_TIME;
        },
    });

    return {
        offerStateQuery,
        timeoutExceeded,
    };
};
