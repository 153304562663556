import type { ReactNode } from 'react';
import React, { useState } from 'react';
import cn from 'classnames';

import { ReactComponent as ArrowDownIcon } from '../../static/svgs/arrow-down.svg';

import styles from './Dropdown.module.scss';

export type DropdownProps = {
    renderBaseElement: () => ReactNode;
    renderDropdownBlock: () => ReactNode;
};

const Dropdown = ({ renderBaseElement, renderDropdownBlock }: DropdownProps) => {
    const [open, setOpen] = useState(false);

    const toggle = () => setOpen(!open);

    const onChange = () => setOpen(false);

    return (
        <div className={styles.container}>
            <div onClick={toggle} className={styles.baseElement}>
                <div
                    className={cn(styles.baseElementIconWrapper, styles.baseElementIcon, {
                        [styles.baseElementIconRotated]: open,
                    })}
                >
                    <ArrowDownIcon />
                </div>
                {renderBaseElement()}
            </div>
            {open && (
                <div className={styles.dropdownBlockWrapper}>
                    <div onClick={onChange} className={styles.dropdownBlock}>
                        {renderDropdownBlock()}
                    </div>
                </div>
            )}
        </div>
    );
};

Dropdown.displayName = 'Dropdown';

export default Dropdown;
