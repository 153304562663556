import React from 'react';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import { DzoLogoSkeleton } from '../DzoLogo';

import styles from './SuccessPageCard.module.scss';

export const SKELETON_CARD_TEST_ID = 'skeleton-card';
export const SKELETON_CARD_QR_TEST_ID = 'skeleton-card-qr';

export type QRCardSkeletonProps = {
    isSingle?: boolean;
};

const SuccessPageCardSkeleton = ({ isSingle }: QRCardSkeletonProps) => (
    <div className={cn(styles.cardWrapper, { [styles.single]: isSingle })} data-test-id={SKELETON_CARD_TEST_ID}>
        <DzoLogoSkeleton />
        <div className={styles.descriptionWrapper}>
            <Skeleton containerClassName={cn(styles.descriptionLineWrapper, styles.offerDescription)} count={1.6} />
        </div>
        {isSingle && (
            <div data-test-id={SKELETON_CARD_QR_TEST_ID}>
                <Skeleton
                    className={cn(styles.qrWrapper, styles.qrWrapperSkeleton)}
                    containerClassName={styles.qrWrapperSkeletonContainer}
                />
            </div>
        )}
        <div className={styles.bottomBlock}>
            <Skeleton containerClassName={styles.buttonWrapper} className={styles.buttonSkeleton} />
            <Skeleton containerClassName={styles.buttonWrapper} className={styles.buttonSkeleton} />
        </div>
    </div>
);

export default SuccessPageCardSkeleton;
