import React from 'react';
import { useController } from 'react-hook-form';

import { sbolPhoneConfirmationCodeValidator } from '../../../utils/validators';
import NumberInput from '../../NumberInput';

const CodeInput = () => {
    const {
        field: { onChange, ...field },
        fieldState: { invalid },
    } = useController({
        name: 'code',
        rules: { required: true, validate: sbolPhoneConfirmationCodeValidator },
    });

    return (
        <NumberInput
            label="Код из PUSH-уведомления"
            mask="_"
            format="######"
            onValueChange={({ formattedValue }) => onChange?.(formattedValue)}
            error={invalid}
            onClear={() => onChange('')}
            fullWidth
            autoComplete="off"
            autoFocus
            {...field}
        />
    );
};

export default CodeInput;
