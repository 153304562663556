import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import Lottie from 'react-lottie';

import { useAnalyticsQueue } from '../../hooks';
import { PARAMS } from '../../constants/route';
import type { productTargetKeys } from '../../utils/clickStream';
import {
    DATA_TEST_RESET_BUTTON,
    DESCRIPTION_TEXT,
    HEADER_TEXT,
    SPIN_TEXT,
    SPINING_TEXT,
    WIN_DESCRIPTION_TEXT,
    WIN_TEXT,
} from './constants';
import FortuneWheel from './FortuneWheel';
import animationData from './confetti.json';

import './FortuneWheel.scss';
//TODO: сделать все стили через css modules

type State = {
    rotateAngle: number; // DEGREES
    easeOutTime: number; // SECONDS
    result: number | null; // INDEX
    spinning: boolean;
    resetCount: number;
};

const initialState: State = {
    rotateAngle: 0, // DEGREES
    easeOutTime: 0, // SECONDS
    result: null, // INDEX
    spinning: false,
    resetCount: 0,
};

export interface SectorBgType {
    [key: string]: any;
}

export type SectorObjType = {
    title: string;
    description?: string;
    discount: string;
    additionalText?: string;
    image: string;
    background: string | SectorBgType;
    svEvent: productTargetKeys;
};

type WheelProps = {
    sectorsList: SectorObjType[];
};

const FortuneWheelWrapper = ({ sectorsList }: WheelProps) => {
    const [{ rotateAngle, easeOutTime, result, spinning, resetCount }, setState] = useState<State>(initialState);
    const numOptions = sectorsList.length;
    const { pushMetrics } = useAnalyticsQueue();
    const {
        params: { appCode },
    } = useMatch(`/${PARAMS.APP}`)!;

    useEffect(() => {
        pushMetrics('LOTTERY_WHEEL_SHOW', { appCode });
    }, []);

    const handleSpinRandomNumber = () => {
        pushMetrics('LOTTERY_WHEEL_SPIN_CLICK', { appCode });

        const winNumber = Math.floor(Math.random() * numOptions);
        const { svEvent } = sectorsList[winNumber];
        const randomSpin = Math.floor(Math.random() * 4 + 3) * 360 + (90 - (360 / numOptions) * (winNumber + 0.5));
        setState((prevState) => ({
            ...prevState,
            rotateAngle: randomSpin,
            easeOutTime: 4,
            spinning: true,
        }));

        // calculate result after wheel stops spinning
        setTimeout(() => {
            pushMetrics(svEvent, { appCode });

            setState((prevState) => ({
                ...prevState,
                result: winNumber,
            }));
        }, 4200);
    };

    const reset = () => {
        // reset wheel and result
        pushMetrics('LOTTERY_WHEEL_RESULT_CLOSE_CLICK', { appCode });

        setState((prevState) => ({
            ...initialState,
            resetCount: prevState.resetCount + 1,
        }));
    };

    return (
        <div className="page">
            <div className="fortune-wheel">
                <div className="top-panel">
                    {result !== null ? (
                        <>
                            <div className="top-right-panel" onClick={reset}>
                                <div className="close-icon" data-test-id={DATA_TEST_RESET_BUTTON} />
                            </div>
                            <div className="winner-text">{WIN_DESCRIPTION_TEXT}</div>
                        </>
                    ) : (
                        <div className="header">
                            <div className="header-text">{HEADER_TEXT}</div>
                            <div className="description-text">{DESCRIPTION_TEXT}</div>
                        </div>
                    )}
                </div>
                <FortuneWheel
                    key={resetCount}
                    easeOutTime={easeOutTime}
                    rotateAngle={rotateAngle}
                    winNumber={result}
                    sectorsList={sectorsList}
                />
                <div className="btn">
                    {spinning ? (
                        result === null ? (
                            <div id="spinning">
                                <span>{SPINING_TEXT}</span>
                            </div>
                        ) : (
                            <div id="done">
                                <span>{WIN_TEXT}</span>
                            </div>
                        )
                    ) : (
                        <div id="spin" onClick={handleSpinRandomNumber}>
                            <span>{SPIN_TEXT}</span>
                        </div>
                    )}
                </div>
                <div className="winning-animation">
                    {result !== null && spinning && (
                        <Lottie
                            options={{
                                loop: false,
                                autoplay: true,
                                animationData: animationData,
                                rendererSettings: {
                                    preserveAspectRatio: 'xMidYMid slice',
                                },
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default FortuneWheelWrapper;
