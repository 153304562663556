import type { ReactNode } from 'react';
import React from 'react';
import cn from 'classnames';

import Button from '../../Button';

import styles from './CommonPopUpLayout.module.scss';

export type CommonPopUpLayoutProps = {
    onClick: () => void;
    title?: string;
    buttonLabel?: string;
    centered?: boolean;
    icon?: ReactNode;
    children?: ReactNode;
};

const CommonPopUpLayout = ({
    onClick,
    title,
    buttonLabel = 'Понятно',
    centered = false,
    icon,
    children,
}: CommonPopUpLayoutProps) => (
    <div className={cn(styles.content, { [styles.centered]: centered })}>
        {icon && <div className={styles.iconWrapper}>{icon}</div>}
        {title && <p className={styles.title}>{title}</p>}
        {children}
        <div className={styles.footer}>
            <Button label={buttonLabel} onClick={onClick} type="contained" dataTestId="information-close-button" />
        </div>
    </div>
);

export default CommonPopUpLayout;
