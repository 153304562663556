import type { ReactNode } from 'react';
import React from 'react';
import cn from 'classnames';

import { useAppTheme, useDevices } from '../../hooks';
import { ReactComponent as Locator } from '../../static/svgs/locator.svg';

import styles from './DecorationPanelContainer.module.scss';

type Props = {
    children?: ReactNode;
};

const DecorationPanelContainer = ({ children }: Props) => {
    const appTheme = useAppTheme();
    const { isDesktop } = useDevices();

    return (
        <div className={cn(styles.contentWrapper, { [styles[appTheme]]: !isDesktop })}>
            {children}
            <div className={cn(styles.decorations, styles[appTheme])}>
                <Locator />
            </div>
        </div>
    );
};

export default DecorationPanelContainer;
