import React from 'react';

import type { ButtonSizes } from '../../Button';
import Button from '../../Button';
import SuccessPageModalBody from '../../SuccessPageModalBody';
import sberkotImg from '../../../static/images/sberkot-like.png';

import styles from './FeedbackFormModal.module.scss';

export type FeedbackFormModalProps = {
    onClick: () => void;
    buttonSize: ButtonSizes;
};

export const SUCCESS_MESSAGE = 'Спасибо за вашу оценку';
export const SUCCESS_DESCRIPTION = 'Благодаря вам наши сервисы станут лучше';
export const TEST_ID_FEEDBACK_SUCCESS_MODAL = 'successful-feedback-sending-modal';

const FeedbackSuccessModalBody = ({ onClick, buttonSize }: FeedbackFormModalProps) => (
    <SuccessPageModalBody
        renderContent={() => (
            <div className={styles.successContent}>
                <img src={sberkotImg} className={styles.successImage} alt="Cберкот одобряет" />
                <div className={styles.textContent}>
                    <p className={styles.header}>{SUCCESS_MESSAGE}</p>
                    <p className={styles.description}>{SUCCESS_DESCRIPTION}</p>
                </div>
            </div>
        )}
        dataTestId={TEST_ID_FEEDBACK_SUCCESS_MODAL}
        renderButtons={() => (
            <Button
                label="Закрыть"
                onClick={onClick}
                type="contained"
                size={buttonSize}
                dataTestId="feedback-success-continue-button"
                fullWidth
            />
        )}
    />
);

export default FeedbackSuccessModalBody;
