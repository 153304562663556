import React from 'react';
import type { UseControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';

import AdvertisingConsent from '../Consent/AdvertisingConsent';
import type { productTargetKeys } from '../../utils/clickStream';

const ADVERTISING_CHECKBOX_NAME = 'agreeWithAdvertising';

type Props = {
    eventAction: productTargetKeys;
} & Omit<UseControllerProps<{ [ADVERTISING_CHECKBOX_NAME]: boolean }>, 'name'>;

const AdvertisingConsentField = ({ eventAction }: Props) => {
    const {
        field,
        fieldState: { invalid },
    } = useController({
        defaultValue: false,
        name: ADVERTISING_CHECKBOX_NAME,
        rules: { required: true },
    });

    return <AdvertisingConsent {...field} eventAction={eventAction} error={invalid} />;
};

export default AdvertisingConsentField;
