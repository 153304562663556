import React, { useEffect } from 'react';

import { useAppSettings } from '../../../AppSettings';
import { SuccessPageCardLayout } from '../../../SuccessPageCard';
import { ClientAuthForm } from '../../../../containers/Client/Form';
import { useClient } from '../../../Client';

import styles from '../SuccessPageForm.module.scss';

type Props = {
    onSubmit: () => void;
};

const SuccessPagePromoCampaignsForm = ({ onSubmit }: Props) => {
    const { showAuthByPhoneNumber, showAuthBySberID } = useAppSettings();
    const { client } = useClient();

    useEffect(() => {
        if (client) {
            onSubmit();
        }
    }, [client, onSubmit]);

    if (client) {
        return null;
    }

    return (
        <SuccessPageCardLayout>
            <ClientAuthForm
                title={<p className={styles.formTitle}>{`Войдите, чтобы получить предложения`}</p>}
                showAuthByPhoneNumber={!!showAuthByPhoneNumber}
                showAuthBySberID={!!showAuthBySberID}
            />
        </SuccessPageCardLayout>
    );
};

export default SuccessPagePromoCampaignsForm;
