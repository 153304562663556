import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { noop } from '../../../utils/helper';
import InformContainer from '../../../component/InformContainer';
import type { ButtonProps } from '../../../component/Button';

import icon from '../../../static/images/cat-empty.png';

import styles from '../Errors.module.scss';

export interface PageAccessDeniedProps {
    header?: string;
    message?: string;
    requestId?: string;
    buttonLabel?: string;
    onClick?: () => void;
    onMount?: () => void;
}

const PageAccessDenied = ({
    requestId,
    onClick,
    header = 'Access denied',
    message,
    buttonLabel,
    onMount = noop,
}: PageAccessDeniedProps) => {
    const { state } = useLocation();
    if (!isEmpty(state)) {
        onClick = state.onClick ? state.onClick : onClick;
        header = state.header ? state.header : header;
        message = state.message ? state.message : message;
        requestId = state.requestId ? state.requestId : requestId;
        buttonLabel = state.buttonLabel ? state.buttonLabel : buttonLabel;
    }
    const buttonProps: ButtonProps = { size: 'x-large' };

    useEffect(onMount, []);

    return (
        <div className={styles.page}>
            <InformContainer
                icon={icon}
                header={header}
                message={message}
                requestId={requestId}
                contentClassName={styles.content}
                adaptive
                {...(buttonLabel && onClick
                    ? {
                          buttonLabel,
                          onClick,
                          buttonClassName: styles.button,
                          buttonProps,
                      }
                    : {})}
            />
        </div>
    );
};

export default PageAccessDenied;
