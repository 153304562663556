import React, { useEffect } from 'react';

import { useAnalyticsQueue } from '../../../hooks';
import SuccessPageModalBody from '../../SuccessPageModalBody';
import type { ButtonSizes } from '../../Button';
import Button from '../../Button';
import type { ErrorArguments } from '../types';
import { ReactComponent as ErrorIcon } from '../../../static/svgs/not-found.svg';

type Props = ErrorArguments & {
    onClick: () => void;
    onCancelClick: () => void;
    buttonSize: ButtonSizes;
};

const ErrorModalBody = ({ message, description, requestId, svEvent, onClick, onCancelClick, buttonSize }: Props) => {
    const { pushMetrics } = useAnalyticsQueue();

    useEffect(() => {
        svEvent && pushMetrics(svEvent);
    }, []);

    return (
        <SuccessPageModalBody
            onCloseButtonClick={onCancelClick}
            renderTopBlock={() => <ErrorIcon />}
            header={message}
            description={description}
            requestId={requestId}
            renderButtons={() => (
                <Button
                    label="Попробовать ещё раз"
                    onClick={onClick}
                    type="contained"
                    size={buttonSize}
                    dataTestId="send-email-again-button"
                />
            )}
        />
    );
};

export default ErrorModalBody;
