import { generateAdditionalInfoForRequest } from '../../../utils/promoCampaignHelper';
import type {
    AddClientOffersOptions,
    IAddOffersResponse,
    IClientOffer,
    IClientOffers,
    IOfferInfoResponse,
} from './types';
import { authenticatedRequest, publicRequest } from '../../axios';
import type { IntegrationPointTypes } from '../../../constants/integrationPointTypes';
import { APP_CODE_HEADER } from '../../../constants/common';
import type { OfferStateResponse } from './types';

export async function addClientOffers({
    clientId,
    promoCampaignIds,
    campaignGroupId,
    integrationPoint,
}: AddClientOffersOptions): Promise<IClientOffers> {
    const additionalInfo = generateAdditionalInfoForRequest(campaignGroupId);
    // в случае пустого массива: HTTP code 200, status 'NOK' и message
    // если вернётся только часть офферов: HTTP code 200, status 'Ok' и description
    const {
        data: { list, description, requestId },
    } = await authenticatedRequest.post<IAddOffersResponse>('/offer/add/some', {
        clientId,
        promoCampaignIds,
        additionalInfo,
        integrationPoint,
    });

    return {
        offers: list.reduce(
            (offers, { campaignId, offerId, personalUrl, promoCode, promoCodeId, paymentUrl }) => ({
                ...offers,
                [campaignId]: { offerId, personalUrl, promoCode, promoCodeId, paymentUrl },
            }),
            {}
        ),
        description,
        requestId,
    };
}

export type AddGiftOfferOptions = {
    appCode: string;
    clientId: number;
    promoCampaignId: string;
    offerId: string;
    integrationPoint?: IntegrationPointTypes;
};

export async function addGiftClientOffer({
    appCode,
    clientId,
    promoCampaignId,
    offerId,
    integrationPoint,
}: AddGiftOfferOptions) {
    const { data } = await publicRequest.post<IClientOffer>(
        '/offer/gift/add',
        {
            clientId,
            promoCampaignId,
            integrationPoint,
            additionalInfo: { baseOfferUUID: offerId },
        },
        {
            headers: {
                [APP_CODE_HEADER]: appCode,
            },
        }
    );

    return data;
}

export async function getGiftOffer(offerId: string) {
    const { data } = await publicRequest.get<IOfferInfoResponse>(`/offer/view/gift/${offerId}`);

    return data.campaign;
}

export async function getOfferState(offerId: number) {
    const { data } = await authenticatedRequest.post<OfferStateResponse>(`/offer/complete`, { offerId });

    return data;
}
