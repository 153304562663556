import React from 'react';
import cn from 'classnames';

import styles from './FooterInfo.module.scss';

type Props = {
    contactInfo?: string;
    className?: string;
    textWhite?: boolean;
    position?: 'left' | 'center' | 'right';
};

export const COMPANY_INFORMATION = `© 1997‑${new Date().getFullYear()} ПАО Сбербанк`;

const FooterInfo = ({ position = 'right', contactInfo, className, textWhite }: Props) => (
    <div className={cn(styles.content, className, styles[position], { [styles.darkBackground]: textWhite })}>
        {contactInfo && <p className={styles.text}>{contactInfo}</p>}
        <p className={styles.text}>{COMPANY_INFORMATION}</p>
    </div>
);

export default FooterInfo;
