import { CHECK_CERTIFICATE_URL } from '../../constants/appEnvironments';

export const SHOW_CERTIFICATE_WARNING = 'show_certificate_warning';

export const getShowCertificateWarning = () => window.localStorage.getItem(SHOW_CERTIFICATE_WARNING) !== 'false';

export const closeCertificateWarning = () => window.localStorage.setItem(SHOW_CERTIFICATE_WARNING, 'false');

export async function checkSupportsCertificate() {
    try {
        await fetch(CHECK_CERTIFICATE_URL, { mode: 'no-cors' });
        return { supportsCertificate: true };
    } catch (_) {
        return { supportsCertificate: false };
    }
}
