import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../Button';

export const BACK_BUTTON_LABEL = 'Все предложения';

export type BackButtonProps = {
    onClick?: () => void;
    dataTestId?: string;
    label?: string;
    adaptive?: boolean;
};

const BackButton = ({ onClick, dataTestId = 'back-button', label = BACK_BUTTON_LABEL, adaptive }: BackButtonProps) => {
    const navigate = useNavigate();
    const backButtonClick = useCallback(() => {
        if (onClick) {
            onClick();
        } else {
            navigate(-1);
        }
    }, [onClick]);

    return (
        <Button onClick={backButtonClick} label={label} adaptive={adaptive} type="outline" dataTestId={dataTestId} />
    );
};

export default BackButton;
