import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import type { RenderSuggestion, SuggestionsFetchRequested } from 'react-autosuggest';
import Modal from 'react-modal';
import cn from 'classnames';

import AppThemeDecorator from '../../component/AppThemeDecorator';
import FormContainer from '../../component/FormContainer/FormContainer';
import Input from '../../component/Input';
import Autosuggest from '../../component/Autosuggest';
import SelectV2 from '../../component/SelectV2';
import type { LocationItem } from '../../api/services/locationService';
import { getLocationsByText } from '../../api/services/locationService';
import type { SalePoint } from '../../api/services/salePointService';
import { getSalePointsByText } from '../../api/services/salePointService';
import type { SellingRole } from '../../api/services/sellingRoleService';
import { getSellingRoleList } from '../../api/services/sellingRoleService';
import Button from '../../component/Button';
import { SUPPORT_EMAIL } from '../../constants/common';
import { useAnalyticsQueue } from '../../hooks';
import { useJwtLogin } from '../../hooks/services/user';

import styles from './JWTLoginForm.module.scss';
import modalStyles from '../../component/CustomModal/CustomModal.module.scss';

type Props = {
    personalNumber: string;
    token: string;
    goToApp: () => void;
};

type FormValues = {
    salePoint: SalePoint | null;
    sellingRoleId: string;
    locationItem: LocationItem | null;
};

const renderSuggestion: RenderSuggestion<LocationItem | SalePoint> = (suggestion) => (
    <div className={styles.suggestion}>
        <div className={styles.suggestionTitle}>{suggestion.parentName}</div>
        <div className={styles.suggestionDescription}>{suggestion.name}</div>
    </div>
);

const getLocationValue = (suggestion: LocationItem | null) => suggestion?.name || '';

const getSalePointValue = (suggestion: SalePoint | null) =>
    suggestion ? `${suggestion.name}, ${suggestion.description}` : '';

const INFO_MESSAGE =
    'С 21.06.2021 г. при переходе из продуктовой карточки Сбол.Про «Подарок за визит» в Витрину Экосистемы ' +
    'действующим сотрудникам логин и пароль будет автоматически передаваться в Витрину Экосистемы ' +
    'и учитываться при расчете мотивации сотрудникам. \n\n' +
    'Для первой авторизации новому сотруднику необходимо указать:\n' +
    '1. Отделение ВСП в формате ТБ_ГОСБ_ВСП (ВСП указывается в формате 4х чисел. Например 054_8622_0019).\n' +
    '2. Продающая роль (например, Старший клиентский менеджер, Клиентский менеджер ВСП).\n\n' +
    'После первой авторизации вход будет осуществляться также без пароля и логина путем автоподставления из Сбол.Про.\n' +
    `\nПри возникающих вопросах просим обращаться на \n${SUPPORT_EMAIL}`;

const JWTLoginForm = ({ personalNumber, token, goToApp }: Props) => {
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { isValid, isSubmitting },
    } = useForm<FormValues>({
        defaultValues: {
            salePoint: null,
            locationItem: null,
            sellingRoleId: '',
        },
        mode: 'all',
    });

    const { pushMetrics } = useAnalyticsQueue();
    const { mutateAsync: jwtLogin, error } = useJwtLogin();

    const [showInfo, setShowInfo] = useState(true);
    const [sellingRoleList, setSellingRoleList] = useState<SellingRole[]>([]);
    const [locationList, setLocationList] = useState<LocationItem[]>([]);
    const [salePoints, setSalePoints] = useState<SalePoint[]>([]);
    const locationItem = watch('locationItem');
    const salePoint = watch('salePoint');

    useEffect(() => {
        pushMetrics('ADDITIONAL_LOGIN_INFO_SHOW');

        const loadSellingRoleList = async () => {
            try {
                const { list } = await getSellingRoleList();

                setSellingRoleList(list);
            } catch (e) {
                console.error(e);
            }
        };

        loadSellingRoleList();
    }, []);

    const loadLocationList = useDebouncedCallback<SuggestionsFetchRequested>(async ({ value }) => {
        try {
            const { list } = await getLocationsByText(value);
            setLocationList(list);
        } catch (e) {
            console.error(e);
        }
    }, 300);

    const loadSalePoints = useDebouncedCallback<SuggestionsFetchRequested>(async ({ value }) => {
        try {
            const { list } = await getSalePointsByText({
                locationId: locationItem ? `${locationItem.id}` : '',
                name: value,
            });
            setSalePoints(list);
        } catch (e) {
            console.error(e);
        }
    }, 300);

    const clearLocationList = () => {
        setLocationList([]);
    };

    const clearSalePoints = () => {
        setSalePoints([]);
    };

    const getSelectValue = (value: string) =>
        sellingRoleList.find((sellingRole) => sellingRole.id === parseInt(value, 10))?.name || '';

    const onSubmit = handleSubmit(async ({ salePoint, sellingRoleId }) => {
        const { authority, loginType } = await jwtLogin({
            authData: token,
            extraInfo: {
                salePointId: salePoint!.id,
                sellingRoleId: parseInt(sellingRoleId, 10),
            },
        });

        pushMetrics('LOGIN_AFTER_ADDITIONAL_REG', { authority, loginType });
        goToApp();
    });

    const onShowInfoClick = useCallback(() => setShowInfo(true), []);

    return (
        <AppThemeDecorator>
            <FormContainer contentClassName={styles.formWrapper} title="Дополнительная информация">
                {sellingRoleList.length !== 0 ? (
                    <>
                        <form className={styles.form} onSubmit={onSubmit}>
                            <div className={styles.field}>
                                <Input
                                    label="Табельный номер"
                                    name="personalNumber"
                                    value={personalNumber}
                                    disabled
                                    readOnly
                                    fullWidth
                                />
                            </div>

                            <div className={styles.field}>
                                <Controller
                                    control={control}
                                    name="locationItem"
                                    render={({ field: { onChange, ...field }, fieldState: { invalid } }) => (
                                        <Autosuggest
                                            suggestions={locationList}
                                            onSuggestionsFetchRequested={loadLocationList}
                                            onSuggestionsClearRequested={clearLocationList}
                                            renderSuggestion={renderSuggestion}
                                            getSuggestionValue={getLocationValue}
                                            error={invalid}
                                            fullWidth
                                            label="Локация"
                                            onChange={(value) => {
                                                onChange(value);

                                                if (salePoint) {
                                                    setValue('salePoint', null, {
                                                        shouldValidate: true,
                                                    });
                                                }
                                            }}
                                            onClear={() => {
                                                onChange(null);

                                                if (salePoint) {
                                                    setValue('salePoint', null, {
                                                        shouldValidate: true,
                                                    });
                                                }
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </div>

                            <div className={styles.field}>
                                <Controller
                                    control={control}
                                    name="salePoint"
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field: { onChange, ...field }, fieldState: { invalid } }) => (
                                        <Autosuggest
                                            suggestions={salePoints}
                                            onSuggestionsFetchRequested={loadSalePoints}
                                            onSuggestionsClearRequested={clearSalePoints}
                                            renderSuggestion={renderSuggestion}
                                            getSuggestionValue={getSalePointValue}
                                            shouldRenderSuggestions={() => true}
                                            error={invalid}
                                            fullWidth
                                            label="Отделение ВСП"
                                            onChange={(value: SalePoint) => {
                                                onChange(value);
                                                setValue('locationItem', value.location);
                                            }}
                                            onClear={() => {
                                                onChange(null);
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </div>

                            <div className={styles.field}>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    name="sellingRoleId"
                                    render={({ field, fieldState: { invalid } }) => (
                                        <SelectV2
                                            options={sellingRoleList}
                                            getValue={getSelectValue}
                                            label="Продающая роль"
                                            error={invalid}
                                            fullWidth
                                            {...field}
                                        />
                                    )}
                                />
                            </div>

                            {error && <div className={cn(styles.field, styles.error)}>{error.message}</div>}

                            <Button
                                htmlType="submit"
                                size="large"
                                type="contained"
                                label="Продолжить"
                                loading={isSubmitting}
                                disabled={!isValid}
                                dataTestId="form-submit-button"
                                fullWidth
                            />

                            <Button
                                label="Что нужно указать"
                                onClick={onShowInfoClick}
                                size="x-large"
                                type="text"
                                fullWidth
                            />
                        </form>
                    </>
                ) : null}
            </FormContainer>
            <Modal
                isOpen={showInfo}
                className={cn(modalStyles.content, styles.modalContent)}
                overlayClassName={cn(modalStyles.overlay, styles.modalOverlay)}
                bodyOpenClassName={modalStyles.bodyOpen}
                ariaHideApp={false}
                shouldFocusAfterRender={false}
            >
                <div className={styles.info}>
                    <p className={styles.infoHeader}>Важно</p>
                    <p className={styles.infoMessage}>{INFO_MESSAGE}</p>
                    <Button
                        label="Понятно"
                        onClick={() => setShowInfo(false)}
                        size="x-large"
                        type="contained"
                        dataTestId="info-modal-close-button"
                    />
                </div>
            </Modal>
        </AppThemeDecorator>
    );
};

export default JWTLoginForm;
