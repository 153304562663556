export enum CampaignSettings {
    CLIENT_IDENTIFICATION_TYPES = 'client_identification_types',
    CLIENT_NOTIFICATION_ENABLED = 'client_notification_enabled',
    CLIENT_REGISTRATION_REQUIRED = 'client_registration_required',
    LANDING_HIDING_ENABLED = 'landing_hiding_enabled',
    DISPLAY_LOGO_ON_BUNDLE = 'display_logo_on_bundle',
    FRIEND_NOTIFICATION_ENABLED = 'friend_notification_enabled',
    LANDING_BYPASS_ENABLED = 'landing_bypass_enabled',
    SALE_ENABLED = 'sale_enabled',
}
