import React, { useEffect, useState } from 'react';
import uniqBy from 'lodash/uniqBy';

import { getBundleList } from '../../api/services/bundleService';
import { getAllPromoCampaigns } from '../../api/services/promoCampaignService';
import { getCategoryListFromCampaignList } from '../../utils/promoCampaignHelper';
import { bundleLinkToPromoCampaign, promoCampaignDtoToPromoCampaign } from '../../utils/mapper';
import type {
    IBaseResponse,
    IBundle,
    IBundleLink,
    ICategoryDto,
    IPromoCampaign,
    IPromoCampaignDto,
} from '../../api/types';
import { useAnalyticsQueue } from '../../hooks';
import MainPageContent from '../../component/MainPageContent';
import FilterPanel from '../../component/FilterPanel/FilterPanel';
import MainPageLayout from '../../component/MainPageLayout/MainPageLayout';
import { useAppSettings } from '../../component/AppSettings';
import { IntegrationPointTypes } from '../../constants/integrationPointTypes';
import promoCampaignTypes from '../../constants/promoCampaignTypes';
import { MainPageCardSkeleton } from '../../component/MainPageCard';
import MainPageCardList from '../../component/MainPageCardList';

type Props = {
    goToOffer: (promoCampaign: IPromoCampaign) => void;
    goToProduct: (promoCampaign: IPromoCampaign) => void;
    goToBundle: (bundle: IBundle) => void;
};

type State = {
    bundles: IBundle[];
    products: IPromoCampaign[];
    categories: ICategoryDto[];
};

const Mix = ({ goToOffer, goToProduct, goToBundle }: Props) => {
    const [{ bundles, products, categories }, setData] = useState<State>({
        bundles: [],
        products: [],
        categories: [],
    });
    const [errorInfo, setErrorInfo] = useState<IBaseResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [filterList, setFilterList] = useState<number[]>([]);
    const { pushMetrics } = useAnalyticsQueue();
    const appSettings = useAppSettings();

    useEffect(() => {
        let isMounted = true;
        pushMetrics('MIX_MAIN_SHOW');

        (async function () {
            try {
                setLoading(true);

                const [bundlesResult, productsResult] = await Promise.allSettled([
                    getBundleList({
                        integrationPoint: IntegrationPointTypes.MIX,
                    }),
                    getAllPromoCampaigns({
                        integrationPoint: IntegrationPointTypes.MIX,
                        type: promoCampaignTypes.PRODUCT,
                    }),
                ]);

                if (bundlesResult.status === 'rejected' && productsResult.status === 'rejected') {
                    await Promise.reject(bundlesResult.reason);
                }

                const bundles: IBundle[] = bundlesResult.status === 'fulfilled' ? bundlesResult.value : [];
                const promoCampaigns: IPromoCampaignDto[] =
                    productsResult.status === 'fulfilled' ? productsResult.value : [];
                const products = promoCampaigns.map((el) => promoCampaignDtoToPromoCampaign(el, appSettings));
                const categoryProducts = getCategoryListFromCampaignList(products);
                const categoryBundles = getCategoryListFromCampaignList(
                    bundles
                        .reduce((links: IBundleLink[], bundle) => [...links, ...bundle.links], [])
                        .map((el) => bundleLinkToPromoCampaign(el, appSettings))
                );

                const categories = uniqBy([...categoryProducts, ...categoryBundles], 'categoryId');

                isMounted &&
                    setData({
                        bundles,
                        products,
                        categories,
                    });
            } catch (error) {
                setErrorInfo(error);
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <MainPageLayout
            filterPanel={
                <FilterPanel categoryList={categories} filterList={filterList} setFilterList={setFilterList} />
            }
            cardList={
                !loading && !errorInfo ? (
                    <MainPageContent
                        onBundleCardClick={goToBundle}
                        onProductDetailClick={goToProduct}
                        onProductCardClick={goToOffer}
                        bundles={bundles}
                        products={products}
                        filterList={filterList}
                    />
                ) : (
                    <MainPageCardList>
                        {Array.from(Array(3)).map((_, index) => (
                            <MainPageCardSkeleton key={`card-${index}`} id={index} />
                        ))}
                    </MainPageCardList>
                )
            }
            errorData={errorInfo}
        />
    );
};

export default Mix;
