import React, { useState } from 'react';

import { closeCertificateWarning, getShowCertificateWarning } from '../../api/services/certificate';
import { CERTIFICATE_CHANGE } from '../../constants/appEnvironments';
import {
    AppCertificateChangeTypes,
    INSTALLING_CERTIFICATES_WEBSITE,
    YANDEX_BROWSER_WEBSITE,
} from '../../constants/common';
import { INSTALL_CERTIFICATES_BUTTON_LABEL, INSTALL_YANDEX_BROWSER_BUTTON_LABEL } from '../../constants/labels';
import { moveToExternalResource } from '../../utils/helper';
import { useAnalyticsQueue, useSupportsCertificate } from '../../hooks';
import Button from '../Button';
import { ReactComponent as CrossIcon } from '../../static/svgs/cross-black.svg';
import { ReactComponent as AlertIcon } from '../../static/svgs/alert.svg';
import yandexBrowserIcon from '../../static/images/yandex-browser.png';

import styles from './CertificateWarning.module.scss';

export const DATA_TEST_ID_CERTIFICATE_WARNING = 'certificate-warning';
export const MORE_BUTTON_LABEL = 'Подробнее';
export const INFO_PART_ONE =
    'В ближайшее время все онлайн-сервисы Сбера будут переведены на работу с применением сертификатов Минцифры России. ';
export const INFO_PART_TWO =
    'Для бесперебойного и безопасного доступа к веб-ресурсам Сбера настоятельно рекомендуем установить ' +
    'на свои устройства сертификаты или браузер, поддерживающий отечественные сертификаты.';

const CertificateWarning = () => {
    const { pushMetrics } = useAnalyticsQueue();
    const { data } = useSupportsCertificate();
    const [showFullInfo, setShowFullInfo] = useState(false);
    const [showCertificateWarning, setShowCertificateWarning] = useState(() => getShowCertificateWarning());

    const onShowMoreClick = () => {
        pushMetrics('WARNING_PLATE_MORE_DETAILED_CLICK');
        setShowFullInfo((prevState) => !prevState);
    };

    const goToInstallCertificate = () => {
        pushMetrics('WARNING_PLATE_INSTALLING_CERTIFICATE_CLICK');
        moveToExternalResource({ url: INSTALLING_CERTIFICATES_WEBSITE, target: '_blank' });
    };

    const goToYandexBrowser = () => {
        pushMetrics('WARNING_PLATE_INSTALLING_YANDEX_CLICK');
        moveToExternalResource({ url: YANDEX_BROWSER_WEBSITE, target: '_blank' });
    };

    const onCloseWarning = () => {
        pushMetrics('WARNING_PLATE_CLOSE_CLICK');
        closeCertificateWarning();
        setShowCertificateWarning(false);
    };

    if (
        CERTIFICATE_CHANGE !== AppCertificateChangeTypes.WARNING ||
        !data ||
        data.supportsCertificate ||
        !showCertificateWarning
    ) {
        return null;
    }

    return (
        <div className={styles.container} data-test-id={DATA_TEST_ID_CERTIFICATE_WARNING}>
            <AlertIcon className={styles.icon} />
            <div className={styles.content}>
                <div className={styles.textBlock}>
                    <p className={styles.header}>Сертификаты не установлены</p>
                    <div className={styles.descriptionWrapper}>
                        <p className={styles.description}>
                            {INFO_PART_ONE}
                            {showFullInfo ? (
                                <span>{INFO_PART_TWO}</span>
                            ) : (
                                <span className={styles.showMoreLink} onClick={onShowMoreClick}>
                                    {MORE_BUTTON_LABEL}
                                </span>
                            )}
                        </p>
                    </div>
                </div>
                <div className={styles.buttonsBlock}>
                    <Button
                        label={INSTALL_CERTIFICATES_BUTTON_LABEL}
                        onClick={goToInstallCertificate}
                        type="contained"
                        dataTestId="go-to-install-certificate-btn"
                    />
                    <Button
                        icon={<img src={yandexBrowserIcon} alt="" />}
                        label={INSTALL_YANDEX_BROWSER_BUTTON_LABEL}
                        onClick={goToYandexBrowser}
                        dataTestId="go-to-yandex-browser-btn"
                    />
                </div>
            </div>
            <Button
                icon={<CrossIcon />}
                type="text"
                onClick={onCloseWarning}
                className={styles.closeButton}
                size="small"
                dataTestId="certificate-warning-close-btn"
            />
        </div>
    );
};

export default CertificateWarning;
