import type { ReactNode } from 'react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import cn from 'classnames';

import { ModalCtx } from '../../reducer/modal';
import { CLOSE_MODAL_ACTION, ModalTypes } from '../../constants/modal';
import { Authority } from '../../api/services/auth';
import { useWindowResize } from '../../hooks/window-event-hooks';
import { useAppSettings } from '../AppSettings';
import { useClient } from '../Client';
import { useActiveMechanics, useDevices, useLogoutClient, useAnalyticsQueue } from '../../hooks';
import Button from '../Button';
import ExitButton, { ID_EXIT_BUTTON } from '../ExitButton';
import MechanicsNavbar from '../MechanicsNavbar';
import { ROUTE } from '../../constants/route';
import { useUser } from '../User';
import { generateProductsPath } from '../../utils/generatePathHelper';

import { ReactComponent as ApplyIcon } from '../../static/svgs/apply-icon.svg';
import { ReactComponent as ArrowIcon } from '../../static/svgs/arrow-right-black-small.svg';
import { ReactComponent as ClientIcon } from '../../static/svgs/client.svg';
import { ReactComponent as LogoutIcon } from '../../static/svgs/logout.svg';

import styles from './LogoutModal.module.scss';

const { ReferralLink } = Authority;

function isOpen(isModalOpen: boolean, modalType: ModalTypes): boolean {
    return isModalOpen && modalType === ModalTypes.LOGOUT;
}

interface ILogoutModalPosition {
    top?: number;
    right?: number;
}

export interface IMenuElement {
    label: string;
    onClick: () => void;
    dataTestId: string;
}

const LogoutModal = () => {
    const {
        state: { modalType, isModalOpen, additionalMenuList, exitButtonId = ID_EXIT_BUTTON },
        dispatch,
    } = useContext(ModalCtx);
    const navigate = useNavigate();
    const { client, removeClient } = useClient();
    const { mutate: closeClientSession } = useLogoutClient(() => {
        flushSync(() => {
            removeClient();
            // TODO: исправить для перс подбора
            navigate(generateProductsPath(appCode));
        });
    });
    const { mutate: changeApp } = useLogoutClient(() => {
        flushSync(() => {
            removeClient();
            navigate(ROUTE.APPS);
        });
    });
    const { hasOnlyOneMechanic, appCode } = useAppSettings();
    const { isMobile } = useDevices();
    const { pushMetrics } = useAnalyticsQueue();
    const tab = useActiveMechanics();
    const [modalPosition, setModalPosition] = useState<ILogoutModalPosition>({});
    const { role } = useUser();
    const showPhoneNumber = !!client?.phoneNumber && !client.isTechnical;

    const handleClose = useCallback(() => dispatch({ type: CLOSE_MODAL_ACTION }), []);

    const updateModalPosition = useCallback(() => setModalPosition(calcModalPosition(exitButtonId)), []);

    useEffect(() => {
        function onScroll() {
            if (!isMobile) {
                handleClose();
            }
        }

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [isMobile]);

    useEffect(() => {
        updateModalPosition();

        return () => {
            pushMetrics('CLOSE_LOGOUT_MODAL', { tab });
        };
    }, []);

    useWindowResize(updateModalPosition);

    const onCloseClientSession = () => {
        handleClose();
        pushMetrics('CLIENT_LOGOUT_CLICK', { tab });
        closeClientSession();
    };

    const onChangeApp = () => {
        handleClose();
        pushMetrics('SELECT_APP_LOGOUT_CLICK');
        changeApp();
    };

    return (
        <Modal
            isOpen={isOpen(isModalOpen, modalType)}
            bodyOpenClassName={styles.bodyOpen}
            overlayClassName={styles.overlay}
            className={cn(styles.content, { [styles.sidebar]: isMobile })}
            {...(isMobile ? {} : { style: { content: modalPosition } })}
            ariaHideApp={false}
            onRequestClose={handleClose}
            shouldCloseOnOverlayClick={true}
            shouldFocusAfterRender={false}
        >
            {isMobile && (
                <div className={styles.section}>
                    <div className={styles.exitButton}>
                        <ExitButton id="logout-modal-exit-button" dataTestId="logout-modal-exit-button" />
                    </div>
                    {!hasOnlyOneMechanic && (
                        <>
                            <MechanicsNavbar />
                            <div className={styles.divider} />
                        </>
                    )}
                </div>
            )}
            <div className={styles.section}>
                {isMobile && !!additionalMenuList?.length && (
                    <>
                        {additionalMenuList.map(({ label, onClick, dataTestId }, index) => (
                            <ListItem
                                key={`${index}-${dataTestId}`}
                                description={label}
                                onClick={onClick}
                                rightIcon={<ArrowIcon />}
                                dataTestId={dataTestId}
                            />
                        ))}
                        <div className={styles.divider} />
                    </>
                )}
                {role !== ReferralLink && (
                    <>
                        <ListItem
                            description="Выбрать витрину"
                            onClick={onChangeApp}
                            leftIcon={<ApplyIcon />}
                            rightIcon={<ArrowIcon />}
                            dataTestId="change-app"
                        />
                        {showPhoneNumber && <div className={styles.divider} />}
                    </>
                )}
                {showPhoneNumber && (
                    <ListItem
                        description="Клиент"
                        value={client.phoneNumber}
                        onClick={onCloseClientSession}
                        leftIcon={<ClientIcon />}
                        rightIcon={<LogoutIcon />}
                        dataTestId="session-block-client"
                    />
                )}
            </div>
        </Modal>
    );
};

interface IListItem {
    description: string;
    value?: string | null;
    onClick: () => void;
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
    dataTestId: string;
}

const ListItem = ({ description, value, onClick, leftIcon, rightIcon, dataTestId }: IListItem) => (
    <Button
        onClick={onClick}
        className={cn(styles.descriptionBlock, { [styles.wideItem]: !!value })}
        dataTestId={dataTestId}
        type="text"
        fullWidth
    >
        <div className={styles.left}>
            {leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
            <div className={styles.block}>
                <div className={styles.description}>{description}</div>
                {value && <div className={styles.info}>{value}</div>}
            </div>
        </div>
        <div className={styles.right}>{rightIcon && <span className={styles.rightIcon}>{rightIcon}</span>}</div>
    </Button>
);

export function calcModalPosition(id: string) {
    const exitButton = document.getElementById(id);

    if (!exitButton) {
        return {};
    }

    const { width: bodyWidth } = document.body.getBoundingClientRect();
    const { height, top, right } = exitButton.getBoundingClientRect();
    const topOffset = height + top + 16;
    const rightOffset = Math.round(bodyWidth - right);
    return { top: topOffset, right: rightOffset };
}

export default LogoutModal;
