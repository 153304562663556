import React from 'react';

import SuccessPageCardSkeleton from '../SuccessPageCard/SuccessPageCardSkeleton';

export type SuccessPageLayoutSkeletonProps = {
    selectedItems?: number;
};

const SuccessPageSkeletonLayout = ({ selectedItems = 1 }: SuccessPageLayoutSkeletonProps) => (
    <>
        {selectedItems === 1 ? (
            <SuccessPageCardSkeleton isSingle />
        ) : (
            Array.from(Array(selectedItems))
                .filter((_, i) => i < 3)
                .map((_, i) => <SuccessPageCardSkeleton key={i} />)
        )}
    </>
);

export default SuccessPageSkeletonLayout;
