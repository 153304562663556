import type {
    ClientApplicationDto,
    CreativeDto,
    Creatives,
    IBundleLink,
    IPromoCampaign,
    IPromoCampaignDto,
} from '../api/types';
import { EridDtoType, EridType, PromoCampaignUrlTypes, SaleMechanicCode } from '../api/types';
import { DEFAULT_GO_TO_SITE_BTN_LABEL } from '../component/SuccessPageCard';
import behaviorTypes from '../constants/behaviorTypes';
import bannerTypes from '../constants/bannerTypes';
import { ClientIdentificationTypes } from '../constants/ClientIdentificationTypes';
import promoTextTypes from '../constants/promoTextTypes';
import promoCampaignTypes from '../constants/promoCampaignTypes';
import PromoCampaignTypes from '../constants/promoCampaignTypes';
import { CampaignSettings } from '../constants/CampaignSettings';
import type promoCodeTypes from '../constants/promoCodeTypes';
import PromoCodeTypes from '../constants/promoCodeTypes';
import type { IAppSettings } from '../api/services/settingService';
import { getCampaignBanner, getCampaignText } from './promoCampaignHelper';

export function convertArrayToRecord<T extends Record<string, any>, K extends keyof T>(
    array: T[],
    selector: K
): Record<T[K], T> {
    return array.reduce((acc, item) => ({ ...acc, [item[selector]]: item }), {} as Record<T[K], T>);
}

export function convertArrayToRecordByType<T extends Record<string, any>>(array: T[]): Record<string, T> {
    return convertArrayToRecord(array, 'type');
}

export function bundleLinkToPromoCampaign(bundleLink: IBundleLink, appSettings: IAppSettings): IPromoCampaign {
    return promoCampaignDtoToPromoCampaign(
        {
            ...bundleLink,
            id: bundleLink.campaignId,
            type: PromoCampaignTypes.PRESENT,
            clientApplicationDto: {} as ClientApplicationDto,
            promoCodeType: '' as promoCodeTypes,
        },
        {
            ...appSettings,
            prodCatEnabled: false,
        }
    );
}

const { CARD, CARD_PRESENT, CARD_PRODUCT, LOGO_MAIN, SCREEN, SCREEN_MOBILE, LINK_OFFER, TEXT_OFFER } = bannerTypes;
const {
    HEADER,
    HEADER_PRODUCT,
    HEADER_PRODUCT_QR,
    HEADER_PRESENT,
    HEADER_PRESENT_QR,
    RULES,
    DESCRIPTION_PRESENT,
    DESCRIPTION_PRODUCT,
    DESCRIPTION,
    MANUAL_SUBSCRIPTION,
    HEADER_MANUAL,
} = promoTextTypes;
const {
    CLIENT_IDENTIFICATION_TYPES,
    CLIENT_REGISTRATION_REQUIRED,
    CLIENT_NOTIFICATION_ENABLED,
    LANDING_HIDING_ENABLED,
    FRIEND_NOTIFICATION_ENABLED,
    LANDING_BYPASS_ENABLED,
} = CampaignSettings;
const { PHONE, SBER_ID, TECHNICAL } = ClientIdentificationTypes;

const EridMap = {
    [EridDtoType.PRESENT_MAIN_ERID]: EridType.MAIN_ERID,
    [EridDtoType.PRODUCT_MAIN_ERID]: EridType.MAIN_ERID,
    [EridDtoType.LANDING_ERID]: EridType.LANDING_ERID,
    [EridDtoType.SUCCESS_ERID]: EridType.SUCCESS_ERID,
} as const;

export function getClientIdentificationTypes(
    campaign: IPromoCampaignDto,
    { hasSapsanMechanics, showAuthBySberID, showAuthByPhoneNumber }: IAppSettings
): ClientIdentificationTypes[] {
    const clientIdentificationTypes = campaign.settings[CLIENT_IDENTIFICATION_TYPES] || [];
    const result: ClientIdentificationTypes[] = [
        (clientIdentificationTypes.includes(SBER_ID) || (!clientIdentificationTypes.length && showAuthBySberID)) &&
            SBER_ID,
        (clientIdentificationTypes.includes(PHONE) || (!clientIdentificationTypes.length && showAuthByPhoneNumber)) &&
            PHONE,
    ].filter(Boolean) as ClientIdentificationTypes[];

    if (campaign.saleMechanicCode === SaleMechanicCode.SALE_SUBSCRIPTION) {
        return [SBER_ID];
    }

    if (
        hasSapsanMechanics &&
        campaign.settings[CLIENT_REGISTRATION_REQUIRED] === false &&
        [PromoCodeTypes.NONE, PromoCodeTypes.COMMON].includes(campaign.promoCodeType)
    ) {
        return [...result, TECHNICAL];
    }

    return result;
}

export function promoCampaignDtoToPromoCampaign(
    campaign: IPromoCampaignDto,
    appSettings: IAppSettings
): IPromoCampaign {
    const id = appSettings.prodCatEnabled
        ? (campaign.productOfferingIds.productCampaignId as string)
        : `${campaign.id}`;
    const productCampaignId = campaign.productOfferingIds.productCampaignId;
    const originalId = campaign.id;
    const banners = convertArrayToRecordByType(campaign.banners);
    const texts = convertArrayToRecordByType(campaign.texts);
    const customButtons = convertArrayToRecordByType(campaign.promoCampaignActions);
    const successButtonLabel =
        (customButtons[PromoCampaignUrlTypes.URL_SUCCESS_BUTTON]?.name ?? '').trim() || DEFAULT_GO_TO_SITE_BTN_LABEL;
    const allowClientNotification = campaign.settings[CLIENT_NOTIFICATION_ENABLED] !== false;
    const allowFriendNotification = campaign.settings[FRIEND_NOTIFICATION_ENABLED] !== false;
    const showLanding = campaign.settings[LANDING_HIDING_ENABLED] !== true;
    const landingBypassEnabled = campaign.settings[LANDING_BYPASS_ENABLED];
    const clientIdentificationTypes = getClientIdentificationTypes(campaign, appSettings);
    const showQR = [behaviorTypes.QR, behaviorTypes.COMBO].includes(campaign.behaviorType);
    const showGoToSiteButton = [behaviorTypes.WEB_ONLY, behaviorTypes.COMBO].includes(campaign.behaviorType);
    const detailsButton = customButtons[PromoCampaignUrlTypes.URL_LANDING];
    const detailsButtonLabel = (detailsButton?.name ?? '').trim() || 'Подробнее';
    const showDetailButton = detailsButton?.urlActive && !!detailsButton?.url;
    const detailsButtonUrl = detailsButton?.url;
    const isSaleSubscription = campaign.saleMechanicCode === SaleMechanicCode.SALE_SUBSCRIPTION;
    const targetActionButton = customButtons[PromoCampaignUrlTypes.TARGET_ACTION_BUTTON];
    const targetActionButtonLabel =
        targetActionButton?.urlActive && targetActionButton?.name
            ? targetActionButton.name.trim()
            : isSaleSubscription
            ? 'Оформить'
            : 'Получить';

    const offerTermsLink = isSaleSubscription ? getCampaignBanner(banners, [LINK_OFFER, TEXT_OFFER]) : '';
    const logoUrl = getCampaignBanner(banners, LOGO_MAIN);
    const rules = getCampaignText(texts, RULES);
    const offerDescription = getCampaignText(texts, [
        ...(campaign.type === promoCampaignTypes.PRODUCT
            ? [HEADER_PRODUCT_QR, HEADER_PRODUCT]
            : [HEADER_PRESENT_QR, HEADER_PRESENT]),
        HEADER,
    ]);
    const header = getCampaignText(texts, [
        campaign.type === PromoCampaignTypes.PRODUCT ? HEADER_PRODUCT : HEADER_PRESENT,
        HEADER,
    ]);
    const description = getCampaignText(texts, [
        campaign.type === promoCampaignTypes.PRODUCT ? DESCRIPTION_PRODUCT : DESCRIPTION_PRESENT,
        DESCRIPTION,
    ]);
    const headerManual = getCampaignText(texts, HEADER_MANUAL);
    const manualSubscription = getCampaignText(texts, MANUAL_SUBSCRIPTION);
    const cardUrl = getCampaignBanner(banners, [
        campaign.type === PromoCampaignTypes.PRODUCT ? CARD_PRODUCT : CARD_PRESENT,
        CARD,
    ]);
    const screenUrl = getCampaignBanner(banners, SCREEN);
    const screenMobileUrl = getCampaignBanner(banners, SCREEN_MOBILE);
    const creatives = arrayOfCreativesToRecord(campaign.creatives, campaign.type);

    const {
        dzoId,
        dzoName,
        dzoCode,
        tradeName,
        name,
        type,
        promoCodeType,
        categoryList,
        integrationPoint,
        saleMechanicCode,
    } = campaign;

    return {
        id,
        type,
        name,
        dzoId,
        dzoName,
        dzoCode,
        tradeName,
        logoUrl,
        rules,
        offerDescription,
        allowClientNotification,
        allowFriendNotification,
        showQR,
        showGoToSiteButton,
        productCampaignId,
        originalId,
        promoCodeType,
        screenUrl,
        screenMobileUrl,
        successButtonLabel,
        detailsButtonLabel,
        detailsButtonUrl,
        showDetailButton,
        creatives,
        header,
        cardUrl,
        description,
        headerManual,
        manualSubscription,
        offerTermsLink,
        categoryList,
        integrationPoint,
        saleMechanicCode,
        clientIdentificationTypes,
        landingBypassEnabled,
        showLanding,
        targetActionButtonLabel,
    };
}

export function campaignListToSelectedRecord(list: IPromoCampaign[]) {
    return list
        .map(({ id }) => id)
        .reduce<Record<string, boolean>>((selectedList, id) => {
            selectedList[id] = true;
            return selectedList;
        }, {});
}

export function arrayOfCreativesToRecord(creatives: CreativeDto[], promoCampaignType: PromoCampaignTypes) {
    const skipEridDtoType =
        promoCampaignType === promoCampaignTypes.PRODUCT
            ? EridDtoType.PRESENT_MAIN_ERID
            : EridDtoType.PRODUCT_MAIN_ERID;

    return creatives.reduce<Creatives>((obj, { type, erid }) => {
        if (type === skipEridDtoType) {
            return obj;
        }

        obj[EridMap[type]] = erid;
        return obj;
    }, {} as Creatives);
}
