import uniqBy from 'lodash/uniqBy';

import type { IAdditionalInfo } from '../api/services/offerService/types';
import type { ICategoryDto, IPromoCampaign, IPromoCampaignBanner, IPromoCampaignText } from '../api/types';

export function getCampaignBanner(banners: Record<string, IPromoCampaignBanner>, types: string | string[]) {
    if (Array.isArray(types)) {
        const type = types.find((type) => banners[type]);
        return type ? banners[type]?.url || '' : '';
    }
    return banners[types]?.url || '';
}

export function getCampaignText(texts: Record<string, IPromoCampaignText>, types: string | string[]) {
    if (Array.isArray(types)) {
        const type = types.find((type) => texts[type]);
        return type ? texts[type]?.value || '' : '';
    }
    return texts[types]?.value || '';
}

export function generateAdditionalInfoForRequest(campaignGroupId?: string | null): IAdditionalInfo {
    const additionalInfo: IAdditionalInfo = {};
    campaignGroupId && (additionalInfo.campaignGroupId = Number(campaignGroupId));
    return additionalInfo;
}

export function getCategoryListFromCampaignList(list: IPromoCampaign[]) {
    return list.reduce(
        (categories: ICategoryDto[], campaign) =>
            campaign.categoryList?.length
                ? uniqBy([...categories, ...campaign.categoryList], 'categoryId')
                : categories,
        []
    );
}
