import { useCallback, useLayoutEffect } from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery } from 'react-query';

import type { IAppSettings, ISettingsListResponse } from '../../../api/services/settingService';
import { getAppSettings, getConsent, setSettings } from '../../../api/services/settingService';
import type { ClientApplicationDto, IBaseResponse } from '../../../api/types';
import { Authority, getApps } from '../../../api/services/auth';
import { authenticatedRequest } from '../../../api/axios';
import { APP_CODE_HEADER } from '../../../constants/common';
import { useAppSettings } from '../../../component/AppSettings';

export const keys = {
    apps: () => ['apps'] as const,
    app: (appCode: string) => [...keys.apps(), appCode] as const,

    appSettings: (appCode: string) => [...keys.app(appCode), 'appSettings'] as const,

    consent: (appCode: string, consentId: number) => [...keys.app(appCode), 'consent', consentId] as const,
};

export const useAppsQuery = () =>
    useQuery<ClientApplicationDto[], IBaseResponse>(keys.apps(), getApps, {
        useErrorBoundary: true,
    });

export const useAppInterceptor = (appCode: string) => {
    useLayoutEffect(() => {
        const interceptor = authenticatedRequest.interceptors.request.use((config) => {
            config.headers = {
                ...config.headers,
                [APP_CODE_HEADER]: appCode,
            };

            return config;
        });

        return () => {
            authenticatedRequest.interceptors.request.eject(interceptor);
        };
    }, [appCode]);
};

type AppSettingsQueryKeys = ReturnType<typeof keys.appSettings>;

const getAppSettingsFn = ({ queryKey: [, appCode] }: QueryFunctionContext<AppSettingsQueryKeys>) =>
    getAppSettings(appCode);

const getAppSettingsQueryOptions = (appCode: string) => ({
    queryKey: keys.appSettings(appCode),
    queryFn: getAppSettingsFn,
});

export const useAppSettingsQuery = (appCode: string, role?: Authority) => {
    const select = useCallback(
        (data: ISettingsListResponse) => {
            const appSettings = setSettings(data, appCode);

            if (!role) {
                return appSettings;
            }

            const allowPushNotification = appSettings.allowPushNotification && role !== Authority.ReferralLink;
            const showAuthBySberID =
                appSettings.showAuthBySberID && [Authority.User, Authority.ReferralLink].includes(role);
            const clientNotificationEnabled = allowPushNotification && appSettings.clientNotificationEnabled;
            const friendNotificationEnabled = allowPushNotification && appSettings.friendNotificationEnabled;

            return {
                ...appSettings,
                allowPushNotification,
                showAuthBySberID,
                clientNotificationEnabled,
                friendNotificationEnabled,
            };
        },
        [role, appCode]
    );

    return useQuery<ISettingsListResponse, IBaseResponse, IAppSettings, AppSettingsQueryKeys>({
        ...getAppSettingsQueryOptions(appCode),
        select,
        useErrorBoundary: true,
    });
};

type ConsentQueryKeys = ReturnType<typeof keys.consent>;

const getConsentFn = ({ queryKey: [, appCode, , consentId] }: QueryFunctionContext<ConsentQueryKeys>) =>
    getConsent(appCode, consentId);

const getConsentQueryOptions = (appCode: string, consentId: number) => ({
    queryKey: keys.consent(appCode, consentId),
    queryFn: getConsentFn,
});

export const useConsentQuery = (consentId: number) => {
    const { appCode } = useAppSettings();

    return useQuery({ ...getConsentQueryOptions(appCode, consentId), enabled: !!consentId });
};
