import React, { useContext } from 'react';

import { Authority } from '../../api/services/auth';
import { ModalCtx } from '../../reducer/modal';
import { CLOSE_MODAL_ACTION, ModalTypes, OPEN_MODAL_ACTION } from '../../constants/modal';
import type { IMenuElement } from '../LogoutModal';
import { useClient } from '../Client';
import { useAppSettings } from '../AppSettings';
import { useActiveMechanics, useDevices, useAnalyticsQueue } from '../../hooks';
import Button from '../Button';
import { ReactComponent as CloseIcon } from '../../static/svgs/cross-black.svg';
import { ReactComponent as MenuIcon } from '../../static/svgs/menu.svg';
import { useUser } from '../User';

export interface ExitButtonProps {
    id?: string;
    additionalMenuList?: IMenuElement[];
    dataTestId?: string;
}

const { ReferralLink } = Authority;

export const ID_EXIT_BUTTON = 'exit-button';
const DATA_TEST_ID_EXIT_BUTTON = 'exit-button';

const ExitButton = ({
    id = ID_EXIT_BUTTON,
    dataTestId = DATA_TEST_ID_EXIT_BUTTON,
    additionalMenuList = [],
}: ExitButtonProps) => {
    const {
        state: { isModalOpen, modalType },
        dispatch,
    } = useContext(ModalCtx);
    const { client } = useClient();
    const { hasOnlyOneMechanic } = useAppSettings();
    const { pushMetrics } = useAnalyticsQueue();
    const tab = useActiveMechanics();
    const isOpen = isModalOpen && modalType === ModalTypes.LOGOUT;
    const { isMobile } = useDevices();
    const { role } = useUser();
    const showButton =
        (client && !client.isTechnical) ||
        role !== ReferralLink ||
        (isMobile && (!hasOnlyOneMechanic || !!additionalMenuList?.length));

    const openMenu = () => {
        pushMetrics('OPEN_LOGOUT_MODAL', { tab });
        dispatch({
            type: OPEN_MODAL_ACTION,
            payload: { modalType: ModalTypes.LOGOUT, additionalMenuList, exitButtonId: id },
        });
    };

    const closeMenu = () => dispatch({ type: CLOSE_MODAL_ACTION });

    return (
        <>
            {showButton && (
                <Button
                    id={id}
                    icon={isOpen ? <CloseIcon /> : <MenuIcon />}
                    type="outline"
                    onClick={isOpen ? closeMenu : openMenu}
                    dataTestId={dataTestId}
                />
            )}
        </>
    );
};

export default ExitButton;
