import type { productTargetKeys } from '../../utils/clickStream';

export type FormValues = {
    email: string;
    agreeWithAdvertising?: boolean;
};

export type Submit = (data: FormValues) => void | Promise<any>;

export type Props = {
    onCancel: () => void;
    onSubmit: () => void;
};

export enum EmailStage {
    CONFIRMATION,
    AWAIT,
    ERROR,
    SUCCESS,
}

export type EmailState = {
    email: string;
    isEmailConfirmed: boolean;
};

export type ErrorArguments = {
    message: string;
    svEvent: productTargetKeys;
    description?: string;
    requestId?: string;
};

export type StagesState = {
    stage: EmailStage;
    errorData?: ErrorArguments;
};
