import type { ReactNode } from 'react';
import React, { useEffect } from 'react';

import Button from '../Button';
import { ReactComponent as CrossIcon } from '../../static/svgs/cross-black.svg';

import styles from './SuccessPageModalBody.module.scss';

export type SuccessPageModalBodyProps = {
    header?: string;
    description?: string;
    requestId?: string;
    onCloseButtonClick?: () => void;
    renderTopBlock?: () => ReactNode;
    renderContent?: () => ReactNode;
    renderButtons?: () => ReactNode;
    dataTestId?: string;
    onMount?: () => void;
};

export const DATA_TEST_ID_OUTER_MODAL_CLOSE_BUTTON = 'outer-modal-close-button';

const SuccessPageModalBody = ({
    header,
    description,
    requestId,
    onCloseButtonClick,
    renderTopBlock,
    renderContent,
    renderButtons,
    dataTestId = 'modal-body',
    onMount,
}: SuccessPageModalBodyProps) => {
    useEffect(() => {
        onMount?.();
    }, []);

    return (
        <div className={styles.body} data-test-id={dataTestId}>
            {onCloseButtonClick && (
                <Button
                    icon={<CrossIcon />}
                    onClick={onCloseButtonClick}
                    className={styles.buttonClose}
                    type="outline"
                    dataTestId={DATA_TEST_ID_OUTER_MODAL_CLOSE_BUTTON}
                />
            )}
            {renderTopBlock && <div className={styles.topBlock}>{renderTopBlock()}</div>}
            {(header || description || requestId) && (
                <div className={styles.headerBlock}>
                    {header && <p className={styles.header}>{header}</p>}
                    {description && <p className={styles.description}>{description}</p>}
                    {requestId && <p className={styles.description}>ID запроса: {requestId}</p>}
                </div>
            )}
            {renderContent && <div className={styles.contentBlock}>{renderContent()}</div>}
            {renderButtons && <div className={styles.buttonsBlock}>{renderButtons()}</div>}
        </div>
    );
};

export default SuccessPageModalBody;
