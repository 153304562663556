import React, { useCallback } from 'react';
import NavItemRoute from './NavItemRoute';
import { useAppSettings } from '../AppSettings';
import { APP_MECHANICS_VALUES, APP_TAB_LABELS } from '../../constants/appMechanics';
import { useAnalyticsQueue } from '../../hooks';
import {
    generateBundlesPath,
    generateMixPath,
    generatePresentsPath,
    generateProductsPath,
} from '../../utils/generatePathHelper';

export const NavEcosystemRoute = () => {
    const { pushMetrics } = useAnalyticsQueue();
    const { hasProductMechanics, appCode } = useAppSettings();
    const path = generateProductsPath(appCode);

    const onClick = useCallback(() => {
        pushMetrics('TO_ANOTHER_MECHANICS_TAB', { tab: APP_MECHANICS_VALUES.PRODUCTS });
    }, [pushMetrics]);

    return hasProductMechanics ? (
        <NavItemRoute to={path} onClick={onClick} dataTestId="nav-item-products">
            {APP_TAB_LABELS.PRODUCTS}
        </NavItemRoute>
    ) : null;
};

export const NavPresentsRoute = () => {
    const { pushMetrics } = useAnalyticsQueue();
    const { hasPresentsMechanics, appCode } = useAppSettings();
    const path = generatePresentsPath(appCode);

    const onClick = useCallback(() => {
        pushMetrics('TO_ANOTHER_MECHANICS_TAB', { tab: APP_MECHANICS_VALUES.PRESENTS });
    }, [pushMetrics]);

    return hasPresentsMechanics ? (
        <NavItemRoute to={path} onClick={onClick} dataTestId="nav-item-presents">
            {APP_TAB_LABELS.PRESENTS}
        </NavItemRoute>
    ) : null;
};

export const NavBundleRoute = () => {
    const { pushMetrics } = useAnalyticsQueue();
    const { hasBundleMechanics, appCode } = useAppSettings();
    const path = generateBundlesPath(appCode);

    const onClick = useCallback(() => {
        pushMetrics('TO_ANOTHER_MECHANICS_TAB', { tab: APP_MECHANICS_VALUES.BUNDLES });
    }, [pushMetrics]);

    return hasBundleMechanics ? (
        <NavItemRoute to={path} onClick={onClick} dataTestId="nav-item-bundle">
            {APP_TAB_LABELS.BUNDLES}
        </NavItemRoute>
    ) : null;
};

export const NavMixRoute = () => {
    const { pushMetrics } = useAnalyticsQueue();
    const { hasMixMechanics, appCode } = useAppSettings();
    const path = generateMixPath(appCode);

    const onClick = useCallback(() => {
        pushMetrics('TO_ANOTHER_MECHANICS_TAB', { tab: APP_MECHANICS_VALUES.MIX });
    }, [pushMetrics]);

    return hasMixMechanics ? (
        <NavItemRoute to={path} onClick={onClick} dataTestId="nav-item-mix">
            {APP_TAB_LABELS.MIX}
        </NavItemRoute>
    ) : null;
};
