export const SELECTION = {
    SELECT: 'Выбрать',
    SELECTED: 'Выбран',
};

export const INSTALL_CERTIFICATES_BUTTON_LABEL = 'Установить сертификаты';
export const INSTALL_YANDEX_BROWSER_BUTTON_LABEL = 'Установить Яндекс Браузер';

export const LABELS = {
    ERRORS: {
        _404: 'Извините, данной страницы не существует',
        _500: 'Нет ответа от сервера',
        COMMON: 'Что-то пошло не так',
    },
};
