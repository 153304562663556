import React from 'react';

type Props = {
    color?: string;
    filled?: boolean;
};

const Star = ({ color = '#0D8523', filled }: Props) => (
    <svg
        width="35.000000"
        height="35.000000"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path
            id="Star default"
            d="M11.0872 9.58203L14.7856 1.74414C15.8835 -0.581299 19.116 -0.581299 20.2139 1.74414L23.9136 9.58276C23.9668 9.6958 24.0593 9.76611 24.1538 9.78052L32.4258 11.0369C34.8962 11.4136 35.8567 14.5054 34.1082 16.2871L28.124 22.3875C28.0327 22.4805 27.9873 22.6265 28.0112 22.772L29.4238 31.386C29.8357 33.8931 27.2583 35.8513 25.0254 34.6238L17.6294 30.5569C17.5459 30.5112 17.4536 30.5112 17.3716 30.5562L9.97363 34.624C7.73999 35.8508 5.16382 33.8931 5.57568 31.3862L6.98828 22.7717C7.01221 22.6265 6.9668 22.4805 6.87524 22.3872L0.891357 16.2869C-0.856445 14.5046 0.10376 11.4133 2.5752 11.0366L10.8442 9.78076C10.9407 9.76611 11.0337 9.69531 11.0872 9.58203Z"
            clipRule="evenodd"
            fillRule="evenodd"
            fill={color}
            fillOpacity={`${filled ? 1 : 0}`}
        />
        <path
            id="Star default"
            d="M14.7856 1.74414L11.0872 9.58203C11.0337 9.69531 10.9407 9.76611 10.8442 9.78076L2.5752 11.0366C0.10376 11.4133 -0.856445 14.5046 0.891357 16.2869L6.87524 22.3872C6.9668 22.4805 7.01221 22.6265 6.98828 22.7717L5.57568 31.3862C5.16382 33.8931 7.73999 35.8508 9.97363 34.624L17.3716 30.5562C17.4536 30.5112 17.5459 30.5112 17.6294 30.5569L25.0254 34.6238C27.2583 35.8513 29.8357 33.8931 29.4238 31.386L28.0112 22.772C27.9873 22.6265 28.0327 22.4805 28.124 22.3875L34.1082 16.2871C35.8567 14.5054 34.8962 11.4136 32.4258 11.0369L24.1538 9.78052C24.0593 9.76611 23.9668 9.6958 23.9136 9.58276L20.2139 1.74414C19.116 -0.581299 15.8835 -0.581299 14.7856 1.74414ZM11.032 11.0166C11.5776 10.9333 11.9729 10.6333 12.2173 10.1165L12.2178 10.1155L15.916 2.27759C16.2395 1.59253 16.7673 1.25 17.4998 1.25C18.2322 1.25 18.76 1.59253 19.0835 2.27783L22.7832 10.1165C23.0266 10.6333 23.4207 10.9331 23.9651 11.0164L32.2383 12.2727C32.9573 12.3823 33.4304 12.7957 33.6575 13.5125C33.8889 14.2429 33.7417 14.8757 33.2161 15.4114L27.2317 21.5122C26.8352 21.916 26.6841 22.4036 26.7778 22.9746L28.1902 31.5881C28.3149 32.3472 28.0747 32.9521 27.4697 33.4028C26.8835 33.8394 26.2695 33.8811 25.6274 33.5283L18.2317 29.4617Q17.8626 29.2593 17.4942 29.2606Q17.132 29.262 16.7705 29.4602L9.37134 33.5286C8.72925 33.8813 8.11548 33.8394 7.5293 33.4028C6.92456 32.9521 6.68457 32.3477 6.80908 31.5889L8.22168 22.9739C8.31543 22.4033 8.16406 21.916 7.76758 21.5117L1.78369 15.4116C1.25806 14.8757 1.11108 14.2427 1.34253 13.5125C1.56982 12.7954 2.04346 12.3821 2.76367 12.2725L11.032 11.0166Z"
            clipRule="evenodd"
            fillRule="evenodd"
            fill={color}
            fillOpacity="1.000000"
        />
    </svg>
);

export default Star;
