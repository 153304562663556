import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import cn from 'classnames';

import { MIN_TABLET_WIDTH } from '../../constants/breakpoints';
import { SBER_CONTACT_INFO } from '../../constants/contactInfo';
import { FeedbackScreenType } from '../../constants/FeedbackScreenTypes';
import { CHANGE_FOOTER_ACTION } from '../../constants/modal';
import { Authority } from '../../api/services/auth';
import { ModalCtx } from '../../reducer/modal';
import { scrollToTop } from '../../utils/helper';
import { useDevices, useFooterHeight, useAnalyticsQueue } from '../../hooks';
import AdvertisingTag from '../AdvertisingTag';
import FeedbackBanner from '../FeedbackBanner';
import FooterInfo from '../FooterInfo';
import { Footer } from '../Footer';
import Header, { HeaderBackgroundColor } from '../Header';
import { useUser } from '../User';
import type { LandingProps } from './types';
import LandingSkeleton from './LandingSkeleton';

import styles from './Landing.module.scss';

const Landing = ({
    renderHeaderLeftBlock,
    renderHeaderRightBlock,
    renderStaticFooter,
    renderFixedFooter,
    campaign,
}: LandingProps) => {
    const { role } = useUser();
    const { pushMetrics } = useAnalyticsQueue();
    const { dispatch } = useContext(ModalCtx);
    const [imgLoaded, setImgLoaded] = useState(false);
    const { footerHeight } = useFooterHeight();
    const { isMobile } = useDevices();
    const contentLoaded = campaign && imgLoaded;
    const {
        name: campaignName,
        dzoName,
        productCampaignId,
        originalId: campaignId,
        tradeName,
        screenUrl,
        screenMobileUrl,
        creatives,
        rules,
    } = campaign || {};
    const erid = creatives?.LANDING_ERID;
    const commonMetrics = {
        campaignId,
        campaignName,
        dzoName,
        productCampaignId,
    };

    useEffect(() => {
        setImgLoaded(false);
    }, [screenUrl, screenMobileUrl]);

    useEffect(() => {
        if (campaignId || productCampaignId) {
            pushMetrics('SHOW_PRODUCT_DETAILS', {
                campaignId,
                campaignName,
                dzoName,
                productCampaignId,
            });
        }
    }, [pushMetrics, campaignId, campaignName, dzoName, productCampaignId]);

    useEffect(scrollToTop, [campaign]);

    useLayoutEffect(() => {
        dispatch({ type: CHANGE_FOOTER_ACTION });
    }, [contentLoaded]);

    const imgHasLoaded = () => {
        // Downloading img before stop showing skeleton
        setImgLoaded(true);
    };

    const onAdvertisingTagClick = () => {
        pushMetrics('ADVERTISEMENT_CLICK', {
            ...commonMetrics,
            tradeName,
            erid,
        });
    };

    return (
        <div className={styles.page}>
            <div className={cn(styles.body, styles.widthLimit)} style={{ marginBottom: `${footerHeight}px` }}>
                {(renderHeaderLeftBlock || renderHeaderRightBlock) && (
                    <Header
                        position={isMobile ? 'static' : 'sticky'}
                        backgroundColor={HeaderBackgroundColor.WHITE_ALPHA}
                        className={styles.widthLimit}
                        renderLeft={renderHeaderLeftBlock}
                        renderRight={renderHeaderRightBlock}
                    />
                )}
                <div className={imgLoaded ? styles.imgWrapper : styles.imgHidden}>
                    {screenUrl && (
                        <>
                            <picture>
                                {screenMobileUrl && (
                                    <source srcSet={screenMobileUrl} media={`(max-width: ${MIN_TABLET_WIDTH - 1}px)`} />
                                )}
                                <source srcSet={screenUrl} media={`(min-width: ${MIN_TABLET_WIDTH}px)`} />
                                <img src={screenUrl} alt={campaignName} className={styles.img} onLoad={imgHasLoaded} />
                            </picture>
                            <AdvertisingTag
                                tradeName={tradeName}
                                erid={erid}
                                onClick={onAdvertisingTagClick}
                                rules={rules}
                            />
                        </>
                    )}
                    {campaign && (
                        <div className={styles.feedbackBannerWrapper}>
                            <FeedbackBanner screenType={FeedbackScreenType.LANDING} campaign={campaign} />
                        </div>
                    )}
                </div>
                {!contentLoaded && <LandingSkeleton />}
                {role === Authority.ReferralLink && (
                    <div className={styles.footerInfoWrapper}>
                        <FooterInfo
                            contactInfo={SBER_CONTACT_INFO}
                            className={styles.footerInfo}
                            position={isMobile ? 'left' : 'right'}
                        />
                    </div>
                )}
                {renderStaticFooter && contentLoaded && <div className={styles.content}>{renderStaticFooter?.()}</div>}
                {renderFixedFooter && contentLoaded && (
                    <Footer className={styles.widthLimit}>{renderFixedFooter?.()}</Footer>
                )}
            </div>
        </div>
    );
};

export default Landing;
