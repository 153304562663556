import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMount } from 'react-use';

import { CODE, ID } from '../../../constants/queryParams';
import { GIFT_ROUTE } from '../../../constants/route';
import { useAnalyticsQueue, useDevices } from '../../../hooks';
import type { ButtonSizes } from '../../../component/Button';
import Button from '../../../component/Button';
import TextJaw from '../../../component/TextJaw';
import AppThemeDecorator from '../../../component/AppThemeDecorator';
import ClientOfferShareSkeleton from './ClientOfferShareSkeleton';
import ClientOfferLayout from '../ClientOfferLayout';
import type { GiftOfferInfo } from '../../../hooks/services/offer/gift-offer';
import { useGiftOfferQuery } from '../../../hooks/services/offer/gift-offer';
import AdvertisingTag from '../../../component/AdvertisingTag';
import DzoLogo from '../../../component/DzoLogo';

import styles from '../ClientOffer.module.scss';

export function generateOfferMessage(dzoName = '', header?: string) {
    const headerBlock = header ? `${header} \n` : '';
    return `У меня для тебя есть предложение от ${dzoName}. \n${headerBlock}`;
}

type ClientOfferShareProps = {
    giftOfferInfo: GiftOfferInfo;
    linkUrl: string;
};

const ClientOfferShare = ({ giftOfferInfo, linkUrl }: ClientOfferShareProps) => {
    const { dzoName, header, tradeName, campaignName, campaignId, productCampaignId, appCode, logoUrl, cardUrl, erid } =
        giftOfferInfo;
    const { pushMetrics } = useAnalyticsQueue();
    const { isMobile } = useDevices();
    const buttonSize: ButtonSizes = !isMobile ? 'x-large' : 'large';
    const offer = generateOfferMessage(dzoName, header);

    useMount(() => {
        pushMetrics('SUCCESS_CLIENT_OFFER_SHOW', {
            campaignId,
            campaignName,
            dzoName,
            productCampaignId,
            appCode,
        });
    });

    const onShareOfferClick = async () => {
        try {
            pushMetrics('SUCCESS_CLIENT_OFFER_GIVE_OFFER_CLICK', {
                campaignId,
                campaignName,
                dzoName,
                productCampaignId,
                appCode,
            });
            await navigator.share({
                text: offer,
                url: linkUrl,
            });
        } catch (error) {
            console.error('Something went wrong sharing the offer', error);
        }
    };

    const onCopyOffer = () =>
        pushMetrics('SUCCESS_CLIENT_OFFER_COPY_OFFER_CLICK', {
            campaignId,
            campaignName,
            dzoName,
            productCampaignId,
            appCode,
        });

    const onAdvertisingTagClick = () => {
        pushMetrics('ADVERTISEMENT_CLICK', {
            campaignId,
            campaignName,
            dzoName,
            productCampaignId,
            appCode,
            tradeName,
            erid,
        });
    };

    return (
        <div className={styles.content}>
            <DzoLogo logoUrl={logoUrl} dzoName={dzoName} />
            {header && <p className={styles.info} dangerouslySetInnerHTML={{ __html: header }} />}
            {cardUrl && <img src={cardUrl} alt="Banner" className={styles.banner} />}
            <p className={styles.instructions}>Скопируйте и поделитесь</p>
            <TextJaw
                text={`${offer}${linkUrl}`}
                visibleText={linkUrl}
                dataTestId="copy-offer-button"
                successMessage="Ссылка скопирована"
                onCopyText={onCopyOffer}
                clipped
            />
            {!!navigator.share && (
                <Button
                    label="Поделиться"
                    size={buttonSize}
                    type="contained"
                    fullWidth
                    className={styles.button}
                    onClick={onShareOfferClick}
                    dataTestId="share-offer-button"
                />
            )}
            <AdvertisingTag tradeName={tradeName} erid={erid} onClick={onAdvertisingTagClick} />
        </div>
    );
};

const ClientOfferShareContainer = () => {
    const [searchParams] = useSearchParams();
    const offerUuid = searchParams.get(ID) ?? searchParams.get(CODE) ?? '';
    const { pushMetrics } = useAnalyticsQueue();
    const { data } = useGiftOfferQuery(offerUuid, () => pushMetrics('ERROR_CLIENT_OFFER_SHOW'));
    const linkUrl = `${window.location.origin}${GIFT_ROUTE.GET_PAGE}?${ID}=${offerUuid}`;

    return (
        <AppThemeDecorator>
            <ClientOfferLayout>
                {data ? <ClientOfferShare giftOfferInfo={data} linkUrl={linkUrl} /> : <ClientOfferShareSkeleton />}
            </ClientOfferLayout>
        </AppThemeDecorator>
    );
};

export default ClientOfferShareContainer;
