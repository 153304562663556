import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import { sleep } from '../../utils/helper';
import type { ButtonProps } from '../Button';
import Button from '../Button';

import styles from './ButtonWithTimeout.module.css';

export type IButtonWithTimeoutProps = ButtonProps & {
    labelSent?: string; // Текст кнопки во время паузы после успешного нажатия.
    timeoutSuffix?: string; // Суффикс для текста кнопки во время таймаута (`${suffix}XX сек`).
    timeoutClassName?: string;
    timeout?: number; // Время таймаута (сек) после успешного нажатия ${clicksLimit} раз
    pauseAfterSuccess?: number; // Длительность паузы (сек) для уведомления пользователя об успешном нажатии.
    clicksLimit?: number; // Количество нажатий кнопки после которого происходит таймаут.
    handleError?: (e: any) => void;
    timeoutOnStart?: boolean;
};

type State = {
    buttonLabel: string;
    time: number;
    pressCount: number;
    loading: boolean;
    disabled: boolean;
    sent: boolean;
    isInTimeout: boolean;
};

const initialState = {
    time: 0,
    pressCount: 0,
    loading: false,
    disabled: false,
    sent: false,
    isInTimeout: false,
};

const DEFAULT_BUTTON_LABEL_SENT = 'Отправлено!';
const DEFAULT_PAUSE_AFTER_SUCCESS = 5; // in seconds
const DEFAULT_TIMEOUT = 30; // in seconds

const ButtonWithTimeout = ({
    label = '',
    labelSent = DEFAULT_BUTTON_LABEL_SENT,
    timeoutSuffix = '',
    className = '',
    timeoutClassName = styles.buttonTimeout,
    onClick,
    handleError,
    dataTestId = 'button-with-timeout',
    icon,
    timeout = DEFAULT_TIMEOUT,
    pauseAfterSuccess = DEFAULT_PAUSE_AFTER_SUCCESS,
    clicksLimit,
    timeoutOnStart = false,
    ...restProps
}: IButtonWithTimeoutProps) => {
    const [{ loading, buttonLabel, disabled, time, sent, isInTimeout }, setState] = useState<State>({
        ...initialState,
        buttonLabel: label,
    });

    useEffect(() => {
        let timeout: number;
        if (time > 0) {
            timeout = window.setTimeout(() => {
                setState((prevState) => ({
                    ...prevState,
                    buttonLabel: `${timeoutSuffix}${prevState.time - 1} сек`,
                    time: prevState.time - 1,
                }));
            }, 1000);
        } else {
            setState((prevState) => ({
                ...prevState,
                buttonLabel: label,
                disabled: false,
                isInTimeout: false,
            }));
        }
        return () => window.clearTimeout(timeout);
    }, [time, timeoutSuffix, label]);

    useEffect(() => {
        let isSubscribed = true;
        sent &&
            (async () => {
                if (pauseAfterSuccess > 0) {
                    isSubscribed &&
                        setState((prevState) => ({
                            ...prevState,
                            buttonLabel: labelSent,
                            loading: false,
                        }));
                    isSubscribed && (await sleep(pauseAfterSuccess));
                }
                isSubscribed &&
                    setState((prevState) => ({
                        ...prevState,
                        loading: false,
                        pressCount: prevState.pressCount + 1,
                        sent: false,
                        ...(clicksLimit && (prevState.pressCount + 1) % clicksLimit === 0
                            ? {
                                  buttonLabel: `${timeoutSuffix}${timeout} сек`,
                                  time: timeout,
                                  isInTimeout: true,
                              }
                            : {
                                  disabled: false,
                                  buttonLabel: label,
                              }),
                    }));
            })();
        return () => {
            isSubscribed = false;
        };
    }, [sent, label, labelSent, pauseAfterSuccess, clicksLimit, timeoutSuffix, timeout]);

    useEffect(() => {
        if (timeoutOnStart) {
            setState((prevState) => ({ ...prevState, sent: true, disabled: true }));
        }
    }, [timeoutOnStart]);

    const onButtonClick = useCallback(
        async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            try {
                setState((prevState) => ({
                    ...prevState,
                    sent: true,
                    disabled: true,
                    loading: true,
                }));
                await onClick?.(event);
            } catch (e) {
                handleError?.(e);
            }
        },
        [onClick, handleError]
    );

    return (
        <Button
            {...restProps}
            label={buttonLabel}
            className={cn(styles.wrapper, className, { [timeoutClassName]: isInTimeout })}
            onClick={onButtonClick}
            dataTestId={dataTestId}
            disabled={disabled || restProps.disabled}
            loading={loading || restProps.loading}
            icon={!disabled ? icon : ''}
        />
    );
};

export default ButtonWithTimeout;
