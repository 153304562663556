import type { ClientAppCodeListResponse, IBaseResponse } from '../types';
import { authenticatedRequest, publicRequest } from '../axios';

export enum LoginType {
    SBER_REGISTRY = 'SBER_REGISTRY',
    DIRECT_LINK = 'DIRECT_LINK',
    GIFT_LINK = 'GIFT_LINK',
    TEAM_ID = 'TEAM_ID',
}

export enum Authority {
    User = 'User',
    UserGift = 'UserGift',
    ReferralLink = 'ReferralLink',
}

export type AuthResponse = {
    authority: Authority;
    loginType: LoginType;
    personalNumber: string;
};

type ILoginGiftResponse = AuthResponse & {
    campaignId: string;
    appCode: string;
};

export type AuthError = IBaseResponse & {
    personalNumber?: string;
};

export type User = {
    personalNumber: string;
    role: Authority;
    loginType: LoginType;
};

export type UserOptions = {
    resource?: string;
    actionId?: string;
};

export type UserCurrent = {
    user: User;
    options?: UserOptions;
};

export type UserInfo = User & UserOptions;

export function updateTokenLifetime() {
    return authenticatedRequest.post<void>('/user/token/update');
}

export async function getUser() {
    const { data } = await publicRequest.get<UserCurrent>('/user/current');

    return data;
}

export async function getApps() {
    const { data } = await publicRequest.get<ClientAppCodeListResponse>('/user/availableApps');

    return data.clientApplicationCodeList;
}

export type ExtraInfo = {
    sellingRoleId?: number;
    salePointId?: number;
} & UserOptions;

export type LoginRequestData = {
    authData: string;
    extraInfo?: ExtraInfo;
};

export async function jwtLogin(loginRequestData: LoginRequestData) {
    const { data } = await publicRequest.post<AuthResponse>('/login/jwt', loginRequestData);

    return data;
}

export async function qrLogin(loginRequestData: LoginRequestData) {
    const { data } = await publicRequest.post<AuthResponse>('/login/qr', loginRequestData);

    return data;
}

export async function loginGift(loginRequestData: LoginRequestData) {
    const { data } = await publicRequest.post<ILoginGiftResponse>('/login/gift', loginRequestData);

    return data;
}
