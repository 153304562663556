import React from 'react';
import { useFormState } from 'react-hook-form';

import type { ButtonProps } from '../Button';
import Button from '../Button';

const SubmitButton = (props: ButtonProps) => {
    const { isSubmitting } = useFormState();

    return (
        <Button
            dataTestId="form-submit-button"
            type="contained"
            {...props}
            htmlType="submit"
            loading={isSubmitting || props.loading}
        />
    );
};

export default SubmitButton;
