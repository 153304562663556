import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMount } from 'react-use';

import type { IAppSettings } from '../../api/services/settingService';
import { useAppSettings } from '../AppSettings';
import { EXTERNAL_BUNDLE_ID, EXTERNAL_ID } from '../../constants/queryParams';
import { getAllPromoCampaigns } from '../../api/services/promoCampaignService';
import { getBundleList } from '../../api/services/bundleService';
import { IntegrationPointTypes } from '../../constants/integrationPointTypes';
import {
    generateAppPath,
    generateBundlePath,
    generateBundlesPath,
    generateMixBundlePath,
    generateMixPath,
    generateMixProductPath,
    generatePresentsPath,
    generateProductPath,
    generateProductsPath,
    generateSapsanPath,
    generateSapsanProductPath,
} from '../../utils/generatePathHelper';
import PromoCampaignTypes from '../../constants/promoCampaignTypes';
import { PAGES } from '../../constants/route';
import { useClickPromoCampaignOffer } from '../../hooks/useClickPromoCampaignOffer';
import { promoCampaignDtoToPromoCampaign } from '../../utils/mapper';

type CheckExternalId = {
    integrationPoint: IntegrationPointTypes;
    externalId: string | null;
};

async function checkCampaignExternalId({ integrationPoint, externalId }: CheckExternalId) {
    if (!externalId) {
        return null;
    }

    try {
        const promoCampaignDtoList = await getAllPromoCampaigns({
            integrationPoint,
            externalId,
        });

        return promoCampaignDtoList[0] || null;
    } catch (e) {
        return null;
    }
}

async function checkBundleExternalId({ integrationPoint, externalId }: CheckExternalId) {
    if (!externalId) {
        return null;
    }

    try {
        const groups = await getBundleList({ integrationPoint, externalId });

        return groups[0] || null;
    } catch (e) {
        return null;
    }
}

export const getPath = async (
    appSettings: IAppSettings,
    externalProductId: string | null,
    externalBundleId: string | null
): Promise<{ from: string | null; to: string }> => {
    const { hasMixMechanics, hasBundleMechanics, hasProductMechanics, hasPresentsMechanics, appCode } = appSettings;
    const productsPath = generateProductsPath(appCode);
    const mixPath = generateMixPath(appCode);
    const presentsPath = generatePresentsPath(appCode);
    const bundlesPath = generateBundlesPath(appCode);
    const appPath = generateAppPath(appCode);

    if (hasProductMechanics || hasMixMechanics) {
        const promoCampaignDto = await checkCampaignExternalId({
            integrationPoint: hasMixMechanics ? IntegrationPointTypes.MIX : IntegrationPointTypes.PRODUCTS,
            externalId: externalProductId,
        });

        if (promoCampaignDto) {
            const promoCampaign = promoCampaignDtoToPromoCampaign(promoCampaignDto, appSettings);
            const pageRoute = promoCampaign.landingBypassEnabled ? `/${PAGES.SUCCESS}` : '';
            const mixProductPath = `${generateMixProductPath(appCode, promoCampaign.id)}${pageRoute}`;
            const productPath = `${generateProductPath(appCode, promoCampaign.id)}${pageRoute}`;

            return hasMixMechanics
                ? {
                      from: mixProductPath,
                      to: mixProductPath,
                  }
                : {
                      from: productsPath,
                      to: productPath,
                  };
        }
    }

    if (hasBundleMechanics || hasMixMechanics) {
        const bundle = await checkBundleExternalId({
            integrationPoint: hasMixMechanics ? IntegrationPointTypes.MIX : IntegrationPointTypes.BUNDLE,
            externalId: externalBundleId,
        });

        if (bundle) {
            const mixBundlePath = generateMixBundlePath(appCode, bundle.id);
            const bundlePath = generateBundlePath(appCode, bundle.id);

            return hasMixMechanics
                ? {
                      from: mixBundlePath,
                      to: mixBundlePath,
                  }
                : {
                      from: bundlePath,
                      to: bundlePath,
                  };
        }
    }

    switch (true) {
        case hasMixMechanics:
            return {
                from: mixPath,
                to: mixPath,
            };
        case hasProductMechanics:
            return {
                from: productsPath,
                to: productsPath,
            };
        case hasPresentsMechanics:
            return {
                from: null,
                to: presentsPath,
            };
        case hasBundleMechanics:
            return {
                from: bundlesPath,
                to: bundlesPath,
            };
        default:
            return { from: appPath, to: appPath };
    }
};

export const QRRedirect = () => {
    const appSettings = useAppSettings();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const externalProductId = searchParams.get(EXTERNAL_ID);
    const externalBundleId = searchParams.get(EXTERNAL_BUNDLE_ID);
    const { onClickPromoCampaignOffer } = useClickPromoCampaignOffer();

    useMount(() => {
        const redirect = async () => {
            const { to } = await getPath(appSettings, externalProductId, externalBundleId);

            navigate(to, { replace: true });
        };

        const redirectToSapsan = async () => {
            const promoCampaignDto = await checkCampaignExternalId({
                integrationPoint: IntegrationPointTypes.SAPSAN,
                externalId: externalProductId,
            });

            if (!promoCampaignDto) {
                navigate(generateSapsanPath(appSettings.appCode), { replace: true });
                return;
            }

            const promoCampaign = promoCampaignDtoToPromoCampaign(promoCampaignDto, appSettings);

            if (
                promoCampaign.showLanding &&
                !promoCampaign.landingBypassEnabled &&
                promoCampaign.type === PromoCampaignTypes.PRODUCT
            ) {
                navigate(generateSapsanProductPath(appSettings.appCode, promoCampaign.id), { replace: true });
                return;
            }

            onClickPromoCampaignOffer({
                promoCampaign,
                goToOffer: () =>
                    navigate(`${generateSapsanProductPath(appSettings.appCode, promoCampaign.id)}/${PAGES.SUCCESS}`, {
                        replace: true,
                    }),
                target: '_self',
            });
        };

        if (!appSettings.hasSapsanMechanics) {
            void redirect();
        } else {
            void redirectToSapsan();
        }
    });

    return null;
};
