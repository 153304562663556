import React from 'react';
import cn from 'classnames';

import Button from '../Button';
import DotsLoader from '../DotsLoader';
import { RequestStatusTypes } from '../../constants/RequestStatusTypes';
import type { ErrorScreenProps } from './types';

import catLoaderImg from '../../static/images/cat-loader.png';
import catNotFound from '../../static/images/cat-not-found.png';
import catError from '../../static/images/cat-error.png';
import catEmpty from '../../static/images/cat-empty.png';

import styles from './CatLayout.module.scss';

const images = {
    [RequestStatusTypes.LOADING]: catLoaderImg,
    [RequestStatusTypes.NOT_FOUND]: catNotFound,
    [RequestStatusTypes.EMPTY]: catEmpty,
    [RequestStatusTypes.ERROR]: catError,
};

const LABEL = 'Продолжить';

const CatLayoutBody = ({
    type,
    title,
    description,
    requestId,
    onClick,
    label = LABEL,
    contentClassName,
}: ErrorScreenProps) => (
    <>
        <div className={cn(styles.content, contentClassName)}>
            <div className={styles.imgContainer}>
                {type === RequestStatusTypes.LOADING && (
                    <div className={styles.loader}>
                        <span className={styles.loaderText}>Загружаем</span>
                        <div className={styles.loaderDots}>
                            <DotsLoader color="black" />
                        </div>
                    </div>
                )}
                <img className={styles.img} alt="" src={images[type]} />
            </div>
            {title && <p className={styles.title}>{title}</p>}
            {description && <p className={styles.description}>{description}</p>}
            {requestId && <p className={styles.info}>ID запроса: {requestId}</p>}
            {onClick && (
                <Button
                    size="x-large"
                    onClick={onClick}
                    label={label}
                    type="contained"
                    className={styles.button}
                    dataTestId="button-submit"
                />
            )}
        </div>
    </>
);

export default CatLayoutBody;
