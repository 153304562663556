import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useErrorHandler } from 'react-error-boundary';
import { flushSync } from 'react-dom';

import { ModalCtx } from '../../../reducer/modal';
import { CLOSE_MODAL_ACTION } from '../../../constants/modal';
import { getNonBreakingString, getPluralWord } from '../../../utils/stringHelper';
import { useClient } from '../../Client';
import { useLogoutClient, useAnalyticsQueue } from '../../../hooks';
import useDevices from '../../../hooks/useDevices';
import type { ButtonSizes } from '../../Button';
import Button from '../../Button';
import { ReactComponent as AttentionIcon } from '../../../static/svgs/attention.svg';
import { useAppSettings } from '../../AppSettings';
import { generateProductsPath } from '../../../utils/generatePathHelper';
import { useUser } from '../../User';
import { LoginType } from '../../../api/services/auth';
import { TECHNICAL_PERSONAL_NUMBER } from '../../../constants/common';
import { REFERRAL_CLIENT_ERROR } from '../../../constants/ErrorLabels';
import { getNumbers } from '../../../utils/helper';

import styles from './InactivityPopUp.module.scss';

export const DEFAULT_TIMER = 10;
export const BUTTON_CONTINUE_LABEL = 'Продолжить';
export const BUTTON_QUIT_LABEL = 'Завершить';
export const DATA_TEST_ID_INACTIVITY_MODAL = 'inactivity-popup';
export const DATA_TEST_ID_BTN_CONTINUE = 'inactivity-continue-button';
export const DATA_TEST_ID_BTN_QUIT = 'inactivity-close-button';

const InactivityPopUp = () => {
    const handleError = useErrorHandler();
    const navigate = useNavigate();
    const [time, setTime] = useState(DEFAULT_TIMER);
    const { dispatch } = useContext(ModalCtx);
    const { loginType } = useUser();
    const { client, removeClient } = useClient();
    const { appCode } = useAppSettings();
    const { phoneNumber = '' } = client || {};
    const { mutate: logoutClient } = useLogoutClient(() => {
        if (loginType === LoginType.GIFT_LINK) {
            handleError({ message: REFERRAL_CLIENT_ERROR });
            return;
        }

        dispatch({ type: CLOSE_MODAL_ACTION });

        flushSync(() => {
            removeClient();
            // Todo: исправить для перс подбора
            navigate(generateProductsPath(appCode));
        });
    });
    const { pushMetrics } = useAnalyticsQueue();
    const { isMobile } = useDevices();

    const buttonSize: ButtonSizes = !isMobile ? 'x-large' : 'large';

    useEffect(() => {
        pushMetrics('INACTIVITY_SHOW');
    }, []);

    useEffect(() => {
        let timeout: number;
        if (time > 0) {
            timeout = window.setTimeout(() => setTime(time - 1), 1000);
        } else {
            resetClientSession();
        }
        return () => window.clearTimeout(timeout);
    }, [time]);

    const onContinueClick = () => {
        pushMetrics('INACTIVITY_NEXT_CLICK');
        dispatch({ type: CLOSE_MODAL_ACTION });
    };

    const onCloseClick = () => resetClientSession();

    const resetClientSession = () => {
        pushMetrics('INACTIVITY_EXIT_CLICK');
        logoutClient();
    };

    return (
        <div className={styles.content} data-test-id={DATA_TEST_ID_INACTIVITY_MODAL}>
            <div>
                <div className={styles.iconWrapper}>
                    <AttentionIcon />
                </div>
                <div className={styles.header}>{createHeader(time)}</div>
            </div>
            <div className={styles.message}>
                {createMessage(getNumbers(phoneNumber) !== TECHNICAL_PERSONAL_NUMBER ? phoneNumber : '')}
            </div>
            <Button
                label={BUTTON_CONTINUE_LABEL}
                type="contained"
                size={buttonSize}
                onClick={onContinueClick}
                dataTestId={DATA_TEST_ID_BTN_CONTINUE}
                fullWidth
            />
            <Button
                label={BUTTON_QUIT_LABEL}
                type="outline"
                size={buttonSize}
                onClick={onCloseClick}
                dataTestId={DATA_TEST_ID_BTN_QUIT}
                fullWidth
            />
        </div>
    );
};

export function createHeader(time: number) {
    const word = getPluralWord(' секунд', time, 'у', 'ы', '');
    return `Сессия клиента истекает через ${time}${word}`;
}

function createMessage(phoneNumber: string) {
    const val = phoneNumber ? ` ${getNonBreakingString(phoneNumber)}` : '';

    return `Завершить сессию клиента${val} или продолжить?`;
}

export default InactivityPopUp;
