import React from 'react';
import Skeleton from 'react-loading-skeleton';
import cn from 'classnames';

import type { PromoCodeProps } from './types';

import baseStyles from '../TextJaw/TextJaw.module.scss';
import styles from './PromoCode.module.scss';

const PromoCodeSkeleton = ({ dataTestId = 'promo-code' }: Pick<PromoCodeProps, 'dataTestId'>) => (
    <Skeleton
        containerClassName={cn(baseStyles.textWrapper, styles.promoCodeWrapper, styles.skeleton)}
        className={cn(baseStyles.text, styles.promoCode)}
        containerTestId={`${dataTestId}-skeleton`}
    />
);

export default PromoCodeSkeleton;
