import type { ReactNode } from 'react';
import React from 'react';
import cn from 'classnames';

import { useAppTheme } from '../../hooks';
import useDevices from '../../hooks/useDevices';

import styles from './AppThemeDecorator.module.scss';

/**
 *  Все возможные темы должны быть перечислены в enum Themes.
 *  При этом для каждой темы должен быть создан отдельный класс в AppThemeDecorator.module.scss,
 *  в котором должно быть указано соответствующее теме свойство "background-image: linear-gradient()".
 *  Имя класса задается по стандартным правилам - ".victory-day" для темы "victoryDay".
 *  Для мобильных устройств можно задать свой градиент, для этого к имени класса для темы нужно добавить "-mobile".
 *  Также для каждой темы должно быть добавлено изображение для Главной страницы, которое должно быть
 *  размещено в папке "/public/themes/${theme}/" (подробнее в файле AppThemes.ts).
 */

type Props = {
    children?: ReactNode;
};

const AppThemeDecorator = ({ children }: Props) => {
    const appTheme = useAppTheme();
    const { isDesktop } = useDevices();

    return (
        <>
            <div
                className={cn(styles.background, { [styles[appTheme]]: !isDesktop })}
                data-test-id="theme-background"
            />
            {children}
        </>
    );
};

export default AppThemeDecorator;
