import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import axios from 'axios';

async function init() {
    const { data } = await axios.get<Omit<typeof window.__app_env__, 'MIDDLEWARE_URL'>>(
        window.__app_env__.MIDDLEWARE_URL
    );

    window.__app_env__ = {
        ...window.__app_env__,
        ...data,
    };

    import(
        /* webpackMode: "eager" */
        './InitialApp'
    );
}

void init();
