export function getPluralWord(word: string, number: number, one: string, two: string, five: string) {
    let suffix = '';
    number %= 100;
    if (number >= 5 && number <= 20) {
        suffix = five;
    } else {
        number %= 10;
        if (number === 1) {
            suffix = one;
        } else {
            if (number >= 2 && number <= 4) {
                suffix = two;
            } else {
                suffix = five;
            }
        }
    }
    return word + suffix;
}

export function getCorrectString(text?: string) {
    return text ? text.replaceAll(' ₽', ' ₽') : text;
}

export function getNonBreakingString(text: string) {
    // Replace a space with a non-breaking space and a hyphen with a non-breaking hyphen (U+2011) in text
    return text.replace(/ /g, ' ').replace(/-/g, '‑');
}

type GetTextFullWidth = {
    text: string;
    fontSize: number;
    fontFamily: string;
    fontWeight?: number;
};

export function getTextFullWidth({ text, fontSize, fontFamily, fontWeight = 400 }: GetTextFullWidth) {
    const cnv = document.createElement('canvas');
    cnv.width = 1024;
    const ctx = cnv.getContext('2d');

    if (!ctx) {
        return 0;
    }

    ctx.font = `${fontWeight >= 700 ? 'bold ' : ''}${fontSize}px ${fontFamily}`;
    const fullWidth = ctx.measureText(text).width;
    cnv.remove();

    return fullWidth;
}
