import React from 'react';
import { Route, Routes, useResolvedPath, useNavigate } from 'react-router-dom';

import Products from './Products';
import { ProductRouter } from '../Product';
import { PAGES, PARAMS } from '../../constants/route';

const ProductsRouter = () => {
    const navigate = useNavigate();
    const { pathname } = useResolvedPath('.');

    const goToProduct = (promoCampaignId: string) => {
        navigate(promoCampaignId);
    };

    const goToOffer = (promoCampaignId: string) => {
        navigate(`${promoCampaignId}/${PAGES.SUCCESS}`);
    };

    return (
        <Routes>
            <Route path="/" element={<Products goToOffer={goToOffer} goToProduct={goToProduct} />} />
            <Route path={`${PARAMS.PRODUCT}/*`} element={<ProductRouter goBackPath={pathname} />} />
        </Routes>
    );
};

export default ProductsRouter;
