import type { IBundle, IBundleListResponse } from '../types';
import type { IntegrationPointTypes } from '../../constants/integrationPointTypes';
import { authenticatedRequest } from '../axios';

export enum BundleType {
    IDEA = 'IDEA',
}

type GetBundleListOptions = {
    integrationPoint: IntegrationPointTypes;
    clientId?: number;
    ids?: number[];
    externalId?: string;
};

export async function getBundleList({
    clientId,
    ids,
    integrationPoint,
    externalId,
}: GetBundleListOptions): Promise<IBundle[]> {
    // message возвращается только в случае 4xx ошибки
    const {
        data: { groups },
    } = await authenticatedRequest.post<IBundleListResponse>(`/campaign-group`, {
        type: BundleType.IDEA,
        clientId,
        ids,
        integrationPoint,
        externalId,
        campaignSettings: {},
    });

    return groups;
}
