import type { PropsWithChildren } from 'react';
import React, { useState, forwardRef } from 'react';

import type { BaseInputProps } from '../BaseInput';
import BaseInput from '../BaseInput';
import Dropdown from '../Dropdown';
import Line from '../Line';

import styles from './SelectV2.module.scss';

export type SelectProps = {
    options: ReadonlyArray<any>;
    value: string;
    name: string;
    onChange: (value: any) => void;
    getValue: (value: string) => string;
} & Pick<BaseInputProps, 'label' | 'error' | 'fullWidth'>;

const SelectV2 = forwardRef<HTMLSelectElement, PropsWithChildren<SelectProps>>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ value, name, label, fullWidth, getValue, options, error, onChange }, ref) => {
        const [focused, setFocused] = useState(false);

        const handleInputFocus = () => {
            setFocused(true);
        };

        return (
            <Dropdown
                renderBaseElement={() => (
                    <BaseInput
                        value={getValue(value)}
                        name={`select-${name}`}
                        onFocus={handleInputFocus}
                        focused={focused}
                        label={label}
                        error={error || (focused && !getValue(value))}
                        autoComplete="false"
                        fullWidth={fullWidth}
                        inputClassName={styles.input}
                        readOnly
                    />
                )}
                renderDropdownBlock={() => (
                    <div className={styles.itemsBlock}>
                        {options.map(({ id, name }, index) => (
                            <div key={`option-${id}`} className={styles.itemWrapper}>
                                <div
                                    className={styles.item}
                                    data-test-id={`form-control-select-${name}`}
                                    onClick={() => onChange(id)}
                                >
                                    {name}
                                </div>
                                {index < options.length - 1 && <Line color="black12" />}
                            </div>
                        ))}
                    </div>
                )}
            />
        );
    }
);

SelectV2.displayName = 'SelectV2';

export default SelectV2;
