import React, { useMemo } from 'react';
import cn from 'classnames';

import { APP_MECHANICS_VALUES } from '../../constants/appMechanics';
import { AppThemes } from '../../constants/AppThemes';
import { useAppSettings } from '../AppSettings';
import { useActiveMechanics } from '../../hooks';
import useDevices from '../../hooks/useDevices';
import { getAppThemeImage } from './utils';

import styles from './HomePageTopPanel.module.scss';

export const DATA_TEST_ID_BANNER = 'main-page-banner';
export const DATA_TEST_ID_TITLE = 'main-page-title';

const HomePageTopPanel = () => {
    const { homePageHeader, homePageHeaderPresents, homePageHeaderBundles, homePageHeaderMix, appTheme } =
        useAppSettings();
    const { isMobile } = useDevices();
    const title = getHomePageHeader(useActiveMechanics());

    function getHomePageHeader(mechanics: string): string {
        switch (mechanics) {
            case APP_MECHANICS_VALUES.PRODUCTS:
                return homePageHeader;
            case APP_MECHANICS_VALUES.PRESENTS:
                return homePageHeaderPresents;
            case APP_MECHANICS_VALUES.BUNDLES:
                return homePageHeaderBundles;
            case APP_MECHANICS_VALUES.MIX:
                return homePageHeaderMix;
            case APP_MECHANICS_VALUES.SAPSAN:
                return homePageHeader;
            default:
                return '';
        }
    }

    const appThemeImage = useMemo(() => <img src={getAppThemeImage(appTheme)} alt="AppTheme image" />, [appTheme]);

    return (
        <div className={cn(styles.panel, styles[appTheme])}>
            <div className={styles.content}>
                {[AppThemes.GREEN_DAY, AppThemes.NEW_YEAR].includes(appTheme) ? (
                    <div className={cn(styles.backImageWrapper, styles.imageOnly)} data-test-id={DATA_TEST_ID_BANNER}>
                        {appThemeImage}
                    </div>
                ) : (
                    <>
                        {title && (
                            <div className={styles.mainMessage}>
                                <p className={styles.title} data-test-id={DATA_TEST_ID_TITLE}>
                                    {title}
                                </p>
                            </div>
                        )}
                        <div
                            className={styles.backImageWrapper}
                            style={isMobile ? { display: 'none' } : {}}
                            data-test-id={DATA_TEST_ID_BANNER}
                        >
                            {appThemeImage}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default HomePageTopPanel;
