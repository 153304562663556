import { publicRequest } from '../axios';

export type SellingRole = {
    id: number;
    name: string;
};

export type SellingRoleListResponse = {
    list: SellingRole[];
};

export async function getSellingRoleList(): Promise<SellingRoleListResponse> {
    try {
        const { data } = await publicRequest.get<SellingRoleListResponse>('/selling-role/self-registration-roles');

        return data;
    } catch (e) {
        return { list: [] };
    }
}
