import React, { useMemo } from 'react';
import { Navigate, Route, Routes, useNavigate, useLocation, useParams } from 'react-router-dom';

import Product from './Product';
import SuccessPage from '../SuccessPage/SuccessPage';
import { PAGES } from '../../constants/route';

type Props = {
    goBackPath: string;
};

type Params = {
    promoCampaignId: string;
};

const ProductRouter = ({ goBackPath }: Props) => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const { promoCampaignId = '' } = useParams<Params>();

    const goToSuccessPage = () => navigate(`${PAGES.SUCCESS}${search}`);

    const goBack = () => navigate(goBackPath);

    const selectedOffers = useMemo(() => [promoCampaignId], [promoCampaignId]);

    return (
        <Routes>
            <Route
                path="/"
                element={<Product campaignId={promoCampaignId} goBack={goBack} goNext={goToSuccessPage} />}
            />
            <Route
                path={PAGES.SUCCESS}
                element={<SuccessPage selectedItems={selectedOffers} goBackPath={goBackPath} goBack={goBack} />}
            />
            <Route path="*" element={<Navigate to=".." replace />} />
        </Routes>
    );
};

export default ProductRouter;
