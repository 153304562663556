import React, { forwardRef } from 'react';
import Modal from 'react-modal';
import cn from 'classnames';

import CommonPopUpLayout from '../../PopUp/CommonPopUpLayout';
import { useConsentQuery } from '../../../hooks/services/apps';
import Loader from '../../Loader';
import type { CheckboxProps } from '../../Checkbox';
import Checkbox from '../../Checkbox';

// Todo не забыть исправить при рефакторинге модалок
import modalStyles from '../../CustomModal/CustomModal.module.scss';
import styles from './ConsentCheckbox.module.scss';

export type ConsentModalProps = {
    title: string;
    consentId: number;
    getConsentMessage: (consent: string) => string;
    onClose: () => void;
};

type ConsentCheckboxProps = {
    checkboxProps: CheckboxProps;
    consentModalProps?: ConsentModalProps;
};

const ConsentModalBody = ({ onClose, consentId, title, getConsentMessage }: ConsentModalProps) => {
    const { data, isFetching, isIdle } = useConsentQuery(consentId);

    return (
        <div className={styles.modalBody}>
            <CommonPopUpLayout title={title} onClick={onClose}>
                {isFetching || isIdle || !data ? (
                    <Loader />
                ) : (
                    <div dangerouslySetInnerHTML={{ __html: getConsentMessage(data) }} className={styles.message} />
                )}
            </CommonPopUpLayout>
        </div>
    );
};

const ConsentCheckbox = forwardRef<HTMLInputElement, ConsentCheckboxProps>(
    ({ checkboxProps, consentModalProps }, ref) => (
        <>
            <Checkbox {...checkboxProps} ref={ref} />
            {consentModalProps && (
                <Modal
                    isOpen={true}
                    className={cn(modalStyles.content, modalStyles.informationModal, styles.modal)}
                    overlayClassName={modalStyles.overlay}
                    bodyOpenClassName={modalStyles.bodyOpen}
                    ariaHideApp={false}
                    shouldFocusAfterRender={false}
                >
                    <ConsentModalBody {...consentModalProps} />
                </Modal>
            )}
        </>
    )
);

ConsentCheckbox.displayName = 'ConsentCheckbox';

export default ConsentCheckbox;
