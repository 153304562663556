enum SuccessPageModalTypes {
    NONE = 0,
    EMAIL,
    SBOL_PHONE_NUMBER_CONFIRMATION,
    ADVERTISING_AGREEMENT,
    QR,
    ERROR,
}

export default SuccessPageModalTypes;
