import { useCallback, useContext } from 'react';

import { ClientCtx } from '../component/Client';
import { AppSettingsCtx } from '../component/AppSettings';
import type { EventPushData, productTargetKeys } from '../utils/clickStream';
import { UserCtx } from '../component/User';
import useStateRef from './useStateRef';
import ClickStream from '../utils/clickStream/ClickStream';

const useAnalyticsQueue = () => {
    const clientId = useContext(ClientCtx)?.client?.id;
    const appCode = useContext(AppSettingsCtx)?.appCode;
    const user = useContext(UserCtx);

    const clientIdRef = useStateRef(clientId);
    const appCodeRef = useStateRef(appCode);
    const userRef = useStateRef(user);

    const push = useCallback((eventString: productTargetKeys, properties?: EventPushData) => {
        ClickStream.getInstance().pushMetrics({
            eventString,
            properties: {
                clientId: clientIdRef.current,
                appCode: appCodeRef.current,
                resource: userRef.current?.resource,
                actionId: userRef.current?.actionId,
                ...properties,
            },
        });
    }, []);

    return { pushMetrics: push };
};

export default useAnalyticsQueue;
