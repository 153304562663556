import type { ReactNode } from 'react';
import React, { useEffect } from 'react';

import { useSupportsCertificate } from '../../hooks';
import { CERTIFICATE_CHANGE, IS_DEV } from '../../constants/appEnvironments';
import { AppCertificateChangeTypes } from '../../constants/common';
import CertificateExpired from '../Errors/CertificateExpired';
import { IS_HTTP, SOURCE_URL } from './constants';

type Props = {
    children?: ReactNode;
};

const CertificateContainer = ({ children }: Props) => {
    const { data } = useSupportsCertificate();

    useEffect(() => {
        if (IS_HTTP && data && data.supportsCertificate && !IS_DEV) {
            const httpsUrl = new URL(SOURCE_URL);
            httpsUrl.protocol = 'https:';
            window.open(httpsUrl.toString(), '_self');
        }
    }, [data]);

    if (!data) {
        return null;
    }

    if (CERTIFICATE_CHANGE === AppCertificateChangeTypes.FORBIDDEN && !data.supportsCertificate) {
        return <CertificateExpired />;
    }

    return <>{children}</>;
};

export default CertificateContainer;
