import React from 'react';
import { useController } from 'react-hook-form';

import { emailPattern } from '../../../utils/validators';
import Input from '../../Input';

const EmailInput = () => {
    const {
        field: { onChange, ...field },
        fieldState: { invalid },
    } = useController({
        name: 'email',
        rules: {
            required: true,
            pattern: emailPattern,
        },
    });

    return (
        <Input
            placeholder="IvanovIvan@mail.ru"
            error={invalid}
            onChange={onChange}
            onClear={() => onChange('')}
            fullWidth
            autoComplete="off"
            maxLength={60}
            type="text"
            autoFocus
            {...field}
        />
    );
};

export default EmailInput;
