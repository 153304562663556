import getQueryStringFromObj from '../../utils/getQueryStringFromObj';
import { publicRequest } from '../axios';
import type { LocationItem } from './locationService';

export type SalePointQueryParams = {
    locationId?: string;
    name?: string;
};

export type SalePoint = {
    id: number;
    name: string;
    parentName: string;
    description: string;
    location: LocationItem;
};

export type SalePointListResponse = {
    list: SalePoint[];
};

export async function getSalePointsByText(queryParams = {} as SalePointQueryParams): Promise<SalePointListResponse> {
    try {
        const { data } = await publicRequest.get<SalePointListResponse>(
            `/salepoint/search?${getQueryStringFromObj(queryParams)}`
        );

        return data;
    } catch (e) {
        return { list: [] };
    }
}
