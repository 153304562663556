import { useContext } from 'react';
import ClientCtx from './ClientCtx';

const useClient = () => {
    const clientAuthCtx = useContext(ClientCtx);

    if (typeof clientAuthCtx === 'undefined') {
        throw Error(`Use useClientAuth() inside <ProvideClientAuth>`);
    }

    return clientAuthCtx;
};

export default useClient;
