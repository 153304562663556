import type { ReactNode } from 'react';
import React, { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { updateTokenLifetime } from '../../api/services/auth';
import { TOKEN_REFRESH_TIME } from '../../constants/common';

type RefreshToken = {
    start?: () => void;
    pause?: () => void;
};

const RefreshTokenTimerCtx = createContext<RefreshToken>({} as RefreshToken);

export const useRefreshTokenTimer = () => useContext(RefreshTokenTimerCtx);

type Props = {
    children?: ReactNode;
};

export const RefreshTokenTimerProvider = ({ children }: Props) => {
    const isStart = useRef(false);
    const { start, pause } = useIdleTimer({
        onAction: () => {
            void updateTokenLifetime();
        },
        startOnMount: false,
        startManually: true,
        throttle: TOKEN_REFRESH_TIME,
    });

    const _start = useCallback(() => {
        if (!isStart.current) {
            isStart.current = true;
            start();
        }
    }, [start]);

    const _pause = useCallback(() => {
        if (isStart.current) {
            isStart.current = false;
            pause();
        }
    }, [pause]);

    const value = useMemo(
        () => ({
            start: _start,
            pause: _pause,
        }),
        [_start, _pause]
    );

    return <RefreshTokenTimerCtx.Provider value={value}>{children}</RefreshTokenTimerCtx.Provider>;
};
