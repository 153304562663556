import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import cssVars from 'css-vars-ponyfill';
import { changeScalingFactor, ieDetector } from './utils/helper';
import { config } from './constants/cssVarsConfig';
import App from './App';
import { createQueryClient } from './api/react-query';
import 'react-loading-skeleton/dist/skeleton.css';
import '@sberid/js-sdk/dist/styles/common.css';
import './styles/global.scss';

if (ieDetector()) {
    cssVars(config);
}

changeScalingFactor();

const queryClient = createQueryClient();

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
