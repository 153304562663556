import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ROUTE } from '../../constants/route';
import { APP_CODE, JWT_TOKEN } from '../../constants/queryParams';
import JWTLoginForm from '../../containers/JWTLoginForm/JWTLoginForm';
import { useMount } from 'react-use';
import { useJwtLogin } from '../../hooks/services/user';
import { ERROR_STATUS_CODE } from '../../constants/common';
import { generateAppPath } from '../../utils/generatePathHelper';

const JWTLogin = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const jwtToken = searchParams.get(JWT_TOKEN) || '';
    const appCode = searchParams.get(APP_CODE) || '';
    const { mutate: jwtLogin, error } = useJwtLogin();

    const goToApp = () => navigate(appCode ? generateAppPath(appCode) : ROUTE.APPS, { replace: true });

    useMount(() => {
        jwtLogin(
            { authData: jwtToken },
            {
                onSuccess: () => {
                    goToApp();
                },
            }
        );
    });

    if (error && error.statusCode === ERROR_STATUS_CODE.UNPROCESSABLE_ENTITY && error.personalNumber) {
        return <JWTLoginForm token={jwtToken} personalNumber={error.personalNumber} goToApp={goToApp} />;
    }

    return null;
};

export default JWTLogin;
