import { useMedia } from 'react-use';
import { MIN_DESKTOP_WIDTH, MIN_TABLET_WIDTH } from '../constants/breakpoints';

export default function useDevices() {
    const isMobile = useMedia(`(max-width: ${MIN_TABLET_WIDTH - 1}px)`);
    const isTablet = useMedia(`(min-width: ${MIN_TABLET_WIDTH}px) and (max-width: ${MIN_DESKTOP_WIDTH - 1}px)`);
    const isDesktop = useMedia(`(min-width: ${MIN_DESKTOP_WIDTH}px)`);

    return { isMobile, isTablet, isDesktop };
}
