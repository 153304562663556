import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMount } from 'react-use';
import { useErrorHandler } from 'react-error-boundary';

import { ACTION_ID, APP_CODE, PARTNER_NO, RESOURCE } from '../../constants/queryParams';
import { ROUTE } from '../../constants/route';
import { LABELS } from '../../constants/labels';
import { useQrLogin } from '../../hooks/services/user';
import { generateQrRedirectPath } from '../../utils/generatePathHelper';

const QRAuth = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { mutate: qrLogin } = useQrLogin();
    const handleError = useErrorHandler();
    const appCode = searchParams.get(APP_CODE) || '';
    const partnerNo = searchParams.get(PARTNER_NO) || '';
    const resource = searchParams.get(RESOURCE) || undefined;
    const actionId = searchParams.get(ACTION_ID) || undefined;

    useMount(() => {
        if (!appCode || !partnerNo) {
            navigate(ROUTE.APPS, { replace: true });
            return;
        }

        if (!/^[a-zA-Z_-]+$/.test(appCode)) {
            handleError({ message: LABELS.ERRORS.COMMON });
            return;
        }

        qrLogin(
            {
                authData: partnerNo,
                extraInfo: { resource, actionId },
            },
            {
                onSuccess: () => {
                    [APP_CODE, PARTNER_NO, RESOURCE, ACTION_ID].forEach((el) => searchParams.delete(el));

                    navigate(
                        {
                            pathname: generateQrRedirectPath(appCode),
                            search: `${searchParams}`,
                        },
                        { replace: true }
                    );
                },
            }
        );
    });

    return null;
};

export default QRAuth;
