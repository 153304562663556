import { useMutation, useQueryClient } from 'react-query';

import { logoutClient, registerClient } from '../../../api/services/clientService';
import ConsentsTypes from '../../../constants/ConsentsTypes';
import { useClient } from '../../../component/Client';
import type { RegisterClientRequest, RegisterClientResponse } from '../../../api/services/clientService/types';
import type { IBaseResponse } from '../../../api/types';
import { useAnalyticsQueue } from '../../../hooks';
import type { productTargetKeys } from '../../../utils/clickStream';
import { formatPhoneNumber } from '../../../utils/phoneNumberHelper';
import { getNumbers } from '../../../utils/helper';
import type {
    GetSberIdClientInfoOptions,
    SberIdClientInfoError,
    SberIdClientInfoSuccess,
} from '../../../api/services/sberIdService';
import { getSberIdClientInfo } from '../../../api/services/sberIdService';
import { useAppSettings } from '../../../component/AppSettings';
import { TECHNICAL_PERSONAL_NUMBER } from '../../../constants/common';
import { ClientIdentificationTypes } from '../../../constants/ClientIdentificationTypes';

const { PHONE, SBER_ID, TECHNICAL } = ClientIdentificationTypes;

export const useLoginClient = (eventAction: productTargetKeys) => {
    const { client, setClient } = useClient();
    const { pushMetrics } = useAnalyticsQueue();
    const { appCode } = useAppSettings();

    return useMutation<RegisterClientResponse, IBaseResponse, RegisterClientRequest>({
        mutationFn: (args) => registerClient(appCode, args),
        onSuccess: (
            { clientId, signedConsents = [], email = '' },
            { state, phoneNumber, consentId, smmrConsentId }
        ) => {
            const identificationType: ClientIdentificationTypes =
                phoneNumber === TECHNICAL_PERSONAL_NUMBER ? TECHNICAL : state ? SBER_ID : PHONE;
            const isSberIdAuth = identificationType === SBER_ID;
            const isTechnical = identificationType === TECHNICAL;

            setClient({
                id: clientId,
                phoneNumber: isTechnical ? phoneNumber : formatPhoneNumber(getNumbers(phoneNumber)),
                email,
                identificationType,
                isTechnical,
                isSberIdAuth,
                isPhoneNumberConfirmed: isSberIdAuth,
                isEmailConfirmed: email.includes('*'),
                // TODO: убрать заглушку для agreeWithAdvertising ("|| true"), когда потребуется согласие на рассылку
                agreeWithAdvertising: signedConsents.includes(ConsentsTypes.ADVERTISING) || true,
            });

            pushMetrics(eventAction, { clientId, consentId, smmrConsentId, reauthorization: `${!!client}` });
        },
    });
};

export const useLogoutClient = (onSettled?: () => void) => {
    const { client, removeClient } = useClient();
    const { appCode } = useAppSettings();
    const queryClient = useQueryClient();

    return useMutation<void, IBaseResponse>({
        mutationFn: async () => {
            if (client?.id) {
                await logoutClient(appCode, client.id);
            }
        },
        onSettled: () => {
            if (onSettled) {
                onSettled();
            } else {
                removeClient();
            }

            queryClient.removeQueries({ queryKey: ['offers'] });
        },
    });
};

export const useGetSberIdClientInfo = (useErrorBoundary?: ((error: SberIdClientInfoError) => boolean) | boolean) =>
    useMutation<SberIdClientInfoSuccess, SberIdClientInfoError, GetSberIdClientInfoOptions>({
        mutationFn: getSberIdClientInfo,
        useErrorBoundary,
    });
