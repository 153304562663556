import type { ReactNode } from 'react';
import React from 'react';

import type { IPromoCampaign } from '../../../api/types';
import SuccessPageCardHeaderBlock from '../SuccessPageCardHeaderBlock';

import styles from './SuccessPageCardLayout.module.scss';

type Props = {
    promoCampaign?: IPromoCampaign;
    children?: ReactNode;
};

const SuccessPageCardLayout = ({ promoCampaign, children }: Props) => (
    <div className={styles.card}>
        {promoCampaign && <SuccessPageCardHeaderBlock promoCampaign={promoCampaign} />}
        <div className={styles.body}>{children}</div>
    </div>
);

export default SuccessPageCardLayout;
