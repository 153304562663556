import type { IBundleLink } from '../../api/types';
import { convertArrayToRecordByType } from '../../utils/mapper';
import { getCampaignBanner } from '../../utils/promoCampaignHelper';
import promoBannersTypes from '../../constants/bannerTypes';
import { CampaignSettings } from '../../constants/CampaignSettings';

const { LOGO_ICON } = promoBannersTypes;

export const getIconUrls = (links: IBundleLink[]): string[] =>
    links
        .filter((link) => link.settings?.[CampaignSettings.DISPLAY_LOGO_ON_BUNDLE])
        .map((link) => getCampaignBanner(convertArrayToRecordByType(link.banners), LOGO_ICON))
        .filter((logoIcon) => !!logoIcon)
        .filter((_, index) => index <= 3);
