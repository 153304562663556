import React, { useEffect } from 'react';

import { useAnalyticsQueue } from '../../../hooks';
import SuccessPageModalBody from '../../SuccessPageModalBody';
import type { ButtonSizes } from '../../Button';
import Button from '../../Button';
import { ReactComponent as SuccessIcon } from '../../../static/svgs/apply.svg';

type Props = {
    onClick: () => void;
    buttonSize: ButtonSizes;
};

export const SUCCESS_MESSAGE = 'Предложение отправлено';

const SuccessModalBody = ({ onClick, buttonSize }: Props) => {
    const { pushMetrics } = useAnalyticsQueue();

    useEffect(() => {
        pushMetrics('SUCCESS_SEND_OFFER_EMAIL_SHOW');
    }, []);

    return (
        <SuccessPageModalBody
            renderTopBlock={() => <SuccessIcon />}
            header={SUCCESS_MESSAGE}
            dataTestId="successful-email-sending-modal"
            renderButtons={() => (
                <Button
                    label="Продолжить"
                    onClick={onClick}
                    type="contained"
                    size={buttonSize}
                    dataTestId="email-success-continue-button"
                />
            )}
        />
    );
};

export default SuccessModalBody;
