import { useClient } from '../component/Client';
import { useLoginClient } from './services/client';
import type { IPromoCampaign } from '../api/types';
import { ClientIdentificationTypes } from '../constants/ClientIdentificationTypes';
import { TECHNICAL_PERSONAL_NUMBER } from '../constants/common';
import { IntegrationPointTypes } from '../constants/integrationPointTypes';
import { useAddOffers } from './services/offer/offers';
import { moveToExternalResource } from '../utils/helper';
import { USED_WITH_VALUE } from '../constants/queryParams';
import PromoCodeTypes from '../constants/promoCodeTypes';

export const useClickPromoCampaignOffer = () => {
    const { client } = useClient();
    const { mutateAsync: loginClient, isLoading: isLoginClientLoading } = useLoginClient(
        'IDENTIFICATION_INSTALLATION_GET_CLICK'
    );
    const { mutateAsync: addOffers, isLoading: isAddOffersLoading } = useAddOffers();
    const isLoading = isLoginClientLoading || isAddOffersLoading;

    const onClick = async ({
        promoCampaign: { clientIdentificationTypes, id, promoCodeType },
        goToOffer,
        target = '_blank',
    }: {
        promoCampaign: IPromoCampaign;
        goToOffer: () => void;
        target?: '_blank' | '_self';
    }) => {
        if (
            !clientIdentificationTypes.includes(ClientIdentificationTypes.TECHNICAL) ||
            promoCodeType !== PromoCodeTypes.NONE
        ) {
            goToOffer();
            return;
        }

        const clientId = client ? client.id : (await loginClient({ phoneNumber: TECHNICAL_PERSONAL_NUMBER })).clientId;

        const { offers } = await addOffers({
            promoCampaignIds: [id],
            integrationPoint: IntegrationPointTypes.SAPSAN,
            clientId,
        });

        const { personalUrl } = offers[id];

        moveToExternalResource({
            url: personalUrl,
            target,
            usedWithValue: USED_WITH_VALUE.BUTTON,
        });

        if (target === '_self') {
            return;
        }

        goToOffer();
    };

    return { onClickPromoCampaignOffer: onClick, isLoading };
};
