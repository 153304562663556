import { useCallback, useContext, useEffect, useState } from 'react';
import { useWindowResize } from './window-event-hooks';
import { ModalCtx } from '../reducer/modal';
import { CHANGE_FOOTER_ACTION } from '../constants/modal';

export const useFooterHeight = () => {
    const [footerHeight, setFooterHeight] = useState(0);
    const {
        dispatch,
        state: { footerChanges },
    } = useContext(ModalCtx);

    const footerChange = useCallback(() => {
        dispatch({ type: CHANGE_FOOTER_ACTION });
    }, []);

    useWindowResize(footerChange);

    useEffect(() => {
        const footer = document.getElementById('footer');

        setFooterHeight(footer ? footer.getBoundingClientRect().height : 0);
    }, [footerChanges]);

    return {
        footerChange,
        footerHeight,
    };
};
