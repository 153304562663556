import type { ReactNode } from 'react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import useDevices from '../../hooks/useDevices';
import type { ButtonSizes } from '../Button';
import SubmitButton from '../SubmitButton';
import type { IPromoCampaign } from '../../api/types';
import OfferTermsConsentField from '../OfferTermsConsentField';

import styles from './OfferTermsConsentForm.module.scss';

type FormValues = {
    agreeWithOfferTerms: boolean;
};

type Props = {
    title?: ReactNode;
    onSubmit: () => void;
    promoCampaign: IPromoCampaign;
};

const OfferTermsConsentForm = ({ onSubmit, title, promoCampaign }: Props) => {
    const { isMobile } = useDevices();
    const buttonSize: ButtonSizes = !isMobile ? 'x-large' : 'large';
    const methods = useForm<FormValues>({
        defaultValues: {
            agreeWithOfferTerms: false,
        },
        mode: 'all',
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                {title}
                <div className={styles.field}>
                    <OfferTermsConsentField promoCampaign={promoCampaign} />
                </div>
                <SubmitButton
                    label="Оформить"
                    size={buttonSize}
                    fullWidth
                    dataTestId="offer-terms-consent-form-submit-button"
                />
            </form>
        </FormProvider>
    );
};

export default OfferTermsConsentForm;
