import React, { useCallback, useMemo } from 'react';

import MainPageCard from '../../component/MainPageCard';
import Button from '../../component/Button';
import type { IBundle } from '../../api/types';
import { getCampaignBanner, getCampaignText } from '../../utils/promoCampaignHelper';
import { convertArrayToRecordByType } from '../../utils/mapper';
import promoBannersTypes from '../../constants/bannerTypes';
import promoTextTypes from '../../constants/promoTextTypes';
import { getIconUrls } from './helpers';

type Props = {
    bundle: IBundle;
    label?: string;
    onClick: (bundle: IBundle) => void;
};

const { CARD_BUNDLE } = promoBannersTypes;
const { DESCRIPTION, HEADER } = promoTextTypes;

const BundleCard = ({ bundle, onClick, label = 'Получить' }: Props) => {
    const { id, links } = bundle;
    const texts = useMemo(() => convertArrayToRecordByType(bundle.texts), [bundle.texts]);
    const banners = useMemo(() => convertArrayToRecordByType(bundle.banners), [bundle.banners]);

    const handleClick = useCallback(() => {
        onClick(bundle);
    }, [onClick, bundle]);

    return (
        <MainPageCard
            imgUrl={getCampaignBanner(banners, CARD_BUNDLE)}
            title={getCampaignText(texts, HEADER)}
            description={getCampaignText(texts, DESCRIPTION)}
            dataTestId={`bundle-card-${id}`}
            icons={getIconUrls(links)}
            renderFooter={() => (
                <Button label={label} onClick={handleClick} type="contained" dataTestId={`bundle-card-btn-${id}`} />
            )}
        />
    );
};

export default BundleCard;
