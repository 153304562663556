import React from 'react';

import type { IPromoCampaign } from '../../../api/types';
import { useAnalyticsQueue } from '../../../hooks';
import AdvertisingTag from '../../AdvertisingTag';
import DzoLogo from '../../DzoLogo';

import styles from './SuccessPageCardHeaderBlock.module.scss';

type Props = {
    promoCampaign: IPromoCampaign;
};

const SuccessPageCardHeaderBlock = ({
    promoCampaign: {
        logoUrl,
        dzoName,
        offerDescription,
        tradeName,
        creatives,
        originalId: campaignId,
        name: campaignName,
        productCampaignId,
        rules,
    },
}: Props) => {
    const { pushMetrics } = useAnalyticsQueue();
    const erid = creatives.SUCCESS_ERID;

    const onAdvertisingTagClick = () => {
        pushMetrics('ADVERTISEMENT_CLICK', {
            campaignId,
            campaignName,
            dzoName,
            productCampaignId,
            tradeName,
            erid,
        });
    };

    return (
        <div className={styles.infoBlock}>
            <AdvertisingTag tradeName={tradeName} erid={erid} onClick={onAdvertisingTagClick} rules={rules} />
            <DzoLogo logoUrl={logoUrl} dzoName={dzoName} dataTestId={`dzo-logo-${campaignId}`} />
            <div className={styles.descriptionWrapper}>
                <div className={styles.offerDescription} dangerouslySetInnerHTML={{ __html: offerDescription }} />
            </div>
        </div>
    );
};

export default SuccessPageCardHeaderBlock;
