import React from 'react';

import SuccessPageLayout from '../../component/SuccessPageLayout';
import { useActiveMechanics, useIntegrationPoint } from '../../hooks';
import { usePromoCampaignsQuery } from '../../hooks/services/promoCampaigns';
import { APP_MECHANICS_VALUES } from '../../constants/appMechanics';
import PromoCampaignTypes from '../../constants/promoCampaignTypes';
import { useAppSettings } from '../../component/AppSettings';

type Props = {
    goBackPath?: string;
    goBack: () => void;
    selectedItems: string[];
};

const SuccessPage = ({ goBack, selectedItems }: Props) => {
    const mechanics = useActiveMechanics();
    const integrationPoint = useIntegrationPoint();
    const { hasSapsanMechanics } = useAppSettings();

    const promoCampaignsQuery = usePromoCampaignsQuery({
        integrationPoint,
        campaigns: selectedItems,
        type: hasSapsanMechanics
            ? undefined
            : mechanics === APP_MECHANICS_VALUES.PRESENTS
            ? PromoCampaignTypes.PRESENT
            : PromoCampaignTypes.PRODUCT,
    });

    const promoCampaigns =
        !promoCampaignsQuery.isFetching && promoCampaignsQuery.data ? promoCampaignsQuery.data : undefined;

    return (
        <SuccessPageLayout
            promoCampaigns={promoCampaigns}
            promoCampaignIds={selectedItems}
            goBack={goBack}
            promoCampaignsError={promoCampaignsQuery.error}
        />
    );
};

export default SuccessPage;
