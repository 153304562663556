import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import type { FeedbackQuestionDto, IPromoCampaign } from '../../../api/types';
import { saveFeedback } from '../../../api/services/feedbackService';
import type { FeedbackScreenType } from '../../../constants/FeedbackScreenTypes';
import { useAnalyticsQueue, useDevices } from '../../../hooks';
import { useAppSettings } from '../../AppSettings';
import { useClient } from '../../Client';
import type { ButtonSizes } from '../../Button';
import SuccessPageModal from '../../SuccessPageModal';
import type { CampaignData, FormValues, Submit } from '../types';
import { getFeedbackEventsForScreen, normalizeFeedbackComment } from '../utils';
import FeedbackFormModalBody from './FeedbackFormModalBody';
import FeedbackSuccessModalBody from './FeedbackSuccessModalBody';

import styles from './FeedbackFormModal.module.scss';

export type FeedbackFormModalProps = {
    questionData: FeedbackQuestionDto;
    screenType: FeedbackScreenType;
    campaign?: CampaignData;
    campaignName?: IPromoCampaign['name'];
    closeModal: () => void;
    closeBanner: () => void;
};

const FeedbackFormModal = ({
    questionData: { id: feedbackQuestionId, question },
    screenType,
    campaign,
    closeModal,
    closeBanner,
}: FeedbackFormModalProps) => {
    const { pushMetrics } = useAnalyticsQueue();
    const { appCode } = useAppSettings();
    const clientId = useClient()?.client?.id;
    const { isMobile } = useDevices();
    const buttonSize: ButtonSizes = !isMobile ? 'x-large' : 'large';
    const methods = useForm<FormValues>({
        defaultValues: {
            rating: 0,
            clientFeedback: '',
        },
    });
    const { eventForSendFeedback } = getFeedbackEventsForScreen(screenType);
    const { id: campaignId, dzoName, name: campaignName, originalId, productCampaignId } = campaign || {};
    const metrics = {
        campaignId: originalId,
        campaignName,
        dzoName,
        productCampaignId,
    };
    const {
        formState: { isSubmitted },
    } = methods;

    useEffect(() => {
        if (isSubmitted) {
            pushMetrics('FEEDBACK_SUCCESS_SHOW');
        }
    }, [isSubmitted]);

    const _saveFeedback: Submit = async ({ rating, clientFeedback }) => {
        try {
            await saveFeedback(
                {
                    clientId,
                    feedbackQuestionId,
                    rating,
                    clientFeedback: normalizeFeedbackComment(clientFeedback),
                    campaignId,
                    campaignName,
                },
                appCode
            );
        } catch (e) {
            // TODO: добавить обработку ошибки
        }
    };

    const handleSubmit: Submit = ({ rating, clientFeedback }) => {
        pushMetrics(eventForSendFeedback, {
            ...metrics,
            estimation: rating,
        });

        void _saveFeedback({ rating, clientFeedback });
    };

    const onSuccessExit = () => {
        pushMetrics('FEEDBACK_SUCCESS_EXIT_BTN_CLICK');
        closeBanner();
    };

    const handleClose = () => {
        pushMetrics('FEEDBACK_MODAL_CLOSE_BTN_CLICK', metrics);
        closeModal();
    };

    return (
        <SuccessPageModal isOpen={true} closeModal={handleClose}>
            {!isSubmitted ? (
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(handleSubmit)} className={styles.form}>
                        <FeedbackFormModalBody question={question} onCancel={handleClose} buttonSize={buttonSize} />
                    </form>
                </FormProvider>
            ) : (
                <FeedbackSuccessModalBody onClick={onSuccessExit} buttonSize={buttonSize} />
            )}
        </SuccessPageModal>
    );
};

export default FeedbackFormModal;
