import type { OidcParams } from '@sberid/js-sdk';
import {
    SBER_ID_REDIRECT_URL,
    SBER_ID_SCOPE,
    SBER_ID_USER_CLIENT_ID,
    SBER_ID_REFERRAL_LINK_CLIENT_ID,
} from '../../constants/sberId';
import { authenticatedRequest, publicRequest } from '../axios';
import type { IBaseResponse } from '../types';
import { Authority } from './auth';

export type SberIdState = {
    state: string;
    nonce: string;
};

export type GetSberIdStateOptions = {
    path?: string;
};

export type GetOidcParamsOptions = GetSberIdStateOptions & {
    role: Authority;
};

export async function getSberIdState(options: GetSberIdStateOptions) {
    const { data } = await authenticatedRequest.post<SberIdState>('/sberid/state', options);

    return data;
}

export async function getOidcParams({ path, role }: GetOidcParamsOptions): Promise<OidcParams> {
    const { state, nonce } = await getSberIdState({ path });

    return {
        state,
        nonce,
        response_type: 'code',
        client_type: 'PRIVATE',
        scope: SBER_ID_SCOPE,
        client_id: role === Authority.User ? SBER_ID_USER_CLIENT_ID : SBER_ID_REFERRAL_LINK_CLIENT_ID,
        redirect_uri: SBER_ID_REDIRECT_URL,
    };
}

export type GetSberIdClientInfoOptions = {
    code: string;
    state: string;
    redirectUri: string;
};

export type SberIdUserState = {
    appCode: string;
    path: string;
};

export type SberIdClientInfoSuccess = {
    phoneNumber: string;
    user: SberIdUserState;
};

export type SberIdClientInfoError = IBaseResponse & {
    user: SberIdUserState;
};

export async function getSberIdClientInfo(options: GetSberIdClientInfoOptions) {
    const { data } = await publicRequest.post<SberIdClientInfoSuccess>('/sberid/getClientInfo', options);

    return data;
}
