import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useErrorHandler } from 'react-error-boundary';

import { ProductsRouter } from '../../containers/Products';
import { useAppSettings } from '../../component/AppSettings';
import { PresentsRouter } from '../../containers/Presents';
import { BundlesRouter } from '../../containers/Bundles';
import { MixRouter } from '../../containers/Mix';
import { PAGES } from '../../constants/route';
import { ERROR_STATUS_CODE } from '../../constants/common';
import { LABELS } from '../../constants/labels';

const { PRODUCTS, PRESENTS, MIX, BUNDLES, SAPSAN } = PAGES;

const General = () => {
    const { hasProductMechanics, hasPresentsMechanics, hasBundleMechanics, hasMixMechanics, hasSapsanMechanics } =
        useAppSettings();

    useErrorHandler(
        hasProductMechanics || hasPresentsMechanics || hasBundleMechanics || hasMixMechanics || hasSapsanMechanics
            ? undefined
            : { statusCode: ERROR_STATUS_CODE.FORBIDDEN, message: LABELS.ERRORS.COMMON }
    );

    return (
        <Routes>
            <Route
                path={`${PRODUCTS}/*`}
                element={
                    hasSapsanMechanics ? (
                        <Navigate to={`../${SAPSAN}`} replace />
                    ) : hasProductMechanics ? (
                        <ProductsRouter />
                    ) : hasPresentsMechanics ? (
                        <Navigate to={`../${PRESENTS}`} replace />
                    ) : hasBundleMechanics ? (
                        <Navigate to={`../${BUNDLES}`} replace />
                    ) : hasMixMechanics ? (
                        <Navigate to={`../${MIX}`} replace />
                    ) : null
                }
            />
            <Route
                path={`${SAPSAN}/*`}
                element={hasSapsanMechanics ? <ProductsRouter /> : <Navigate to={`../${PRODUCTS}`} replace />}
            />
            <Route
                path={`${PRESENTS}/*`}
                element={hasPresentsMechanics ? <PresentsRouter /> : <Navigate to={`../${PRODUCTS}`} replace />}
            />
            <Route
                path={`${BUNDLES}/*`}
                element={hasBundleMechanics ? <BundlesRouter /> : <Navigate to={`../${PRODUCTS}`} replace />}
            />
            <Route
                path={`${MIX}/*`}
                element={hasMixMechanics ? <MixRouter /> : <Navigate to={`../${PRODUCTS}`} replace />}
            />
            <Route path="*" element={<Navigate to={`../${PRODUCTS}`} replace />} />
        </Routes>
    );
};

export default General;
