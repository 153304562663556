import React from 'react';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import { TextJawSkeleton } from '../../../component/TextJaw';

import styles from '../ClientOffer.module.scss';

const ClientOfferShareSkeleton = () => (
    <div className={styles.content}>
        <Skeleton containerClassName={cn(styles.logo, styles.logoSkeleton)} />
        <Skeleton containerClassName={cn(styles.info, styles.infoSkeleton)} />
        <Skeleton
            containerClassName={styles.bannerSkeletonWrapper}
            className={cn(styles.banner, styles.bannerSkeleton)}
        />
        <Skeleton containerClassName={cn(styles.instructions, styles.instructionsShareSkeleton)} />
        <TextJawSkeleton />
    </div>
);

export default ClientOfferShareSkeleton;
